import React, {useContext, useEffect, useState} from "react";
import {CardHeader, Col, Container, Row, Card} from "reactstrap";
import MatrixChart from "../../components/Charts";
import {AuthContext} from "../../contexts/AuthContext";
import Filter from "../../components/Filters/Filter";
import BadgePillFilter from "../../components/Filters/Badge";

const Chart = () => {

    const {user, isAuthentication} = useContext(AuthContext)
    const [filter, setFilter] = useState({
        states: [],
        inventory_location: [],
    })
    const [params, setParams] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [clearall, setClearAll] = useState(false)



    const handleStates = (e) => {
        if (e == '__all__') {
            let status = []

            let checkbox = document.querySelectorAll('.checkbox_state')
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                status.push({value: checkbox[i].value, label: checkbox[i].dataset.label})
            }
            setFilter({...filter, states: status})
        } else {
            let checkbox = [...new Set(filter.states)]

            if (e.target.checked) {
                checkbox.push({value: e.target.value, label: e.target.dataset.label})
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value)
            }

            setFilter({...filter, states: checkbox})
        }
    }

    const handleInventoryLocation = (e) => {


        if (e == '__all__') {
            let status = []

            let checkbox = document.querySelectorAll('.checkbox_inventory_location')
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                status.push({value: checkbox[i].value, label: checkbox[i].dataset.label})
            }
            setFilter({...filter, inventory_location: status})
        } else {
            let checkbox = [...new Set(filter.inventory_location)]

            if (e.target.checked) {
                checkbox.push({value: e.target.value, label: e.target.dataset.label})
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value)
            }

            setFilter({...filter, inventory_location: checkbox})
        }
    }


    const clearFilter = (prefix) => {
        if (prefix === "state") {
            setFilter({
                ...filter, states: []
            })
        } else if (prefix === "inventory_location") {
            setFilter({
                ...filter, inventory_location: []
            })
        }
        setClearAll(false)
        let checkbox = document.querySelectorAll('.checkbox_' + prefix + ':checked')
        if (checkbox.length > 0) {
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = false;
            }
        }
    }
    const removeBadge = (e) => {

        if (e.component === 'state') {
            let items = filter.states
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, states: items})
        } else if (e.component === 'inventory_location') {
            let items = filter.inventory_location
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, inventory_location: items})
        }
    }
    const handleRefresh = () => {
        setRefresh(!refresh)
    }
    const handleClearAll = () => {

        setClearAll(false)
        setFilter({
            states: [],
            inventory_location: [],
        })
    }

  return (
      <>
        {(isAuthentication && user.isAdmin) &&
            <Container fluid>
                <div className={"pt-7"}>
                    <Row style={{display: "block"}}>
                        <Card style={{backgroundColor: "transparent", borderColor: "transparent"}}>
                            <div className="container-fluid mt--12">
                                <div className="row">
                                    <div className="col-md-10 mx-auto">
                                        <Row style={{marginBottom: "20px"}}>
                                            <Col className={"text-left"}>
                                                        { clearall &&
                                                            <a href={void (0)}
                                                               className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                               onClick={handleClearAll}
                                                               id="clear_all_filters">
                                                                <i className="fas fa-times-circle"></i> Clear All filters
                                                            </a>
                                                        }
                                                    </Col>
                                            <Col className={"text-right"}>
                                            <div className={"d-none d-lg-inline-block mr-2"}>

                                                    <button className="btn btn-neutral btn-sm" onClick={handleRefresh}>
                                                        <i className="fas fa-sync-alt"></i>
                                                    </button>
                                                <Filter
                                                    name={"State"}
                                                    color={"primary"}
                                                    prefix={"state"}
                                                    onChange={ handleStates}
                                                    newparams={ params}
                                                    selected={filter.states}
                                                    url={"/order/filter/state/"}
                                                    onClear={() => clearFilter("state")}
                                                />
                                                <Filter
                                                    name={"Inventory Location"}
                                                    color={"primary"}
                                                    prefix={"inventory_location"}
                                                    onChange={ handleInventoryLocation}
                                                    newparams={ params}
                                                    selected={filter.inventory_location}
                                                    url={"/order/filter/inventory-location/"}
                                                    onClear={() => clearFilter("inventory_location")}
                                                />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{paddingBottom: "20px"}}>
                                            <Col sm="12"><BadgePillFilter data={filter}
                                                      onClick={removeBadge}/></Col>
                                        </Row>

                                        <div className="card bg-default">
                                            <div className="card-header bg-default text-white">Fulfillment states by retailer</div>
                                            <div className="card-body text-center text-white" id="daily-sales-orders">
                                                <MatrixChart
                                                    filter={filter}
                                                    setClearAll={setClearAll}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Row>
                </div>
            </Container>
        }

      </>
  )
}

export default Chart;