import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";

const DeleteRetailerPayoutOverrideModal = ({
    delete_retailer_override_assignee_list,
    openDeleteModal,
    toggle,
    deleteRetailerOverrideState,
    deleteRetailerOverrideSalesChannel,
    deleteRetailerOverrideShippingRate,
    deleteRetailerOverrideFeeType,
    deleteRetailerOverrideMarketingFee,
    deleteRetailerOverrideRetailer,
    deleteRetailerOverrideInventoryLocation,
    deleteRetailerOverrideSKU,
    deleteRetailerOverrideId,
    DeleteRetailerOverride,
    setOpenDeleteModal,
    deleteOrdersShippingValue
}) => {
    return (
        <>
            <Modal style={{ marginTop: "100px" }} isOpen={openDeleteModal} size={"lg"} onExit={toggle} toggle={() => toggle()}>
                <ModalHeader className="bg-primary text-white">
                    <span className="text-white h3">Retailer Payout Overrides</span>
                </ModalHeader>
                <ModalBody>
                    <h3 className="mb-4">
                        Are you sure you want to delete the following {delete_retailer_override_assignee_list.length} mappings:
                    </h3>
                    {delete_retailer_override_assignee_list.length > 0 ? (
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="pl-3 pr-3">
                                        Ship to state
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Variant
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Inventory Location
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Retailer
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Marketing Fee
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Marketing Fee Type
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Shipping Rate
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Order's Shipping
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Sales Channel
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {delete_retailer_override_assignee_list.map(record => (
                                    <tr>
                                        <td className="pl-3 pr-3">{record && record.ship_to_state}</td>
                                        <td className="pl-3 pr-3">{record && record.sku}</td>
                                        <td className="pl-3 pr-3">{record && record.inventory_location}</td>
                                        <td className="pl-3 pr-3">{record && record.retailer}</td>
                                        <td className="pl-3 pr-3">{record && record.marketing_fee}</td>
                                        <td className="pl-3 pr-3">{record && record.fee_type}</td>
                                        <td className="pl-3 pr-3">{record && record.shipping_rate}</td>
                                        <td className="pl-3 pr-3">{record && record.orders_shipping_value}</td>
                                        <td className="pl-3 pr-3">{record && record.sales_channel}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="pl-3 pr-3">
                                        Ship to state
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Variant SKU
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Inventory Location
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Retailer
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Marketing Fee
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Marketing Fee Types
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Shipping Rate
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Order's Shipping
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Sales Channel
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <td className="pl-3 pr-3">{deleteRetailerOverrideState && deleteRetailerOverrideState}</td>
                                <td className="pl-3 pr-3">{deleteRetailerOverrideSKU && deleteRetailerOverrideSKU}</td>
                                <td className="pl-3 pr-3">
                                    {deleteRetailerOverrideInventoryLocation && deleteRetailerOverrideInventoryLocation}
                                </td>
                                <td className="pl-3 pr-3">{deleteRetailerOverrideRetailer && deleteRetailerOverrideRetailer}</td>
                                <td className="pl-3 pr-3">{deleteRetailerOverrideMarketingFee && deleteRetailerOverrideMarketingFee}</td>
                                <td className="pl-3 pr-3">{deleteRetailerOverrideFeeType && deleteRetailerOverrideFeeType}</td>
                                <td className="pl-3 pr-3">{deleteRetailerOverrideShippingRate && deleteRetailerOverrideShippingRate}</td>
                                <td className="pl-3 pr-3">{deleteOrdersShippingValue ? "Yes" : "No"}</td>
                                <td className="pl-3 pr-3">{deleteRetailerOverrideSalesChannel && deleteRetailerOverrideSalesChannel} </td>
                            </tbody>
                        </Table>
                    )}
                </ModalBody>
                <ModalFooter className="mt-0 pt-0">
                    <Button color="secondary" onClick={e => setOpenDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        color="danger"
                        id="delete_btn"
                        onClick={e =>
                            DeleteRetailerOverride(
                                delete_retailer_override_assignee_list.length > 0
                                    ? delete_retailer_override_assignee_list
                                    : deleteRetailerOverrideId
                            )
                        }>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default DeleteRetailerPayoutOverrideModal;
