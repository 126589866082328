import React, { useContext } from "react";
import { Card, Row, Col } from "reactstrap";
import LineChart from "../../../../src/components/Dashboard/Charts/LineChart";
import BarChart from "../../../../src/components/Dashboard/Charts/BarChart";
import { ProductsTable, RetailerPerformanceWidget, SalesChannelTable, } from "../../../../src/components/Dashboard/Table";
import USPageMap from "components/Dashboard/Charts/Maps";
import { AuthContext } from "contexts/AuthContext";

const Charts = ({ filters }) => {
  const isStartDateAndEndDateDifferenceZero = filters.startDate.diff(filters.endDate, 'days') == "0";
  const {user} = useContext(AuthContext);
  return (
    <>
      <Row className="row-cols-md-1">
        <Col className="col-md-8">
          {/** Daily Sales */}
          {!isStartDateAndEndDateDifferenceZero &&
            <Card className="bg-default p-2 p-md-3 pt-2 pt-lg-0 p-lg-4 mb-4">
              <h3 className="text-white pb-4"> {"Daily Sales"}</h3>
              <LineChart url={"/order/react/daily-sales-order/"} filters={filters} />
            </Card>}

          {/** Sales by state */}
          <Card className="bg-default p-2 p-md-3 pt-2 pt-lg-0 p-lg-4 mb-4">
            <h3 className="text-white pb-4"> {"Sales by state"}</h3>
            <USPageMap filters={filters} />
          </Card>
        </Col>

        <Col className="col-md-4">
          {/* Performance - Total Orders */}
          {!isStartDateAndEndDateDifferenceZero &&
            < Card className="bg-neutral p-2 p-md-3 pt-2 pt-lg-0 p-lg-4 mb-4">
              <h5 className="text-uppercase text-muted"> {"Performance"}</h5>
              <h2 className="text-black"> {"Total Orders"}</h2>
              <BarChart url={"/order/react/bars-chart/"} filters={filters} />
            </Card>
          }

          {/* Performance - Transactions by day of the week */}
          {!isStartDateAndEndDateDifferenceZero &&
            <Card className="bg-neutral p-2 p-md-3 pt-2 pt-lg-0 p-lg-4 mb-4">
              <h5 className="text-uppercase text-muted"> {"Performance"}</h5>
              <h2 className="text-black"> {"Transactions by Day of Week"}</h2>
              <BarChart url={"/order/react/sales-week/"} filters={filters} />
            </Card>
          }

          {/* Top products */}
          <Card className="bg-neutral p-2 p-md-3 pt-2 pt-lg-0 p-lg-4 mb-4">
            <ProductsTable filters={filters} />
          </Card>

          {/* Retailer Performance */}
          {user?.isAdmin && 
            <Card className="bg-neutral p-2 p-md-3 pt-2 pt-lg-0 p-lg-4 mb-4">
              <RetailerPerformanceWidget filters={filters} />
            </Card>
          }

          {/* Sales channel */}
          <Card className="bg-neutral p-2 p-md-3 pt-2 pt-lg-0 p-lg-4 mb-4">
            <SalesChannelTable filters={filters} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Charts;
