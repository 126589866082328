import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

function CustomeMultiSelect({ onChange, values, ...props }) {

  const customStyles = {
    control: (base) => ({
      ...base,
      // props.isValid can display red borderColor if field is not selcetd
      borderColor: props.isValid ?
        '#ddd' : 'red',
    })
  };

  /**
   * On change of dropdown valueorder\serializers.py
   * @param {*} options selected options
   */
  const onValueChange = (options) => {
    let selectedOptions = [];

    if (options.length > 0) {
      let lastElement = options[options.length - 1];
      // Check all value is selected
      // If yes remove all other state label otherwise remove select all state label from selected value
      if (lastElement.value === "__all__") {
        // Remove all other selected state label
        selectedOptions = options.filter((option) => option.value == "__all__");
      } else {
        // Remove all state label
        selectedOptions = options.filter((option) => option.value !== "__all__");
      }
    }

    // Call parent on change callback
    onChange(selectedOptions);
  };


  return (
    <CreatableSelect
      onChange={onValueChange}
      value={values}
      styles={customStyles}
      {...props}
    />
  );
}
export default CustomeMultiSelect;