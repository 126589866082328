import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from "chart.js";
import { api } from "services/api";
import Spinner from "../../Spinner";
import { getParams } from "utils/commonFunctions";
import axios from "axios";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

ChartJS.defaults.color = "#FFF";

export const options = {
  responsive: true, maintainAspectRatio: false, scales: { y: { beginAtZero: true, grid: { display: false, }, }, x: { grid: { display: false, }, }, },
  plugins: { legend: { display: false, }, title: { display: false, }, tooltip: { mode: "index", intersect: false, } },
  hover: { mode: "nearest", intersect: false, },
};

export default function LineChart({ url, filters }) {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const cancelToken = React.useRef(undefined);

  useEffect(() => {
    setIsLoading(true);
    let new_params = getParams(filters);
    if (cancelToken.current !== undefined) {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }
    cancelToken.current = axios.CancelToken.source();
    api.get(url, { params: new_params, cancelToken: cancelToken.current.token, })
      .then((response) => {
        if (response.status === 200 && response?.data) {
          setChartData({ labels: response.data?.labels, data: response.data?.subtotal, });
          setIsLoading(false);
        }
      })
      .catch((error) => { console.error(error); });
  }, [filters]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="chart text-white">
          <Line options={options}
            data={{
              type: "line", labels: chartData?.labels,
              datasets: [
                {
                  data: chartData?.data, type: "line", borderColor: "#5e72e4", borderWidth: 3, lineTension: 0.4, backgroundColor: "#5e72e4",
                  color: "#5e72e4", tension: 0.4,
                },
              ],
            }}
          />
        </div>
      )}
    </>
  );
}
