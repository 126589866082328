import React from "react";

const useInventoryLocationHook = (data) => {
    const [inventoryLocation, setInventoryLocation] = React.useState({
        ...data,
        is_auto_assignable: !!data?.is_auto_assignable,
    });
    return [inventoryLocation, setInventoryLocation];
}

export default useInventoryLocationHook;