import React from 'react';
import Select from 'react-select';

const Dropdown = ({ options, onChange, placeholder, zIndex }) => {
  return (
    <Select
      options={options}
      placeholder={placeholder}
      onChange={(option) => onChange(option.value)}
      menuPortalTarget={zIndex ? document.body : undefined}
      styles={zIndex ? { menuPortal: base => ({ ...base, zIndex }) } : undefined}
    />
  );
};

export default Dropdown;