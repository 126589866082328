import React from 'react'
import { Button, Spinner } from 'reactstrap'

const CButton = (props) => {
    return (
        <div className='d-flex'>
            <Button {...props} disabled={props.isLoading}>
                {
                    props.isLoading &&
                    <Spinner className='mr-2' size="sm" color="light" />
                }
                {props.children}
            </Button>
        </div>
    )
}

export default CButton;