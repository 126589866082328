import React from "react";
import { Table } from "reactstrap";
import SubscriptionTableRow from "./SubscriptionTableRow";

const SubscriptionTable = ({ subscriptions, user, brandOptions, handleBrandChange, planOptions, handlePlanChange }) => {
    return (
        <div className="col px-0">
            <Table className="align-items-center table-flush">
                <thead className="thead-light">
                    <tr>
                        <th className="pl-2 pr-2">SUBSCRIPTION ID</th>
                        <th className="pl-2 pr-2">SUBSCRIPTION ITEM ID</th>
                        <th className="pl-2 pr-2">OWNER</th>
                        <th className="pl-2 pr-2">EMAIL</th>
                        <th className="pl-2 pr-2">CREATED AT</th>
                        <th className="pl-2 pr-2">STATUS</th>
                        <th className="pl-2 pr-2" style={{ width: "200px" }}>PLAN</th>
                        <th className="pl-2 pr-2" style={{ width: "300px" }}>BRANDS</th>
                        <th className="pl-2 pr-2">TOTAL BRANDS</th>
                        <th className="pl-2 pr-2">INVOICE AMOUNT</th>
                        <th className="pl-2 pr-2">ITEM AMOUNT</th>
                        <th className="pl-2 pr-2">DISCOUNT AMOUNT</th>
                        <th className="pl-2 pr-2 text-center">NET</th>
                        <th className="pl-2 pr-2">FREQUENCY</th>
                        <th className="pl-2 pr-2">BILLING</th>
                    </tr>
                </thead>
                <tbody>
                    {subscriptions?.map(subscription => (
                        subscription.subscription_items.map(item => (
                            <SubscriptionTableRow 
                                key={item.subscription_item_id}
                                subscription={subscription}
                                planOptions={planOptions}
                                handlePlanChange={handlePlanChange}
                                subscriptionItem={item}
                                user={user}
                                brandOptions={brandOptions}
                                handleBrandChange={handleBrandChange}
                            />
                        ))
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
export default SubscriptionTable;
