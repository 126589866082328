import React, { useContext, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { api } from '../../services/api'
import { AuthContext } from '../../contexts/AuthContext'
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap'

const AssigneeSelect = (props) => {
  const [order, setOrder] = useState([])
  const [value, setValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [assigneeOptions, setAssigneeOptions] = useState([])

  const { addToast } = useToasts()
  useEffect(() => {
    setValue(props.children)
  }, [])

  const getAssigneeOptions = () => {
    setLoading(true)
    api.get(`/order/assignee-options/?order_id=${props.orderId}`).
      then((response) => {
        setAssigneeOptions(response.data)
      }).
      catch((error) => console.log(error)).
      finally(() => setLoading(false))
  }

  const handleAssigneeOrder = (e, idAssignee, assignee_name) => {
    e.preventDefault()
    const data = {
      id_assignee: idAssignee,
      id_orders: [parseInt(props.orderId)],
    }

    setSubmitting(true)
    api.put('order/assignee-states/update/', data).then((response) => {
      setValue(assignee_name)
      setSubmitting(false)
      if (response.status === 200 && response.data?.success) {
        addToast('Saved Successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
        props.updateOrderStatus(props.orderId)
      } else {
        addToast('Something went wrong, please try again!!', {
          appearance: 'error',
        })
      }
    }).catch((error) => {
      setSubmitting(false)
      addToast(error.message, { appearance: 'error' })
    })
    return true
  }

  const { user } = useContext(AuthContext)

  const showAssigneeDropdownItem = (assigneeStates) => {
    return !!assigneeStates.includes(props.stateOrder)
  }

  return (
    <>
      {user && user.isAdmin ? (
        <UncontrolledDropdown size={''}>
          {/* 6193203826 - Block order changes on WMS Orders */}
          <DropdownToggle
            color={'white'}
            className={'text-primary text-left border-primary px-2 py-1'}
            style={{ width: '130px' }}
            onClick={getAssigneeOptions}
            caret
            disabled={props.disabled}
          >
            <span
              className={'d-inline-block text-truncate'}
              style={{ width: '100px', fontSize: 'small' }}
              id={'id_assignee_' + parseInt(props.orderId)}
            >
              {value && value !== '-' ? value : 'Assignee'}
            </span>
            {submitting && <Spinner size="sm" color="primary"/>}
          </DropdownToggle>
          <DropdownMenu
            style={{ maxHeight: '300px', overflow: 'scroll' }}
            positionFixed={true}
            className="more-filters-dropdown-menu"
            right
          >
            {!loading
              ? (assigneeOptions.length
                  ? assigneeOptions.map(
                    (option, index) => (
                      <DropdownItem
                        key={index}
                        onClick={(e) =>
                          handleAssigneeOrder(e, option?.id, option?.name)
                        }
                      >
                        {option?.name}
                      </DropdownItem>
                    ),
                  )
                  : (
                    <DropdownItem disabled={true}>
                      No options
                    </DropdownItem>
                  )
              ) : (
                <DropdownItem
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Spinner size={'sm'} color={'primary'}/>
                </DropdownItem>
              )
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <Button
          color={'white'}
          className={'text-primary text-left border-primary px-2 py-1'}
          style={{
            width: '130px',
            overflow: 'hidden',
            pointerEvents: 'none',
          }}
        >
    <span
      className={'d-inline-block '}
      style={{ width: '100px' }}
      id={'id_assignee_' + props.orderId}
    >
              {value.substring(0, 16)}
            </span>
        </Button>
      )
      }
    </>
  )
}

export default AssigneeSelect
