//**6322413816 - Store Page Migration Shopify Integration Select */
import React, { useContext, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { api } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";

const ShopifyIntegrationSelect = props => {
    const [value, setValue] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shopifyIntegrationOptions, setShopifyIntegrationOptions] = useState([]);

    const { addToast } = useToasts();
    useEffect(() => {
        setValue(props.children);
    }, []);

    //** Fetching Shopify Integration list records */
    const getShopifyIntegrationOptions = () => {
        setLoading(true);
        api.get(`/store/list/shopify-integration-types`)
            .then(response => {
                setShopifyIntegrationOptions(response.data.results);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    };

    /**
     * Handler for PUT request for Shopify integration
     * @param {*} e
     * @param {*} idBillableUser
     * @param {*} shopify_name
     * @returns
     */
    const handleShopifyIntegration = (e, idBillableUser, shopify_name) => {
        e.preventDefault();
        const data = {
            shopify_integration: idBillableUser
        };

        setSubmitting(true);
        api.put(`store/shopify-integration/${props.storeId}/`, data)
            .then(response => {
                setValue(shopify_name);
                setSubmitting(false);
                if (response.status === 200) {
                    addToast("Saved Successfully", {
                        appearance: "success",
                        autoDismiss: true
                    });
                } else {
                    addToast("Something went wrong, please try again!!", {
                        appearance: "error"
                    });
                }
            })
            .catch(error => {
                setSubmitting(false);
                addToast(error.message, { appearance: "error" });
            });
        return true;
    };

    const { user } = useContext(AuthContext);

    return (
        <>
            {user && user.isAdmin ? (
                <UncontrolledDropdown size={""}>
                    <DropdownToggle
                        color={"white"}
                        className={"text-primary text-left border-primary px-2 py-1"}
                        style={{ width: "130px" }}
                        onClick={getShopifyIntegrationOptions}
                        caret>
                        <span
                            className={"d-inline-block text-truncate"}
                            style={{ width: "100px", fontSize: "small" }}
                            id={"id_assignee_" + parseInt(props.storeId)}>
                            {value && value !== "-" ? value : "--------"}
                        </span>
                        {submitting && <Spinner size="sm" color="primary" />}
                    </DropdownToggle>
                    <DropdownMenu style={{ maxHeight: "300px", overflow: "scroll" }} positionFixed={true} className="more-filters-dropdown-menu" right>
                        {!loading ? (
                            shopifyIntegrationOptions.length ? (
                                shopifyIntegrationOptions.map((option, index) => (
                                    <DropdownItem key={index} onClick={e => handleShopifyIntegration(e, option?.id, option?.name)}>
                                        {option?.name}
                                    </DropdownItem>
                                ))
                            ) : (
                                <DropdownItem disabled={true}>No options</DropdownItem>
                            )
                        ) : (
                            <DropdownItem
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                <Spinner size={"sm"} color={"primary"} />
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>
            ) : (
                <Button
                    color={"white"}
                    className={"text-primary text-left border-primary px-2 py-1"}
                    style={{
                        width: "130px",
                        overflow: "hidden",
                        pointerEvents: "none"
                    }}>
                    <span className={"d-inline-block "} style={{ width: "100px" }} id={"id_shopifyintegration_" + props.storeId}>
                        {value?.substring(0, 16)}
                    </span>
                </Button>
            )}
        </>
    );
};

export default ShopifyIntegrationSelect;
