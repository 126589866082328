import React from 'react'

const LegendItem = ({ legandItemColor, legandItemTitle }) => {
    return (
        <div className='d-flex '>
            <span className="legend-square mr-2" style={{ backgroundColor: legandItemColor, height: '17px', width: '25px' }}></span>
            <h5 className="text-white">{legandItemTitle}</h5>
        </div>
    )
}

export default LegendItem;
