import { createContext, useState } from "react";

const ComponentContextData = {
    component: {},
    setComponent: () => {}
}

export const ComponentContext = createContext(ComponentContextData)


export function ComponentProvider({ children }) {

    const [component, setComponent] = useState({});

    const addComponent =  (obj) => {
        setComponent(obj)
    }

    return (
        <ComponentContext.Provider value={{ component, addComponent }}>
            {children}
        </ComponentContext.Provider>
    )
}