import React, { useContext, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import { api } from "services/api";
import { ComponentContext } from "../../contexts/ComponentContext";

export const UpdateOrderModal = (props) => {
  const [modal, setModal] = useState(props.isOpen);
  const [Order, setOrder] = useState(props.order);
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLasttName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [checkZipcodeValidation, setCheckZipcodeValidation] = useState();
  const [checkPhoneNumberValidation, setCheckPhoneNumberValidation] =
    useState();
  const [checkVariant, setCheckVariant] = useState(true);
  const [note, setGiftNote] = useState("");
  const item_sku_list = [];
  const variant_list = [];
  const { addToast } = useToasts();
  const state_dict = {
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };
  const baseURL = window.env.API_URL;

  // If reshipped order, fields should be editable.
  // For other orders, if channel id is 40 and status should be open, then fields should be editable
  const disableCheck = (props.order.channel_id != 40 & props.order.shopify_api_id) || props.order.order_status !== "OPEN";

  const toggle = () => {
    setModal(!modal);
  };
  const checkRetailerVariant = (e) => {
    getVariantShopifyId(e, props.order);
  };

  const checkRetailerVariantViaSku = (e) => {
    setState(e.target.value);
    setCheckVariant(true);
    const url = new URL(baseURL + "retailer-react");
    for (var i = 0; i < variant_list.length; i++) {
      url.searchParams.append("variant_id", variant_list[i]);
    }
    url.searchParams.append("state_code", e.target.value);
    url.searchParams.append("zip_code", zipcode);
    if (variant_list.length === item_sku_list.length) {
      api
        .get(url.href)
        .then((response) => response)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.result.length !== 0) {
              const list = response.data.result.map((result) => {
                return result;
              });
              list.forEach((element) => {
                for (let key in element) {
                  if (element[key][0].type === 0) {
                    setCheckVariant(false);
                    break;
                  }
                }
              });
            }
          } else {
            addToast("Something went wrong, please try again!! ", {
              appearance: "error",
              autoDismiss: true,
            });
            setCheckVariant(false);
            setModal(false);
          }
        })
        .catch((err) => {
          emailNotification(
            "riyam@babylonleads.com",
            "Retailer warehouseList API ",
            err.toString()
          );
          addToast(
            "There was an error updating this order. Please contact riyam@getbarcart.com immediately ",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          setCheckVariant(false);
          setModal(false);
        });
    }
  };

  const emailNotification = (recipient, email_subject, email_body) => {
    api
      .post(`order/internal-email-notificiation/`, {
        recipient: recipient,
        subject: email_subject,
        message: email_body,
      })
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((err) => {});
  };

  const getVariantShopifyId = (e, order) => {
    for (var i = 0; i < order.items.length; i++) {
      item_sku_list.push(order.items[i].sku);
    }
    if (item_sku_list.length === Object.keys(order.items).length) {
      for (var k = 0; k < item_sku_list.length; k++) {
        api
          .get(`order/get-variant-shopify/?sku=${item_sku_list[k]}`)
          .then((response) => response)
          .then((response) => {
            if (response.status === 200) {
              variant_list.push(response.data.variant_shopify_id);
              checkRetailerVariantViaSku(e, variant_list);
            }
          })
          .catch((err) => {});
      }
    }
  };

  const checkZipcodeInput = () => {
    if (zipcode.length < 5 || zipcode.length > 5) {
      setCheckZipcodeValidation(true);
    } else {
      setCheckZipcodeValidation(false);
    }
  };
  const checkPhoneNumberInput = () => {
    if (phone_number.length <= 8 || phone_number.length > 20) {
      setCheckPhoneNumberValidation(true);
    } else {
      setCheckPhoneNumberValidation(false);
    }
  };
  useEffect(() => {
    let options = props.order;
    setOrder(options);
    setCheckVariant(true);
  }, [props.order]);

  useEffect(() => {
    setModal(props.isOpen);
    setCheckVariant(true);
    if (props.isOpen === true) {
      // getVariantShopifyId(props.order)

      api
        .get(`order/update-order-information/?id=${props.order.id}`)
        .then((response) => response)
        .then((response) => {
          if (response.status === 200) {
            setEmail(response.data.queryset[0].email);
            setFirstName(response.data.queryset[0].shipping_name.split(" ")[0]);
            setLasttName(response.data.queryset[0].shipping_name.split(" ")[1]);
            setAddress1(response.data.queryset[0].shipping_address1);
            setAddress2(response.data.queryset[0].shipping_address2);
            setCity(response.data.queryset[0].shipping_city);
            setState(response.data.queryset[0].shipping_state);
            setZipcode(response.data.queryset[0].shipping_zip);
            setPhoneNumber(response.data.queryset[0].shipping_phone);
            setGiftNote(response.data.queryset[0].note);
          } else {
            setModal(false);
          }
        })
        .catch((err) => {
          addToast("Something went wrong, please try again!!", {
            appearance: "error",
            autoDismiss: true,
          });
          setModal(false);
        });
    }
  }, [props.isOpen]);

  const handleUpdateOrderInfo = (e) => {
    e.preventDefault();
    checkZipcodeInput();
    checkPhoneNumberInput();
    if (zipcode.length < 5 || zipcode.length > 5) {
      addToast("Zipcode is required and must be 5 digits", {
        appearance: "error",
        autoDismiss: true,
      });
      setModal(true);
    } else if (phone_number.length <= 8 || phone_number.length > 20) {
      setCheckPhoneNumberValidation(true);
      addToast("Phone number is required and should be valid", {
        appearance: "error",
        autoDismiss: true,
      });
      setModal(true);
    } else if (checkVariant !== true) {
      addToast("No fulfillment options available to this state", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      document.getElementById("save_btn").disabled = true;
      api
        .patch(`order/update-order-information/?id=${props.order.id}`, {
          email: email,
          shipping_name: first_name + " " + last_name,
          shipping_address1: address1,
          shipping_address2: address2,
          shipping_city: city,
          shipping_zip: zipcode,
          shipping_state: state,
          shipping_phone: phone_number,
          note: note,
        })
        .then((response) => response)
        .then((response) => {
          if (response.status === 200) {
            addToast(response.data, {
              appearance: "success",
              autoDismiss: true,
            });
            setModal(false);
          } else {
            addToast(response.data, {
              appearance: "error",
              autoDismiss: true,
            });
            setModal(true);
          }
        })
        .catch((err) => {
          addToast("Something went wrong, please try again!!", {
            appearance: "error",
            autoDismiss: true,
          });
          setModal(true);
        });
    }
  };

  return (
    <>
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-90w"
          onExit={props.onExit}
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <ModalHeader
            id="example-custom-modal-styling-title"
            toggle={toggle}
            className="bg-primary text-white"
          >
            <span className="text-white h3">
              Edit shipping information : {props.order.shopify_id}
            </span>
          </ModalHeader>
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form
                  role="form"
                  method="post"
                  onSubmit={handleUpdateOrderInfo}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Email Address *</Label>
                        <Input
                          id="email"
                          placeholder="name@example.com"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Phone number *</Label>
                        <Input
                          id="phone_number"
                          placeholder="phone number"
                          type="text"
                          value={phone_number}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          invalid={checkPhoneNumberValidation}
                          required
                          disabled={disableCheck}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>First name *</Label>
                        <Input
                          id="first_name"
                          placeholder="first name"
                          type="text"
                          value={first_name}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                          disabled={disableCheck}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Last name *</Label>
                        <Input
                          id="last_name"
                          placeholder="last name"
                          type="text"
                          value={last_name}
                          onChange={(e) => setLasttName(e.target.value)}
                          required
                          disabled={disableCheck}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Address 1 *</Label>
                        <Input
                          id="address1"
                          placeholder="address 1"
                          type="text"
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          required
                          disabled={disableCheck}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Address 2</Label>
                        <Input
                          id="address2"
                          placeholder="address 2"
                          type="text"
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                          disabled={disableCheck}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>City *</Label>
                        <Input
                          id="city"
                          placeholder="city"
                          type="text"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          required
                          disabled={disableCheck}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="">
                        <Label>State *</Label>
                        <br />
                        <select
                          style={{ width: "200px" }}
                          onChange={checkRetailerVariant}
                          className="form-control"
                          required
                          disabled={disableCheck}
                        >
                          <option value={state}>{state_dict[state]} </option>
                          <option disabled>____________________</option>
                          <option
                            value="AK"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Alaska
                          </option>
                          <option
                            value="AZ"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Arizona
                          </option>
                          <option
                            value="AR"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Arkansas
                          </option>
                          <option
                            value="CA"
                            onClick={(e) => setState(e.target.value)}
                          >
                            California
                          </option>
                          <option
                            value="CO"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Colorado
                          </option>
                          <option
                            value="CT"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Connecticut
                          </option>
                          <option
                            value="DE"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Delaware
                          </option>
                          <option
                            value="DC"
                            onClick={(e) => setState(e.target.value)}
                          >
                            District Of Columbia
                          </option>
                          <option
                            value="FL"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Florida
                          </option>
                          <option
                            value="GA"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Georgia
                          </option>
                          <option
                            value="HI"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Hawaii
                          </option>
                          <option
                            value="ID"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Idaho
                          </option>
                          <option
                            value="IL"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Illinois
                          </option>
                          <option
                            value="IN"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Indiana
                          </option>
                          <option
                            value="IA"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Iowa
                          </option>
                          <option
                            value="KS"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Kansas
                          </option>
                          <option
                            value="KY"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Kentucky
                          </option>
                          <option
                            value="LA"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Louisiana
                          </option>
                          <option
                            value="ME"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Maine
                          </option>
                          <option
                            value="MD"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Maryland
                          </option>
                          <option
                            value="MA"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Massachusetts
                          </option>
                          <option
                            value="MI"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Michigan
                          </option>
                          <option
                            value="MN"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Minnesota
                          </option>
                          <option
                            value="MS"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Mississippi
                          </option>
                          <option
                            value="MO"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Missouri
                          </option>
                          <option
                            value="MT"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Montana
                          </option>
                          <option
                            value="NE"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Nebraska
                          </option>
                          <option
                            value="NV"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Nevada
                          </option>
                          <option
                            value="NH"
                            onClick={(e) => setState(e.target.value)}
                          >
                            New Hampshire
                          </option>
                          <option
                            value="NJ"
                            onClick={(e) => setState(e.target.value)}
                          >
                            New Jersey
                          </option>
                          <option
                            value="NM"
                            onClick={(e) => setState(e.target.value)}
                          >
                            New Mexico
                          </option>
                          <option
                            value="NY"
                            onClick={(e) => setState(e.target.value)}
                          >
                            New York
                          </option>
                          <option
                            value="NC"
                            onClick={(e) => setState(e.target.value)}
                          >
                            North Carolina
                          </option>
                          <option
                            value="ND"
                            onClick={(e) => setState(e.target.value)}
                          >
                            North Dakota
                          </option>
                          <option
                            value="OH"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Ohio
                          </option>
                          <option
                            value="OK"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Oklahoma
                          </option>
                          <option
                            value="OR"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Oregon
                          </option>
                          <option
                            value="PA"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Pennsylvania
                          </option>
                          <option
                            value="RI"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Rhode Island
                          </option>
                          <option
                            value="SC"
                            onClick={(e) => setState(e.target.value)}
                          >
                            South Carolina
                          </option>
                          <option
                            value="SD"
                            onClick={(e) => setState(e.target.value)}
                          >
                            South Dakota
                          </option>
                          <option
                            value="TN"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Tennessee
                          </option>
                          <option
                            value="TX"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Texas
                          </option>
                          <option
                            value="UT"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Utah
                          </option>
                          <option
                            value="VT"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Vermont
                          </option>
                          <option
                            value="VA"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Virginia
                          </option>
                          <option
                            value="WA"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Washington
                          </option>
                          <option
                            value="WV"
                            onClick={(e) => setState(e.target.value)}
                          >
                            West Virginia
                          </option>
                          <option
                            value="WI"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Wisconsin
                          </option>
                          <option
                            value="WY"
                            onClick={(e) => setState(e.target.value)}
                          >
                            Wyoming
                          </option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Zipcode *</Label>
                        <Input
                          id="zipcode"
                          placeholder="zipcode"
                          type="text"
                          value={zipcode}
                          onChange={(e) => setZipcode(e.target.value)}
                          invalid={checkZipcodeValidation}
                          required
                          disabled={disableCheck}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Gift Note</Label>
                        <Input
                          id="note"
                          placeholder="Enter the gift note"
                          type="note"
                          value={note}
                          onChange={(e) => setGiftNote(e.target.value)}
                          required
                          disabled={disableCheck}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      {checkVariant === false && (
                        <small className="text-center" style={{ color: "red" }}>
                          No fulfillment options available to this state
                        </small>
                      )}
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="submit"
                      id="save_btn"
                      disabled={!checkVariant}
                      onClick={handleUpdateOrderInfo}
                    >
                      Save changes
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      )}
    </>
  );
};
