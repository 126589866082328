import MoreFilters from 'components/Filters/MoreFilters'
import { AuthContext } from 'contexts/AuthContext';
import React, { useContext } from 'react'
import { removeURLParam, addURLParam } from 'utils/urls';
import { prefixAndFilterMapping } from '../../../customHooks/useFilter'
import { handleDateRange } from '../../../utils/commonFunctions';

const DashboardMoreFilters = ({ filter, setFilter }) => {

    const { user } = useContext(AuthContext);

    const clearFilter = (filterState) => {
        let currentFilter = filter;
        currentFilter[prefixAndFilterMapping[filterState]] = [];
        setFilter({ ...currentFilter });
        removeURLParam(window, prefixAndFilterMapping[filterState]);
    };

    const filterOnChangeHandler = (e, prefix) => {
        // Is user select "Select All"
        if (e === "__all__") {
            let filteredRecords = [];
            let checkbox = document.querySelectorAll(`.checkbox_${prefix}`);
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                filteredRecords.push({ value: checkbox[i].value, label: checkbox[i].dataset.label, });
                addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
            }
            let updatedFilter = { ...filter };
            updatedFilter[prefixAndFilterMapping[prefix]] = filteredRecords;
            setFilter(updatedFilter);
        } else {
            prefix = prefixAndFilterMapping[prefix];
            let filteredRecords = [...new Set(filter[`${prefix}`])];
            if (e.target.checked) {
                // If user ticks checkbox
                filteredRecords.push({ value: e.target.value, label: e.target.dataset.label });
                addURLParam(window, prefix, e.target.value);
            } 
            else {
                // If user un-ticks checkbox
                filteredRecords = filteredRecords.filter(item => item.value !== e.target.value);
                removeURLParam(window, prefix, e.target.value);
            }
            let updatedFilter = { ...filter };
            updatedFilter[prefix] = filteredRecords;
            setFilter(updatedFilter);
        }
    };

    return (
        <>
            <MoreFilters
                backgroundColor="secondary"
                textColor="text-primary"
                user={user}
                filter={filter}
                onChange={{
                    assignee: (e) => filterOnChangeHandler(e, "assignee"),
                    fulfillment: (e) => filterOnChangeHandler(e, "fulfillment"),
                    state: (e) => filterOnChangeHandler(e, "state"),
                    productTypes: (e) => filterOnChangeHandler(e, "product_type"),
                    product: (e) => filterOnChangeHandler(e, "product"),
                    brand: (e) => filterOnChangeHandler(e, "brand"),
                    dateRange: (startDate, endDate) => handleDateRange(startDate, endDate, setFilter, filter),
                    utm: (e) => filterOnChangeHandler(e, "utm"),
                    utmMedium: (e) => filterOnChangeHandler(e, "utm_medium"),
                    utmCampaign: (e) => filterOnChangeHandler(e, "utm_campaign"),
                    discountCode: (e) => filterOnChangeHandler(e, "discount_code"),
                    channel: (e) => filterOnChangeHandler(e, "sales_channel"),
                    shippingRate: (e) => filterOnChangeHandler(e, "shippingRate"),
                    productCategory: (e) => filterOnChangeHandler(e, "productCategory"),
                }}
                onClear={{
                    assignee: () => clearFilter("assignee"),
                    fulfillment: () => clearFilter("fulfillment"),
                    state: () => clearFilter("state"),
                    productTypes: () => clearFilter("product_type"),
                    utm: () => clearFilter("utm"),
                    utm_medium: () => clearFilter("utm_medium"),
                    utm_campaign: () => clearFilter("utm_campaign"),
                    discount_code: () => clearFilter("discount_code"),
                    channel: () => clearFilter("sales_channel"),
                    shippingRate: () => clearFilter("shippingRate"),
                    productCategory: () => clearFilter("productCategory"),
                }}
                // Hide following filters on desktop and show them on mobile
                moreFiltersOnDesktop={{ 
                    assignee : { hide : true },
                    product : { hide : true },
                    brand : { hide : true },
                    dateRange : { hide : true },
                }}
                clearFilter = {clearFilter}
            />
        </>
    )
}

export default DashboardMoreFilters;
