import React from "react";
import { Table } from "reactstrap";
import { Sort, handleSort } from "../../utils/commonFunctions";
import ChildOrderTableRow from "./ChildOrderTableRow";

const ChildOrderTable = ({
    childOrderData,
    sort,
    setSort,
    setSortOrder,
    sortOrder,
    user
}) => {
    return (
        <div className="col p-0">
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                        <th scope="col" className="pl-3 pr-3">
                            Batch ID
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            Parent Order
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort
                                onClick={() => handleSort(sort, "order__created_at", setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="order__created_at">
                                Existing Child Orders
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "created_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="created_at">
                                Child Order Type
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "code", setSortOrder, setSort)} sortingOrder={sortOrder} column="code">
                                New Child Order
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            Parent Order Line Item Selected
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            Child Order Line Item(s) Selected
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "order__subtotal", setSortOrder, setSort)} sortingOrder={sortOrder} column="order__subtotal">
                                Batch Status
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "order__source", setSortOrder, setSort)} sortingOrder={sortOrder} column="order__source">
                                Submitted User
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            Date Submitted
                        </th>
                        {user?.isAdmin && (
                            <th scope="col" className="pl-3 pr-3">
                                Billable User
                            </th>
                        )}
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "billing_status", setSortOrder, setSort)} sortingOrder={sortOrder} column="billing_status">
                                Error Message
                            </Sort>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {childOrderData?.map(childOrder => (
                        <ChildOrderTableRow key={childOrder?.id} chargeback={childOrder} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ChildOrderTable;
