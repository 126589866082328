import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { api } from "services/api";
import { getParams } from "utils/commonFunctions";
import Spinner from "../Spinner";
import axios from "axios";

export const CardComponent = ({ title, name, url, icon, backgroundColor, info, filters, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState();
  const cancelToken = React.useRef(undefined);

  useEffect(() => {
    setIsLoading(true);
    const new_params = getParams(filters);
    if (cancelToken.current !== undefined) {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }
    cancelToken.current = axios.CancelToken.source();
    api.get(url, { params: new_params, cancelToken: cancelToken.current.token, })
      .then((response) => {
        let data = null;
        if (response.status === 200 && response?.data) {
          data = title == "Sales" || title == "Average Order" ? `$${response?.data[name]}` : response?.data[name];
        }
        setValue(data);
        setIsLoading(false);
      })
      .catch((error) => { console.error(error); });
  }, [filters]);

  return (
    <Card style={{ margin: 0, padding: 0 }} onClick={onClick} role={onClick && "button"}>
      <CardBody style={{ padding: "1rem 1rem" }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Row>
            <Col className="col-xs-10 col-8">
              { (title !== "") ?
              <>
              <h5 className="text-uppercase text-muted mb-0"> {title} </h5>
              <span className="h2" style={{ fontWeight: "600", fontSize: "1.25rem", }}>{value}</span>
              </>
              : 
              <>
              <h5 className="text-uppercase text-muted mb-0"> Orders <i className="fas fa-arrow-right"></i></h5>
              <span className="h2" style={{ fontWeight: "600", fontSize: "1.25rem", }}>{value}</span>
              </>
              }
            </Col>
            <Col className="col-xs-10 col-4 text-right">
              <div className={`icon icon-shape bg-gradient-${backgroundColor} text-white rounded-circle shadow`}>
                <i className={icon}></i>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};
