import React from "react";

const ParentOrderLineItems = ({ parentOrdersData, selectedOrder, onSelectParentOrderLineItem }) => {
    const handleCheckboxChange = orderId => {
        // This is used to handle multiple parent order line items
        // if (selectedOrders.includes(orderId)) {
        //   // If the order is already selected, remove it from the array
        //   setSelectedOrders(selectedOrders.filter(id => id !== orderId));
        // } else {
        //   // If the order is not selected, add it to the array
        //   setSelectedOrders([...selectedOrders, orderId]);
        // }
        // setSelectedOrders((prevSelectedOrder) => (prevSelectedOrder === orderId ? null : orderId));

        onSelectParentOrderLineItem(selectedOrder === orderId ? null : orderId);
    };

    return (
        <div className="mx-20px p-10px" style={{ border: "1px solid #d3d3d3" }}>
            {parentOrdersData.map(order => (
                <div key={order.id}>
                    <input
                        type="radio"
                        id={order.id}
                        checked={selectedOrder === order.id}
                        onChange={() => handleCheckboxChange(order.id)}
                        style={{ marginRight: "5px" }}
                    />
                    <label htmlFor={order.id}>{order.name}</label>
                </div>
            ))}
        </div>
    );
};

export default ParentOrderLineItems;
