import React from "react";
import { Input } from "reactstrap";

const CustomInput = (props) => {
    return (
        <>
            <Input {...props} />
            {props.errors && <small className='text-muted text-red'>{props.errors}</small >}
        </>
    );
};

export default CustomInput;