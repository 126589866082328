/*

  Author - Akshay Vishwakarma
  
  Description - RoleBasedRoute handles routing based on user roles. It checks if the user is a 'wmsRetailer'.
  If true, it redirects the user to the react's profile page. Otherwise, it allows the rendering of child components.
  Utilizes AuthContext to get user information and manage routing behavior dynamically.

 */

import { useEffect, useContext, useState } from 'react';
import { AuthContext } from 'contexts/AuthContext';

const RoleBasedRoute = ({ children }) => {
    const { user } = useContext(AuthContext);
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        if (user) {
            if (user.isWmsRetailer) {
                // Monday ID: 6850014751 - redirect to Reacts profile page
                window.location.href = 'profile';
            } else {
                // Only render children if the user is not a wms_retailer
                setShouldRender(true);
            }
        }
    }, [user]);

    return (
        <>
            {
                shouldRender && children
            }
        </>

    )
};

export default RoleBasedRoute;
