/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to section three for product Details.
              - In this all variant are displayed along with their details
  
 */

import React, { useContext } from "react";
import styles from "./viewProduct.module.css";
import { AuthContext } from "contexts/AuthContext";

export default function ProdDetailsSectionThree({ productDetails }) {
    const { user } = useContext(AuthContext);
    const placeholderImage = "https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png";
    return (
        <div className={styles.sectionConatiner}>
            <div>
                <h2>Variants</h2>
                <hr />
            </div>
            {productDetails.product.product_variants.length > 0 &&
                productDetails.product.product_variants.map((prod_variant, index) => (
                    <div key={index}>
                        {/* <div style={{ marginBottom: "1rem" }}>{`#Variant-${index + 1}`}</div> */}
                        <div style={{ display: "flex" }}>
                            <div
                                style={{
                                    padding: "10px",
                                    maxHeight: "150px",
                                    maxWidth: "150px",
                                    backgroundColor: "#f8f9fe",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <img
                                    src={productDetails.product.product_images.length > 0 ? prod_variant.product_images !== "" ? prod_variant.product_images : placeholderImage : placeholderImage}
                                    alt="previewIage"
                                    width={150}
                                    style={{ objectFit: "contain" }}
                                />
                            </div>
                            <div style={{ flexGrow: 1, paddingLeft: "10px" }}>
                                <div className="row">
                                    <div className="col">
                                        <h3>Option</h3>
                                        <p>{prod_variant.option ?? "None"}</p>
                                    </div>
                                    <div className="col">
                                        <h3>Online SRP</h3>
                                        <p>$ {prod_variant.online_srp ?? "None"}</p>
                                    </div>
                                    {/* 6943725569 - Added weight field on variant level */}
                                    {user && user.isAdmin && (
                                        <div className="col">
                                            <h3>Weight (lbs)</h3>
                                            <p>{prod_variant.weight ?? "-"}</p>
                                        </div>
                                    )}
                                    <div className="col">
                                        <h3>UPC</h3>
                                        <p>{prod_variant.upc ?? "-"}</p>
                                    </div>
                                    <div className="col">
                                        <h3>Sku</h3>
                                        <p>{prod_variant.sku ?? "None"}</p>
                                    </div>
                                    <div className="col">
                                        <h3>Available QTY</h3>
                                        <p>{prod_variant.available_qty ?? "-"}</p>
                                    </div>
                                    {/* <div className="col">
                                        <h3>Engravable</h3>
                                        <p>{prod_variant?.is_engravable ? "True":"False"}</p>
                                    </div>
                                    <div className="col">
                                        <h3>Fulfillable</h3>
                                        <p>{prod_variant?.is_fulfillable ? "True":"False"}</p>
                                    </div> */}
                                    {/* Monday Task ID: 7357465749 - Display variant id in product details page */}
                                    <div className="col">
                                        <h3>Variant ID</h3>
                                        <p>{prod_variant?.id ?? "-"}</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h3>Cost To Retailer By State</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h3>States</h3>
                                        </div>
                                        <div className="col">
                                            <h3>Cost</h3>
                                        </div>
                                        <div className="col">
                                            <h3>Qty Limit</h3>
                                        </div>
                                        <div className="col">
                                            <h3>Distributor</h3>
                                        </div>
                                    </div>
                                    {prod_variant.cost_per_item.map((data, dindex) => (
                                        <div className="row" key={dindex}>
                                            <div
                                                className="col"
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    wordWrap: "break-word",
                                                    maxWidth: "100%"
                                                }}>
                                                {data.state_details && data.state_details.map((state, index) => <span key={state.id}>{state.name}{index !== data?.state_details?.length - 1 && ","}&nbsp;</span>)}
                                            </div>
                                            <div className="col">
                                                <p>{data.price ?? "-"}</p>
                                            </div>
                                            <div className="col">
                                                <p>{data.quantity ?? "-"}</p>
                                            </div>
                                            <div className="col">
                                                <p>{data.distributor_details ? data.distributor_details.name : "-"}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                ))}
        </div>
    );
}
