import axios from 'axios';
import { SignOut } from 'contexts/AuthContext';
import { getRefreshToken, getToken, setRefreshToken, setToken } from './auth';

export const api = axios.create({
    baseURL: window.env.API_URL
})

let isRefreshing = false;
let failedRequestsQueue = []


api.interceptors.request.use(async config => {
    const token = getToken();
    console.log(window.env.API_URL)

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

api.interceptors.response.use(response=>{ 
    return response;
}, error => {
    if (error.response.status === 401){

        if (error.response.config.url === "refresh/"){
            console.log("error", error.response)
            window.location.href = window.env.APP_URL
        }
        if (error.response.data?.code === "token_not_valid"){
            
            const refresh =  getRefreshToken()

            const orginalConfig = error.config

            
            if (!isRefreshing){
                isRefreshing = true;

                api.post("refresh/", {
                    refresh
                }).then(response => {
                    const {access} = response.data
                    setToken(access)
                    setRefreshToken(refresh)

                    api.defaults.headers["Authorization"] = `Bearer ${access}`

                    failedRequestsQueue.forEach(request => request.onSuccess(access))
                    failedRequestsQueue = []

                }).catch(err => {
                    failedRequestsQueue.forEach(request => request.onFailure(err))
                    failedRequestsQueue = []
                }).finally(() => {
                    isRefreshing = false;
                })
            }

            return new Promise((resolve, reject) => {
                failedRequestsQueue.push({
                    onSuccess: (token) => {
                        orginalConfig.headers["Authorization"] = `Bearer ${token}`
                        resolve(api(orginalConfig))
                    },
                    onFailure: (err) => {
                        reject(err)
                    } 
                })
            })
        }else{
            // logout
            console.log("##### LOGOUT #####")
            SignOut();
        }
    }
    
    return Promise.reject(error);
})


