import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

const CustomCreatableSelect = (props) => {
    // Usestates
    const [options, setOptions] = useState(props.options);
    const [value, setValue] = useState(props.value);

    // Functions
    const createOption = (label) => ({ label, value: label, });
    const handleCreate = (inputValue) => {
        inputValue && props.onChange(inputValue)
        const newOption = createOption(inputValue);
        setOptions((prev) => [...prev, newOption]);
        setValue(newOption);
    };

    return (
        <>
            <CreatableSelect
                isClearable
                onChange={(newValue) => {
                    if (newValue) {
                        newValue.value && props.onChange(newValue.value);
                        setValue(newValue);
                    }
                }}
                onCreateOption={handleCreate}
                options={options}
                value={value}
            />
            {props?.errors && <small className='text-muted text-red'>{props.errors}</small >}
        </>
    )
}

export default CustomCreatableSelect;