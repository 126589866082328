import {useToasts} from "react-toast-notifications";
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import React, {useContext, useEffect, useState} from "react";
import {api} from "../../services/api";
import Select from "react-select";
import {ComponentContext} from "../../contexts/ComponentContext";
import list from "../../views/product/list";
import {AuthContext} from "../../contexts/AuthContext";


export const  SubmitShopifyModalForm = props => {

    const [title, setTitle] = useState(props.title)
    const [url, setUrl] = useState(props.url)
    const [approvalUrl, setApprovalUrl] = useState(props.approval_url)

    const [modal, setModal] = useState(props.isOpen);
    const [valid, setValid] = useState(false)

    const [products, setProducts] = useState([])

    const [listProducts, setListProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [names, setNames] = useState([])

    const [ids, setIds] = useState([])
    const {addToast} = useToasts();
    const {addComponent} = useContext(ComponentContext)

    const toggle = () => {
        setModal(!modal)
    };
    const [paramsFilter, setParamsFilter] = useState('?')

    useEffect(() => {
        setModal(props.isOpen)
        let str = '?'
        props?.products.map((item, index) => {
            str = str + 'id=' + item.id + '&'
        })
        setParamsFilter(str)
    }, [props.isOpen])

    useEffect(() => {
        url ?
        api.get(`${approvalUrl}${paramsFilter}`)
            .then(response=> {
                setProducts(response.data.products)
                setValid(response.data.valid > 0)
                setIds(response.data.ids)
                setNames(response.data.product_names)
            })
            .catch(error=> console.log(error)): void (0)
    }, [paramsFilter])

    const handleSubmit = (e) => {
        let data = {
            products: ids
        }
        setLoading(true)
        url ?
        api.put(url, data)
                .then(response => {
                setModal(false)
                addToast('Saved Successfully', {appearance: 'success', autoDismiss: true});
                setLoading(false)
                addComponent({SubmitShopifyModalForm: data})
            }).catch(error => addToast(error.message, {appearance: 'error'})) : void(0)
    }


    return (
        <>
            {modal &&
            <Modal isOpen={modal} toggle={toggle} onExit={props.onExit} style={{paddingTop: 200}}>
                <Form onSubmit={handleSubmit} >
                    <ModalHeader toggle={toggle} className="">
                        <h5 className="modal-title">{title}</h5>
                    </ModalHeader>
                    {
                        valid ?
                            <ModalBody>
                        <FormGroup>
                            <ModalBody>
                        <FormGroup>
                            {names.map(function(name, index){
                    return <>
                    <div  className="input-group input-group-alternative mb-1" style={{backgroundColor: "#eee"}}>
                        <input type="text"
                               className="form-control-plaintext text-sm p-3"
                               value={name}
                               readOnly/>
                            </div>
                    </>

                  })}
                        </FormGroup>
                    </ModalBody>
                        </FormGroup>
                    </ModalBody> :

                            <>
                                <div className="alert alert-warning text-center">
                                    No Applicable Products
                                </div>
                            </>
                        }

                    <ModalFooter>
                        {
                            valid ?
                        <>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                            <Button color="primary" type="submit" disabled={!valid}
                            >Change { loading && <Spinner size="sm" color="light" />}</Button>
                        </> :
                                <Button color="secondary" onClick={toggle}>Cancel</Button>
                            }

                    </ModalFooter>
                </Form>
            </Modal>}
        </>
    )
}


export const  ArchiveDeleteModalForm = props => {

    const [url, setUrl] = useState(props.url)


    const [modal, setModal] = useState(props.isOpen);


    const [products, setProducts] = useState(props.products)

    const [loading, setLoading] = useState(false)

    const [names, setNames] = useState([])

    const [ids, setIds] = useState([])
    const {addToast} = useToasts();
    const {addComponent} = useContext(ComponentContext)



    const toggle = () => {
        setModal(!modal)
    };
    const [paramsFilter, setParamsFilter] = useState('?')

    useEffect(() => {
        setModal(props.isOpen)
    }, [props.isOpen])

    useEffect(() => {
        setProducts(props.products)

    }, [props.products])


    const handleSubmit = (e) => {




        setLoading(true)
        let ids = products.map(item => {return item.id})
        let data = {
            products: ids
        }

        api.put(url, data)
                .then(response => {
                props.onClick()
                setModal(false)
                addToast('Saved Successfully', {appearance: 'success', autoDismiss: true});
                setLoading(false)

                addComponent({ArchiveDeleteModalForm: {}})
            }).catch(error => addToast(error.message, {appearance: 'error'}))
    }


    return (
        <>
            {modal &&
            <Modal isOpen={modal} toggle={toggle} onExit={props.onExit} style={{paddingTop: 200}}>
                <Form onSubmit={handleSubmit} >
                    <ModalHeader toggle={toggle}>
                        <h4 className="modal-title">{props.title}</h4>
                    </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <ModalBody>
                                    <FormGroup>
                                        {products.length > 0 && products.map(function(name, index){
                                            return (
                                                <div key={index} className="input-group input-group-alternative mb-1" style={{backgroundColor: "#eee"}}>
                                                    <input type="text"
                                                           className="form-control-plaintext text-sm p-3"
                                                           value={name.title}
                                                           style={{ width: "100%"}}
                                                           readOnly/>
                                                </div>
                                            )
                                        })}
                                    </FormGroup>
                                </ModalBody>
                            </FormGroup>
                        </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            {props.title} { loading && <Spinner size="sm" color="light" />}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>}
        </>
    )
}


export const DeleteModalForm = props => {

    const [url, setUrl] = useState(props.url)


    const [modal, setModal] = useState(props.isOpen);


    const [products, setProducts] = useState(props.products)

    const [loading, setLoading] = useState(false)

    const [names, setNames] = useState([])

    const [ids, setIds] = useState([])
    const {addToast} = useToasts();
    const {addComponent} = useContext(ComponentContext)



    const toggle = () => {
        setModal(!modal)
    };
    const [paramsFilter, setParamsFilter] = useState('?')

    useEffect(() => {
        setModal(props.isOpen)
    }, [props.isOpen])


    const handleSubmit = (e) => {

        setLoading(true)
        let ids = props.products.map(item => {return item.id})
        let data = {
            products: ids
        }

        api.put(url, data)
                .then(response => {
                props.onClick()
                setModal(false)
                addToast('Saved Successfully', {appearance: 'success', autoDismiss: true});
                setLoading(false)

                addComponent({ArchiveDeleteModalForm: {}})
            }).catch(error => addToast(error.message, {appearance: 'error'}))
    }


    return (
        <>
            {modal &&
            <Modal isOpen={modal} toggle={toggle} onExit={props.onExit} style={{paddingTop: 200}}>
                <Form onSubmit={handleSubmit} >
                    <ModalHeader toggle={toggle} className="">
                        <h5 className="modal-title">{props.title}</h5>
                    </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <ModalBody>
                                    <FormGroup>
                                        {props.products.map(function(name, index){
                                            return (
                                            <>
                                                <div  className="input-group input-group-alternative mb-1" style={{backgroundColor: "#eee"}}>
                                                    <input type="text"
                                                           className="form-control-plaintext text-sm p-3"
                                                           value={name.title}
                                                           style={{ width: "100%"}}
                                                           readOnly/>
                                                </div>
                                            </>
                                            )
                                        })}
                                    </FormGroup>
                                </ModalBody>
                            </FormGroup>
                        </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            {props.title} { loading && <Spinner size="sm" color="light" />}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>}
        </>
    )
}

export const ProductStatus = (props) => {

    let currentValue = props.children || 'IMAGE';

    const [value, setValue] = useState('')
    const [statusColor, setStatusColor] = useState('')

    const {addToast} = useToasts();

    useEffect(() => {
        setValue(currentValue)
    }, [])

    useEffect(() => {
        let color = '';
        if (value === "READY") {
            color = "border-success text-success"
        } else if (value === "WAITING ON BRAND") {
            color = "border-danger text-danger"
        } else if (value === "MISSING INFORMATION") {
            color = "border-danger text-danger"
        } else if (value === "PRICING INCORRECT") {
            color = "border-warning text-danger"
        } else if (value === "IMAGE ISSUE") {
            color = "border-danger text-danger"
        } else if (value === "NEW") {
            color = "border-primary text-primary"
        }
        setStatusColor(color);
    }, [value])

    const {user} = useContext(AuthContext);
    const handleStatusProduct = (e) => {
        const data = {
            product_status:  e.target.value,
            products: [parseInt(props.product)]
        }

        api.put("product/product-status/update/", data)
            .then(response => {
                const {product_status} = response.data;
                setValue(product_status)
                addToast('Saved Successfully', {appearance: 'success', autoDismiss: true});
            }).catch(error => addToast(error.message, {appearance: 'error'}));

    }

    return (
        <>
            {user.isAdmin ?
                <select name="product_status"
                        className={`form-control form-control-sm mx-1 ${statusColor}`}
                        onChange={(e) => handleStatusProduct(e)}
                        style={{width: '10rem'}}
                        value={value}
                >
                    {props.data?.map(status =>
                        <option
                            key={status.id}
                            value={status.id}
                        >{status.name}</option>
                    )}
                </select>
                :
                <span className={statusColor}>{props.children}</span>
            }
        </>
    )
}


export const ConfirmIndividualActionForm = (props) => {
    const [url, setUrl] = useState(props.url)


    const [modal, setModal] = useState(props.isOpen);


    const [product, setProduct] = useState(props.product)

    const [loading, setLoading] = useState(false)


    const [ids, setIds] = useState([])
    const {addToast} = useToasts();
    const {addComponent} = useContext(ComponentContext)



    const toggle = () => {
        setModal(!modal)
    };
    const [paramsFilter, setParamsFilter] = useState('?')

    useEffect(() => {
        setModal(props.isOpen)
    }, [props.isOpen])


    useEffect(() => {
        setProduct(props.product)
    }, [props.product])



    const handleSubmit = (e) => {

        setLoading(true)
        let data = {
            products: [product.id]
        }

        api.put(url, data)
                .then(response => {
                props.onClick()
                setModal(false)
                addToast('Saved Successfully', {appearance: 'success', autoDismiss: true});
                setLoading(false)

                addComponent({ArchiveDeleteModalForm: {}})
            }).catch(error => addToast(error.message, {appearance: 'error'}))
    }


    return (
        <>
            {modal &&
            <Modal isOpen={modal} toggle={toggle} onExit={props.onExit} style={{paddingTop: 200}}>
                <Form onSubmit={handleSubmit} >
                    <ModalHeader toggle={toggle} className="">
                        <h5 className="modal-title">Confirm {props.title}</h5>
                    </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <ModalBody>
                                    <FormGroup>
                                        <div  className="input-group input-group-alternative mb-1" style={{backgroundColor: "#eee"}}>
                                            <input type="text"
                                                   className="form-control-plaintext text-sm p-3"
                                                   value={product.name}
                                                   style={{ width: "100%"}}
                                                   readOnly/>
                                        </div>
                                    </FormGroup>
                                </ModalBody>
                            </FormGroup>
                        </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            {props.title} { loading && <Spinner size="sm" color="light" />}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>}
        </>
    )
}