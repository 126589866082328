import React, { useState, useEffect, useContext } from 'react'
import { api } from '../../services/api'
import { useToasts } from 'react-toast-notifications'
import {
  Modal,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup, Label,
  Input,
  Spinner, Collapse
} from 'reactstrap'
import { ComponentContext } from '../../contexts/ComponentContext'
import PropTypes from 'prop-types'

const PayoutStatusModal = ({ open, setOpen, payouts, onExit, onComplete }) => {
  const [loading, setLoading] = useState(false)

  const [payoutStatus, setPayoutStatus] = useState('')
  const [transferId, setTransferId] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [stripeAccounts, setStripeAccounts] = useState([])
  const [blockStatusUpdate, setBlockStatusUpdate] = useState(false)

  const { addToast } = useToasts()
  const { addComponent } = useContext(ComponentContext)

  useEffect(() => {
    let blocked = false
    let assignees = []
    for (let i = 0; i < payouts.length; i++) {
      assignees.push(payouts[i].assignee)
      if (payouts[i].status === 'paid' || [...new Set(assignees)].length > 1 || payouts[i].status === "void" || payouts[i].status === "Void") {
        blocked = true
        break
      }
    }
    setBlockStatusUpdate(!!blocked)
  }, [payouts])

  useEffect(() => {
    api.get('order/payout/status/').then(response => {
      setStripeAccounts(response?.data?.accounts)
    }).catch(reason => console.log(reason))
  }, [])

  const toggle = () => {
    setOpen(!open)
  }

  /**
   * On click of submit call the API to update chargeback order status
   * @param {*} e
   * @returns
   */
  const handleUpdateStatus = (e) => {
    e.preventDefault()
    setLoading(true)

    let payoutIds = payouts.map((payouts) => payouts.id)

    const data = {
      payout_ids: payoutIds,
      payout_status: payoutStatus,
      transfer_id: transferId,
      payment_id: paymentId,
    }

    api.put('order/payout/status/', data).then(response => {
      toggle()
      addToast(response?.data?.message, { appearance: 'success', autoDismiss: true, })
      addComponent({ statusChanged: true })

      setPayoutStatus('')
      setTransferId('')
      setPaymentId('')

      if (onComplete) {
        onComplete()
      }
      setLoading(false)
    }).catch(error => addToast(error.message,
      { appearance: 'error', autoDismiss: true }
    )).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      {open &&
        <Modal size="md" isOpen={open} toggle={toggle} onExit={onExit} centered={true} unmountOnClose={true}>
          <Form onSubmit={handleUpdateStatus}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">Update Payout Status</span>
            </ModalHeader>
            <ModalBody>
              {!!blockStatusUpdate &&
                <div className={'alert alert-danger'} style={{ marginBottom: '2rem' }}>
                  {'Two or more payouts have a different assignee or have already been paid or have the status as Void'}
                </div>}
              <FormGroup>
                <Label for="payoutStatus">Payout Status *</Label>
                <Input
                  id="payoutStatus"
                  placeholder="Select Status"
                  type="select"
                  defaultValue={payoutStatus}
                  onChange={(e) => setPayoutStatus(e.target.value)}
                  disabled={!!blockStatusUpdate}
                  required>
                  <option value="">------</option>
                  <option value={'unpaid'}>Unpaid</option>
                  <option value={'pending'}>Pending</option>
                  <option value={'paid'}>Paid</option>
                  <option value={'void'}>Void</option>
                </Input>
              </FormGroup>
              <Collapse isOpen={(payoutStatus === 'paid')}>
                <FormGroup>
                  <Label for="transferId">Transfer ID *</Label>
                  <Input
                    id="transferId"
                    type="text"
                    defaultValue={transferId}
                    placeholder="Transfer ID"
                    onChange={(e) => setTransferId(e.target.value)}
                    required={(payoutStatus === 'paid')}
                    disabled={!!blockStatusUpdate}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="paymentId">Payment ID *</Label>
                  <Input
                    id="paymentId"
                    type="text"
                    defaultValue={paymentId}
                    placeholder="Payment ID"
                    onChange={(e) => setPaymentId(e.target.value)}
                    required={(payoutStatus === 'paid')}
                    disabled={!!blockStatusUpdate}
                  />
                </FormGroup>
              </Collapse>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>Cancel</Button>
              <Button color="primary" type="submit" disabled={!!blockStatusUpdate || loading}>
                Update
                {loading && <Spinner size="sm" color="light"/>}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  )
}

PayoutStatusModal.propTypes = {
  open: PropTypes.bool,
  payouts: PropTypes.array,
  onExit: PropTypes.func,
  onComplete: PropTypes.func,
}

export default PayoutStatusModal
