import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap'
import { api } from 'services/api'
import CreateCredentialsDialog from './createCredentialsDialog'

const ProductLocatorTokenGenerator = () => {

    const { user, isAuthentication } = useContext(AuthContext)
    const [webWidgetApiKeyDetails, setwebWidgetApiKeyDetails] = React.useState([])
    const [modal, setModal] = React.useState(false);

    React.useEffect(() => {
        api.get("product-locator-web-widget/key/", {})
            .then(response => setwebWidgetApiKeyDetails(response.data.data))
            .catch(error => console.log(error))
    }, [])

    React.useEffect(() => {
        if (modal === false) {
            api.get("product-locator-web-widget/key/", {})
                .then(response => setwebWidgetApiKeyDetails(response.data.data))
                .catch(error => console.log(error))
        }
    }, [modal])

    return (
        <Container fluid>
            {(isAuthentication && user.isAdmin) ?
                <>
                    <CreateCredentialsDialog modal={modal} setModal={setModal} />
                    <div className={"pt-7"}>
                        <Card className="shadow ">
                            <CardHeader className="border-0 mb-0 pb-0">
                                <h3 className="mb-0">
                                    Inventory Location Widget Integration
                                </h3>
                            </CardHeader>
                            <hr className='mt-2 mb-3'></hr>
                            <CardBody className='mt-0 pt-0 py-0'>
                                <div className='d-flex justify-content-between align-items-center px-1 mb-3'>
                                    <h4>
                                        API Keys &nbsp;({webWidgetApiKeyDetails.length})
                                    </h4>
                                    <Button color='primary' onClick={() => { setModal(true) }}>
                                        <i class="fas fa-plus">&nbsp;</i>   Create Credentials &nbsp;
                                    </Button>
                                </div>

                                <div>
                                    <Table className="align-items-center table-flush mb-2" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" className="pl-1 pr-1">Widget Id</th>
                                                <th scope="col" className="pl-1 pr-1">host</th>
                                                <th scope="col" className="pl-1 pr-1">Created At</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {webWidgetApiKeyDetails.length == 0 ? <tr><h5 className='m-2'>No API keys to display</h5></tr> :
                                                webWidgetApiKeyDetails && webWidgetApiKeyDetails?.map((webWidgetApiKeyDetail) => <tr>
                                                    <th scope="col" className="pl-1 pr-1">{webWidgetApiKeyDetail.web_widget_id}</th>
                                                    <th scope="col" className="pl-1 pr-1">{webWidgetApiKeyDetail.host}</th>
                                                    <th scope="col" className="pl-1 pr-1">{webWidgetApiKeyDetail.created_at}</th>
                                                </tr>)
                                            }
                                        </tbody>

                                    </Table>
                                </div>


                            </CardBody>
                        </Card>
                    </div>
                </> : null
            }
        </Container >
    )
}

export default ProductLocatorTokenGenerator