import React from 'react';
import Select from 'react-select';

function CustomeSelect(props) {

    const customStyles = {
        control: (base, state) => ({
            ...base,
            // props.isValid can display red borderColor if field is not selcetd
            borderColor: props.isValid ?
                '#ddd' : 'red',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 20, // Set the zIndex for the dropdown menu
        }),
    }
    return <Select {...props} styles={customStyles}></Select>
}
export default CustomeSelect;