/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to section two for product Details (variants)
              -  In this section all the images related to product is displayed
  
 */

import React, { useState, useEffect } from "react";
import styles from "./viewProduct.module.css";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { Spinner } from "reactstrap";

export default function ProdDetailsSectionTwo({ productDetails, refeshPage }) {
    const arrayOfImages = productDetails.product.product_images.map(data => data.image);
    // eslint-disable-next-line no-unused-vars
    const [images, setImage] = useState([...arrayOfImages]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(images.length === 0);

    const goToNextSlide = () => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    const goToPrevSlide = () => {
        setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
    };

    useEffect(() => {
        if (images.length === 0) {
            setLoading(true);
            const timer = setTimeout(() => {
                // Call your refresh function here
                refeshPage();
                setLoading(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images.length]);

    useEffect(() => {
        setImage(productDetails.product.product_images.map(data => data.image));
    }, [productDetails]);

    return (
        <div className={styles.sectionConatiner}>
            <div>
                <h2>Product Images</h2>
                <hr />
            </div>
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px", paddingBottom: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ marginBottom: "5px" }}>
                            <Spinner style={{fontSize:"5px",height:"1rem",width:"1rem"}}/>
                        </div>
                        <span style={{ fontSize: "1rem" }}>Loading...</span>
                    </div>
                </div>
            ) : images.length > 0 ? (
                <div id="carouselExampleControls" className={`${styles.carousel} carousel slide`}>
                    <div className="carousel-inner">
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className={`carousel-item ${index === currentIndex ? "active" : ""}`}
                                style={{ display: index === currentIndex ? "flex" : "", justifyContent: index === currentIndex ? "center" : "" }}>
                                <img className="d-block" width={300} height={300} src={image} style={{ objectFit: "contain" }} alt={`Slide ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                    <span class="carousel-control-prev d-flex justify-content-center" role="button" data-slide="prev" onClick={goToPrevSlide}>
                        <span class="btn btn-neutral rounded-circle d-flex align-content-center justify-content-center p-3">
                            <span class="" aria-hidden="true">
                                <FaAngleLeft color="black" size={25} />
                            </span>
                        </span>
                    </span>
                    <span
                        class="carousel-control-next d-flex justify-content-center"
                        href="#carousel-23431"
                        role="button"
                        data-slide="next"
                        onClick={goToNextSlide}>
                        <span class="btn btn-neutral rounded-circle d-flex align-content-center justify-content-center p-3">
                            <span class="" aria-hidden="true">
                                <FaAngleRight color="black" size={25} />
                            </span>
                        </span>
                    </span>
                </div>
            ) : (
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px", paddingBottom: "10px" }}>
                    <span style={{ marginTop: "10px" }}> No Variant Images!</span>
                </div>
            )}
        </div>
    );
}
