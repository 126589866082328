import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table
} from "reactstrap";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
import ConfirmationModal from '../Modal/ConfirmationModal'

export const OrderRestockingModal = ({ isOpen = false, order, orderId, onExit }) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [restockingOrder, setRestockingOrder] = useState([]);
  const { addToast } = useToasts();
  const [modelIsOpen, setmodelIsOpen] = useState(false);

  /**
   * show and hide the module alternatively with each click of the create restocking option
   */
  const toggle = () => {
    setShowModal(!showModal);
  };

  /**
   * API call to create create and send restocking payment link
   */
  const handleRestockingFee = () => {
    let data = {
        order_id: orderId
    };
    api
    .post("order/charge/restocking-fee", data)
    .then((response) => {
        if (response.status === 200) {
            addToast(response.data.message, { appearance: "success", autoDismiss: true });
        } else {
            let message = "Something went wrong, please try again!!"
            if (response?.data?.message) {
                message = response.data.message;
            };
            addToast(message, { appearance: "error" });
        }
    }).catch((error) => {
        let message = "Something went wrong, please try again!!"
        if (error?.response?.data?.message) {
            message = error.response.data.message;
        };
        addToast(message, { appearance: "error" });
    });
  };

  // Open restocking module and set all the fields
  useEffect(() => {
    setShowModal(isOpen);
    if (isOpen && order) {
        setRestockingOrder(order);
    }
  }, [isOpen, order]);

  /**
   * Toggle Confirmation popup
   */
  const toggleModel = () => {
    setmodelIsOpen(!modelIsOpen);
  };

  /**
   * Open confirmation popup and close the Charge Restocking Fee module
   */
  const handleConfirm = () => {
    toggleModel();
    setShowModal(!showModal);
  };

  return (
    <>
      <ConfirmationModal
        modelIsOpen={modelIsOpen}
        setmodelIsOpen={setmodelIsOpen}
        onConfirmation={handleRestockingFee}
        modalBodyMessage="Are you sure you want to send Restocking Fee Link?"
      />
      {showModal &&
        <Modal size="lg" isOpen={showModal} toggle={toggle} onExit={onExit}>
          <ModalHeader toggle={toggle} className="bg-primary text-white">
            <span className="text-white h3">{`Charge Reshipping Fee`}</span>
          </ModalHeader>
          <ModalBody>
            <Table className="align-items-center table-flush" responsive>
                <thead className=" thead-light">
                    <tr>
                        <th scope="col" className="pl-3 pr-3"> Order ID </th>
                        <th scope="col" className="pl-3 pr-3"> Customer Email </th>
                        <th scope="col" className="pl-3 pr-3"> Payment Link ID </th>
                        <th scope="col" className="pl-3 pr-3"> Restocking Status </th>
                        <th scope="col" className="pl-3 pr-3"> Created At </th>
                        <th scope="col" className="pl-3 pr-3"> Updated At </th>
                    </tr>
                </thead>
                <tbody>
                    {restockingOrder.map((restockingObject) => (
                        <tr key={restockingObject.order}>
                            <td>{restockingObject.order}</td>
                            <td>{restockingObject.customer_email}</td>
                            <td>{restockingObject.payment_link_id}</td>
                            <td>{restockingObject.order_restocking_status}</td>
                            <td>{restockingObject.created_at}</td>
                            <td>{restockingObject.updated_at}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
          </ModalBody>
          <div className="text-center">
            <Button
              className="my-4"
              color="primary"
              type="submit"
              id="save_btn"
              onClick={handleConfirm}
            >
              Charge Reshipping Fee
            </Button>
          </div>
        </Modal>
      }
    </>
  );
};
