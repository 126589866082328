/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to section five for product Details
              - This components shows all the changes made to the prouct in tabular form.
  
 */

import React from "react";
import styles from "./viewProduct.module.css";
import moment from "moment/moment";
import DataTable from "./DataTable";

const columns = ["USER","FUNCTION","TIMESTAMP","REASON OF CHANGE"];

export default function ProdDetailsSectionFive({productDetails}) {
    const formattedData = productDetails.product.product_changes.map(data => (
        {
            "USER":data.user,
            "FUNCTION":data.function,
            "TIMESTAMP":(data.created_at && data.created_at !== "")?moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a"):"-",
            "REASON OF CHANGE":data.reason_of_changes ?? "-" ,
        }
    ))
    return (
        <div className={styles.sectionConatiner} style={{overflowX:"scroll"}}>
            <div>
                <h2>Changes</h2>
                <hr />
            </div>
            <div style={{ display: "flex" }}>
                <DataTable columns={columns} data={formattedData} />
            </div>
        </div>
    );
}
