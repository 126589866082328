import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { api } from "../../services/api";
import Select from "react-select";
import { getParams } from "utils/commonFunctions";

export default function UpdateRetailerAssignmentLocationModal({
  toggle,
  isOpen,
  retailerOverrideList,
  updateInventoryLocations,
  totalOrdersCount,
  selectAllRetailerOverridesInThisView,
  filters,
  onComplete
}) {
  const [inventoryLocationList, setInventoryLocationList] = useState([]);
  const [inventoryLocation, setInventoryLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    api
      .get("inventory-location-list/")
      .then((response) => response)
      .then((response) => {
        if (response.status === 200 && response.data) {
          let inventoryLocations = response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setInventoryLocationList(inventoryLocations);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleInventoryLocationChange = (location) => {
    setInventoryLocation(location);
    setError(false);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (!inventoryLocation?.value) {
      return setError(true);
    }

    setError(false);
    setLoading(true);
    const retailerOverrideIds = retailerOverrideList.map((item) => item.id);

    let retailerOverridePageParams = getParams(filters);
    /**
    * Jira: BCD-4024 
    * Description: Added Bulk (Select All in this view functionality) 
    * Update inventory location records functionality
    */
    api
      .patch("retailer-override/", {
        retailer_override_list: retailerOverrideList,
        inventory_location_id: inventoryLocation?.value,
        select_all_retailer_overrides_in_this_view : selectAllRetailerOverridesInThisView,
        retailer_override_count_on_ui : totalOrdersCount,
        params: retailerOverridePageParams
      })
      .then((response) => {
        setLoading(false);
        toggle();
        setInventoryLocation(null);
        if (response.status === 200 && response.data?.success) {
          addToast(response.data.message, {
            appearance: "success",
            autoDismiss: true,
          });
          updateInventoryLocations(retailerOverrideIds);
          if (onComplete) {
            onComplete();
          }
        } else {
          addToast(
            response?.error ?? "Something went wrong, please try again!!",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      })
      .catch((err) => {
        addToast(err?.message ?? "Something went wrong, please try again!!", {
          appearance: "error",
          autoDismiss: true,
        });
        setInventoryLocation(null);
        setLoading(false);
        toggle();
      });
  };

  return (
    <div className="form-group mb-3 d-flex flex-column" data-field="name">
      <Modal
        style={{ marginTop: "100px" }}
        isOpen={isOpen}
        onExit={toggle}
        toggle={() => toggle()}
        size={"lg"}
      >
        <Form>
          <ModalHeader toggle={toggle} className="bg-primary text-white">
            <span className="text-white h3">
              Bulk Update - Inventory Location
            </span>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Row>
                <Col>
                  <Label>Inventory Location *</Label>
                  <br />
                </Col>
                <Col>
                  <Select
                    className="basic-single form-control m-0 p-0 border-0"
                    classNamePrefix="select"
                    placeholder="Select Inventory Location..."
                    isDisabled={false}
                    defaultValue={inventoryLocation || "Select"}
                    isClearable={true}
                    isSearchable={true}
                    name="retailer-sku"
                    options={inventoryLocationList}
                    onChange={(value) => handleInventoryLocationChange(value)}
                  />
                  {error && (
                    <div style={{ color: "red" }}>
                      {`Select value for "Inventory Location"`}
                    </div>
                  )}
                </Col>
              </Row>
            </FormGroup>
            {selectAllRetailerOverridesInThisView && (
              <>
                <h5 className="text-danger fw-500">
                  This action will affect {totalOrdersCount} Retailer Overrides in current view
                </h5>
              </>
            )}
          </ModalBody>
          <ModalFooter className="mt-0 pt-0">
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" id="update_btn" disabled={loading} onClick={handleSubmitForm}>
              Update
              {loading && <Spinner size="sm" color="light" />}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}
