
import React, { useEffect, useState } from "react";
import { Modal, Button, ModalBody, ModalHeader, Form, Spinner } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import moment from "moment/moment";
import { api } from "services/api";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const BulkETA = ({ isOpen, onExit, selected,handleRefresh}) => {
    const { addToast } = useToasts();
    const [modal, setModal] = useState();
    const [selectedDate,setSelectedDate] = useState(new Date())
    const [loading,setLoading] = useState(false);

    let ids =[]
    // eslint-disable-next-line array-callback-return
    selected.map(selectedInformation => {
        ids.push(selectedInformation?.id);
    });

    const toggle = () => {
        setModal(!isOpen);
    };

    const bulkEtaDateHandler = (e) =>{
        setSelectedDate(e)
    }

    const updateBulkHandlerEta = (e) =>{
        e.preventDefault();
        setLoading(true);
        let eta_date = moment(selectedDate).format("MM/DD/YYYY");
        
        let data ={
            eta_date,
            ids
        }
        api
      .put("order/update-bulk-eta/", data)
      .then((response) => {
        if(response.data.success){
            if (handleRefresh) {
                handleRefresh();
            }
            addToast("ETA's Updated ", { appearance: "success", autoDismiss: true });
            setLoading(false)
            // Clear selected orders 
            // window.location.reload(true);
            onExit()
        }
        
      })
      .catch((error) => {
        addToast(error.message, { appearance: "error" });
        setLoading(false)
        onExit()
      });
    }

    useEffect(() => {
        setModal(isOpen);
    }, [isOpen]);
    return (
        <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
                <span className="text-white h3">Bulk ETA</span>
            </ModalHeader>
            <ModalBody>
                <Form >
                    <div style={{display:"flex",gap:"20px",justifyContent:"center",alignItems:"center"}}>
                    <span>Bulk ETA</span>
                    <DatePicker
                        selected={selectedDate}
                        dateFormat={"MM-dd-yyyy"}
                        placeholderText={'mm-dd-yyyy'}
                        isClearable={true}
                        onChange={(e)=> bulkEtaDateHandler(e)}
                    />
                    </div>

                    <div className="d-flex justify-content-between">
                        <Button color="danger" onClick={onExit}>
                            Close
                        </Button>
                        <Button color={"success"} onClick={updateBulkHandlerEta} style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px"}}>
                            Update
                            {loading && <Spinner size="sm" color="light" />}
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default BulkETA