import React from "react";

const ChildOrderLineItems = ({ childOrderData }) => {
    const tableStyle = {
        width: "100%",
        borderCollapse: "collapse",
    };

    const cellStyle = {
        border: "1px solid #dddddd",
        padding: "8px",
        textAlign: "left"
    };

    const headerCellStyle = {
        ...cellStyle,
        backgroundColor: "#f2f2f2"
    };

    const trackingStatusStyle = {
        ...cellStyle,
        textTransform: "capitalize"
    };

    const linkStyle = {
        color: "#007bff",
        textDecoration: "none"
    };

    return (
        <table style={tableStyle}>
            <thead>
                <tr>
                    <th style={headerCellStyle}>Order Name</th>
                    <th style={headerCellStyle}>Order Status</th>
                    <th style={headerCellStyle}>Fulfillment Tracking Number</th>
                    <th style={headerCellStyle}>Tracking Status</th>
                    <th style={headerCellStyle}>WMS Reference ID</th>
                    <th style={headerCellStyle}>BOB Reference ID</th>
                    <th style={headerCellStyle}>Benjamin Reference ID</th>
                </tr>
            </thead>
            <tbody>
                {childOrderData?.map((order, orderIndex) => (
                    <>
                        {order.order_fulfillments.map((fulfillment, index) => (
                            <tr key={`${order.order_name}_${index}`}>
                                {index === 0 ? (
                                    <>
                                        <td style={cellStyle} rowSpan={order.order_fulfillments.length}>
                                            {order.order_name}
                                        </td>
                                        <td style={cellStyle} rowSpan={order.order_fulfillments.length}>
                                            {order.order_status}
                                        </td>
                                    </>
                                ) : null}
                                <td style={cellStyle}>{fulfillment.tracking_number}</td>
                                <td style={trackingStatusStyle}>{fulfillment.shipment_status}</td>
                                {index === 0 ? (
                                    <>
                                        <td style={cellStyle} rowSpan={order.order_fulfillments.length}>
                                            {order.order_bob_details.length > 0 ? order.order_bob_details.map(detail => (
                                                <div key={detail.wms_reference_id}>
                                                    <a href={detail.wms_url} target="_blank" rel="noopener noreferrer" style={linkStyle}>
                                                        {detail.wms_reference_id}
                                                    </a>
                                                </div>
                                            )) : "-"}
                                        </td>
                                        <td style={cellStyle} rowSpan={order.order_fulfillments.length}>
                                            {order.order_bob_details.length > 0 ? order.order_bob_details.map(detail => detail.bob_reference_id).join(", ") : "-"}
                                        </td>
                                        <td style={cellStyle} rowSpan={order.order_fulfillments.length}>
                                            {order.order_bob_details.length > 0 ? order.order_bob_details.map(detail => detail.benjamin_reference_id).join(", ") : "-"}
                                        </td>
                                    </>
                                ) : null}
                            </tr>
                        ))}
                        {/* Render a row with order_name if order_fulfillments is empty */}
                        {order.order_fulfillments.length === 0 && (
                            <tr key={`${order.order_name}_empty`}>
                                <td style={cellStyle}>{order.order_name}</td>
                                <td style={cellStyle}>{order.order_status}</td>
                                {/* Add additional cells for other details if needed */}
                                <td style={cellStyle}>-</td>
                                <td style={cellStyle}>-</td>
                                <td style={cellStyle}>
                                    {" "}
                                    {order.order_bob_details.length > 0 ? order.order_bob_details.map(detail => (
                                        <div key={detail.wms_reference_id}>
                                            <a href={detail.wms_url} target="_blank" rel="noopener noreferrer" style={linkStyle}>
                                                {detail.wms_reference_id}
                                            </a>
                                        </div>
                                    )) : "-"}
                                </td>
                                <td style={cellStyle}>{order.order_bob_details.length > 0 ? order.order_bob_details.map(detail => detail.bob_reference_id).join(", ") : "-"}</td>
                                <td style={cellStyle}>{order.order_bob_details.length > 0 ? order.order_bob_details.map(detail => detail.benjamin_reference_id).join(", ") : "-"}</td>
                            </tr>
                        )}
                    </>
                ))}
            </tbody>
        </table>
    );
};

export default ChildOrderLineItems;
