import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";

function ConfirmationModal({ modelIsOpen, setmodelIsOpen, onConfirmation, modalBodyMessage, modalHeaderMessage = "Confirmation" }) {

    const toggleModel = () => {
        setmodelIsOpen(!modelIsOpen)
    }

    return (
        <Modal isOpen={modelIsOpen}
            toggle={toggleModel}
            modalTransition={{ timeout: 500 }}>
            <ModalHeader toggle={toggleModel}>
                <h2>{modalHeaderMessage}</h2>
            </ModalHeader>
            <ModalBody>{modalBodyMessage}</ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => {
                    toggleModel();
                    onConfirmation();
                }}>Yes</Button>
                <Button color="light" outline onClick={toggleModel}>No</Button>
            </ModalFooter>
        </Modal >
    )
}

export default ConfirmationModal;


ConfirmationModal.prototypes = {
    modalHeaderMessage: PropTypes.string,
    modalBodyMessage: PropTypes.string,
}