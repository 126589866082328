import React, { useState, useEffect } from 'react';
import { api } from "../../services/api";
import { useToasts } from "react-toast-notifications";
import {
  Modal,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Form,
  FormFeedback,
  FormGroup, Label,
  Input,
  Spinner
} from 'reactstrap';
import RichTextEditor from "components/RichTextEditor";

export const RetailerEmail = ({ isOpen, onExit, orders, toEmail }) => {
  const [modal, setModal] = useState(isOpen);

  const [loading, setLoading] = useState(false);
  const [fromEmail, setFromEmail] = useState("");
  const [ccEmail, setCcEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");
  const { addToast } = useToasts();

  const toMail = toEmail.data?.retailer_email_id_response;
  const order_id = orders[0]?.id;

  /**
   * function for sending email on click of submit button
   * @param {*} e 
   * @returns 
   */
  const handleSendEmail = (e) => {
    e.preventDefault();

    // Check user has added email content or not
    if (!content) {
      setContentError("Please enter email content");
      return;
    }

    const data = {
      subject,
      content,
      order_id,
      email_cc: ccEmail,
      email_to: toMail,
      email_from: fromEmail,
    };

    setLoading(true);

    api
      .post("order/on-hold/email", data)
      .then(() => {
        setModal(false);
        setLoading(false);
        addToast("E-mail being sent!", { appearance: "success", autoDismiss: true });
      })
      .catch((error) => {
        setLoading(false);
        addToast("Something went wrong, please try again!!", { appearance: "error" });
      });
  };

  /**
   * function for toggle of modal
   */
  const toggle = () => {
    setModal(!isOpen);
  };

  useEffect(() => {
    setModal(isOpen);
    if (isOpen && orders) {

      // Clear form data if set last time
      setContent("");
      setCcEmail("");
      setFromEmail("");
      setContentError("");

      // Set email subject
      if (orders.length === 1) {
        setSubject(`A message about your order ${orders[0].shopify_id}`);
      } else {
        setSubject(`A message about your order [Order Number]`);
      }
    }
  }, [isOpen]);

  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={toggle} onExit={onExit}>
        <Form onSubmit={handleSendEmail}>
          <ModalHeader toggle={toggle} className="bg-primary text-white">
            <span className="text-white h3">Email Retailer</span>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="emailFrom">From *</Label>
              <Input
                id="emailFrom"
                placeholder="name@example.com"
                type="select"
                onChange={(e) => setFromEmail(e.target.value)}
                required
              >
                <option value="">------</option>
                <option>help@flaviar.com</option>
                <option>partner@flaviar.com</option>
                {/*<option>openbar@mashandgrape.com</option>
                <option>support@getbarcart.com</option> 6833483186-Barcart emails rebrand - Changes*/}
                <option> retail@flaviar.com</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="emailTo">To *</Label>
              <Input
                id="emailTo"
                placeholder="name@example.com"
                type="email"
                value={toMail}
                readOnly={true}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emailCc">Cc</Label>
              <Input
                id="emailCc"
                placeholder="Cc"
                type="email"
                value={ccEmail}
                onChange={(e) => setCcEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="subject">Subject *</Label>
              <Input
                id="subject"
                placeholder="Subject"
                type="text"
                // defaultValue={subject}
                onChange={(e) => setSubject(e.target.value)}
                readOnly={false}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="contect">Content *</Label>
              <RichTextEditor
                onChange={setContent}
                defaultValue={content}
              />
              <Input hidden={true} invalid />
              <FormFeedback>{contentError}</FormFeedback>
            </FormGroup>
            <a href={`mailto:${toMail}?bcc=${toMail}&subject=${subject}`} target="_blank">Compose in Gmail</a>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Cancel</Button>
            <Button color="primary" type="submit"
            >
              Submit
              {loading && <Spinner size="sm" color="light" />}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}