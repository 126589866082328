import React, { useRef } from "react"
import "ol/ol.css";
import * as ol from "ol";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import { Vector as VectorLayer } from "ol/layer";
import { Circle, Fill, Style } from "ol/style";
import { OpenStreetMapContext } from "../../contexts/MapContext"
import './openStreetMap.css'

const OpenStreetMap = ({ children }) => {
    const mapRef = useRef();
    const { flyTo, vectorSource, viewState, center, zoom , placeSelected} = React.useContext(OpenStreetMapContext)

    React.useEffect(() => {
        if (viewState != null && vectorSource != null) {
            let options = {
                view: viewState,
                layers: [tileLayer, vectorLayer],
                controls: [],
                overlays: []
            };

            let mapObject = new ol.Map(options);

            var extent = vectorLayer.getSource().getExtent();
            extent[0] != "Infinity" && mapObject.getView().fit(vectorLayer.getSource().getExtent(), {
                maxZoom: 15,
            })

            mapObject.setTarget(mapRef.current);

            placeSelected && vectorSource && viewState && viewState.animate({
                center: fromLonLat(center),
                duration: 2000,
                zoom: zoom,
            });

            return () => mapObject.setTarget(undefined);
        }
    }, [viewState, vectorSource])

    const flyToLocation = (lat, lon) => {
        let flytoLatLan = fromLonLat([lat, lon]);
        vectorSource && viewState && viewState.animate({
            center: flytoLatLan,
            zoom: 13,
        });
    }

    React.useEffect(() => {
        flyToLocation(flyTo[0], flyTo[1])
    }, [flyTo])


    const tileLayer = new TileLayer({
        preload: 4,
        source: new OSM({ url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png" }),
    });

    const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
            image: new Circle({
                radius: 5,
                fill: new Fill({ color: 'blue' }),
            }),
        }),
    });

    return (
        <div ref={mapRef} className="map-container py-3 px-3">
            {children}
        </div>
    )
}
export default OpenStreetMap