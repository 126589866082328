import React from 'react';
import CustomeSelect from 'components/ValidationForDropdown/CustomSelectValidation';
import CustomeMultiSelect from 'components/ValidationForDropdown/MultiSelectValidation';

export const CustomDropdown = (props) => {
  return (
    <>
      <CustomeSelect
        {...props}
        options={props.options}
        placeholder={props.placeholder}
        onChange={(option) => props.onChange(option.value)}
        isValid={props.isValid}
        value={props.options?.find(item => item.value === props.value)}
      />
      {props?.errors && <small className='text-muted text-red'>{props.errors}</small >}
    </>
  );
};

export const CustomMultiSelectDropdown = ({ options, onChangeHandler, placeholder, isValid, values,isDisabled }) => {
  return (
    <CustomeMultiSelect
      options={options}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable={true}
      autoFocus={true}
      placeholder={placeholder}
      noOptionMessage={() => "No more states!"}
      onChange={onChangeHandler}
      allowSelectAll={true}
      checked={true}
      isValid={isValid}
      values={values}
      isDisabled={isDisabled}
    />
  )
};