import { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { useToasts } from 'react-toast-notifications';
import { api } from 'services/api';
import { OpenStreetMapContext } from '../../contexts/MapContext';
import InventoryLocationCard from './InventoryLocationCard';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import './inventoryLocation.css';

const InventoryLocationSidebar = () => {
  const { changeFlyTo, fetchAndStoreInventoryLocations, inventoryLocations, setinventoryLocations, placeSelected, changePlaceSelectedOrNot, changeMapCenter, changeMapZoom } =
    useContext(OpenStreetMapContext);
  const { addToast } = useToasts();

  const [initialbrandOptions, setinitialbrandOptions] = useState([]);
  const [brandOptions, setbrandOptions] = useState([]);
  const [selectedBrandOption, setselectedBrandOption] = useState({ label: '', value: '' });
  const [selectedPlaceValue, setselectedPlaceValue] = useState('');
  const [modelIsOpen, setmodelIsOpen] = useState(false);
  const [placesSuggestions, setplacesSuggestions] = useState([]);
  const [selectedPlaceDetail, setselectedPlaceDetail] = useState({});

  useEffect(() => {
    api
      .get('product/filter/brand', {})
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          setinitialbrandOptions(
            response.data.results.map((brand) => {
              return { value: brand.id, label: brand.name };
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const toggleModel = () => {
    setmodelIsOpen(!modelIsOpen);
  };

  const getBrandDropdownOptions = (searchValue = '') => {
    api
      .get('product/filter/brand', {
        params: { search: searchValue },
      })
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          setbrandOptions(response.data);
          return response.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loadBrandDropdownOptions = async (inputValue) => {
    getBrandDropdownOptions(inputValue);
    return brandOptions.results.map((brand) => {
      return { value: brand.id, label: brand.name };
    });
  };

  const brandDropdownOnChange = (newValue) => {
    setselectedBrandOption(newValue);
    newValue.value && fetchAndStoreInventoryLocations(newValue.value);
  };

  const getPlacesUsingGoogleMapsAutoComplete = (inputText, callback) => {
    let map = new window.google.maps.Map(document.getElementById('map'), {});
    let request = { query: inputText, fields: ['name', 'rating', 'formatted_phone_number', 'geometry', 'formatted_address'] };
    let service = new window.google.maps.places.PlacesService(map);
    let suggestions;

    service.textSearch(request, (places, status) => {
      suggestions = places.map((prediction) => {
        return {
          label: prediction.name + ', ' + prediction?.formatted_address,
          value: [prediction.geometry.location.lat(), prediction.geometry.location.lng()],
        };
      });
      callback(suggestions);
      setplacesSuggestions(suggestions);
    });
  };

  const loadPlaceDropdownOptions = (inputValue, callback) => {
    if (inputValue) {
      getPlacesUsingGoogleMapsAutoComplete(inputValue, callback);
    }
  };

  const onPlaceDropDownSelectGetPlaceDetail = async (placeDetail) => {
    if (placeDetail.label && placeDetail.value) {
      setselectedPlaceDetail({
        formatted_address: placeDetail.label,
        name: placeDetail.label.split(',')[0],
        geometry: {
          location: {
            lang: placeDetail.value[0],
            lat: placeDetail.value[1],
          },
        },
      });
      setselectedPlaceValue(placeDetail.value);
      changePlaceSelectedOrNot(true);
      
      changeFlyTo([placeDetail.value[1], placeDetail.value[0]]);
      setinventoryLocations([
        ...inventoryLocations.filter((location) => location.flag != true),
        {
          full_name: '',
          lat: placeDetail.value[1],
          long: placeDetail.value[0],
          name: '',
          ship_from_address1: '',
          flag: true,
        },
      ]);
      selectedPlaceValue == '' && changePlaceSelectedOrNot(!placeSelected);
      changeMapCenter([placeDetail.value[1], placeDetail.value[0]]);
      changeMapZoom(18);
    }
  };

  const addStoreButtonOnClickHandler = () => {
    changePlaceSelectedOrNot(!placeSelected);
    toggleModel();
  };

  const addInventoryLocation = async () => {
    if (selectedPlaceDetail.geometry.location) {
      let result = await api.post('inventory-location/find/by-brand-id/', {
        name: selectedPlaceDetail.name,
        full_name: selectedPlaceDetail.name,
        ship_from_address1: selectedPlaceDetail.formatted_address,
        brand_id: selectedBrandOption.value,
        lat: selectedPlaceDetail.geometry.location.lat,
        long: selectedPlaceDetail.geometry.location.lang,
      });
      result.data.success === true &&
        addToast('Store added successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      result.data.success === false &&
        addToast(result.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });
    }
    await fetchAndStoreInventoryLocations(selectedBrandOption.value);
  };

  const inventoryLocationCardOnClickHandler = (location) => {
    location.long && location.lat && changeFlyTo([parseFloat(location.lat), parseFloat(location.long)]);
  };

  return (
    <div className="p-3 StoreMarkerSidebar w-25">
      <div id="map" />
      <ConfirmationModal
        modelIsOpen={modelIsOpen}
        setmodelIsOpen={setmodelIsOpen}
        onConfirmation={addInventoryLocation}
        modalBodyMessage="Are you sure you want to add this location as Inventory Location ?"
      />

      <AsyncSelect
        className="mb-2"
        placeholder="Select Brand"
        cacheOptions
        clearValue
        defaultOptions={initialbrandOptions}
        loadOptions={loadBrandDropdownOptions}
        onChange={brandDropdownOnChange}
      />

      <AsyncSelect
        disabled
        className="mb-3"
        placeholder="Search place to add"
        cacheOptions
        clearValue
        defaultOptions
        loadOptions={loadPlaceDropdownOptions}
        onInputChange={onPlaceDropDownSelectGetPlaceDetail}
        onChange={onPlaceDropDownSelectGetPlaceDetail}
      />

      <Button disabled={!(selectedBrandOption.label != '' && placeSelected == true)} color="warning" className="mb-3" onClick={addStoreButtonOnClickHandler}>
        <i className="fas fa-map-marker-alt"> &nbsp;</i> Add Store
      </Button>

      {selectedBrandOption.label == '' ? <h5>Select brand from above to see respective Inventory Locations</h5> : <h5>{selectedBrandOption.label} Locations</h5>}
      <Row>
        <Col>
          {inventoryLocations.length > 0 &&
            inventoryLocations.map((location) => {
              if (location.flag != true) {
                return <InventoryLocationCard key={location.id} location={location} cardOnClick={inventoryLocationCardOnClickHandler}></InventoryLocationCard>;
              }
            })}
        </Col>
      </Row>
    </div>
  );
};

export default InventoryLocationSidebar;
