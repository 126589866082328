import React from "react";
import { Badge} from "reactstrap";

const BadgePillFilter = ({title, data, selected, onClick, backgroundColor, textColor }) => {
    const badgeStyles = {cursor: "pointer"};
    const styles = {badgeStyle : `badege-pill-text bg-${backgroundColor ? "white" : "primary"} text-${textColor ? textColor : "white"} mr-1`}
    // Remove duplicate data from Array of selected filter values to avoid multiple badges from being generated
    for (let key in data){
        if (typeof(data[key]) === "object" && data[key] && data[key].length>0){
            const uniqueArray = data[key].filter((value, index) => {
                const _value = JSON.stringify(value);
                return index === data[key].findIndex(obj => {
                    return JSON.stringify(obj).toUpperCase() === _value.toUpperCase();
                });
            });
            data[key] = uniqueArray;
        }
    }

    return (
        <>
            {data.search &&
                <Badge className={styles.badgeStyle}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'search'})}
                       pill={true}>
                    Search: {data.search}
                    <span>×</span>
                </Badge>
            }

            {data?.q &&
                <Badge className={styles.badgeStyle}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'search'})}
                       pill={true}>
                    Search: {data.q}
                    <span>×</span>
                </Badge>
            }
            {data.fulfillmentStartDate && data.fulfillmentEndDate &&
                <Badge
                    style={badgeStyles}
                    className={styles.badgeStyle}
                    onClick={() => onClick({ component: 'fulfillmentDate' })}
                    pill={true}>Dates: {data.fulfillmentStartDate.format("MMMM D, Y")} - {data.fulfillmentEndDate.format("MMMM D, Y")}
                    <span>×</span></Badge>
            }
            
            {data.invoiceStartDate && data.invoiceEndDate &&
                <Badge
                    style={badgeStyles}
                    className={styles.badgeStyle}
                    onClick={() => onClick({ component: 'invoice_status' })}
                    pill={true}>Invoice Dates: {data.invoiceStartDate.format("MMMM D, Y")} - {data.invoiceStartDate.format("MMMM D, Y")}
                    <span>×</span></Badge>
            }

            {data.startDate && data.endDate &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'date'})}
                pill={true}>DATES: {data.startDate.format("MMMM D, Y")} - {data.endDate.format("MMMM D, Y")}
                <span>×</span></Badge>
            }
            {data.chargeBackStartDate && data.ChargeBackEndDate &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'chargeBackDate'})}
                pill={true}>Chargeback Created Dates: {data.chargeBackStartDate.format("MMMM D, Y")} - {data.ChargeBackEndDate.format("MMMM D, Y")}
                <span>×</span></Badge>
            }
            {/** 6367053025 - Generic Date Filter bug changes- Start*/}
            {data.InvoiceShippingStartDate && data.InvoiceShippingEndDate &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'invoiceShippingChargeBackDate'})}
                pill={true}>Shipping Chargeback Invoice Dates: {data.InvoiceShippingStartDate.format("MMMM D, Y")} - {data.InvoiceShippingEndDate.format("MMMM D, Y")}
                <span>×</span></Badge>
            }
            {data.InvoiceDiscountCodeChargebackStartDate && data.InvoiceDiscountCodeChargebackEndDate &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'invoiceDiscountCodeChargeBackDate'})}
                pill={true}>DiscountCode Chargeback Invoice Created Dates: {data.InvoiceDiscountCodeChargebackStartDate.format("MMMM D, Y")} - {data.InvoiceDiscountCodeChargebackEndDate.format("MMMM D, Y")}
                <span>×</span></Badge>
            }
            {data.customerStartDate && data.customerEndDate &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'customerDate'})}
                pill={true}>Dates: {data.customerStartDate.format("MMMM D, Y")} - {data.customerEndDate.format("MMMM D, Y")}
                <span>×</span></Badge>
            }
            {data.discountCodeChargebackStartDate && data.discountCodeChargebackEndDate &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'discountCodeChargebackDate'})}
                pill={true}>Dates: {data.discountCodeChargebackStartDate.format("MMMM D, Y")} - {data.discountCodeChargebackEndDate.format("MMMM D, Y")}
                <span>×</span></Badge>
            }
            {/** 6367053025 - Generic Date Filter bug changes- End*/}

            {data?.chargebackStartDate && data?.chargebackEndDate &&
                <Badge
                    style={badgeStyles}
                    className={styles.badgeStyle}
                    onClick={() => onClick({component: 'date'})}
                    pill={true}>CHARGEBACKS DATES: {data?.chargebackStartDate?.format("MMMM D, Y")} - {data?.chargebackEndDate?.format("MMMM D, Y")}
                    <span>×</span>
                </Badge>
            }

            {data.orderStartDate && data.orderEndDate &&
                <Badge
                    style={badgeStyles}
                    className={styles.badgeStyle}
                    onClick={() => onClick({ component: 'orderDate' })}
                    pill={true}>Order Dates: {data.orderStartDate.format("MMMM D, Y")} - {data.orderEndDate.format("MMMM D, Y")}
                    <span>×</span></Badge>
            }

            { selected && selected.map((item, index) =>
                <Badge
                    key={index}
                    style={badgeStyles}
                    onClick={() => onClick({component: 'selected', item})}
                    className={styles.badgeStyle}
                    pill={true}>
                    {title ? title : "Order"}: {item.title}
                <span> ×</span>
                </Badge>
            )}

            {data?.brands && data?.brands.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'brand', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Brand: {item.label}
                    <span>×</span>
                </Badge>
            )}

            {data?.invoiceStatus && data?.invoiceStatus.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'invoice_status', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Invoice Status: {item.label}
                    <span>×</span>
                </Badge>
            )}

            {data?.shippingChargebackBrands && data?.shippingChargebackBrands.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'brand', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Brand: {item.label}
                    <span>×</span>
                </Badge>
            )}
            {data?.financial_status && data?.financial_status.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'financial_status', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Financial Status: {item.label}
                    <span>×</span>
                </Badge>
            )}
            {data?.order_status && data?.order_status.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'order_status', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Order Status: {item.label}
                    <span>×</span>
                </Badge>
            )}

            {data?.distributors && data?.distributors.map((item, index) =>
                <Badge key={index}
                    style={badgeStyles}
                    onClick={() => onClick({ component: 'distributors', item })}
                    className="bg-primary text-white mr-1"
                    pill={true}>
                    Distributor: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            { data?.products && data?.products.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'product', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    {"Product"} : {item.label}
                    <span> ×</span>
                </Badge>
            )}

            { data?.productTypes?.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'product_type', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Product Type: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.states && data?.states.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                   onClick={() => onClick({component: 'state', item})}
                   className={styles.badgeStyle}
                   pill={true}>
                    State: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {/* BCD - 4275 */}
            {data?.bulkOrders && data?.bulkOrders.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                   onClick={() => onClick({component: 'bulk_order', item})}
                   className={styles.badgeStyle}
                   pill={true}>
                    BulkOrder: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.distribution_states && data?.distribution_states.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                   onClick={() => onClick({component: 'distribution_states', item})}
                   className={styles.badgeStyle}
                   pill={true}>
                    Distribution State: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.users && data?.users.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                   onClick={() => onClick({component: 'userFilter', item})}
                   className={styles.badgeStyle}
                   pill={true}>
                    User: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.fulfillments && data?.fulfillments.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'fulfillment', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Fulfillment: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.statuses && data?.statuses.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'status', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Status: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.financialStatus && data?.financialStatus.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'payment_status', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Payment Status: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.assignees && data?.assignees.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'assignee', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Assignee: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.risk_level && data?.risk_level.map((item, index) =>
              <Badge key={index}
                     style={badgeStyles}
                     onClick={() => onClick({component: 'risk_level', item})}
                     className={styles.badgeStyle}
                     pill={true}>
                  Risk Level: {item.label}
                  <span> ×</span>
              </Badge>
            )}

            {data?.utms && data?.utms.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'utm', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    UTM: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.utmsMedium && data?.utmsMedium.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'utm_medium', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    UTM Medium: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.utmsCampaign && data?.utmsCampaign.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'utm_campaign', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    UTM Campaign: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.discountCodes && data?.discountCodes.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'discount_code', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Discount Code: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.channels && data?.channels.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'sales_channel', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Channel: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.typeDiscounts && data?.typeDiscounts.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'typeDiscount', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Discount Code Type: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.invoiceNumbers && data?.invoiceNumbers.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'invoiceNumbers', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Invoice Number: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.sku && data?.sku.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'sku', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Sku: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.shippingRate && data?.shippingRate.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'shippingRate', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    shipping Rate: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.referringSites && data?.referringSites.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'referringSites', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Referring Sites: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {/**6509739352 - Store Filter on Shipping and discount code chargebacks */}
            {data?.store && data?.store.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'store', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Store: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.labels && data?.labels.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'label', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Label: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data.bottleMin > 0 &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'bottleMin'})}
                pill={true}>Bottles Min: {data.bottleMin}
                <span>×</span></Badge>
            }

            {data.bottleMax > 0 &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'bottleMax'})}
                pill={true}>Bottles Max: {data.bottleMax}
                <span>×</span></Badge>
            }

            {data.StatusDayMin > 0 &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'StatusDayMin'})}
                pill={true}>Status Age Min: {data.StatusDayMin}
                <span>×</span></Badge>
            }

            {data.StatusDayMax > 0 &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'StatusDayMax'})}
                pill={true}>Status Age Max: {data.StatusDayMax}
                <span>×</span></Badge>
            }

            {data.OrderDayMin > 0 &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'OrderDayMin'})}
                pill={true}>Order Age Min: {data.OrderDayMin}
                <span>×</span></Badge>
            }

            {data.OrderDayMax > 0 &&
            <Badge
                style={badgeStyles}
                className={styles.badgeStyle}
                onClick={() => onClick({component: 'OrderDayMax'})}
                pill={true}>Order Age Max: {data.OrderDayMax}
                <span>×</span></Badge>
            }

            {data?.tracking_statuses && data?.tracking_statuses.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'tracking_statuses', item})}
                       className={styles.badgeStyle}
                       pill={true}>Tracking Status: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.tracking_companies && data?.tracking_companies.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'tracking_companies', item})}
                       className={styles.badgeStyle}
                       pill={true}>Tracking Company: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.payoutStatus && data?.payoutStatus.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'payoutStatus', item})}
                       className={styles.badgeStyle}
                       pill={true}>Payout Status: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.product_status && data?.product_status.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'product_status', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Status: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.inventory_location && data?.inventory_location.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'inventory_location', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Inventory Location: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.productName &&
                <Badge 
                       style={badgeStyles}
                       className={styles.badgeStyle}
                       onClick={() => onClick({component: 'productName'})}
                       pill>
                    Product : {data.productName}
                    <span> ×</span>
                </Badge>
            }

            {data?.productNames && data?.productNames.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'productNames', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Products: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.locationIds && data?.locationIds.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'locationIds', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Locations: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.sizeIds && data?.sizeIds.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'sizeIds', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Size: {item.label}
                    <span> ×</span>
                </Badge>
            )}

            {data?.stagingQuantityMin > 0 &&
                <Badge
                    style={badgeStyles}
                    onClick={() => onClick({component: 'stagingQuantityMin'})}
                    className={styles.badgeStyle}
                    pill={true}>
                    Quantity Min: {data.stagingQuantityMin}
                    <span> ×</span>
                </Badge>
            }

            {data?.stagingQuantityMax > 0 &&
                <Badge
                    style={badgeStyles}
                    onClick={() => onClick({component: 'stagingQuantityMax'})}
                    className={styles.badgeStyle}
                    pill={true}>
                    Quantity Max: {data.stagingQuantityMax}
                    <span> ×</span>
                </Badge>
            }

            {data?.stagingPriceMin > 0 &&
                <Badge
                    style={badgeStyles}
                    onClick={() => onClick({component: 'stagingPriceMin'})}
                    className={styles.badgeStyle}
                    pill={true}>
                    Price Min: {data.stagingPriceMin}
                    <span> ×</span>
                </Badge>
            }

            {data?.stagingPriceMax > 0 &&
                <Badge
                    style={badgeStyles}
                    onClick={() => onClick({component: 'stagingPriceMax'})}
                    className={styles.badgeStyle}
                    pill={true}>
                    Price Max: {data.stagingPriceMax}
                    <span> ×</span>
                </Badge>
            }
            
            {data?.billingStatuss && data?.billingStatuss.map((item, index) =>
                <Badge key={index}
                    style={badgeStyles}
                    onClick={() => onClick({ component: 'status', item })}
                    className={styles.badgeStyle}
                    pill={true}>
                    Status: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.orderCity && data?.orderCity.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'orderCity', item})}
                       className={styles.badgeStyle}
                       pill={true}>Cities: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.orderState && data?.orderState.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'orderState', item})}
                       className={styles.badgeStyle}
                       pill={true}>States: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.orderZipCodes && data?.orderZipCodes.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'orderZipCodes', item})}
                       className={styles.badgeStyle}
                       pill={true}>Zip Codes: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.orderCount && data?.orderCount.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'orderCount', item})}
                       className="bg-primary text-white mr-1"
                       pill={true}>Order Count: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.customer_states && data?.customer_states.map
                ((item, index) =>
                    <Badge key={index}
                        style={badgeStyles}
                        onClick={() => onClick({ component: 'customer_states', item })}
                        className="bg-primary text-white mr-1"
                        pill={true}>
                        States: {item.label}
                        <span> ×</span>
                    </Badge>
                )
            }
            {data?.cities && data?.cities.map
                ((item, index) =>
                    <Badge key={index}
                        style={badgeStyles}
                        onClick={() => onClick({ component: 'cities', item })}
                        className="bg-primary text-white mr-1"
                        pill={true}>
                        Cities: {item.label}
                        <span> ×</span>
                    </Badge>
                )
            }
            {data?.accepts_marketing && data?.accepts_marketing.map
                ((item, index) =>
                    <Badge key={index}
                        style={badgeStyles}
                        onClick={() => onClick({ component: 'accepts_marketing', item })}
                        className="bg-primary text-white mr-1"
                        pill={true}>
                        Accepts Marketing: {item.label}
                        <span> ×</span>
                    </Badge>
                )
            }            
            { data?.product && data?.product.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'product', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    {"Product"} : {item.label}
                    <span> ×</span>
                </Badge>
            )}
            { data?.user && data?.user.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'user', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    {"User"} : {item.label}
                    <span> ×</span>
                </Badge>
            )}
            { data?.discountCodeBillingStatus && data?.discountCodeBillingStatus.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'discountCodeBillingStatus', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    {"Billing Status"} : {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.discountCodeType && data?.discountCodeType.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'discountCodeType', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Discount Code Type: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.discountCodeBrand && data?.discountCodeBrand.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'discountCodeBrand', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Brand: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.inventoryStatus && data?.inventoryStatus.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'inventoryStatus', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Inventory Status: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.billableUser && data?.billableUser.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'billableUser', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Billable User: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {data?.reshipStatuses && data?.reshipStatuses.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'reship_status', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Reship Status: {item.label}
                    <span>×</span>
                </Badge>
            )}
            {data?.productCategory && data?.productCategory.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'productCategory', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Product Category: {item?.label}
                    <span>×</span>
                </Badge>
            )}
            {data?.userRoles && data?.userRoles.map((item, index) => 
                <Badge key={index}
                    style={badgeStyles}
                    onClick={() => onClick({component: 'userRoles', item})}
                    className={styles.badgeStyle}
                    pill={true}>
                    User Role: {item?.label}
                    <span>×</span>
                </Badge>
            )}
            {data?.userStatus && data?.userStatus.map((item, index) => 
                <Badge key={index}
                    style={badgeStyles}
                    onClick={() => onClick({component: 'userStatus', item})}
                    className={styles.badgeStyle}
                    pill={true}>
                    User Status: {item?.label}
                    <span>×</span>
                </Badge>
            )}
            {data?.retailerInventory && data?.retailerInventory.map((item, index) => 
                <Badge key={index}
                    style={badgeStyles}
                    onClick={() => onClick({component: 'retailerInventory', item})}
                    className={styles.badgeStyle}
                    pill={true}>
                    Retailer Inventory: {item?.label}
                    <span>×</span>
                </Badge>
            )}
            {data?.childOrderType && data?.childOrderType.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'childOrderType', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Child Order Type: {item?.label}
                    <span>×</span>
                </Badge>
            )}
            {data?.batchStatus && data?.batchStatus.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'batchStatus', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Batch Status: {item?.label}
                    <span>×</span>
                </Badge>
            )}
            {data.dataSubmittedAtStart && data.dataSubmittedAtEnd &&
                <Badge
                    style={badgeStyles}
                    className={styles.badgeStyle}
                    onClick={() => onClick({component: 'chargeBackDate'})}
                    pill={true}>Date Submitted: {data.dataSubmittedAtStart.format("MMMM D, Y")} - {data.dataSubmittedAtEnd.format("MMMM D, Y")}
                    <span>×</span></Badge>
                }
            {data?.assignee && data?.assignee.map((item, index) =>
                <Badge key={index}
                        style={badgeStyles}
                        onClick={() => onClick({component: 'assignee', item})}
                        className={styles.badgeStyle}
                        pill={true}>
                    Assignee: {item.label}
                    <span> ×</span>
                </Badge>
            )}
            {/* 6660698130 - Tag Pre Orders */}
            {data?.preOrders && data?.preOrders.map((item, index) =>
                <Badge key={index}
                        style={badgeStyles}
                        onClick={() => onClick({component: 'preOrders', item})}
                        className={styles.badgeStyle}
                        pill={true}>
                    Pre-Orders: {item?.label}
                    <span>×</span>
                </Badge>
            )}
            {/* 6873707985 - Subscription Page Plan Filter */}
            {data?.plan && data?.plan.map((item, index) =>
                <Badge key={index}
                        style={badgeStyles}
                        onClick={() => onClick({component: 'plan', item})}
                        className={styles.badgeStyle}
                        pill={true}>
                    Plan: {item?.label}
                    <span>×</span>
                </Badge>
            )}
            {/* 6847419241 - Add filters to Store Page */}
            {data?.shopifyIntegrations && data?.shopifyIntegrations.map((item, index) =>
                <Badge key={index}
                       style={badgeStyles}
                       onClick={() => onClick({component: 'shopifyIntegrations', item})}
                       className={styles.badgeStyle}
                       pill={true}>
                    Shopify Integration: {item.label}
                    <span>×</span>
                </Badge>
            )}
            {/* 7196353058 - Subscription Page Status Filter */}
            {data?.status && data?.status.map((item, index) =>
                <Badge key={index}
                        style={badgeStyles}
                        onClick={() => onClick({component: 'status', item})}
                        className={styles.badgeStyle}
                        pill={true}>
                    Status: {item?.label}
                    <span>×</span>
                </Badge>
            )}
        </>
    )
}

export default BadgePillFilter;
