import React, { useContext, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { api } from "../../services/api";
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Input } from "reactstrap";
import Select from "react-select";
import { ComponentContext } from "../../contexts/ComponentContext";

const TrackingStatusModalForm = props => {

    const [listStatus, setListStatus] = useState([]);

    const [modal, setModal] = useState(props.isOpen);

    const [idFulfillment, setIdFulfillment] = useState([]);

    const [trackingStatus, setTrackingStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const { addComponent } = useContext(ComponentContext);

    const { addToast } = useToasts();

    /**
     * Handler for modal togle
     */
    const toggle = () => {
        setModal(!modal);
    };

    useEffect(() => {
        setModal(props.isOpen);
    }, [props.isOpen]);

    useEffect(() => {
        let options = [];
        props.data.map(result => options.push({
            value: result.id,
            label: result.name
        }));

        setListStatus(options);
    }, [props.data]);

    useEffect(() => {
        setIdFulfillment(props.fulfillment);
    }, [props.fulfillment]);

    /**
     * Handler for Tracking Status
     * @param {*} e 
     */

    const handleTrackingStatus = (e) => {
        e.preventDefault();
        let ids = [];
        let tracking_status = trackingStatus.label;

        const data = {
            fulfillment: props.orders[0].id,
            tracking_status,
        }
        setLoading(true);
        api.put("fulfillment/trackingstatus/update/", data)
            .then(response => {
                setModal(false)
                addToast('Saved Successfully', { appearance: 'success', autoDismiss: true });
                setLoading(false);
                addComponent({ assigneeModalForm: data });
            }).catch(error => addToast(error.message, { appearance: 'error' }));
    }

    return (
        <>
            {modal &&
                <Modal isOpen={modal} toggle={toggle} onExit={props.onExit}>
                    <Form onSubmit={handleTrackingStatus}>
                        <ModalHeader toggle={toggle} className="bg-primary text-white">
                            <span className="text-white h3">Update Tracking Status</span>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="exampleEmail">Tracking Status</Label>
                                <Select
                                    className="basic-single form-control m-0 p-0 border-0"
                                    classNamePrefix="select"
                                    placeholder={"Tracking Status"}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="tracking_status"
                                    options={listStatus}
                                    onChange={(e) => setTrackingStatus(e)}
                                />
                                <Input readOnly defaultValue={props.orders[0].id} style={{ marginTop: 20 }} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                            <Button color="primary" type="submit" disabled={loading}>
                                Change
                                {loading && <Spinner size="sm" color="light" />}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>}
        </>
    )
}

export default TrackingStatusModalForm;