import React, {useContext, useEffect, useState, useReducer} from "react";
import {useToasts} from "react-toast-notifications";
import {api} from "../../services/api";
import {AuthContext} from "../../contexts/AuthContext";
import moment from "moment/moment";
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label, PopoverBody, PopoverHeader,
    Spinner,
    UncontrolledDropdown,
    UncontrolledPopover
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Moment from "react-moment";

const EAT_OOS = (props) => {

    const [loading, setLoading] = useState(false)

    const [startDate, setStartDate] = useState(props.eta ? new Date(new Date(props.eta).toLocaleString("en", {timeZone: "America/New_York"})): null) ;
    const {addToast} = useToasts();

    const handleEtaOOS = (e) => {

        const data = {
            eta_date: e ? moment(e).format("MM/DD/YYYY") : null,
            order_id: parseInt(props.order)
        }

        api.put("order/set-eta-oos/", data)
            .then(response => response.data)
            .then(response => {

                if (response.success) {
                    setStartDate(e)
                    addToast(response.message,
                        {appearance: 'success', autoDismiss: true})
                } else {
                    addToast(response.message,
                    {appearance: 'error', autoDismiss: false})
                }
            })
    }
    const {user} = useContext(AuthContext);

  return (
      <>

      {user && (user.isAdmin || user.isRetailer) ?
          <div className={'input-group pr-2'}>

              <DatePicker
                  selected={startDate ?
                      startDate
                      : null}
                  onChange={(e) => handleEtaOOS(e)}
                  placeholderText={'ETA for OOS'}
                  dateFormat={"MM-dd-yyyy"}
                  isClearable={true}
                  disabled={props.order_managed_by}

              />
          </div>
          :

          <div className={'text-center'}>
              {startDate ? <Moment format="MM/D/YYYY" withTitle>{startDate}</Moment> :
                  <span>-</span>}

          </div>

      }
        </>

  );

}

export default EAT_OOS;