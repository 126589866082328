/*
    Author - Akshay Vishwakarma
    Description - This is a delete modal related Order Status Override Page 
    Jira (if any) - BCD - 3870
*/

import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";

const DeleteOrderStatusOverrideModal = ({
    delete_retailer_override_assignee_list,
    openDeleteModal,
    toggle,
    setOpenDeleteModal,
    deleteOrderStatusOverrideState,
    deleteOrderStatusOverrideSKU,
    deleteOrderStatusOverrideSalesChannel,
    deleteOrderStatusOverrideVariant,
    deleteOrderStatusOverrideNewStatus,
    deleteOrderStatusOverrideBrand,
    deleteOrderStatusOverrideCurrentStatus,
    deleteOrderStatusOverrideInventoryLocation,
    DeleteRetailerOverride,
    deleteOrderStatusOverrideId,
    deleteOrderStatusOverrideStartDate,deleteOrderStatusOverrideEndDate
    
}) => {
    return (
        <>
            <Modal style={{ marginTop: "100px" }} isOpen={openDeleteModal} size={"lg"} onExit={toggle} toggle={() => toggle()}>
                <ModalHeader className="bg-primary text-white">
                    <span className="text-white h3">Order Status Override</span>
                </ModalHeader>
                <ModalBody>
                    <h3 className="mb-4">
                        Are you sure you want to delete the following {delete_retailer_override_assignee_list.length} mappings:
                    </h3>
                    {delete_retailer_override_assignee_list.length > 0 ? (
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="pl-3 pr-3">
                                        Ship to state
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Brand
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Variant Name
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Sku
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Inventory Location
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Current Status
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        New Status
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Override State Date
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Orderride End Date
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Sales Channel
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {delete_retailer_override_assignee_list.map(record => (
                                    <tr>
                                        <td className="pl-3 pr-3">{ record.ship_to_state ?? "-"}</td>
                                        <td className="pl-3 pr-3">{ record.brand ?? "-"}</td>
                                        <td className="pl-3 pr-3">{ record.variant ?? "-"}</td>
                                        <td className="pl-3 pr-3">{ record.sku ?? "-"}</td>
                                        <td className="pl-3 pr-3">{ record.inventory_location ?? "-"}</td>
                                        <td className="pl-3 pr-3">{ record.current_status ?? "-"}</td>
                                        <td className="pl-3 pr-3">{ record.new_status ?? "-"}</td>
                                        <td className="pl-3 pr-3">{ record.override_start_date ?? "-"}</td>
                                        <td className="pl-3 pr-3">{ record.override_start_date ?? "-"}</td>
                                        <td className="pl-3 pr-3">{ record.channel ?? "-"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="pl-3 pr-3">
                                        Ship to state
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Brand
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Variant Name
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Sku
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Inventory Location
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Current Status
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        New Status
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Override State Date
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Orderride End Date
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Sales Channel
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <td className="pl-3 pr-3">{deleteOrderStatusOverrideState ?? "-"}</td>
                                <td className="pl-3 pr-3">{deleteOrderStatusOverrideBrand ?? "-"}</td>
                                <td className="pl-3 pr-3">
                                    {deleteOrderStatusOverrideVariant ?? "-"}
                                </td>
                                <td className="pl-3 pr-3">{deleteOrderStatusOverrideSKU ?? "-"}</td>
                                <td className="pl-3 pr-3">{deleteOrderStatusOverrideInventoryLocation ??"-"}</td>
                                <td className="pl-3 pr-3">{deleteOrderStatusOverrideCurrentStatus ??"-"}</td>
                                <td className="pl-3 pr-3">{deleteOrderStatusOverrideNewStatus ?? "-"}</td>
                                <td className="pl-3 pr-3">{deleteOrderStatusOverrideStartDate ?? "-"}</td>
                                <td className="pl-3 pr-3">{deleteOrderStatusOverrideEndDate ?? "-"}</td>
                                <td className="pl-3 pr-3">{deleteOrderStatusOverrideSalesChannel ?? "-"}</td>
                                </tbody>
                        </Table>
                    )}
                </ModalBody>
                <ModalFooter className="mt-0 pt-0">
                    <Button color="secondary" onClick={e => setOpenDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        color="danger"
                        id="delete_btn"
                        onClick={e =>
                            DeleteRetailerOverride(
                                delete_retailer_override_assignee_list.length > 0
                                    ? delete_retailer_override_assignee_list
                                    : deleteOrderStatusOverrideId
                            )
                        }>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default DeleteOrderStatusOverrideModal;