//**6322413816 - Store Page Migration Billable User Select */
import React, { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown, Input } from "reactstrap";

const BillableUserSelect = props => {
    const [value, setValue] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [billableUserOptions, setBillableUserOptions] = useState([]);
    const [filteredBillableUserOptions, setFilteredBillableUserOptions] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        setValue(props.children);
    }, []);

    //** fetching billable users list records */
    const getBillableUserOptions = () => {
        setLoading(true);
        api.get(`/store/list/billable-users`)
            .then(response => {
                const billableUsers = response?.data;
                const notBillableRecord = { name: 'Not Billable', user_id: 'not_billable' };
                const optionsWithNotBillable = [notBillableRecord, ...billableUsers];
                setBillableUserOptions(optionsWithNotBillable);
                setFilteredBillableUserOptions(optionsWithNotBillable);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    };

    /**
     * Handler for PUT request for Billable User
     * @param {*} e
     * @param {*} idBillableUser
     * @param {*} billable_user_name
     * @returns
     */
    const handleBillableUser = (e, idBillableUser, billable_user_name) => {
        e.preventDefault();
        setSubmitting(true);
        setValue(billable_user_name);
        props.onChange(props?.storeId, idBillableUser, billable_user_name);
        setSubmitting(false);
    };

    // 7051427604 - Add & remove billable user
    const handleRemoveUser = (storeId) => {
        setSubmitting(true);
        setValue("Not Billable");
        props.onRemove(storeId);
        setSubmitting(false);
    };

    // 7051427604 - Add & remove billable user
    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setSearch(searchValue);
        const filtered = billableUserOptions.filter((option) =>
            option?.name?.toLowerCase().includes(searchValue?.toLowerCase())
        );
        setFilteredBillableUserOptions(filtered);
    };

    const { user } = useContext(AuthContext);

    return (
        <>
            {user && user.isAdmin ? (
                <UncontrolledDropdown size={""}>
                    <DropdownToggle
                        color="white"
                        className="text-primary text-left border-primary px-2 py-1 position-relative d-flex align-items-center"
                        style={{ width: "130px", justifyContent: "space-between" }}
                        onClick={getBillableUserOptions}
                        caret
                    >
                        <div className="d-flex align-items-center">
                            <span
                                className="d-inline-block"
                                style={{ maxWidth: "80px", fontSize: "small", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                id={"id_assignee_" + parseInt(props.storeId)}
                            >
                                {value ? value : "Not Billable"}
                            </span>
                        </div>

                        {value && value !== "Not Billable" && (
                            <span
                                onClick={() => handleRemoveUser(props.storeId)}
                                style={{ cursor: "pointer", marginLeft: "auto", marginRight: "5px" }}
                            >
                                <i className="fas fa-times text-danger fa-sm"></i>
                            </span>
                        )}

                        {submitting && <Spinner size="sm" color="primary" />}
                    </DropdownToggle>

                    <DropdownMenu style={{ maxHeight: "300px", overflow: "scroll" }} positionFixed={true} className="more-filters-dropdown-menu" right>
                        <Input
                            type="text"
                            placeholder="Search..."
                            value={search}
                            onChange={handleSearch}
                            style={{ marginLeft: "5px", marginRight: "5px" }}
                        />
                        {!loading ? (
                            filteredBillableUserOptions && filteredBillableUserOptions.length > 0 ? (
                                filteredBillableUserOptions.map((option, index) => (
                                    <DropdownItem key={index} onClick={e => handleBillableUser(e, option?.user_id, option?.name)}>
                                        {option?.name}
                                    </DropdownItem>
                                ))
                            ) : (
                                <DropdownItem disabled={true}>No options</DropdownItem>
                            )
                        ) : (
                            <DropdownItem
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                <Spinner size={"sm"} color={"primary"} />
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>
            ) : (
                <Button
                    color={"white"}
                    className={"text-primary text-left border-primary px-2 py-1"}
                    style={{
                        width: "130px",
                        overflow: "hidden",
                        pointerEvents: "none"
                    }}>
                    <span className={"d-inline-block "} style={{ width: "100px" }} id={"id_billableuser_" + props.storeId}>
                        {value?.substring(0, 16)}
                    </span>
                </Button>
            )}
        </>
    );
};

export default BillableUserSelect;
