/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to section three of product page (here images upload is handled).
  
 */

import React, { useEffect, useState } from "react";
import styles from "./createProduct.module.css";
import { CustomDropdown } from "components/CustomDropdown/CustomDropdown";
import { FaTrash } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import "../../../utils/commonStyles.css";

const SectionThree = ({ setVariant_images, payload, setPayload, errors }) => {
    const { addToast } = useToasts();
    const [error, setError] = useState(null);
    const [disabledOptions, setDisabledOptions] = useState([]);

    const handleImageChange = (event, index) => {
        const file = event.target.files[0];

        if (file) {
            if (!["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
                setError(`*The file ${file.name} is not an image of [jpeg, png, jpg].`);
                return;
            }
            if (file.size > 20 * 1024 * 1024) {
                setError(`*The file ${file.name} is too large. Maximum size allowed is 20MB.`);
                return;
            }
            setError(null);
            const reader = new FileReader();
            let tempArray = [...payload.images];
            reader.onloadend = () => {
                tempArray[index].imageToPreview = reader.result;
                setPayload({ ...payload, images: tempArray });
            };
            reader.readAsDataURL(file);
            tempArray[index].image = file;
        }
    };

    const addDefaultImage = () => {
        if (payload.variant_size_for_images.length === 0) {
            addToast("Select size to add image", { appearance: "error", autoDismiss: true });
            return;
        }
        const defaultImageUrl = "https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png";
        const newId = Date.now();
        const newImageId = "img_" + newId; // AW-310524
        setPayload(prevPayload => ({
            ...prevPayload,
            images: [...prevPayload.images, { id: newId, title: [], imageToPreview: defaultImageUrl, image: defaultImageUrl, image_id: newImageId}]
        }));
    };

    const removeImage = index => {
        const val = payload.images.filter((_, i) => i === index);
        const imagesToBeDeleted = Array.isArray(payload.images_to_be_deleted) ? payload.images_to_be_deleted : [];
        const img_to_del = [...imagesToBeDeleted,...val]
        setError(null);
        setPayload({ ...payload, images: payload.images.filter((_, i) => i !== index), disabledOptions:payload.disabledOptions.filter((_, i) => i !== index), images_to_be_deleted:img_to_del});
    };

    const handleVariantChange = (index, variants) => {
        const variantLabels = payload.variant_size_for_images.filter(variant => (variant.value === variants));
        setPayload(prevPayload => ({
            ...prevPayload,
            images: prevPayload.images.map((img, i) => (i === index ? { ...img, id: variantLabels[0].value, title: variantLabels[0].label, variants } : img)),
            disabledOptions:[]
        }));
        updateDisabledOptions();
    };

    const updateDisabledOptions = () => {
        const selectedVariants = payload.images.map(img => img.variants).filter(Boolean);
        setDisabledOptions(selectedVariants);
    };

    const getSizeOptions = () => {
        const allOptions = payload.variant_size_for_images.map(option => ({
            ...option,
            isDisabled: disabledOptions.includes(option.value) || payload.disabledOptions.includes(option.value)
        }));
        return allOptions;
    };

    useEffect(() => {
        setVariant_images([...payload.images]);
        updateDisabledOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload]);

    return (
        <div>
            <div className={styles.sectionConatiner}>
                <div style={{ marginBottom: "2rem" }}>
                    <h2>Images</h2>
                    <h5 style={{ color: "#a9afbd" }}>
                        Images must be square, high-resolution, and no larger than 20MB. If you intend to list your product on Mash&Grape, a white or
                        transparent background is required.
                    </h5>
                    <hr />
                </div>
                {error && <p className={styles.errorMessage}>{error}</p>}
                <div className={styles.imagePreviewContainer}>
                    {payload.images.map((image, index) => (
                        <div style={{ display: "flex", flexDirection: "column", marginBottom: "3rem" }} key={index}>
                            <div key={index} className={styles.imagePreview} style={{ marginBottom: "4rem" }}>
                                <img src={image.imageToPreview} alt={`Preview ${index}`} style={{ objectFit: "contain" }} />
                                <div className={styles.buttonOverlay}>
                                    <label htmlFor={`edit-image-input-${index}`} className={styles.editButton}>
                                        Choose Image
                                    </label>
                                    <input
                                        type="file"
                                        id={`edit-image-input-${index}`}
                                        accept="image/*"
                                        onChange={e => handleImageChange(e, index)}
                                        placeholder="Choose Image"
                                        style={{ display: "none" }}
                                    />
                                    <button type="button" className={styles.deleteButton} onClick={() => removeImage(index)}>
                                        <FaTrash size={18} />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <CustomDropdown
                                    className="select-input"
                                    placeholder="Select Variant"
                                    options={getSizeOptions()}
                                    isValid={true}
                                    onChange={value => handleVariantChange(index, value)}
                                    value={image.id}
                                />
                                {/* Monday Task: 6999628932_7012537690 Added Error message next to field*/}
                                {errors.variant_images && errors.variant_images[index] && (
                                    <>
                                        {errors.variant_images[index].title && <p className={styles.errorMessage}>{errors.variant_images[index].title}</p>}
                                        {errors.variant_images[index].image && <p className={styles.errorMessage}>{errors.variant_images[index].image}</p>}
                                        {errors.variant_images[index].size && <p className={styles.errorMessage}>{errors.variant_images[index].size}</p>}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {/* 7278215305 - increase font size */}
                        <button type="button" onClick={addDefaultImage} className={`${styles.distributionButton} button-font-size`}>
                            Add Variant Image
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionThree;
