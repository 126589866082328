//**6322413816 - Store Page Migration View */
import React, { useContext, useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Card, CardFooter, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { api } from "services/api";
import BillableUserSelect from "components/Select/BillableUser";
import ShopifyIntegrationSelect from "components/Select/ShopifyIntegration";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Filter from "../../components/Filters/Filter";
import { addURLParam, removeAllURLParams, removeURLParam } from "../../utils/urls";
import BadgePillFilter from "components/Filters/Badge";
import { useToasts } from "react-toast-notifications";
import "../../utils/commonStyles.css"

const StoreListing = () => {
    const [isLoading, setLoading] = useState(false);
    const [storesList, setStoresList] = useState([]);
    const [storesCount, setstoresCount] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [sort, setSort] = useState("-id");
    const isMobile = useMobileDetection(mobileScreenThresholdValue);
    const [params, setParams] = useState([]);
    const history = useHistory();
    const [clearall, setClearAll] = useState(false)
    const [selected, setSelected] = useState([])
    const [filter, setFilter] = useState({
        brands: [],
        shopifyIntegrations: [],
        statuses: [],
        group: [],
    });

    const { addToast } = useToasts();

    const onFilterChange = (e, prefix) => {
        let filteredRecords = [...new Set(filter[`${prefix}`])];

        if (e.target.checked) {
            filteredRecords.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, prefix, e.target.value);
        } else {
            filteredRecords = filteredRecords.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefix, e.target.value);
        }
        let updatedFilter = { ...filter };
        updatedFilter[`${prefix}`] = filteredRecords;
        setFilter(updatedFilter);
    }

    const getParams = () => {
        let new_params = {
            page,
            ordering: sort
        }
        if (filter.brands.length > 0) {
            new_params.brand = []
            filter.brands.map(item => new_params.brand.push(item.value))
            setClearAll(true)
        }
        if (filter.shopifyIntegrations.length > 0) {
            new_params.shopifyIntegrations = []
            filter.shopifyIntegrations.map(item => new_params.shopifyIntegrations.push(item.value))
            setClearAll(true)
        }
        if (filter.statuses.length > 0) {
            new_params.statuses = []
            filter.statuses.map(item => new_params.statuses.push(item.value))
            setClearAll(true)
        }
        if (filter.group.length > 0) {
            new_params.group = []
            filter.group.map(item => new_params.group.push(item.value))
            setClearAll(true)
        }
        setClearAll(filter.brands.length > 0 || filter.shopifyIntegrations.length > 0 || filter.statuses.length > 0 || filter.group.length > 0);
        setParams(new_params)
        console.log(new_params)
        return new_params;
    }

    //**useEffect for fetching store records */
    useEffect(() => {
        setLoading(true);
        const new_params = getParams();
        api.get("store/", {
            params: new_params
        })
            .then(response => {
                setStoresList(response?.data?.results);
                setstoresCount(response?.data?.count);
                setLoading(false);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) {
                                pageList.push(i);
                            }
                        }
                        return pageList;
                    }
                });
                delete new_params["page"];
                delete new_params["ordering"];
                setParams(new_params);
            })
            .catch(err => {
                if (err.response.status === 403)
                    addToast(err.response.data.detail, { appearance: "error", autoDismiss: true })
                history.push("dashboard")
            });
    }, [sort, page, isMobile, filter]);

    const handlePagination = page => {
        setPage(page);
    };

    const clearFilter = (prefix) => {

        if (prefix === "brands") {
            setFilter({
                ...filter, brands: []
            })
            removeURLParam(window, "brands");
            setClearAll(false);
        }
        if (prefix === "shopifyIntegrations") {
            setFilter({
                ...filter, shopifyIntegrations: []
            })
            removeURLParam(window, "shopifyIntegrations");
            setClearAll(false);
        }
        if (prefix === "statuses") {
            setFilter({
                ...filter, statuses: []
            })
            removeURLParam(window, "statuses");
            setClearAll(false);
        }
        if (prefix === "group") {
            setFilter({
                ...filter, group: []
            })
            removeURLParam(window, "group");
            setClearAll(false);
        }
    }
    const removeBadge = (e) => {
        if (e.component === 'brand') {
            let items = filter.brands
            items = items.filter(item => item.value !== e.item.value)
            setFilter({ ...filter, brands: items })
            removeURLParam(window, "brands", e.item.value);
        } else if (e.component === "shopifyIntegrations") {
            let items = filter.shopifyIntegrations
            items = items.filter(item => item.value !== e.item.value)
            setFilter({ ...filter, shopifyIntegrations: items })
            removeURLParam(window, "shopifyIntegrations", e.item.value);
        } else if (e.component === "status") {
            let items = filter.statuses
            items = items.filter(item => item.value !== e.item.value)
            setFilter({ ...filter, statuses: items })
            removeURLParam(window, "statuses", e.item.value);
        }
        setClearAll(false)
    }

    const handleClearAll = () => {

        setClearAll(false)

        setFilter({
            brands: [],
            shopifyIntegrations: [],
            statuses: [],
            group: [],
        })
        removeAllURLParams()
    }

    // 7051427604 - Add & remove billable user
    const handleChange = (storeId, idBillableUser, billableUserName) => {
        const data = {
            billable_user: idBillableUser
        };
        api.put(`store/billable-user/${storeId}/`, data)
            .then(response => {
                if (response.status === 200) {
                    addToast("Billable user saved successfully", {
                        appearance: "success",
                        autoDismiss: true
                    });
                } else {
                    addToast("Something went wrong, please try again!!", {
                        appearance: "error"
                    });
                }
            })
            .catch(error => {
                addToast(error.message, { appearance: "error" });
            });
        return true;
    };
    
    const handleRemove = (storeId) => {
        api.put(`store/remove-billable-user/${storeId}/`)
            .then(response => {
                if (response.status === 200) {
                    addToast("Billable user removed successfully", {
                        appearance: "success",
                        autoDismiss: true
                    });
                } else {
                    addToast("Something went wrong, please try again!!", {
                        appearance: "error"
                    });
                }
            })
            .catch(error => {
                addToast(error.message, { appearance: "error" });
            });
        return true;
    };

    return (
        <>
            <Container fluid>
                <div className={"pt-7"}>
                    <Row className={"mb-2"}>
                        <Col xs={8} lg={2} className={"text-left"}></Col>
                        <Col xs={2} lg={"10"} className={"text-right"}>
                            {/* 6847419241 - Add filters to Store Page */}
                            <Filter
                                name={"Brand"}
                                prefix={"brands"}
                                onChange={onFilterChange}
                                newparams={params}
                                selected={filter.brands}
                                url={"store/brand-filter"}
                                inputSearch={true}
                                onClear={() => clearFilter("brands")}
                            />
                            <Filter
                                name={"Shopify Integration"}
                                prefix={"shopifyIntegrations"}
                                onChange={onFilterChange}
                                newparams={params}
                                selected={filter.shopifyIntegrations}
                                url={"store/filter/shopify-integration/"}
                                inputSearch={true}
                                onClear={() => clearFilter("shopifyIntegrations")}
                            />
                            <Filter
                                name={"Status"}
                                prefix={"statuses"}
                                onChange={onFilterChange}
                                newparams={params}
                                selected={filter.statuses}
                                url={"store/filter/status/"}
                                inputSearch={false}
                                onClear={() => clearFilter("statuses")}
                            />
                            <Filter
                              name={"Group"}
                              prefix={"group"}
                              onChange={onFilterChange}
                              newparams={params}
                              selected={filter.group}
                              url={"store/filter/group/"}
                              inputSearch={false}
                              onClear={() => clearFilter("group")}
                            />
                            <div className={"d-none d-lg-inline-block mr-2"}>
                                <a href={window.env.APP_URL + "store/create/"}>
                                    {/* 7278215305 - increase font size */}
                                    <button className="button-font-size btn btn-success">Add Store</button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <div className="col">
                        <Card className="shadow ">
                            <CardHeader className="border-0">
                                <Row className="d-flex justify-content-between align-items-center p-1">
                                    <div className="d-flex">
                                        <h3 className="mb-0">
                                            Store <small>({storesCount})</small>
                                            {clearall &&
                                                <a href={void (0)}
                                                    className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                    onClick={handleClearAll}
                                                    id="clear_all_filters">
                                                    <i className="fas fa-times-circle"></i> Clear All filters
                                                </a>
                                            }
                                        </h3>
                                    </div>
                                    <Col sm="12"><BadgePillFilter title="Store" data={filter}
                                        selected={selected} onClick={removeBadge} />
                                    </Col>
                                </Row>
                            </CardHeader>
                            {isLoading ? (
                                <div className="text-center mt-3 mb-3">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" className="pl-5">
                                                    Name
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    Domain
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    Shopify Integration
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    Code
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    Created at
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    Billable users
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    Status
                                                </th>
                                                <th scope="col" className="pl-5">
                                                    Scopes
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {storesList.map(store => (
                                                <tr key={store.id}>
                                                    <td className="pl-5">
                                                        <a className="btn btn-link p-0" href={`/store-details?${new URLSearchParams({ storeId: `${store?.id}` }).toString()}`} >
                                                            {/* <span
                                                                onClick={() =>
                                                                    history.push({
                                                                        pathname: `/store-details`,
                                                                        search: new URLSearchParams({ storeId: `${store?.id}` }).toString()
                                                                    })
                                                                }>
                                                                {store?.name}
                                                            </span> */}
                                                            {store?.name}
                                                        </a>
                                                    </td>
                                                    <td className="pl-5">{store.domain}</td>
                                                    <td className="pl-5">
                                                        <ShopifyIntegrationSelect data={null} storeId={store.id}>
                                                            {store.shopify_integration}
                                                        </ShopifyIntegrationSelect>
                                                    </td>
                                                    <td className="pl-5">{store.code}</td>
                                                    <td className="pl-5">
                                                        <Moment format="MM/D/YYYY" withTitle>
                                                            {store.created_at}
                                                        </Moment>
                                                    </td>
                                                    <td className="pl-5">
                                                        <BillableUserSelect
                                                            data={null}
                                                            storeId={store.id}
                                                            billable_user_id={store?.billable_user_id}
                                                            billable_status={store?.billable}
                                                            onChange={handleChange}
                                                            onRemove={handleRemove}>
                                                            {store.billable_user_name}
                                                        </BillableUserSelect>
                                                    </td>
                                                    <td className="pl-5">
                                                        {store.is_active ? (
                                                            <i className="fas fa-check-circle text-success text-lg"></i>
                                                        ) : (
                                                            <i className="fas text-gray fa-exclamation-circle text-lg"></i>
                                                        )}
                                                    </td>
                                                    <td className="pl-5">
                                                        {store?.scope?.length > 1 ? (
                                                            <i className="fas text-danger fa-times-circle text-lg"></i>
                                                        ) : (
                                                            <i className="fas fa-check-circle text-success text-lg"></i>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="text-center">
                                        <nav aria-label="...">
                                            {pagination.pages().length > 1 && (
                                                <Pagination className="pagination justify-content-center mb-0" listClassName="justify-content-center mb-0">
                                                    <PaginationItem className="">
                                                        <PaginationLink href="#" onClick={e => handlePagination(pagination?.previous)} tabIndex="-1">
                                                            <i className="fas fa-angle-left" />
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {pagination.pages().map((pageIndex, index) => (
                                                        <PaginationItem key={index} className={page === pageIndex ? "active" : ""}>
                                                            <PaginationLink href="#" onClick={e => handlePagination(pageIndex)}>
                                                                {pageIndex}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                    <PaginationItem>
                                                        <PaginationLink href="#" onClick={e => handlePagination(pagination?.next)}>
                                                            <i className="fas fa-angle-right" />
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            )}
                                        </nav>
                                    </CardFooter>
                                </>
                            )}
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default StoreListing;
