import React from "react"
import 'ol/ol.css';
import OpenStreetMap from "components/Map/OpenStreetMap";
import { MapWrapper } from "contexts/MapContext";
import { api } from "services/api";
import InventoryLocationSidebar from '../../components/InventoryLocation/InventoryLocationSidebar'

export const getInventoryLocations = async (brandId) => {
  let inventoryLocations = api.get("inventory-location/find/by-brand-id/", { params: { "brands_id": brandId } })
    .then((response) => response)
    .then((response) => {
      if (response.status === 200) {
        let pk = response.data;
        return pk.result;
      } else {
        return []
      }
    })
    .catch((err) => { console.log(err) });
  return inventoryLocations
}


const InventoryLocation = () => {
  const [inventoryLocations, setinventoryLocations] = React.useState([])

  const fetchAndStoreInventoryLocations = async (brandId) => {
    setinventoryLocations(await getInventoryLocations(brandId))
  }

  React.useEffect(() => {
    setinventoryLocations([])
  }, [])

  return (
    <MapWrapper inventoryLocations={inventoryLocations} setinventoryLocations={setinventoryLocations} fetchAndStoreInventoryLocations={fetchAndStoreInventoryLocations}>
      <OpenStreetMap>
        <InventoryLocationSidebar />
      </OpenStreetMap>
    </MapWrapper>
  )
}

export default InventoryLocation