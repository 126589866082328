import React from "react";
import "./style.css";

function Toggle(props) {
    const { text, size = "default", checked, disabled, onChange, offstyle = "btn-danger", onstyle = "btn-success", className = "" } = props;

    let displayStyle = checked ? onstyle : offstyle;
    return (
        <label>
            <span className={`${size} switch-wrapper`}>
                <input
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                />
                <span className={`${displayStyle} switch`}>
                    <span className="switch-handle" />
                </span>
            </span>
            <span className="switch-label">{text}</span>
        </label>
    );
}

export default Toggle;
