import React, { createContext, useState } from "react";
import * as ol from "ol";
import VectorSource from 'ol/source/Vector';
import { fromLonLat } from "ol/proj";
import { api } from "services/api";
import { Point } from "ol/geom";
import { Feature } from "ol";
import { Icon, Style } from "ol/style";

export const getInventoryLocations = async (brandId = "2172") => {
    let inventoryLocations = api.get(`inventory-location/find/by-brand-id/?brands_id=${brandId}`)
        .then((response) => response)
        .then((response) => {
            if (response.status === 200) {
                let pk = response.data;
                return pk.result;
            } else {
                return []
            }
        })
        .catch((err) => { console.log(err) });
    return inventoryLocations
}

export const OpenStreetMapContext = createContext()
export function MapWrapper({ children, inventoryLocations, fetchAndStoreInventoryLocations, setinventoryLocations }) {

    let markers = [];

    const iconStyle = new Style({
        image: new Icon({
            anchor: [0.5, 0.5],
            size: [512, 512],
            scale: 0.050,
            src: `${window.env.WEB_WIDGET_BASE_URL}/marker.png`,
        }),
    });

    inventoryLocations.forEach(element => {
        element && element.lat != null && element.long != null && markers.push(new Feature({
            geometry: new Point(fromLonLat([element.lat, element.long])),
        }))
    });

    markers.forEach((element) => {
        element.setStyle(iconStyle)
    })

    const vectorSource = new VectorSource({ features: markers });

    const [flyTo, setFlyTo] = useState([]);
    const [center, setcenter] = useState([-104.49499055277397, 37.89559559742838]);
    const [zoom, setzoom] = useState(4);
    const [placeSelected, setplaceSelected] = useState(false);

    const viewState = new ol.View({
        center: fromLonLat(center),
        zoom: zoom,
    })

    const changeFlyTo = (coodinates) => {
        setFlyTo(coodinates)
    }

    const changeMapCenter = (newCenterCoordinatesArray) => {
        setcenter(newCenterCoordinatesArray)
    }

    const changeMapZoom = (zoomValue) => {
        setzoom(zoomValue)
    }

    const changePlaceSelectedOrNot = (value) => {
        setplaceSelected(value)
    }

    return (
        <OpenStreetMapContext.Provider
            value={{ flyTo, changeFlyTo, vectorSource, viewState, changePlaceSelectedOrNot, placeSelected, fetchAndStoreInventoryLocations, inventoryLocations, zoom, center, setinventoryLocations, changeMapCenter, changeMapZoom }}>
            {children}
        </OpenStreetMapContext.Provider>
    )
}