import React, {useContext, useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import {api} from "../../services/api";
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import Select from "react-select";
import {ComponentContext} from "../../contexts/ComponentContext";

const AssigneeModalForm = props => {

    const [listStatus, setListStatus] = useState([])

    const [modal, setModal] = useState(props.isOpen);

    const [idOrders, setIdOrders] = useState([])

    const [assignee, setAssignee] = useState(null)
    const [loading, setLoading] = useState(false)
    const {addComponent} = useContext(ComponentContext)

    const {addToast} = useToasts();

    const toggle = () => {
        setModal(!modal)
    };

    useEffect(() => {
        setModal(props.isOpen)
    }, [props.isOpen])

    useEffect(() => {
        let options = []
        props.data.map(result => options.push({
            value: result.id,
            label: result.name
        }))

        setListStatus(options)
    }, [props.data])


    useEffect(() => {
        setIdOrders(props.orders)
    }, [props.orders])

    useEffect(() => {
        return () => {
            console.log("cleaned up");
        };

    }, [])

    const handleAssignee = (e) => {
        e.preventDefault();
        let ids = [];
        console.log(assignee)
        console.log(e)
        idOrders.map(order => ids.push(parseInt(order.id)))
        let id_assignee = null
        try{
            id_assignee = assignee.value
        }catch (e) {
            id_assignee = listStatus[0].value
        }

        const data = {
            id_assignee,
            id_orders: ids
        }
        console.log(data)
        setLoading(true)
        api.put("order/assignee-states/update/", data)
            .then(response => {
                setModal(false)
                addToast('Saved Successfully', {appearance: 'success', autoDismiss: true});
                setLoading(false)
                addComponent({assigneeModalForm: data})
                if(props?.onComplete) {
                    props?.onComplete();
                }
            }).catch(error => addToast(error.message, {appearance: 'error'}));
    }

    return (
        <>
            {modal &&
            <Modal isOpen={modal} toggle={toggle} onExit={props.onExit}>
                <Form onSubmit={handleAssignee}>
                    <ModalHeader toggle={toggle} className="bg-primary text-white">
                        <span className="text-white h3">Update Assignee</span>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="exampleEmail">Assignee</Label>
                            <Select
                                className="basic-single form-control m-0 p-0 border-0"
                                classNamePrefix="select"
                                placeholder={"Assignee"}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="order_status"
                                options={listStatus}
                                onChange={(e) => setAssignee(e)}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                        <Button color="primary" type="submit" disabled={loading}>
                            Change
                            { loading && <Spinner size="sm" color="light" /> }
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>}
        </>
    )
}

export default AssigneeModalForm;