import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'
import { api } from 'services/api'
import '../../assets/scss/order-details.scss'

export const OrderPaymentStatus = (props) => {
  let class_element = '';
  if (props.children === 'paid') {
    class_element = 'text-success text-capitalize';
  } else if (props.children === 'refunded') {
    class_element = 'text-gray text-capitalize';
  } else {
    class_element = 'text-yellow text-capitalize';
  }

  const { addToast } = useToasts();
  const { user } = useContext(AuthContext);

  const handleOrderPaymentStatus = (e) => {
    e.preventDefault();

    const data = {
      financial_status: e.target.value,
      orders: [parseInt(props.order)]
    };

    api.put('order/order-financial-status/update', data)
      .then(response => {
        if (response?.data?.status_code == 200 ) {
          addToast(response.data.data, { appearance: 'success', autoDismiss: true });
          props.updateOrderStatus(props.order)
        } else if (response?.data?.status_code == 500) {
          addToast(response.data.data, { appearance: 'error', autoDismiss: true });
        }
      }).catch(error => {
        addToast(error.message, { appearance: 'error' });
      });
  }

  return (
    <>
    {/* 6193203826 - Block order changes on WMS Orders*/}
    {user && (user.isAdmin) && (!props.financialStatusLock) && (props.orderManagedLock===false) ? (
        <select name="payout_status"
                className={`form-control form-control-sm mx-1 ${class_element}`}
                onChange={handleOrderPaymentStatus}
                value={props.status}
        >
          {props.data?.map(status =>
              <option
                key={status.id}
                value={status.id}
                className={`form-control form-control-sm mx-1 ${class_element}`}
              >{status.name}</option>
          )}
        </select>
    ) : (
      <span className={class_element}>{props.children}</span>
    )}
    </>
  )
}
