/*
    Author - Akshay Vishwakarna
    MONDAY ID - 6910917101
    Description - this is a common component to implement toggle feature for fullfiment states
*/

import React, { useState, useEffect } from "react";
import { stateListOptions } from "../../utils/constants";
import { Table } from "reactstrap";

const FulfillmentStatesTable = ({ formState, setFormState, existingUserData, update }) => {
    const [stateListOption, setStateListOption] = useState(stateListOptions.filter(option => option.value !== "none" && option.value !== "__all__"));
    const [toggleAllStates, setToggleAllStates] = useState(false);
    const [toggleAllTaxStates, setToggleAllTaxStates] = useState(false);

    const handleStateChange = value => {
        const updatedOptions = stateListOption.map(option =>
            option.value === value ? { ...option, isStateOn: !option.isStateOn, isTaxOn: option.isStateOn && option.isTaxOn ? false : option.isTaxOn } : option
        );
        setStateListOption(updatedOptions);
        checkAllToggles(updatedOptions);
    };

    const handleTaxChange = value => {
        const updatedOptions = stateListOption.map(option =>
            option.value === value ? { ...option, isTaxOn: !option.isTaxOn, isStateOn: !option.isStateOn ? true : option.isStateOn } : option
        );
        setStateListOption(updatedOptions);
        checkAllToggles(updatedOptions);
    };

    const handleToggleAllStates = () => {
        const newToggleState = !toggleAllStates;
        setToggleAllStates(newToggleState);
        setStateListOption(prev => prev.map(option => ({ ...option, isStateOn: newToggleState, isTaxOn: newToggleState ? option.isTaxOn : false })));
    };

    const handleToggleAllTaxStates = () => {
        const newToggleState = !toggleAllTaxStates;
        setToggleAllTaxStates(newToggleState);
        if (newToggleState) {
            setToggleAllStates(true);
        }
        setStateListOption(prev => prev.map(option => ({ ...option, isTaxOn: newToggleState, isStateOn: newToggleState ? true : option.isStateOn })));
    };

    const checkAllToggles = options => {
        const allStatesOn = options.every(option => option.isStateOn);
        const allTaxOn = options.every(option => option.isTaxOn);
        setToggleAllStates(allStatesOn);
        setToggleAllTaxStates(allTaxOn);

        if (!allStatesOn || !allTaxOn) {
            const filtered = options.filter(option => option.isStateOn || option.isTaxOn);
            setFormState({ ...formState, fulfillmentStates: filtered, all_fullfillment_Active: allStatesOn, all_tax_Active: allTaxOn });
        } else {
            setFormState({ ...formState, fulfillmentStates: [], all_fullfillment_Active: allStatesOn, all_tax_Active: allTaxOn });
        }
    };

    useEffect(() => {
        checkAllToggles(stateListOption);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateListOption]);

    useEffect(() => {
        if (update) {
            setStateListOption(existingUserData.stateListOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingUserData]);

    return (
        <div style={{ maxHeight: "400px", overflowY: "auto", border: "1px solid #ddd" }}>
            <Table>
                <thead style={{ position: "sticky", top: "-1px", backgroundColor: "#ebebeb", zIndex: 1 }}>
                    <tr>
                        <th style={{ padding: "7px", margin: "0", fontSize: "0.8rem", verticalAlign: "middle", textTransform: "capitalize" }}>STATE NAME</th>
                        <th style={{ padding: "7px", margin: "0", fontSize: "0.8rem", textAlign: "center", textTransform: "capitalize" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <span>STATES</span>
                                <label className="switch" style={{ marginTop: "5px" }}>
                                    <input id="fulfillmentState" type="checkbox" onChange={handleToggleAllStates} checked={toggleAllStates} />
                                    <span className="slider round">
                                        <span className="toggle-text on-text">ON</span>
                                        <span className="toggle-text off-text">OFF</span>
                                    </span>
                                </label>
                            </div>
                        </th>
                        <th style={{ padding: "7px", margin: "0", fontSize: "0.8rem", textAlign: "center", textTransform: "capitalize" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <span>CHARGE TAX</span>
                                <label className="switch" style={{ marginTop: "5px" }}>
                                    <input type="checkbox" onChange={handleToggleAllTaxStates} checked={toggleAllTaxStates} />
                                    <span className="slider round">
                                        <span className="toggle-text on-text">ON</span>
                                        <span className="toggle-text off-text">OFF</span>
                                    </span>
                                </label>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {stateListOption.map(options => (
                        <tr key={options.value}>
                            <td style={{ padding: "7px", margin: "0", fontSize: "1rem" }}>{options.label}</td>
                            <td style={{ padding: "7px", margin: "0", fontSize: "1rem", textAlign: "center" }}>
                                <label className="switch">
                                    <input type="checkbox" onChange={() => handleStateChange(options.value)} checked={options.isStateOn || false} />
                                    <span className="slider round">
                                        <span className="toggle-text on-text">ON</span>
                                        <span className="toggle-text off-text">OFF</span>
                                    </span>
                                </label>
                            </td>
                            <td style={{ padding: "7px", margin: "0", fontSize: "1rem", textAlign: "center" }}>
                                <label className="switch">
                                    <input type="checkbox" onChange={() => handleTaxChange(options.value)} checked={options.isTaxOn || false} />
                                    <span className="slider round">
                                        <span className="toggle-text on-text">ON</span>
                                        <span className="toggle-text off-text">OFF</span>
                                    </span>
                                </label>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default FulfillmentStatesTable;
