import React from "react";
import { Table } from "reactstrap";
import ChargebacksTableRow from "./ChargebackTableRow";
import { Sort, handleSort } from "../../utils/commonFunctions";

const ChargebacksTable = ({
    chargebacks,
    handleCheckbox,
    handleSelectAllCheckbox,
    selected,
    handleUpdateChargebackStatusModal,
    refChargebacksCheckbox,
    sort,
    setSort,
    setSortOrder,
    sortOrder,
    user
}) => {
    return (
        <div className="col">
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                        <th scope="col" className="pl-3 pr-3">
                            <input
                                type="checkbox"
                                name={"check_chargebacks"}
                                id={"checkbox_chargebacks"}
                                ref={refChargebacksCheckbox}
                                defaultChecked={refChargebacksCheckbox.current}
                                onChange={e => handleSelectAllCheckbox(e)}
                            />
                        </th>
                        <th scope="col" className="pl-1 pr-1">
                            Brand Name
                        </th>
                        <th scope="col" className="pl-1 pr-1">
                            Order ID
                        </th>
                        <th scope="col" className="pl-1 pr-1">
                            <Sort
                                onClick={() => handleSort(sort, "order__created_at", setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="order__created_at">
                                Order Created At
                            </Sort>
                        </th>
                        {/* BCD-4294 Discount Code Chargeback Created At */}
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "discount_chargeback_created_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="discount_chargeback_created_at">
                                Discount Chargeback Created At
                            </Sort>
                        </th>
                        <th scope="col" className="pl-1 pr-1">
                            <Sort
                                onClick={() => handleSort(sort, "code", setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="code">
                                Discount Code
                            </Sort>
                        </th>
                        <th scope="col" className="pl-1 pr-1">
                            Discount Code Type
                        </th>
                        <th scope="col" className="pl-1 pr-1">
                            Discount Code Value
                        </th>
                        <th scope="col" className="pl-1 pr-1">
                            <Sort onClick={() => handleSort(sort, "order__subtotal", setSortOrder, setSort)} sortingOrder={sortOrder} column="order__subtotal">
                                Order Subtotal
                            </Sort>
                        </th>
                        <th scope="col" className="pl-1 pr-1">
                            <Sort onClick={() => handleSort(sort, "order__source", setSortOrder, setSort)} sortingOrder={sortOrder} column="order__source">
                                Sales Channel
                            </Sort>
                        </th>
                        <th scope="col" className="pl-1 pr-1">
                            Invoice
                        </th>
                        {user?.isAdmin && (
                            <th scope="col" className="pl-1 pr-1">
                                Billable User
                            </th>
                        )}
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "billing_status", setSortOrder, setSort)} sortingOrder={sortOrder} column="billing_status">
                                Billing Status
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {chargebacks?.map(chargeback => (
                        <ChargebacksTableRow
                            key={chargeback?.id}
                            chargeback={chargeback}
                            handleCheckbox={handleCheckbox}
                            selected={selected}
                            handleUpdateChargebackStatusModal={handleUpdateChargebackStatusModal}
                            user={user}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ChargebacksTable;
