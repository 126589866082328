import Cookies from 'universal-cookie';

export const TOKEN_KEY_APP = window.env.TOKEN_KEY_APP;
export const TOKEN_KEY = `${TOKEN_KEY_APP}.token`;
export const REFRESH_KEY = `${TOKEN_KEY_APP}.refresh`;
export const DEBUG = window.env?.DEBUG || false;

export const getToken = () => {
  const cookies = new Cookies(document.cookie);
  return cookies.get(TOKEN_KEY);
}

export const getLogoutToken = () => {
  const cookies = new Cookies(document.cookie);
  if (cookies.get(window.env.LOGOUT_TOKEN) === 'false'){
    return false
  }else{
    return true
  }

}

export const getRefreshToken = () => {
  const cookies = new Cookies(document.cookie);
  return cookies.get(REFRESH_KEY);
}

export const setToken = token => {
  const cookies = new Cookies();
  if (DEBUG){
    cookies.set(TOKEN_KEY, token, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30 // 30 days
    });
  }else{
    cookies.set(TOKEN_KEY, token, {
      path: '/',
      domain: '.flaviar.com',
      maxAge: 60 * 60 * 24 * 30 // 30 days
    });
  }
}

export const setRefreshToken = refresh => {
  const cookies = new Cookies();
  if (DEBUG){
    cookies.set(REFRESH_KEY, refresh, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30 // 30 days
    });
  }else {
    cookies.set(REFRESH_KEY, refresh, {
      path: '/',
      domain: '.flaviar.com',
      maxAge: 60 * 60 * 24 * 30 // 30 days
    });
  }
}

export const removeTokens = () => {
    const cookies = new Cookies(document.cookie);

if (DEBUG){
  cookies.remove(TOKEN_KEY, {
    path: '/',
  });
  cookies.remove(REFRESH_KEY, {
    path: '/',
  });
}else{
  cookies.remove(TOKEN_KEY, {
    path: '/',
    domain: '.flaviar.com',
  });
  cookies.remove(REFRESH_KEY, {
    path: '/',
    domain: '.flaviar.com',
  });
}
}