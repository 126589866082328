/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to section two for product Details
  
 */

import React from "react";
import styles from "./viewProduct.module.css";

const HTMLContent = ({ html }) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const ProdDetailSectionOne = ({ productDetails }) => {
    return (
        <div className={styles.sectionConatiner}>
            <div>
                <h3>Title</h3>
                <p>{productDetails.product.name ?? "None"}</p>
            </div>
            <div style={{marginBottom:"1rem"}}>
                <h3>Description</h3>
                <HTMLContent html={productDetails?.product.description === "" ? "None" : productDetails?.product.description} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                    <h3>Brand</h3>
                    <p>{productDetails.product.brand_name ?? "None"}</p>
                </div>
                <div>
                    <h3>ABV %</h3>
                    <p>{productDetails.product.abv ?? "None"}</p>
                </div>
                <div>
                    <h3>Product Category</h3>
                    <p>{productDetails.product.product_category ?? "None"}</p>
                </div>
                <div>
                    <h3>Product Type</h3>
                    <p>{productDetails.product.product_type ?? "None"}</p>
                </div>
            </div>
            <div>
                <h3>Product Tags</h3>
                <p style={{wordWrap:"break-word"}}>
                {(productDetails?.product?.formatted_tags && productDetails?.product?.formatted_tags.length > 0)
                    ? productDetails?.product.formatted_tags.map((tag,index) => <span key={index}>{tag.label}{index !== productDetails?.product?.formatted_tags.length -1 &&","}</span>)
                    : "None"}
                </p>
            </div>
        </div>
    );
};

export default ProdDetailSectionOne;
