import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Input, Spinner } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { CustomDropdown } from "../../components/CustomDropdown/CustomDropdown";
import { stateListOptions } from "../../utils/constants";
import { api } from "services/api";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "./UserForm.css";
import AsyncCreatableSelect from "react-select/async-creatable";
import FulfillmentStatesTable from "./FulfillmentStatesTable";
import "../../utils/commonStyles.css"
const useDebouncedSearch = (searchTerm, delay = 300) => {
    const [debouncedValue, setDebouncedValue] = useState(searchTerm);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(searchTerm);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm, delay]);
    return debouncedValue;
};
// 1st Section User Details
const FormInput = ({ id, label, type, value, onChange, disabled = false }) => {
    const [emailError, setEmailError] = useState("");
    const inputStyle = id === "email" ? { paddingLeft: "40px", border: "0" } : { border: "0" };
    const handlePaste = e => {
        const pastedValue = e.clipboardData.getData("text/plain");
        console.log("pastedValue: ", pastedValue);
        if (id === "email") {
            // Regular expression for validating email address
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(pastedValue);
            console.log("isValidEmail: ", isValidEmail);
            // Check if the pasted value matches the email format
            if (!isValidEmail) {
                setEmailError("Please enter a valid email address. Ex: test@example.com");
            } else {
                // Clear any existing email error
                setEmailError("");
            }
        }
    };
    return (
        <div className="input-group-user-form">
            <label htmlFor={id}>{label}</label>
            <div className="input-container">
                {id === "email" ? (
                    <input
                        type="email"
                        id={id}
                        placeholder={label}
                        onInput={e => {
                            const inputValue = e.target.value;
                            if (inputValue.trim() === "") {
                                // Clear any existing email error if the input is empty
                                setEmailError("");
                            } else {
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                const isValidEmail = emailRegex.test(inputValue);
                                if (!isValidEmail) {
                                    setEmailError("Please enter a valid email address. Ex: test@example.com");
                                } else {
                                    setEmailError("");
                                }
                            }
                        }}
                        onPaste={handlePaste}
                        value={value}
                        onChange={onChange}
                        style={inputStyle}
                    />
                ) : (
                    <input type={type} id={id} placeholder={label} value={value} onChange={onChange} style={inputStyle} disabled={disabled} />
                )}
                {id === "email" && <i className="fas fa-envelope input-icon" />}
            </div>
            {emailError && <span className="error-message">{emailError}</span>}
        </div>
    );
};
// 2nd Section Contact Form
const RadioButton = ({ label, value, checkedValue, onChange }) => (
    <label>
        <input type="radio" name="contactMethod" value={value} checked={checkedValue === value} onChange={onChange} disabled={true} />
        <span className="contact-label">{label}</span>
    </label>
);
// 3rd Section Invite Link
const InviteLink = ({ inviteType, path, copyInviteToClipboard }) => (
    <label>
        <span>Invite a {inviteType} :</span>
        <span className="invite-link">https://app1.checkout.flaviar.com/register/{path}</span>
        <i className="fas fa-copy pl-1 pr-1" onClick={() => copyInviteToClipboard(path)} style={{ cursor: "pointer" }}></i>
    </label>
);
const permissionOptions = [
    {
        value: 40,
        label: "order | Order | Can view Order"
    },
    {
        value: 32,
        label: "product | brand | Can view brand"
    }
];
function UpdateUser() {
    const searchParams = new URLSearchParams(window.location.search);
    console.log("searchParams: ", searchParams);
    const uID = searchParams.get("userId");
    console.log("batchId: ", uID);
    const { userId } = useParams();
    const baseURL = window.env.API_URL;
    const { addToast } = useToasts();
    const history = useHistory();
    const [imageUrl, setImageUrl] = useState(null);
    const [showImg, setShowImg] = useState(null);
    const [removeClicked, setRemoveClicked] = useState(false);
    const [unsupportedFile, setUnsupportedFile] = useState(false);
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [loadingAvaliableDiscountCode, setLoadingAvaliableDiscountCode] = useState(false);
    const [loadingSelectedeDiscountCode, setLoadingSelectedeDiscountCode] = useState(false);
    // Existing user data fetched from the database
    const fulfillmentStatesRole = ["retailer", "master", "manager"]
    const [existingUserData, setExistingUserData] = useState({
        avatar: "",
        email: "",
        firstName: "",
        lastName: "",
        telephone: "",
        company: "",
        contactMethod: "",
        userType: "",
        inviteLinkToken: "",
        selectedStates: [],
        selectedBrands: [],
        selectedSupplierBrands: [],
        selectedRetailerInventory: [],
        selectedFulfillmentStates: [],
        retailerInventoryLocations: [],
        userPermissions: [],
        discountCodePage: false,
        selectedDiscountCodes: [],
        documentRows: [{ id: Date.now() + Math.floor(Math.random() * 100), document_id: null, title: "", url: "" }],
        barcartButton: false,
        productPage: false,
        weeklyEmail: false,
        dailyEmail: false,
        sonicExport: false,
        retailerBilling: false,
        shipStationIntegration: false,
        shipStationIntegrationData: [],
        stateListOptions: [],
        all_fullfillment_Active: false,
        all_tax_Active: false,
        // 6893788815 - Integrate Insights with Barcart - UI field to edit user's JSON data
        flaviarInsights: false,
        flaviarInsightsProfile: "",
    });
    // 4th Section Permissions Fields
    const [formState, setFormState] = useState({
        userType: "",
        selectedStates: [],
        selectedBrands: [],
        userPermissions: [],
        brandsList: [],
        userTypeList: [],
        newlyCreatedBrands: [],
        fulfillmentStates: [],
        all_fullfillment_Active: false,
        all_tax_Active: false,
    });
    // 5th Section Discount Code Fields
    const [availableCodes, setAvailableCodes] = useState([]);
    const [retailerInventoryLocationList, setRetailerInventoryLocationList] = useState([]);
    const [supplierBrandsList, setSupplierBrandsList] = useState([]);
    const [selectedAvailable, setSelectedAvailable] = useState([]);
    const [selectedSelected, setSelectedSelected] = useState([]);
    const [searchAvaliableCode, setSearchAvailableCodes] = useState("");
    const [searchSelectedCode, setSearchSelectedCode] = useState("");
    const [loadingAddedCodeMessage, setLoadingAddedCodeMessage] = useState(false);
    const [loadingRemovedCodeMessage, setLoadingRemovedCodeMessage] = useState(false);
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FB00}-\u{1FBFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}\u{231A}-\u{231B}\u{23F0}-\u{23F3}\u{23F1}-\u{23F2}\u{23E9}-\u{23EF}\u{23F8}-\u{23FA}\u{25A0}-\u{25A1}\u{25AA}-\u{25AB}\u{25B6}\u{25C0}\u{25FB}-\u{25FE}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{2B50}\u{2B55}\u{3030}\u{303D}\u{3297}\u{3299}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F201}-\u{1F20F}\u{1F21A}\u{1F22F}\u{1F232}-\u{1F251}\u{1F300}-\u{1F321}\u{1F324}-\u{1F393}\u{1F396}-\u{1F397}\u{1F399}-\u{1F39B}\u{1F39E}-\u{1F3F0}\u{1F3F3}-\u{1F3F5}\u{1F3F7}-\u{1F4FD}\u{1F4FF}-\u{1F53D}\u{1F549}-\u{1F54E}\u{1F550}-\u{1F567}\u{1F56F}-\u{1F570}\u{1F573}-\u{1F57A}\u{1F587}\u{1F58A}-\u{1F58D}\u{1F590}\u{1F595}-\u{1F596}\u{1F5A4}-\u{1F5A5}\u{1F5A8}\u{1F5B1}-\u{1F5B2}\u{1F5BC}\u{1F5C2}-\u{1F5C4}\u{1F5D1}-\u{1F5D3}\u{1F5DC}-\u{1F5DE}\u{1F5E1}\u{1F5E3}\u{1F5E8}\u{1F5EF}\u{1F5F3}\u{1F5FA}-\u{1F5FF}\u{1F6C5}-\u{1F6D2}\u{1F6E0}-\u{1F6E5}\u{1F6E9}-\u{1F6EC}\u{1F6F0}-\u{1F6F3}\u{1F6F4}-\u{1F6F9}\u{1F7E0}-\u{1F7EB}\u{1F90D}-\u{1F93A}\u{1F93C}-\u{1F945}\u{1F947}-\u{1F9FF}\u{1FA70}-\u{1FA74}\u{1FA78}-\u{1FA7A}\u{1FA80}-\u{1FA86}\u{1FA90}-\u{1FAA8}\u{1FAB0}-\u{1FAB6}\u{1FAC0}-\u{1FAC2}\u{1FAD0}-\u{1FAD6}\u{1FB00}-\u{1FB02}\u{1FB38}-\u{1FB3C}\u{1FB70}-\u{1FB71}\u{0023}\u{002A}\u{20E3}\u{FE0F}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F1E6}-\u{1F1FF}]/gu;
    const [userTypeValid, setUserTypeValid] = useState(true);
    const contactOptions = [
        { label: "Email", value: "email" },
        { label: "Text", value: "text" },
        { label: "Call", value: "call" }
    ];
    useEffect(() => {
        // Scroll to the top of UpdateUserPage component when it mounts
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        let fetchUserDetails = async () => {
            try {
                setLoadingSelectedeDiscountCode(true);
                setLoadingAvaliableDiscountCode(true);
                const userDetailsResponse = await api.get(`users/react/get-user-details/${uID}/`);
                const userDetails = userDetailsResponse.data;
                // Extract required data from user details
                // const selectedStates = userDetails.states?.map(state => ({ value: state.code, label: state.name }));
                let selectedStates = [];
                if (userDetails.user_states === "__all__") {
                    // If all states are selected, add "__all__" option
                    selectedStates.push({ value: "__all__", label: "All States" });
                } else if (userDetails.user_states === null) {
                    // If no states are associated with the user, handle it as needed
                    selectedStates.push({ value: "none", label: "None" });
                } else if (Array.isArray(userDetails.user_states)) {
                    // If individual states are selected, map them to the desired format
                    selectedStates = userDetails.user_states.map(state => ({
                        value: state.code,
                        label: state.name
                    }));
                }
                // const selectedBrands = userDetails.brands?.map(brand => ({ value: brand.id, label: brand.name }));
                let selectedBrands = [];
                console.log("userDetails.user_brands: ", userDetails.user_brands);
                if (userDetails.user_brands === "__all__") {
                    // If all states are selected, add "__all__" option
                    selectedBrands.push({ value: "__all__", label: " All Brands" });
                } else if (userDetails.user_brands === null) {
                    // If no states are associated with the user, handle it as needed
                    selectedBrands.push({ value: "no", label: "No Brands" });
                } else if (Array.isArray(userDetails.user_brands)) {
                    // If individual states are selected, map them to the desired format
                    selectedBrands = userDetails.user_brands.map(brand => ({
                        value: brand.id,
                        label: brand.name
                    }));
                }
                console.log("selectedBrands: ", selectedBrands);
                const userPermissions = userDetails.user_permissions.map(permission => ({ value: permission.id, label: permission.name }));
                const selectedDiscountCodes = userDetails.discount_codes.map(code => ({ value: code.id, label: code.code }));
                const avaliableDiscountCodes = userDetails.available_discount_codes.map(code => ({ value: code.id, label: code.code }));
                const documentsRow = userDetails.documents.map(document => ({
                    id: Date.now() + Math.floor(Math.random() * 100),
                    document_id: document.id,
                    title: document.title,
                    url: document.url
                }));

                const selectedRetailerInventoryList = userDetails?.retailer_inventory_locations
                    ? userDetails?.retailer_inventory_locations?.map(location => ({ value: location.id, label: location.name }))
                    : [];
                const selectedSupplierBrandList = userDetails?.supplier_brands_list
                    ? userDetails?.supplier_brands_list?.map(brand => ({ value: brand.id, label: brand.name }))
                    : [];
                const locationList = userDetails.inventory_locations.map(location => ({ value: location.id, label: location.name }));

                let selectedFulfillmentStates = [];
                if (userDetails.user_fullfilment_states === "__all__") {
                    // If all states are selected, add "__all__" option
                    selectedFulfillmentStates.push({ value: "__all__", label: "All States" });
                } else if (userDetails.user_fullfilment_states === null) {
                    // If no states are associated with the user, handle it as needed
                    selectedFulfillmentStates.push({ value: "none", label: "None" });
                } else if (Array.isArray(userDetails.user_fullfilment_states)) {
                    // If individual states are selected, map them to the desired format
                    selectedFulfillmentStates = userDetails.user_fullfilment_states.map(state => ({
                        value: state.code,
                        label: state.name
                    }));
                }

                // Set existing user data with fetched values
                setExistingUserData(prevData => ({
                    ...prevData,
                    avatar: userDetails.avatar,
                    email: userDetails.email,
                    firstName: userDetails.first_name,
                    lastName: userDetails.last_name,
                    telephone: userDetails.telephone,
                    company: userDetails.company,
                    marketingFee: userDetails.marketingFee ?? "",
                    contactMethod: userDetails.contact_type,
                    userType: userDetails.role,
                    selectedStates: selectedStates,
                    selectedBrands: selectedBrands,
                    userPermissions: userPermissions,
                    discountCodePage: userDetails.discount_feature,
                    selectedDiscountCodes: selectedDiscountCodes,
                    documentRows: documentsRow,
                    barcartButton: userDetails.show_barcart_button,
                    productPage: userDetails.product_feature,
                    weeklyEmail: userDetails.weekly_report,
                    dailyEmail: userDetails.daily_report,
                    selectedRetailerInventory: selectedRetailerInventoryList,
                    sonicExport: userDetails.sonic_export,
                    retailerBilling: userDetails.retailer_billing,
                    inviteLinkToken: userDetails.token || "",
                    selectedSupplierBrands: selectedSupplierBrandList[0],
                    shipStationIntegration: userDetails.enable_ss_integration,
                    selectedFulfillmentStates: selectedFulfillmentStates,
                    shipStationIntegrationData: userDetails?.ship_station_integration_data,
                    stateListOptions: userDetails?.fulfillment_states_data,
                    all_fullfillment_Active: userDetails?.all_fullfilment_active,
                    all_tax_active: userDetails?.all_tax_active,
                    // 6893788815 - Integrate Insights with Barcart - UI field to edit user's JSON data
                    flaviarInsights: userDetails.insights,
                    flaviarInsightsProfile: userDetails.insights_profile,
                }));
                setAvailableCodes(avaliableDiscountCodes);
                setRetailerInventoryLocationList(locationList);
                setSupplierBrandsList(selectedSupplierBrandList);
                setShowImg(userDetails.avatar);
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setLoadingSelectedeDiscountCode(false);
                setLoadingAvaliableDiscountCode(false);
            }
        };
        fetchUserDetails();
    }, [uID]);

    useEffect(() => {
        const fetchUserTypes = async () => {
            try {
                const response = await api.get("users/react/filter/roles/");
                let reponseUserTypes = response?.data?.results.map(user => ({ value: user.id, label: user.name }));
                setFormState(prevState => ({
                    ...prevState,
                    userTypeList: reponseUserTypes
                }));
            } catch (error) {
                console.error("Error fetching brands:", error);
            }
        };

        fetchUserTypes();
    }, []);
    // Handlers using useCallback to prevent unnecessary re-creations
    const handleChange = e => {
        const { id, value } = e.target;
        setExistingUserData(prev => ({ ...prev, [id]: value }));
    };
    // 2nd Section Contact Form Handler Function
    const handleContactMethodChange = useCallback(e => {
        setExistingUserData(prev => ({ ...prev, contactMethod: e.target.value }));
    }, []);
    /**
     * # 6893788815 - Integrate Insights with Barcart - UI field to edit user's JSON data
     * Function for handling insights
     */
    const handleInsightsProfile = useCallback(e => {
        setExistingUserData(prev => ({ ...prev, flaviarInsightsProfile: e.target.value.replace(emojiRegex, '') }));
    }, []);
    // 3rd Section Invite Links Handler Function
    const copyInviteToClipboard = useCallback(
        path => {
            navigator.clipboard.writeText(`https://app1.checkout.flaviar.com/register/${path}`);
            addToast("Invite URL copied to clipboard!", { appearance: "success", autoDismiss: true });
        },
        [addToast]
    );
    const copyToClipboardShipStation = url => {
        console.log("url: ", url);
        navigator.clipboard.writeText(url).then(
            () => {
                addToast("ShipStation Integration Content copied to clipboard successfully!", { appearance: "success", autoDismiss: true });
            },
            err => {
                console.error("Could not copy text: ", err);
            }
        );
    };
    // 4th Section Permissions Handler Function
    const handleUserTypeChange = newValue => {
        setExistingUserData(prevState => ({
            ...prevState,
            userType: newValue
        }));
    };
    const handleStatesChange = newValues => {
        // Find if '__all__' is currently selected
        const isAllSelected = existingUserData.selectedStates.some(state => state.value === "__all__");
        const isNoSelected = existingUserData.selectedStates.some(state => state.value === "none");
        // Check if '__all__' is among the newly added values
        const isNewValueAll = newValues.some(value => value.value === "__all__");
        const isNewValueNo = newValues.some(value => value.value === "none");
        let updatedValues;
        if (isNoSelected && newValues.length > 1) {
            // If 'none' is currently selected and new selections are added, remove 'none'
            updatedValues = newValues.filter(value => value.value !== "none");
        } else if (!isNoSelected && isNewValueNo) {
            updatedValues = newValues.filter(value => value.value === "none");
        } else if (isAllSelected && newValues.length > 1) {
            // If '__all__' is currently selected and new selections are added, remove '__all__'
            updatedValues = newValues.filter(value => value.value !== "__all__");
        } else if (!isAllSelected && isNewValueAll) {
            // If '__all__' is not currently selected but is now added, keep only '__all__'
            updatedValues = newValues.filter(value => value.value === "__all__");
        } else {
            // In any other case, just use the new values as they are
            updatedValues = newValues;
        }

        setExistingUserData(prevState => ({
            ...prevState,
            selectedStates: updatedValues
        }));
    };

    const handleMultiBrandsChange = newValues => {
        // Find if '__all__' is currently selected
        const isAllSelected = existingUserData.selectedBrands.some(brand => brand.value === "__all__");
        const isNoSelected = existingUserData.selectedBrands.some(brand => brand.value === "no");
        // Check if '__all__' is among the newly added values
        const isNewValueAll = newValues.some(value => value.value === "__all__");
        const isNewValueNo = newValues.some(value => value.value === "no");
        let updatedValues;
        if (isNoSelected && newValues.length > 1) {
            // If 'no' is currently selected and new selections are added, remove 'no'
            updatedValues = newValues.filter(value => value.value !== "no");
        } else if (!isNoSelected && isNewValueNo) {
            updatedValues = newValues.filter(value => value.value === "no");
        } else if (isAllSelected && newValues.length > 1) {
            // If '__all__' is currently selected and new selections are added, remove '__all__'
            updatedValues = newValues.filter(value => value.value !== "__all__");
        } else if (!isAllSelected && isNewValueAll) {
            // If '__all__' is not currently selected but is now added, keep only '__all__'
            updatedValues = [{ value: "__all__", label: "All Brands" }];
        } else {
            // In any other case, just use the new values as they are
            updatedValues = newValues;
        }

        setExistingUserData(prevState => ({
            ...prevState,
            selectedBrands: updatedValues
        }));
    };

    const handleCreatableBrandsChange = (newValue, actionMeta) => {
        console.log(`Action: ${actionMeta.action}`);

        if (actionMeta.action === "create-option") {
            handleCreateOption(newValue);
        } else {
            handleMultiBrandsChange(newValue);
        }
    };

    // Handle the creation of a new option
    const handleCreateOption = newValue => {
        // Assume the last item in newValue is the newly created option
        const newOption = newValue[newValue.length - 1];
        console.log("newOption: ", newOption);

        setFormState(prevState => ({
            ...prevState,
            newlyCreatedBrands: [...prevState.newlyCreatedBrands, newOption]
        }));

        // Optionally, you can add the new option to your state's brands list if needed
        const newBrandsList = [...formState.brandsList, newOption];
        console.log("newBrandsList: ", newBrandsList);
        setFormState(prevState => ({
            ...prevState,
            brandsList: newBrandsList
        }));

        // Call the existing handleBrandsChange with the updated list including the new option
        handleMultiBrandsChange(newValue);
    };

    const handleBrandsChange = newValue => {
        console.log(newValue);
        setExistingUserData(prevState => ({
            ...prevState,
            selectedSupplierBrands: newValue
        }));
    };

    const inviteTypes = [
        { label: "brand", path: `${existingUserData.inviteLinkToken}` },
        { label: "retailer", path: `retailer/${existingUserData.inviteLinkToken}` }
    ];

    const handleRetailerInventoryChange = selectedOptions => {
        console.log("selectedOptions: ", selectedOptions);
        setExistingUserData({
            ...existingUserData,
            selectedRetailerInventory: selectedOptions || [] // Set to empty array if no options are selected
        });
    };

    const handleFulfillmentStatesChange = newValues => {
        // Find if '__all__' is currently selected
        const isAllSelected = existingUserData.selectedFulfillmentStates.some(state => state.value === "__all__");
        const isNoSelected = existingUserData.selectedFulfillmentStates.some(state => state.value === "none");
        // Check if '__all__' is among the newly added values
        const isNewValueAll = newValues.some(value => value.value === "__all__");
        const isNewValueNo = newValues.some(value => value.value === "none");
        let updatedValues;
        if (isNoSelected && newValues.length > 1) {
            // If 'none' is currently selected and new selections are added, remove 'none'
            updatedValues = newValues.filter(value => value.value !== "none");
        } else if (!isNoSelected && isNewValueNo) {
            updatedValues = newValues.filter(value => value.value === "none");
        } else if (isAllSelected && newValues.length > 1) {
            // If '__all__' is currently selected and new selections are added, remove '__all__'
            updatedValues = newValues.filter(value => value.value !== "__all__");
        } else if (!isAllSelected && isNewValueAll) {
            // If '__all__' is not currently selected but is now added, keep only '__all__'
            updatedValues = newValues.filter(value => value.value === "__all__");
        } else {
            // In any other case, just use the new values as they are
            updatedValues = newValues;
        }

        setExistingUserData(prevState => ({
            ...prevState,
            selectedFulfillmentStates: updatedValues
        }));
    };
    const handleUserPermissionsChange = event => {
        const values = Array.from(event.target.selectedOptions, option => ({ value: option.value, label: option.id }));
        setExistingUserData(prevState => ({
            ...prevState,
            userPermissions: values
        }));
    };
    // 5th Section Discount Code Handler Function
    const handleSelectionAvailable = event => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => {
            return { value: +option.value, label: option.label };
        });
        setSelectedAvailable(selectedOptions);
    };
    const handleSelectionSelected = event => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => {
            return { value: +option.value, label: option.label };
        });
        setSelectedSelected(selectedOptions);
    };
    const addToSelected = () => {
        const uniqueSelected = selectedAvailable.filter(sa => !existingUserData.selectedDiscountCodes.some(sc => sc.value === sa.value));
        setExistingUserData(prevState => ({ ...prevState, selectedDiscountCodes: [...existingUserData.selectedDiscountCodes, ...uniqueSelected] }));
        const newAvailableCodes = availableCodes.filter(code => !uniqueSelected.some(selected => selected.value === code.value));
        setAvailableCodes(newAvailableCodes);
        setSelectedAvailable([]);
    };
    const removeFromSelected = () => {
        // Retrieve the codes to be removed from the selected discount codes
        const removedCodes = selectedSelected;
        const newSelectedCodes = existingUserData.selectedDiscountCodes.filter(code => !removedCodes.some(selected => selected.value === code.value));
        setExistingUserData(prevState => ({ ...prevState, selectedDiscountCodes: newSelectedCodes }));
        // Add the removed codes back to the available discount codes if they are not already present
        const newAvailableCodes = removedCodes.filter(code => !availableCodes.some(available => available.value === code.value));
        // Add the removed codes back to the available discount codes if they are not already present
        const prevAvaliableCodes = [...availableCodes, ...newAvailableCodes];
        setAvailableCodes(prevAvaliableCodes);
        setSelectedSelected([]);
    };
    // const addAllToSelected = () => {
    //     setExistingUserData(prevState => ({ ...prevState, selectedDiscountCodes: [...existingUserData.selectedDiscountCodes, ...availableCodes] }));
    //     setAvailableCodes([]);
    // };
    const addAllToSelected = () => {
        // Display loading message
        setLoadingAddedCodeMessage(true);

        // Add a delay to simulate processing time (you can adjust the delay duration)
        setTimeout(() => {
            setExistingUserData(prevState => ({ ...prevState, selectedDiscountCodes: [...existingUserData.selectedDiscountCodes, ...availableCodes] }));
            setAvailableCodes([]);
            // Clear loading message after processing
            setLoadingAddedCodeMessage(false);
        }, 2000); // Adjust time as needed (e.g., 2000 milliseconds = 2 seconds)
    };
    const removeAllFromSelected = () => {
        // Display loading message
        setLoadingRemovedCodeMessage(true);
        setTimeout(() => {
            setAvailableCodes([...availableCodes, ...existingUserData.selectedDiscountCodes]);
            setExistingUserData(prevState => ({ ...prevState, selectedDiscountCodes: [] }));
            // Clear loading message after processing
            setLoadingRemovedCodeMessage(false);
        }, 2000);
    };
    const debouncedSearchAvailableCode = useDebouncedSearch(searchAvaliableCode);
    const debouncedSearchSelectedCode = useDebouncedSearch(searchSelectedCode);
    // Memoized filtered code lists
    const filteredAvailableCodes = useMemo(() => {
        if (!debouncedSearchAvailableCode) {
            return availableCodes;
        }
        setLoadingAvaliableDiscountCode(true);
        const filteredCodes = availableCodes.filter(code => code.label.toLowerCase().includes(debouncedSearchAvailableCode.toLowerCase()));
        // Simulate loading for demonstration purposes
        setTimeout(() => {
            setLoadingAvaliableDiscountCode(false); // Set loading state to false once filtering operation is complete
        }, 500);
        return filteredCodes;
    }, [availableCodes, debouncedSearchAvailableCode]);
    // Memoized filtered selected codes
    const filteredSelectedCodes = useMemo(() => {
        if (!debouncedSearchSelectedCode) {
            return existingUserData.selectedDiscountCodes;
        }
        setLoadingSelectedeDiscountCode(true);
        const filtereSelectedCodes = existingUserData.selectedDiscountCodes.filter(code =>
            code.label.toLowerCase().includes(debouncedSearchSelectedCode.toLowerCase())
        );
        // Simulate loading for demonstration purposes
        setTimeout(() => {
            setLoadingSelectedeDiscountCode(false); // Set loading state to false once filtering operation is complete
        }, 500);

        // Call the function and return its result
        return filtereSelectedCodes;
    }, [debouncedSearchSelectedCode, existingUserData.selectedDiscountCodes]);
    const handleBackClick = (e) => {
        e.preventDefault();
        history.push("/users");
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();
        // Validation on First Name and Email
        if (!existingUserData.firstName || !existingUserData.email) {
            let errorMsg = !existingUserData.email ? "Email is required!" : "First Name is required!";
            addToast(errorMsg, {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }
        const hasEmptyField = existingUserData?.documentRows.some(
            row => (row.title && row.title.trim() !== "" && !row.url) || (row.url && row.url.trim() !== "" && !row.title)
        );

        if (hasEmptyField) {
            // If any row has an empty title or URL, show a warning
            addToast("Please fill in the title and URL for all documents.", { appearance: "warning", autoDismiss: true });
            return; // Stop execution
        }
        const imageFormData = new FormData();
        console.log("imageUrl: ", imageUrl);
        imageFormData.append("image", imageUrl);
        const payload = {
            user_id: +uID,
            email: existingUserData.email,
            first_name: existingUserData.firstName,
            last_name: existingUserData.lastName,
            telephone: existingUserData.telephone,
            company: existingUserData.company,
            contact_type: existingUserData.contactMethod,
            role: existingUserData.userType,
            states: existingUserData.selectedStates.some(item => item.value === "__all__")
                ? "__all__"
                : existingUserData.selectedStates.some(item => item.value === "none")
                    ? "none"
                    : existingUserData.selectedStates.map(item => item.value),
            brands: existingUserData.selectedBrands.some(item => item.value === "__all__")
                ? "__all__"
                : existingUserData.selectedBrands.some(item => item.value === "no")
                    ? "no"
                    : existingUserData.selectedBrands
                        .filter(item => !item.__isNew__) // Assuming __isNew__ is used to identify new brands
                        .map(item => item.value),
            user_permissions: existingUserData.userPermissions.map(permission => permission.value),
            discount_feature: existingUserData.discountCodePage ? "1" : "0",
            show_barcart_button: existingUserData.barcartButton ? "1" : "0",
            product_feature: existingUserData.productPage ? "1" : "0",
            weekly_report: existingUserData.weeklyEmail ? "1" : "0",
            daily_report: existingUserData.dailyEmail ? true : false,
            insights: existingUserData.flaviarInsights ? true : false,  // 6893788815 - Integrate Insights with Barcart - UI field to edit user's JSON data
            insights_profile: existingUserData.flaviarInsightsProfile,  // 6893788815 - Integrate Insights with Barcart - UI field to edit user's JSON data
            documents: existingUserData.documentRows.map(({ document_id, title, url }) => ({
                document_id: document_id,
                document_title: title,
                document_url: url,
                parent_id: null
            })),
            discount_codes: existingUserData.selectedDiscountCodes.map(code => code.value),
            inventory_locations: existingUserData.selectedRetailerInventory.map(location => location.value),
            sonic_export: existingUserData.sonicExport ? true : false,
            retailer_billing: existingUserData.retailerBilling ? true : false,
            enable_ss_integration: existingUserData.shipStationIntegration ? true : false,
            newly_created_brands: formState.newlyCreatedBrands.length > 0 ? formState.newlyCreatedBrands.map(brand => brand.value) : [],
            fulfillment_states: existingUserData.selectedFulfillmentStates.some(item => item.value === "__all__")
                ? "__all__"
                : existingUserData.selectedFulfillmentStates.some(item => item.value === "none")
                    ? "none"
                    : existingUserData.selectedFulfillmentStates.map(item => item.value),
            toggle_fulfillment_states: !fulfillmentStatesRole.includes(existingUserData?.userType) ? [] : formState.fulfillmentStates,
            all_fullfillment_Active: !fulfillmentStatesRole.includes(existingUserData?.userType) ? false : formState.all_fullfillment_Active,
            all_tax_Active: !fulfillmentStatesRole.includes(existingUserData?.userType) ? false : formState.all_tax_Active,
        };
        console.log("payload: ", payload);
        const payloadString = JSON.stringify(payload);
        imageFormData.append("payload", payloadString);
        setSubmitBtnLoading(true);
        api.put("react/users/", imageFormData)
            .then(response => {
                setSubmitBtnLoading(false);
                if (response.data.success) {
                    addToast(response.data.success, { appearance: "success", autoDismiss: true });
                    // Redirect to the listing page when we successfully create user
                    history.push("/users");
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    let message = error?.response?.data?.error;
                    setSubmitBtnLoading(false);
                    addToast(message, { appearance: "error", autoDismiss: true });
                } else {
                    // Handle other errors (network errors, etc.)
                    console.log("error: ", error);
                }
            });
    };
    const handleTitleChange = (value, id) => {
        const updatedRows = existingUserData.documentRows.map(row => (row.id === id ? { ...row, title: value.target.value } : row));
        setExistingUserData(prevState => ({ ...prevState, documentRows: updatedRows }));
    };

    const handleUrlChange = (value, id) => {
        const updatedRows = existingUserData.documentRows.map(row => (row.id === id ? { ...row, url: value.target.value } : row));
        setExistingUserData(prevState => ({ ...prevState, documentRows: updatedRows }));
    };

    const handleDeleteDocumentBtn = id => {
        const remainingRows = existingUserData.documentRows.filter(row => row.id !== id);
        // Delete the row as usual if there are more than one row
        setExistingUserData(prevState => ({
            ...prevState,
            documentRows: remainingRows
        }));
    };
    const handleAddAnother = () => {
        // Check if the last row in documentRows has both title and URL filled
        const lastRow = existingUserData.documentRows[existingUserData.documentRows.length - 1];
        if (lastRow && (!lastRow.title || !lastRow.url)) {
            // If the last row doesn't have both title and URL filled, alert the user or handle it as needed
            addToast("Please fill in the title and URL for the previous document.", { appearance: "warning", autoDismiss: true });
            return; // Stop execution
        }
        const newRow = { id: Date.now() + Math.floor(Math.random() * 100), document_id: null, title: "", url: "" };
        setExistingUserData(prevState => ({
            ...prevState,
            documentRows: [...prevState.documentRows, newRow]
        }));
    };
    // Toggle handler
    const handleToggleChange = toggleName => {
        const newState = !existingUserData[toggleName];
        setExistingUserData(prevStates => ({
            ...prevStates,
            [toggleName]: newState
        }));
    };
    const handleImageChange = e => {
        const file = e.target.files[0];
        if (file && !["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
            setUnsupportedFile(true);
            return;
        }
        setUnsupportedFile(false);
        const reader = new FileReader();
        reader.onload = e => {
            setShowImg(e.target.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
        setImageUrl(file);
    };
    const handleRemoveImage = () => {
        setRemoveClicked(true);
        setShowImg(null);
        setImageUrl(null);
        // Clear the file input field
        document.getElementById("image-upload").value = null;
    };

    console.log("newlyCreatedBrands: ", formState.newlyCreatedBrands);

    const mapResponseToValuesAndLabels = data => ({
        value: data.id,
        label: data.name
    });

    async function callApi(value) {
        let updatedBrands = [
            { value: "__all__", label: " All Brands" },
            { value: "no", label: "No Brands" }
        ];

        let data = await api.get(`brand/?q=${value}`)
            .then((response) => {
                console.log(response.data)
                return response.data
            })
            .then((response) => response?.results.map(mapResponseToValuesAndLabels))
            .then((final) =>
                final.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
            );
        data = updatedBrands.concat(data)
        return data;
    }

    let typing = "";

    return (
        <Container fluid style={{ paddingTop: "7rem" }}>
            {/* First User Details Form ... */}
            <div className="user-form-container">
                <h2>Change User</h2>
                <form className="user-form">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="avatar-block mx-auto" style={{ width: "150px" }}>
                                <img
                                    src={showImg || "https://assets-barcart.s3.amazonaws.com/static/images/avatar.png"}
                                    alt="Preview"
                                    className="rounded-circle object-fit-cover"
                                    height="150"
                                    width="150"
                                />
                                {!showImg && removeClicked && (
                                    <div className="alert alert-info mt-2 mx-auto" style={{ width: "150px", paddingTop: "8px", paddingBottom: "8px" }}>
                                        No Picture
                                    </div>
                                )}
                                {unsupportedFile && (
                                    <div className="error-message" style={{ width: "100%" }}>
                                        Unsupported file format. Please select a JPEG, PNG, or JPG file.
                                    </div>
                                )}
                                <div className="btn-group my-2 btn-group-justified">
                                    <label
                                        className="btn btn-info btn-sm"
                                        htmlFor="image-upload"
                                        data-toggle="tooltip"
                                        title="Browse"
                                        style={{ width: "30px", height: "28px" }}>
                                        <i className="fas fa-upload"></i>
                                        <input type="file" id="image-upload" accept="image/*" className="invisible" onChange={handleImageChange} />
                                    </label>
                                    {showImg && (
                                        <label className="btn btn-danger btn-sm" data-toggle="tooltip" title="Remove" onClick={handleRemoveImage}>
                                            <i className="fas fa-trash"></i>
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormInput id="email" label="Email *" type="email" value={existingUserData.email} onChange={e => handleChange(e)} />
                    <FormInput id="firstName" label="First name *" type="text" value={existingUserData.firstName} onChange={e => handleChange(e)} />
                    <FormInput id="lastName" label="Last name" type="text" value={existingUserData.lastName} onChange={e => handleChange(e)} />
                    <FormInput id="telephone" label="Telephone" type="tel" value={existingUserData.telephone} onChange={e => handleChange(e)} />
                    <FormInput id="company" label="Company" type="text" value={existingUserData.company} onChange={e => handleChange(e)} />
                    {existingUserData.userType === "supplier" && (
                        <>
                            <div className="brand-input-group">
                                <label htmlFor="brands">Brands</label>
                                <div className="brands-multi-select-dropdown">
                                    <Select
                                        className="select-input"
                                        placeholder="Suppplier Brand... "
                                        value={existingUserData.selectedSupplierBrands}
                                        onChange={handleBrandsChange}
                                        options={supplierBrandsList}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        checked={true}
                                    />
                                </div>
                            </div>
                            <div className="input-group-supplier">
                                <label htmlFor="facebookId">Facebook Business Manager ID</label>
                                <input type="text" id="facebookId" className="form-control disabled-input" placeholder="Facebook Business Manager ID" />
                            </div>
                            <div className="input-group-supplier">
                                <label htmlFor="googleAnalyticsId">Google Analytics ID</label>
                                <input type="text" id="googleAnalyticsId" className="form-control disabled-input" placeholder="Google Analytics ID" />
                            </div>
                        </>
                    )}
                    {existingUserData.userType === "retailer" && <FormInput id="marketingFee" label="Marketing Fee" type="text" value={existingUserData?.marketingFee} disabled={true} />}
                </form>
            </div>
            {/* Retailer Proof Documents Form ... */}
            {(existingUserData?.userType === "retailer" || existingUserData?.userType === "wms-retailer") && (
                <div className="user-contact-form-container">
                    <form className="user-form">
                        <h3>Proof Documents</h3>
                        <hr />
                        <div className="document-category">
                            <h4>Shipping Agreements</h4>
                            <h5>No files uploaded.</h5>
                        </div>
                        <div className="document-category">
                            <h4>Alcohol Licenses</h4>
                            <h5>No files uploaded.</h5>
                        </div>
                    </form>
                </div>
            )}
            {/* Second Contact Form ... */}
            <div className="user-contact-form-container">
                <form className="user-form">
                    <h3>Best Way To Contact Me</h3>
                    <hr />
                    {contactOptions.map(option => (
                        <RadioButton
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            checkedValue={existingUserData.contactMethod}
                            onChange={e => handleContactMethodChange(e)}
                        />
                    ))}
                </form>
            </div>
            {/* Third Invite Form ... */}
            <div className="user-invite-barcart-form-container">
                <form className="user-form">
                    <h3>
                        <i class="fas fa-user-plus"></i> Invite to Flaviar Checkout
                    </h3>
                    <hr />
                    <div className="invite-barcart-links">
                        {inviteTypes.map(invite => (
                            <InviteLink key={invite.label} inviteType={invite.label} path={invite.path} copyInviteToClipboard={copyInviteToClipboard} />
                        ))}
                    </div>
                </form>
            </div>
            {/* Forth Permissions Form ... */}
            <div className="user-permission-barcart-form-container">
                <form className="user-form">
                    <h3>Permissions</h3>
                    <hr />
                    <div className="permission-barcart">
                        <div className="select-group">
                            <label htmlFor="email">User Type</label>
                            <CustomDropdown
                                placeholder="Select User Type..."
                                options={formState.userTypeList}
                                value={existingUserData.userType}
                                onChange={handleUserTypeChange}
                                isValid={userTypeValid}
                            />
                        </div>

                        {(existingUserData?.userType === "retailer" || existingUserData?.userType === "wms-retailer") && (
                            <>
                                <div className="select-group">
                                    <label htmlFor="email">Retailer Inventory</label>
                                    <Select
                                        className="select-input"
                                        placeholder="Select Retailer Inventory..."
                                        isMulti
                                        value={existingUserData.selectedRetailerInventory}
                                        onChange={handleRetailerInventoryChange}
                                        options={retailerInventoryLocationList}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                    />
                                </div>
                                <div className="product-btn-form-container">
                                    <form className="sonic-export-retailer-btn">
                                        <div>
                                            <h3>Sonic Export</h3>
                                        </div>
                                        <label className="switch">
                                            <input type="checkbox" onChange={() => handleToggleChange("sonicExport")} checked={existingUserData.sonicExport} />
                                            <span className="slider round">
                                                <span className="toggle-text on-text">On</span>
                                                <span className="toggle-text off-text">Off</span>
                                            </span>
                                        </label>
                                    </form>
                                </div>
                                <div className="product-btn-form-container">
                                    <form className="retailer-billing-btn">
                                        <div>
                                            <h3>Retailer Billing</h3>
                                        </div>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                onChange={() => handleToggleChange("retailerBilling")}
                                                checked={existingUserData.retailerBilling}
                                            />
                                            <span className="slider round">
                                                <span className="toggle-text on-text">On</span>
                                                <span className="toggle-text off-text">Off</span>
                                            </span>
                                        </label>
                                    </form>
                                </div>
                                {/* <div className="select-group disable-cursor">
                                    <label htmlFor="firstName">Fulfillment States</label>
                                    <Select
                                        className="select-input"
                                        placeholder="Select Fulfillment States..."
                                        isMulti
                                        value={existingUserData.selectedFulfillmentStates}
                                        onChange={handleFulfillmentStatesChange}
                                        options={stateListOptions}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        checked={true}
                                        isDisabled={true} //Disable the component
                                    />
                                </div> */}
                            </>
                        )}
                        {(fulfillmentStatesRole.includes(existingUserData?.userType)) && <div style={{ marginBottom: "1rem" }}>
                            <div>Fulfillment States</div>
                            <FulfillmentStatesTable update={true} existingUserData={existingUserData} formState={formState} setFormState={setFormState} />
                        </div>}
                        {existingUserData?.userType === "wms-retailer" && (
                            <>
                                {/* <div className="select-group disable-cursor">
                                    <label htmlFor="firstName">Fulfillment States</label>
                                    <Select
                                        className="select-input"
                                        placeholder="Select Fulfillment States..."
                                        isMulti
                                        value={existingUserData.selectedFulfillmentStates}
                                        onChange={handleFulfillmentStatesChange}
                                        options={stateListOptions}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        checked={true}
                                        isDisabled={true} //Disable the component
                                    />
                                </div> */}
                            </>
                        )}

                        <div className="select-group">
                            <label htmlFor="firstName">States</label>
                            <Select
                                className="select-input"
                                placeholder="Select States..."
                                isMulti
                                value={existingUserData.selectedStates}
                                onChange={handleStatesChange}
                                options={stateListOptions}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                            />
                        </div>
                        <div className="select-group">
                            <label htmlFor="lastName">Brands</label>
                            {/*<Select
                                className="select-input"
                                placeholder="Select Brands... "
                                isMulti
                                value={existingUserData.selectedBrands}
                                onChange={handleMultiBrandsChange}
                                options={formState.brandsList}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                checked={true}
                            />*/}
                            {/*<CreatableSelect*/}
                            {/*    className="select-input"*/}
                            {/*    placeholder="Select Brands... "*/}
                            {/*    isMulti*/}
                            {/*    onChange={handleCreatableBrandsChange}*/}
                            {/*    options={formState.brandsList}*/}
                            {/*    closeMenuOnSelect={false}*/}
                            {/*    hideSelectedOptions={false}*/}
                            {/*    value={existingUserData.selectedBrands}*/}
                            {/*    allowSelectAll={true}*/}
                            {/*/>*/}

                            <AsyncCreatableSelect
                                cacheOptions
                                placeholder="Select Brands... "
                                isMulti
                                loadOptions={callApi}
                                value={existingUserData.selectedBrands}
                                onChange={handleCreatableBrandsChange}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                defaultOptions
                            />
                        </div>
                        <div>
                            <label htmlFor="lastName">User Permissions</label>
                            <Input
                                id="exampleSelectMulti"
                                multiple
                                name="selectMulti"
                                type="select"
                                values={existingUserData.userPermissions}
                                onChange={handleUserPermissionsChange}>
                                {permissionOptions?.map(option => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                        id={option.label}
                                        selected={existingUserData.userPermissions.some(permission => permission.value === option.value)} // Check if the option's value is in existingUserData.userPermissions
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </Input>
                        </div>
                    </div>
                </form>
            </div>
            {/* Fifth Discount Codes Form is remaining... */}
            <div className="user-discount-code-form-container">
                <div className="user-form-discount-code">
                    <form className="user-form-discount-code-btn">
                        <div>
                            <h3>Discount Codes</h3>
                            <p>Enables the discount code page for the user</p>
                        </div>
                        <label className="switch">
                            <input type="checkbox" onChange={() => handleToggleChange("discountCodePage")} checked={existingUserData.discountCodePage} />
                            <span className="slider round">
                                <span className="toggle-text on-text">On</span>
                                <span className="toggle-text off-text">Off</span>
                            </span>
                        </label>
                    </form>

                    {existingUserData.discountCodePage && (
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div style={{ width: "40%" }}>
                                {loadingAddedCodeMessage && (
                                    <div className={`toast-container`}>
                                        <span>Adding all codes, please wait...</span>
                                    </div>
                                )}
                                <h3>Available Discount Codes</h3>
                                <input
                                    type="text"
                                    className="discount-code-search-box"
                                    placeholder="Search"
                                    value={searchAvaliableCode}
                                    onChange={e => setSearchAvailableCodes(e.target.value)}
                                />
                                {loadingAvaliableDiscountCode && (
                                    <div className="text-center mt-3 mb-3">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                {!loadingAvaliableDiscountCode && (
                                    <select multiple className="discount-code-select-box" onChange={handleSelectionAvailable}>
                                        {filteredAvailableCodes?.map(code => (
                                            <option key={code.value} value={code.value}>
                                                {code.label}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                                {/* 7278215305 - increase font size */}
                                <button className="button-font-size discount-code-add-btns" onClick={addToSelected} style={{ marginTop: "1.3rem" }}>
                                    Add <i class="fas fa-arrow-circle-right" />
                                </button>
                                {/* 7278215305 - increase font size */}
                                <button className="button-font-size discount-code-add-btns" onClick={addAllToSelected}>
                                    Add all <i class="fas fa-angle-double-right" style={{ color: "#fff" }} />
                                </button>
                                <hr style={{ margin: 0 }} />
                                {/* 7278215305 - increase font size */}
                                <button className="button-font-size discount-code-remove-btns" onClick={removeFromSelected}>
                                    <i class="fas fa-arrow-circle-left" style={{ color: "#fff" }} /> Remove
                                </button>
                                {/* 7278215305 - increase font size */}
                                <button className="button-font-size discount-code-remove-btns" onClick={removeAllFromSelected}>
                                    <i class="fas fa-angle-double-left" style={{ color: "#fff" }} /> Remove all
                                </button>
                            </div>
                            <div style={{ width: "40%" }}>
                                {loadingRemovedCodeMessage && (
                                    <div className={`toast-container`}>
                                        <span>Removing all codes, please wait...</span>
                                    </div>
                                )}
                                <h3>Selected Discount Codes</h3>
                                <input
                                    type="text"
                                    className="discount-code-search-box"
                                    placeholder="Search"
                                    value={searchSelectedCode}
                                    onChange={e => setSearchSelectedCode(e.target.value)}
                                />
                                {loadingSelectedeDiscountCode && (
                                    <div className="text-center mt-3 mb-3">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                {!loadingSelectedeDiscountCode && (
                                    <select multiple className="discount-code-select-box" onChange={handleSelectionSelected}>
                                        {filteredSelectedCodes
                                            ?.filter(code => code != null)
                                            .map(code => (
                                                <option key={code.value} value={code.value}>
                                                    {code.label}
                                                </option>
                                            ))}
                                    </select>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Sixth Documents Form ... */}
            <div className="user-documents-barcart-form-container">
                <form className="user-form">
                    <h3>Documents</h3>
                    <hr />
                    {existingUserData.documentRows.map((row, index) => (
                        <div className="documents-barcart" key={row.id}>
                            <div className="input-group-user-form-document-row">
                                <label htmlFor={`title-${row.id}`}>Title</label>
                                <input
                                    type="text"
                                    id={`title-${row.id}`}
                                    placeholder="Title"
                                    value={row.title}
                                    onChange={value => handleTitleChange(value, row.id)}
                                    className="document-input"
                                />
                            </div>
                            <div className="input-group-user-form-document-row">
                                <label htmlFor={`url-${row.id}`}>URL</label>
                                <input type="text" id={`url-${row.id}`} placeholder="URL" value={row.url} onChange={value => handleUrlChange(value, row.id)} className="document-input" />

                            </div>
                            {
                                <div className="document-delete-btn">
                                    <button className="btn btn-sm btn-danger" onClick={() => handleDeleteDocumentBtn(row.id)}>
                                        <FaTrash />
                                    </button>
                                </div>
                            }
                        </div>
                    ))}
                    <div className="text-center">
                        <button type="button" onClick={() => handleAddAnother()} className="add-another-btn">
                            Add another
                        </button>
                    </div>
                </form>
            </div>
            {/* Seventh Product Form ... */}
            <div className="product-btn-form-container">
                <form className="user-form-product-btn">
                    <div>
                        <h3>Product</h3>
                        <p>Enables the product page for the user</p>
                    </div>
                    <label className="switch">
                        <input type="checkbox" onChange={() => handleToggleChange("productPage")} checked={existingUserData.productPage} />
                        <span className="slider round">
                            <span className="toggle-text on-text">On</span>
                            <span className="toggle-text off-text">Off</span>
                        </span>
                    </label>
                </form>
            </div>
            {/* Eighth Weekly Email Report Form ... */}
            {existingUserData.userType === "supplier" && (
                <div className="weekly-email-report-btn-form-container">
                    <form className="user-form-weekly-email-btn">
                        <div>
                            <h3>Weekly Email Report</h3>
                        </div>
                        <label className="switch">
                            <input type="checkbox" onChange={() => handleToggleChange("weeklyEmail")} checked={existingUserData.weeklyEmail} />
                            <span className="slider round">
                                <span className="toggle-text on-text">On</span>
                                <span className="toggle-text off-text">Off</span>
                            </span>
                        </label>
                    </form>
                </div>
            )}
            {/* Nineth Daily Email Report Form ... */}
            <div className="weekly-email-report-btn-form-container">
                <form className="user-form-weekly-email-btn">
                    <div>
                        <h3>Daily Email Report</h3>
                    </div>
                    <label className="switch">
                        <input type="checkbox" onChange={() => handleToggleChange("dailyEmail")} checked={existingUserData.dailyEmail} />
                        <span className="slider round">
                            <span className="toggle-text on-text">On</span>
                            <span className="toggle-text off-text">Off</span>
                        </span>
                    </label>
                </form>
            </div>
            {/* Tenth Show Barcart Btn Form ... */}
            <div className="show-barcart-btn-form-container">
                <form className="user-form-show-barcart-btn">
                    <h3>Show Flaviar Checkout Button</h3>
                    <label className="switch">
                        <input type="checkbox" onChange={() => handleToggleChange("barcartButton")} checked={existingUserData.barcartButton} />
                        <span className="slider round">
                            <span className="toggle-text on-text">On</span>
                            <span className="toggle-text off-text">Off</span>
                        </span>
                    </label>
                </form>
            </div>
            {/* Ship Station Intergration Btn Form ... */}
            {existingUserData?.userType === "retailer" && (
                <div className="ship-station-integration-container">
                    <div className="header-container">
                        <h3>ShipStation Integration</h3>
                        <label className="switch">
                            <input
                                type="checkbox"
                                onChange={() => handleToggleChange("shipStationIntegration")}
                                checked={existingUserData.shipStationIntegration}
                            />
                            <span className="slider round">
                                <span className="toggle-text on-text">On</span>
                                <span className="toggle-text off-text">Off</span>
                            </span>
                        </label>
                    </div>
                    {/* Monday Task: 6719208993 - Added ShipStation Integration Token to react user details page */}
                    {existingUserData.shipStationIntegration && (
                        <div className="store-list">
                            {existingUserData?.shipStationIntegrationData?.length > 0 ? (
                                existingUserData.shipStationIntegrationData.map(store =>
                                    store.token ? (
                                        <div className="store-item" key={store.id}>
                                            <span className="store-name">{store.name}</span>
                                            <span className="store-url">{`${baseURL}ss/store/${store.token}/`}</span>
                                            <button className="copy-btn" onClick={() => copyToClipboardShipStation(`${baseURL}ss/store/${store.token}/`)}>
                                                <i className="far fa-copy"></i> <div className="tooltip">Copy To Clipboard</div>
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="store-item" key={store.id}>
                                            <span className="store-name">{store.name}</span>
                                            <span className="store-url">Token not available</span>
                                        </div>
                                    )
                                )
                            ) : (
                                <div className="no-data-message">No ShipStation integration data available.</div>
                            )}
                        </div>
                    )}
                </div>
            )}
            {/** 6893788815 - Integrate Insights with Barcart - UI field to edit user's JSON data */}
            {existingUserData?.userType === "supplier" ? (
            <div className="insights-btn-form-container">
                <form className="user-form-insights-btn">
                    <h3>Insights</h3>
                    <div className="insight-label">Enable Flaviar Insights</div>
                    <div className="insight-toggle">
                        <label className="switch">
                            <input type="checkbox" onChange={() => handleToggleChange("flaviarInsights")} checked={existingUserData.flaviarInsights} />
                            <span className="slider round">
                                <span className="toggle-text on-text">On</span>
                                <span className="toggle-text off-text">Off</span>
                            </span>
                        </label>
                    </div>
                    <div className="insight-input">Flaviar Insights JSON</div>
                    <div className="insight-json">
                        <Input className="insights-textarea" type="textarea" 
                         value={existingUserData.flaviarInsightsProfile} onChange={handleInsightsProfile}/>
                    </div>
                </form>
            </div>
        ) : (
            <></>
        )}
            <div className="submit-back-btn-at-bottom">
                    <button className="back-btn" onClick={(e) => handleBackClick(e)}>
                        ← Back
                    </button>
                    <button className="submit-btn" onClick={(e) => handleSubmitClick(e)} disabled={submitBtnLoading}>
                        Submit → {submitBtnLoading && <Spinner size="sm" color="light" />}
                    </button>
            </div>
        </Container>
    );
}
export default UpdateUser;
