import React, { useEffect, useState, useContext } from "react";
import { Container, Spinner } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { stateListOptions } from "../../utils/constants";
import { api } from "services/api";
import Select from "react-select";
import { Table } from "reactstrap";
import "./UserProfile.css";
import { AuthContext } from "../../contexts/AuthContext";

// 1st Section User Details
const FormInput = ({ id, label, type, value, onChange, errorMessage }) => {
    const [emailError, setEmailError] = useState("");
    const inputStyle = id === "email" ? { paddingLeft: "40px", border: "0" } : { border: "0" };
    const handlePaste = e => {
        const pastedValue = e.clipboardData.getData("text/plain");
        if (id === "email") {
            // Regular expression for validating email address
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(pastedValue);
            // Check if the pasted value matches the email format
            if (!isValidEmail) {
                setEmailError("Please enter a valid email address. Ex: test@example.com");
            } else {
                // Clear any existing email error
                setEmailError("");
            }
        }
    };
    return (
        <div className="input-group">
            <div className="input-group-label-error-message">
                <label htmlFor={id}>{label}</label>
                {errorMessage && <span className="error-message">{errorMessage}</span>}
            </div>
            <div className="input-container">
                {id === "email" ? (
                    <input
                        type="email"
                        id={id}
                        placeholder={label}
                        onInput={e => {
                            const inputValue = e.target.value;
                            if (inputValue.trim() === "") {
                                // Clear any existing email error if the input is empty
                                setEmailError("");
                            } else {
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                const isValidEmail = emailRegex.test(inputValue);
                                if (!isValidEmail) {
                                    setEmailError("Please enter a valid email address. Ex: test@example.com");
                                } else {
                                    setEmailError("");
                                }
                            }
                        }}
                        onPaste={handlePaste}
                        value={value}
                        onChange={onChange}
                        style={inputStyle}
                    />
                ) : (
                    <input type={type} id={id} placeholder={label} value={value} onChange={onChange} style={inputStyle} />
                )}
                {id === "email" && <i className="fas fa-envelope input-icon" />}
            </div>
            {emailError && <span className="error-message">{emailError}</span>}
        </div>
    );
};
function UserProfile() {
    const { user } = useContext(AuthContext);
    const { addToast } = useToasts();
    const history = useHistory();
    const [imageUrl, setImageUrl] = useState(null);
    const [showImg, setShowImg] = useState(null);
    const [removeClicked, setRemoveClicked] = useState(false);
    const [unsupportedFile, setUnsupportedFile] = useState(false);
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    // Existing user data fetched from the database
    const [existingUserData, setExistingUserData] = useState({
        avatar: "",
        email: "",
        notificationEmail: "",
        firstName: "",
        lastName: "",
        ship_from_phone: "",
        fulfillmentState: [],
        fulfillmentOtherState: [],
        fulfillmentChargeTax: [],
        locations: {
            id: null,
            company_name: "",
            dba: "",
            tax_number: "",
            ship_from_address1: "",
            ship_from_city: "",
            ship_from_state_dropdown_value: { value: "", label: "" },
            ship_from_zip: "",
            ship_from_country: ""
        },
        selectedStates: [],
        allSpiritStates: "",
        allOtherStates: "",
        allChargeTax: "",
        stripeButtonData: {}
    });

    const [newLocation, setNewLocation] = useState([]);

    const [toggleFulfillmentStates, setToggleFulfillmentStates] = useState({});
    const [selectAllFulfillmentState, setSelectAllFulfillmentState] = useState(false);
    const [toggleOtherFulfillmentStates, setToggleOtherFulfillmentStates] = useState({});
    const [selectAllOtherFulfillmentState, setSelectAllOtherFulfillmentState] = useState(false);
    const [toggleTaxStates, setToggleTaxStates] = useState({});
    const [selectAllTaxState, setSelectAllTaxState] = useState(false);
    const [stateList, setStateList] = useState([]);

    useEffect(() => {
        if (selectAllTaxState) {
            const newFulfillmentStates = { ...toggleFulfillmentStates };
            stateList.forEach(item => {
                if (!toggleOtherFulfillmentStates[item.code]) {
                    newFulfillmentStates[item.code] = true;
                }
            });
            setToggleFulfillmentStates(newFulfillmentStates);
            setSelectAllFulfillmentState(true);
        }
    }, [selectAllTaxState, stateList]);

    useEffect(() => {
        if (selectAllFulfillmentState) {
            const newFulfillmentStates = {};
            stateList.forEach(item => {
                newFulfillmentStates[item.code] = true;
            });
            setToggleFulfillmentStates(newFulfillmentStates);
            setSelectAllFulfillmentState(true);
        }
    }, [selectAllFulfillmentState]);

    const handleSelectAllFulfillmentStates = () => {
        const newSelectAll = !selectAllFulfillmentState;

        if (!newSelectAll) {
            // Turn off all fulfillment states
            setToggleFulfillmentStates({});
            setSelectAllFulfillmentState(false);

            if (!selectAllOtherFulfillmentState && selectAllTaxState) {
                setToggleTaxStates({});
                setSelectAllTaxState(false);
            }

            // Prepare new tax states
            const newTaxStates = { ...toggleTaxStates };

            // Check each state
            stateList.forEach(item => {
                const isFulfillmentEnabled = toggleFulfillmentStates[item.code]; // Was fulfillment initially on
                const isTaxEnabled = toggleTaxStates[item.code]; // Was tax initially on
                const isOtherFulfillmentEnabled = toggleOtherFulfillmentStates[item.code]; // Is other fulfillment on

                console.log(
                    `State ${item.code}: Fulfillment - ${isFulfillmentEnabled}, Tax - ${isTaxEnabled}, Other Fulfillment - ${isOtherFulfillmentEnabled}`
                );

                if (isFulfillmentEnabled && isTaxEnabled && !isOtherFulfillmentEnabled) {
                    // Turn off tax for states where both fulfillment and tax were initially enabled, but not other fulfillment
                    newTaxStates[item.code] = false;
                }
            });

            // Update tax states
            setToggleTaxStates(newTaxStates);
        } else {
            // Turn on all fulfillment states
            const newState = {};
            stateList.forEach(item => {
                newState[item.code] = true;
            });
            setToggleFulfillmentStates(newState);
            setSelectAllFulfillmentState(true);
        }
    };

    const handleSelectAllOtherFulfillmentStates = () => {
        const newSelectAll = !selectAllOtherFulfillmentState;
        const newState = {};
        stateList.forEach(item => {
            newState[item.code] = newSelectAll;
        });
        setToggleOtherFulfillmentStates(newState);
        setSelectAllOtherFulfillmentState(newSelectAll);

        // Check condition 1
        if (!newSelectAll && !selectAllFulfillmentState && selectAllTaxState) {
            setToggleTaxStates({});
            setSelectAllTaxState(false);
        }
    };

    const handleSelectAllTaxStates = () => {
        const newSelectAll = !selectAllTaxState;
        const newState = {};
        stateList.forEach(item => {
            newState[item.code] = newSelectAll;
        });
        setToggleTaxStates(newState);
        setSelectAllTaxState(newSelectAll);

        if (newSelectAll) {
            const newFulfillmentStates = { ...toggleFulfillmentStates };
            stateList.forEach(item => {
                if (!toggleOtherFulfillmentStates[item.code]) {
                    newFulfillmentStates[item.code] = true;
                }
            });
            setToggleFulfillmentStates(newFulfillmentStates);
        }
    };

    const toggleFulfillmentStatesSwitch = id => {
        setToggleFulfillmentStates(prevState => {
            const updatedFulfillmentStates = {
                ...prevState,
                [id]: !prevState[id]
            };

            // Check if turning off any state should turn off the "Select All" toggle for fulfillment states
            if (!updatedFulfillmentStates[id]) {
                setSelectAllFulfillmentState(false);
            }
            // If turning off Spirit States, do not affect Other States or Charge Tax
            if (toggleOtherFulfillmentStates && !toggleOtherFulfillmentStates[id]) {
                setToggleTaxStates(prevTaxStates => ({
                    ...prevTaxStates,
                    [id]: false // Ensure tax state is turned off for this ID
                }));
            }

            return updatedFulfillmentStates;
        });
    };

    const toggleOtherFulfillmentStatesSwitch = id => {
        setToggleOtherFulfillmentStates(prevState => {
            const updatedStates = {
                ...prevState,
                [id]: !prevState[id]
            };

            if (toggleFulfillmentStates && !toggleFulfillmentStates[id]) {
                setToggleTaxStates(prevTaxStates => ({
                    ...prevTaxStates,
                    [id]: false // Ensure tax state is turned off for this ID
                }));
            }
            if (!updatedStates[id]) {
                setSelectAllOtherFulfillmentState(false);
            }
            return updatedStates;
        });
    };

    const toggleTaxStatesSwitch = id => {
        setToggleTaxStates(prevState => {
            const updatedTaxStates = {
                ...prevState,
                [id]: !prevState[id] // Toggle the tax state for the given id
            };

            // Check if turning off any state should turn off the "Select All" toggle for tax states
            if (!updatedTaxStates[id]) {
                setSelectAllTaxState(false);
            }
            // Only if the tax state is turned on, ensure the fulfillment state is also turned on
            if (updatedTaxStates[id]) {
                if (!toggleOtherFulfillmentStates[id]) {
                    setToggleFulfillmentStates(prevFulfillmentStates => ({
                        ...prevFulfillmentStates,
                        [id]: true // Ensure fulfillment state is turned on for this id
                    }));
                }
            }

            return updatedTaxStates;
        });
    };

    const fullfilmentStates = value => {
        return toggleFulfillmentStates[value];
    };

    const fullfilmentOtherStates = value => {
        return toggleOtherFulfillmentStates[value];
    };

    const fulfillmentChargeTax = value => {
        return toggleTaxStates[value];
    };

    const handleUpdateLocation = (value, id, data) => {
        const data1 = {
            id: data?.id,
            company_name: data?.company_name,
            dba: data?.dba,
            tax_number: data?.tax_number,
            ship_from_address1: data?.ship_from_address1,
            ship_from_city: data?.ship_from_city,
            ship_from_state: data?.ship_from_state_dropdown_value?.value,
            ship_from_zip: data?.ship_from_zip,
            ship_from_country: data?.ship_from_country
        };
        if (newLocation.length > 0) {
            const existing = newLocation.filter(loc => loc.id === id);
            if (existing.length > 0) {
            } else {
                //  setNewLocation({...newLocation, data1})
                newLocation.push(data1);
            }
        } else {
            newLocation.push(data1);
        }
    };
    useEffect(() => {
        // Scroll to the top of UpdateUserPage component when it mounts
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        let fetchUserDetails = async () => {
            try {
                if (user) {
                    const userDetailsResponse = await api.get(`react/profile/${user.id}/`);
                    const userDetails = userDetailsResponse.data;
                    // Extract required data from user details

                    // Set existing user data with fetched values
                    setExistingUserData(prevData => ({
                        ...prevData,
                        avatar: userDetails?.avatar,
                        email: userDetails?.email,
                        firstName: userDetails?.first_name,
                        lastName: userDetails?.last_name,
                        fulfillmentState: userDetails?.fullfilment_states.map(fullfilmentstate => ({
                            ...fullfilmentstate,
                            updated: false
                        })),
                        fulfillmentOtherState: userDetails?.other_fulfillment_states.map(fullfilmentotherstate => ({
                            ...fullfilmentotherstate,
                            updated: false
                        })),
                        fulfillmentChargeTax: userDetails?.fullfilment_states_chargetax_list.map(chargetaxstate => ({
                            ...chargetaxstate,
                            updated: false
                        })),
                        locations: {
                            id: userDetails?.inventory_locations[0]?.id,
                            company_name: userDetails?.inventory_locations[0]?.company_name,
                            dba: userDetails?.inventory_locations[0]?.dba,
                            tax_number: userDetails?.inventory_locations[0]?.tax_number,
                            ship_from_address1: userDetails?.inventory_locations[0]?.ship_from_address1,
                            ship_from_city: userDetails?.inventory_locations[0]?.ship_from_city,
                            ship_from_state_dropdown_value: {
                                value: userDetails?.inventory_locations[0]?.ship_from_state,
                                label: userDetails?.inventory_locations[0]?.ship_from_state_name
                            },
                            ship_from_phone: userDetails?.inventory_locations[0]?.ship_from_phone,
                            ship_from_zip: userDetails?.inventory_locations[0]?.ship_from_zip,
                            ship_from_country: userDetails?.inventory_locations[0]?.ship_from_country
                        },
                        allSpiritStates: userDetails?.all_fullfilments_active,
                        allOtherStates: userDetails?.all_other_fulfillment_states,
                        allChargeTax: userDetails?.all_charge_tax_active,
                        notificationEmail: userDetails?.notification_email,
                        stripeButtonData: userDetails?.stripe_data
                    }));

                    const otherFulfillmentStates = {};
                    userDetails?.other_fulfillment_states?.forEach(state => {
                        otherFulfillmentStates[state.state_code] = true; // Assumes active if listed
                    });
                    setToggleOtherFulfillmentStates(otherFulfillmentStates);

                    const chargeTaxStates = {};
                    userDetails?.fullfilment_states_chargetax_list?.forEach(state => {
                        chargeTaxStates[state.state_code] = true; // Assumes 'true' means tax is to be charged
                    });
                    setToggleTaxStates(chargeTaxStates);

                    const newFulfillmentStates = {};
                    userDetails.fullfilment_states.forEach(state => {
                        newFulfillmentStates[state.state_code] = true; // Example logic
                    });

                    setToggleFulfillmentStates(newFulfillmentStates);
                    setStateList(userDetails?.state_list ?? []);
                    setShowImg(userDetails.avatar);
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
            }
        };
        fetchUserDetails();
    }, [user]);

    // Handlers using useCallback to prevent unnecessary re-creations
    const handleChange = e => {
        const { id, value } = e.target;
        if (id === "ship_from_phone") {
            if (!/^\d*$/.test(value)) {
                setErrorMessages(prev => ({ ...prev, ship_from_phone: "Only numbers are allowed in the telephone field." }));
            } else {
                setErrorMessages(prev => ({ ...prev, ship_from_phone: "" }));
                setExistingUserData(prev => ({ ...prev, [id]: value }));
            }
        } else {
            setExistingUserData(prev => ({ ...prev, [id]: value }));
        }
    };

    const [errorMessages, setErrorMessages] = useState({});

    const handleCompanyChange = (value, id, data) => {
        handleUpdateLocation(value, id, data);
        const updatedLocation = { ...existingUserData.locations, company_name: value.target.value, updated: true };
        setExistingUserData(prevState => ({ ...prevState, locations: updatedLocation }));
        setErrorMessages(prevState => ({ ...prevState, company_name: "" }));
    };

    const handleDbaChange = (value, id) => {
        const updatedLocation = { ...existingUserData.locations, dba: value.target.value, updated: true };
        setExistingUserData(prevState => ({ ...prevState, locations: updatedLocation }));
        setErrorMessages(prevState => ({ ...prevState, dba: "" }));
    };

    const handleTaxNumberChange = (value, id) => {
        const updatedLocation = { ...existingUserData.locations, tax_number: value.target.value, updated: true };
        setExistingUserData(prevState => ({ ...prevState, locations: updatedLocation }));
    };

    const handleStreetChange = (value, id) => {
        const updatedLocation = { ...existingUserData.locations, ship_from_address1: value.target.value, updated: true };
        setExistingUserData(prevState => ({ ...prevState, locations: updatedLocation }));
    };

    const handleCityChange = (value, id) => {
        const updatedLocation = { ...existingUserData.locations, ship_from_city: value.target.value, updated: true };
        setExistingUserData(prevState => ({ ...prevState, locations: updatedLocation }));
    };

    const handleStateChange = (code, id) => {
        const updatedLocation = { ...existingUserData.locations, ship_from_state_dropdown_value: code, updated: true };
        setExistingUserData(prevState => ({ ...prevState, locations: updatedLocation }));
        setErrorMessages(prevState => ({ ...prevState, ship_from_state_dropdown_value: "" }));
    };

    const handleTelephoneChange = (value, id) => {
        const updatedLocation = { ...existingUserData.locations, ship_from_phone: value.target.value, updated: true };
        setExistingUserData(prevState => ({ ...prevState, locations: updatedLocation }));
    };

    const handleZipChange = (value, id) => {
        const isValidNumber = /^\d*$/.test(value.target.value);
        if (isValidNumber) {
            const updatedLocation = { ...existingUserData.locations, ship_from_zip: value.target.value, updated: true };
            setExistingUserData(prevState => ({ ...prevState, locations: updatedLocation }));
            setErrorMessages(prevState => ({ ...prevState, ship_from_zip: "" }));
        } else {
            // Notify the user about the invalid input
            addToast("Please enter only numbers in the zip field.", {
                autoDismiss: true,
                autoDismissTimeout: 3000 // 3 seconds timeout
            });
        }
    };

    const handleCountryChange = (value, id) => {
        const updatedLocation = { ...existingUserData.locations, ship_from_country: value.target.value, updated: true };
        setExistingUserData(prevState => ({ ...prevState, locations: updatedLocation }));
        setErrorMessages(prevState => ({ ...prevState, ship_from_country: "" }));
    };

    const generateErrorMessages = location => {
        const newErrorMessages = {};

        if (!location.company_name) {
            newErrorMessages.company_name = "Required";
        }
        if (!location.dba) {
            newErrorMessages.dba = "Required";
        }
        if (!location.ship_from_state_dropdown_value || !location.ship_from_state_dropdown_value.value) {
            newErrorMessages.ship_from_state_dropdown_value = "Required";
        }
        if (!location.ship_from_zip) {
            newErrorMessages.ship_from_zip = "Required";
        }
        if (!location.ship_from_country) {
            newErrorMessages.ship_from_country = "Required";
        }

        return newErrorMessages;
    };

    const stateIdMapping = stateList?.reduce((acc, state) => {
        acc[state.code] = state.id;
        return acc;
    }, {});

    // Function to create the payload
    function createPayload() {
        // Combine keys from all toggle state objects to ensure all are considered
        const allStateCodes = new Set([...Object.keys(toggleFulfillmentStates), ...Object.keys(toggleOtherFulfillmentStates), ...Object.keys(toggleTaxStates)]);

        // Generate the payload using all state codes
        const fulfillmentStatesData = Array.from(allStateCodes).map(stateCode => {
            const spritFulfillmentId = toggleFulfillmentStates[stateCode] ? stateIdMapping[stateCode] : null;
            const otherFulfilmentId = toggleOtherFulfillmentStates[stateCode] ? stateIdMapping[stateCode] : null;
            const charge_tax = toggleTaxStates[stateCode] ? 1 : 0;

            return { spritFulfillmentId, otherFulfilmentId, charge_tax };
        });

        // Filter out entries where no ID is present (if that's possible in your case)
        const filteredFulfillmentStatesData = fulfillmentStatesData.filter(item => item.spritFulfillmentId !== null || item.otherFulfilmentId !== null);

        return filteredFulfillmentStatesData;
    }

    // Call the function to create the payload
    const togglePayload = createPayload();

    const handleBackClick = event => {
        history.push("/profile");
    };

    const handleSubmitClick = event => {
        event.preventDefault();
        console.log("Form submission initiated...");
        const newErrorMessages = {};

        if (!existingUserData.firstName) {
            newErrorMessages.firstName = "Required";
        }
        if (!existingUserData.lastName) {
            newErrorMessages.lastName = "Required";
        }
        if (!existingUserData.email) {
            newErrorMessages.email = "Required";
        }
        if (!existingUserData.notificationEmail) {
            newErrorMessages.notificationEmail = "Required";
        }
        console.log("Validating location fields...");

        const locationErrorMessages = generateErrorMessages(existingUserData.locations);
        setErrorMessages({ ...newErrorMessages, ...locationErrorMessages });
        const hasErrors = Object.values(newErrorMessages).some(message => message) || Object.values(locationErrorMessages).some(message => message);

        // If there are any error messages, stop the submission process
        if (hasErrors) {
            addToast("Please fill all required fields.", {
                appearance: "error",
                autoDismiss: true
            });
            console.log("Validation errors found: ", { ...newErrorMessages, ...locationErrorMessages });
            return;
        }

        const imageFormData = new FormData();
        imageFormData.append("profile_image", imageUrl);

        // When constructing the payload, only include the locations with the 'updated' flag set to true
        const updatedLocation = existingUserData.locations.updated ? existingUserData.locations : null;

        const payload = {
            user_id: existingUserData.user_id,
            email: existingUserData.email,
            first_name: existingUserData.firstName,
            notification_email: existingUserData?.notificationEmail,
            last_name: existingUserData.lastName,
            contact_type: "text",
            fullfilment_states_data: togglePayload,
            all_fullfillment_active: selectAllFulfillmentState ? 1 : 0,
            all_other_fulfullment_states_active: selectAllOtherFulfillmentState ? 1 : 0,
            all_charge_tax_active: selectAllTaxState ? 1 : 0,
            inventory_location_data: updatedLocation
                ? {
                      id: updatedLocation.id,
                      company_name: updatedLocation.company_name,
                      dba: updatedLocation.dba,
                      tax_number: updatedLocation.tax_number,
                      ship_from_address1: updatedLocation.ship_from_address1,
                      ship_from_city: updatedLocation.ship_from_city,
                      ship_from_state: updatedLocation.ship_from_state_dropdown_value.value,
                      ship_from_zip: updatedLocation.ship_from_zip,
                      ship_from_country: updatedLocation.ship_from_country,
                      ship_from_phone: updatedLocation.ship_from_phone
                  }
                : null
        };

        console.log("payload: ", payload);

        const payloadString = JSON.stringify(payload);
        imageFormData.append("payload", payloadString);
        setSubmitBtnLoading(true);
        api.post(`react/profile/${user.id}/`, imageFormData)
            .then(response => {
                setSubmitBtnLoading(false);
                if (response.data.success) {
                    addToast(response.data.success, { appearance: "success", autoDismiss: true });
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                        window.location.reload();
                    }, 5000);
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    let message = error?.response?.data?.error;
                    setSubmitBtnLoading(false);
                    addToast(message, { appearance: "error", autoDismiss: true });
                } else {
                    // Handle other errors (network errors, etc.)
                }
            });
    };
    const handleImageChange = e => {
        const file = e.target.files[0];
        if (file && !["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
            setUnsupportedFile(true);
            return;
        }
        setUnsupportedFile(false);
        const reader = new FileReader();
        reader.onload = e => {
            setShowImg(e.target.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
        setImageUrl(file);
    };
    const handleRemoveImage = () => {
        setRemoveClicked(true);
        setShowImg(null);
        setImageUrl(null);
        // Clear the file input field
        document.getElementById("image-upload").value = null;
    };

    const handleConnectWithStripe = async () => {
        try {
            const response = await api.get(`user/react/link-to-stripe/${user.id}/`, {});
            console.log("Stripe connection successful:", response.data);
            if (response?.data?.stripe_link) {
                window.open(response?.data?.stripe_link, "_self");
            } else {
                let message = "Stripe Link Not Found!";
                addToast(message, { appearance: "error", autoDismiss: true });
            }
            // Handle successful response (e.g., update UI, notify user)
        } catch (error) {
            console.error("Error connecting with Stripe:", error);
            addToast(error?.response?.data?.error, { appearance: "error", autoDismiss: true });
        }
    };

    return (
        <Container fluid style={{ paddingTop: "7rem" }}>
            {/* First User Details Form ... */}
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 3 }}>
                    <form className="user-form">
                        <h3>User Info</h3>
                        <br></br>
                        <div className="name-inputs">
                            <FormInput
                                id="firstName"
                                label="First name *"
                                type="text"
                                value={existingUserData.firstName}
                                onChange={e => handleChange(e)}
                                errorMessage={errorMessages.firstName}
                            />
                            <FormInput
                                id="lastName"
                                label="Last name *"
                                type="text"
                                value={existingUserData.lastName}
                                onChange={e => handleChange(e)}
                                errorMessage={errorMessages.lastName}
                            />
                        </div>
                        <div className="name-inputs">
                            <FormInput
                                id="email"
                                label="Account Email *"
                                type="email"
                                value={existingUserData.email}
                                onChange={e => handleChange(e)}
                                errorMessage={errorMessages.email}
                            />
                            <FormInput
                                id="notificationEmail"
                                label="Notification Email *"
                                type="email"
                                value={existingUserData.notificationEmail}
                                onChange={e => handleChange(e)}
                                errorMessage={errorMessages.notificationEmail}
                            />
                            <FormInput
                                id="ship_from_phone"
                                label="Telephone"
                                type="tel"
                                value={existingUserData.locations.ship_from_phone}
                                onChange={value => handleTelephoneChange(value, existingUserData.locations.id, existingUserData.locations)}
                            />
                        </div>
                        <div>
                            {existingUserData && (
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ flex: 1, marginRight: "20px" }}>
                                            <FormInput
                                                id="companyName"
                                                label="Company Name *"
                                                type="text"
                                                value={existingUserData.locations.company_name}
                                                onChange={value => handleCompanyChange(value, existingUserData.locations.id, existingUserData.locations)}
                                                errorMessage={errorMessages.company_name}
                                            />
                                        </div>
                                        <div style={{ flex: 1, marginRight: "20px" }}>
                                            <FormInput
                                                id="dba"
                                                label="DBA *"
                                                value={existingUserData.locations.dba}
                                                onChange={value => handleDbaChange(value, existingUserData.locations.id)}
                                                errorMessage={errorMessages.dba}
                                            />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <FormInput
                                                id="taxNumber"
                                                label="Tax Number"
                                                value={existingUserData.locations.tax_number}
                                                onChange={value => handleTaxNumberChange(value, existingUserData.locations.id)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}></div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ flex: 1, marginRight: "20px" }}>
                                            <FormInput
                                                id="email1"
                                                label="Street"
                                                value={existingUserData.locations.ship_from_address1}
                                                onChange={value => handleStreetChange(value, existingUserData.locations.id)}
                                            />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <FormInput
                                                id="shipFromCity"
                                                label="City"
                                                value={existingUserData.locations.ship_from_city}
                                                onChange={value => handleCityChange(value, existingUserData.locations.id)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ flex: 1, marginRight: "20px", marginTop: "5px" }}>
                                            <div className="input-group-label-error-message">
                                                <label className="states-label">States *</label>
                                                {errorMessages.ship_from_state_dropdown_value && (
                                                    <span className="error-message">{errorMessages.ship_from_state_dropdown_value}</span>
                                                )}
                                            </div>
                                            <Select
                                                className="select-input"
                                                placeholder="Select States..."
                                                value={existingUserData.locations.ship_from_state_dropdown_value}
                                                onChange={code => handleStateChange(code, existingUserData.locations.id)}
                                                options={stateListOptions}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                allowSelectAll={true}
                                                checked={true}
                                            />
                                        </div>
                                        <div style={{ flex: 1, marginRight: "20px" }}>
                                            <FormInput
                                                id="shipFromZip"
                                                label="Zip *"
                                                value={existingUserData.locations.ship_from_zip}
                                                onChange={value => handleZipChange(value, existingUserData.locations.id)}
                                                errorMessage={errorMessages.ship_from_zip}
                                            />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <FormInput
                                                id="shipFromCountry"
                                                label="Country *"
                                                value={existingUserData.locations.ship_from_country}
                                                onChange={value => handleCountryChange(value, existingUserData.locations.id)}
                                                errorMessage={errorMessages.ship_from_country}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <hr className="horizontal-line"></hr>
                        <h3 style={{ fontSize: "1rem" }}>Fulfillment States</h3>
                        <div style={{ maxHeight: "400px", overflowY: "auto", border: "1px solid #ddd" }}>
                            <Table>
                                <thead style={{ position: "sticky", top: "-1px", backgroundColor: "#ebebeb", zIndex: 1000 }}>
                                    <tr>
                                        <th style={{ padding: "7px", margin: "0", fontSize: "0.8rem", verticalAlign: "middle", textTransform: "capitalize" }}>
                                            STATE NAME
                                        </th>
                                        <th style={{ padding: "7px", margin: "0", fontSize: "0.8rem", textAlign: "center", textTransform: "capitalize" }}>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <span>SPIRIT STATES</span>
                                                <label className="switch" style={{ marginTop: "5px" }}>
                                                    <input
                                                        id="fulfillmentState"
                                                        type="checkbox"
                                                        value={existingUserData.fulfillmentState}
                                                        onChange={handleSelectAllFulfillmentStates}
                                                        checked={selectAllFulfillmentState || existingUserData?.allSpiritStates}
                                                    />
                                                    <span className="slider round">
                                                        <span className="toggle-text on-text">ON</span>
                                                        <span className="toggle-text off-text">OFF</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </th>
                                        <th style={{ padding: "7px", margin: "0", fontSize: "0.8rem", textAlign: "center", textTransform: "capitalize" }}>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <span>OTHER STATES</span>
                                                <label className="switch" style={{ marginTop: "5px" }}>
                                                    <input
                                                        id="fulfillmentOtherState"
                                                        type="checkbox"
                                                        value={existingUserData.fulfillmentOtherState}
                                                        onChange={handleSelectAllOtherFulfillmentStates}
                                                        checked={selectAllOtherFulfillmentState || existingUserData?.allOtherStates}
                                                    />
                                                    <span className="slider round">
                                                        <span className="toggle-text on-text">ON</span>
                                                        <span className="toggle-text off-text">OFF</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </th>
                                        <th style={{ padding: "7px", margin: "0", fontSize: "0.8rem", textAlign: "center", textTransform: "capitalize" }}>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <span>CHARGE SALES TAX</span>
                                                <label className="switch" style={{ marginTop: "5px" }}>
                                                    <input
                                                        type="checkbox"
                                                        value={existingUserData.fulfillmentChargeTax}
                                                        onChange={handleSelectAllTaxStates}
                                                        checked={selectAllTaxState || existingUserData?.allChargeTax}
                                                    />
                                                    <span className="slider round">
                                                        <span className="toggle-text on-text">ON</span>
                                                        <span className="toggle-text off-text">OFF</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {existingUserData &&
                                        stateList &&
                                        stateList.map(options => (
                                            <tr key={options?.id}>
                                                <td style={{ padding: "7px", margin: "0", fontSize: "1rem" }}>{options?.name}</td>
                                                <td style={{ padding: "7px", margin: "0", fontSize: "1rem", textAlign: "center" }}>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => toggleFulfillmentStatesSwitch(options.code)}
                                                            checked={toggleFulfillmentStates[options.code] === true}
                                                        />
                                                        <span className="slider round">
                                                            <span className="toggle-text on-text">ON</span>
                                                            <span className="toggle-text off-text">OFF</span>
                                                        </span>
                                                    </label>
                                                </td>
                                                <td style={{ padding: "7px", margin: "0", fontSize: "1rem", textAlign: "center" }}>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => toggleOtherFulfillmentStatesSwitch(options.code)}
                                                            checked={toggleOtherFulfillmentStates[options.code] === true}
                                                        />
                                                        <span className="slider round">
                                                            <span className="toggle-text on-text">ON</span>
                                                            <span className="toggle-text off-text">OFF</span>
                                                        </span>
                                                    </label>
                                                </td>
                                                <td style={{ padding: "7px", margin: "0", fontSize: "1rem", textAlign: "center" }}>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => toggleTaxStatesSwitch(options.code)}
                                                            checked={toggleTaxStates[options.code] === true}
                                                        />
                                                        <span className="slider round">
                                                            <span className="toggle-text on-text">ON</span>
                                                            <span className="toggle-text off-text">OFF</span>
                                                        </span>
                                                    </label>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                        <br></br>
                        <div className="my-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="flex-fill">Stripe Account</h3>
                                {existingUserData?.stripeButtonData?.stripe_account_is_active ? (
                                    <button className="btn btn-outline-stripe col-4 col-md-5 col-lg-4 mr-0" type="button">
                                        Connected <i className="far fa-check-circle fa-fw ml-1"></i>
                                    </button>
                                ) : existingUserData?.stripeButtonData?.has_stripe_account_inheritance_0 ? (
                                    <button className="btn btn-outline-stripe col-4 col-md-5 col-lg-4 mr-0" type="button">
                                        Connected By{" "}
                                        {existingUserData?.stripeButtonData?.stripe_business_profile
                                            ? existingUserData?.stripeButtonData?.stripe_business_profile
                                            : existingUserData?.stripeButtonData?.has_stripe_account_inheritance_1}
                                        <i className="far fa-check-circle fa-fw ml-1"></i>
                                    </button>
                                ) : existingUserData?.stripeButtonData?.stripe_account_is_incomplete ? (
                                    <button className="btn btn-outline-stripe col-4 col-md-5 col-lg-4 mr-0" type="button" onClick={handleConnectWithStripe}>
                                        <i className="fa fa-exclamation-circle fa-fw ml-1"></i> Incomplete
                                    </button>
                                ) : (
                                    <button className="btn btn-primary btn-stripe col-4 col-md-5 col-lg-4 mr-0" type="button" onClick={handleConnectWithStripe}>
                                        <i className="fas fa-plus-circle"></i>
                                        <span className="d-none d-md-inline">Connect With Stripe</span>
                                        <span className="d-inline d-md-none">Connect</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <hr className="horizontal-line"></hr>
                        <div className="submit-back-btn-at-bottom-profile">
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button type="button" className="back-btn d-none" onClick={e => handleBackClick(e)}>
                                    ← Back
                                </button>
                                <button type="button" className="submit-btn" onClick={e => handleSubmitClick(e)} disabled={submitBtnLoading}>
                                    Submit → {submitBtnLoading && <Spinner size="sm" color="light" />}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Container>
    );
}

export default UserProfile;
