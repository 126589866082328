// TO BE IMPLEMENTED...
import { useEffect, useState, useRef } from "react";
import * as ChartGeo from "chartjs-chart-geo";
import { ChoroplethChart } from 'chartjs-chart-geo';
import { api } from "services/api";
import { Chart } from "react-chartjs-2";
import { GetStateObjectFromStateValue } from '../../../../utils/states'
import { getParams } from "utils/commonFunctions";
import Spinner from "../../Spinner/index";
import { Chart as ChartJS, CategoryScale, Tooltip, Title, Legend } from "chart.js";
import axios from "axios";
import LegendItem from "./legendItem";

ChartJS.register(Title, Tooltip, Legend, CategoryScale, ChartGeo.ChoroplethController, ChartGeo.ProjectionScale, ChartGeo.ColorScale, ChartGeo.GeoFeature);

var myChart;
const USPageMap = ({ filters }) => {
  const chartRef = useRef();
  const [isLoading, setisLoading] = useState(true);
  const [states, setstates] = useState();
  const [nation, setnation] = useState();
  const [greaterValue, setGreaterValue] = useState(0);
  const cancelToken = useRef(undefined);

  const getValueForGivenStateName = (stateName, usMapDataFromAPI) => {
    let stateCode = GetStateObjectFromStateValue(stateName)?.code;
    let value = (stateCode && usMapDataFromAPI?.states && usMapDataFromAPI?.states[stateCode]);
    return value ? value : 0;
  }

  /**
   * If equals to zero then return colors[0], If less than and equal to 25% then return colors[1]
   * If more than 25% and less than 75% then return colors[2], If more than 75% then return colors[3]
   * @param {*} maximumValue => int => maximum orders count 
   * @param {*} value => int => order count
   */
  const colors = ["#d0d0d0", "#beead8", "#95dabe", "#11c37a"];
  const getColor = (value) => {
    let twentyFivePercent = parseInt(greaterValue / 4);
    let seventyFivePercent = parseInt((greaterValue / 4) * 3);
    if (value === 0) return colors[0];
    if (value <= twentyFivePercent) return colors[1];
    if (value <= seventyFivePercent) return colors[2];
    if (value > seventyFivePercent) return colors[3];
  }

  useEffect(async () => {
    setisLoading(true);
    let usMapDataFromAPI = {};
    let new_params = getParams(filters);
    if (cancelToken.current !== undefined) {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }
    cancelToken.current = axios.CancelToken.source();

    await api
      .get("order/react/dummy_heatmap/", { params: new_params, cancelToken: cancelToken.current.token, })
      .then((response) => {
        if (response.status === 200 && response?.data) {
          usMapDataFromAPI = (response.data);
          setGreaterValue(response.data.greater_value);
        }
      })
      .catch((error) => { console.error(error); });

    fetch("https://unpkg.com/us-atlas/states-10m.json")
      .then((r) => r.json())
      .then((us) => {
        const nation = ChartGeo.topojson.feature(us, us.objects.nation).features[0];
        setnation(nation);
        const tempStates = ChartGeo.topojson.feature(us, us.objects.states).features;
        tempStates.forEach(element => {
          let countOfOrders = getValueForGivenStateName(element.properties.name, usMapDataFromAPI);
          element.properties.value = countOfOrders ? countOfOrders : 0;
        });
        setstates(tempStates);
        setisLoading(false);
      });
  }, [filters]);


  return (
    <>
      {isLoading ? <Spinner /> : <>
        <Chart ref={chartRef} type="choropleth" data={{
          labels: states.map((d) => d.properties.name),
          datasets: [{
            outlineBackgroundColor: 'white', borderColor: "white", label: "States", outline: nation,
            data: states.map((d) => ({ feature: d, value: d.properties.value ? d.properties.value : 0, })),
            backgroundColor: context => {
              if (context.dataIndex == null) return null;
              const value = context.dataset.data[context.dataIndex];
              return getColor(value.value);
            },
          },
          ],
        }}
          options={{
            showOutline: false, showGraticule: false, scales: {
              color: {
                display: false,
                legend: { display: false, width: '0px', length: 0, indicatorWidth: 0, color: "#172b4d", margin: 0 }
              }
            }, plugins: { legend: { display: false }, }
          }} />

        {/* Custom legand for US Map */}
        <div className="mt-">
          <LegendItem legandItemColor={colors[0]} legandItemTitle={`No Orders`} />
          <LegendItem legandItemColor={colors[1]} legandItemTitle={`Less than ${parseInt(greaterValue / 4)} Orders`} />
          <LegendItem legandItemColor={colors[2]} legandItemTitle={`Between ${parseInt(greaterValue / 4)} and ${parseInt((greaterValue / 4) * 3)} Orders`} />
          <LegendItem legandItemColor={colors[3]} legandItemTitle={`More than ${parseInt((greaterValue / 4) * 3)} Orders`} />
        </div>
      </>
      }
    </>)
};
export default USPageMap;