/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to update product setion two.
  
 */

import React, { useContext, useEffect, useState } from "react";
import styles from "../create-product/createProduct.module.css";
import { FaTrash } from "react-icons/fa";
import { generateSku } from "utils/commonFunctions";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { AuthContext } from "contexts/AuthContext";
import "../../../utils/commonStyles.css";

const createOption = label => ({
    label,
    value: label.toLowerCase().replace(/\W/g, "")
});

const SectionTwoUpdateProduct = ({ setPayload, payload, listing, setListing, requiredFields }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useContext(AuthContext);
    //const [productValue, setProductValue] = useState(payload.variant_size_for_images);
    const [productItems, setProductItems] = useState([]);

    const handleKeyDown = (e, field) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    const handleProductChange = (index, field, value) => {
        if (field === "size") {
            const newProductValues = [...payload.productValue];
            newProductValues[index] = value === null ? "" : value;
            //setProductValue(newProductValues);
            const uniqueVariantSizes = [...new Map(newProductValues.map(item => [item.value, item])).values()];

            //setPayload({ ...payload, variant_size_for_images: updatedVariantSizes });
            setPayload({ ...payload, variant_size_for_images: uniqueVariantSizes, productValue: newProductValues });
        }
        const newProductItems = [...payload.product_details];
        newProductItems[index][field] = field === "size" && value !== null ? value : value === null ? "" : value;

        setProductItems(newProductItems);
    };

    const handleDistributionChange = (productIndex, distIndex, field, value) => {
        if (field === "state") {
            const allStatesIndex = value.findIndex(option => option.value === "__all__");
            const noneIndex = value.findIndex(option => option.value === "none");
            const allStatesSelected = value.some(option => option.value === "__all__") && allStatesIndex !== 0;
            const noneSelected = value.some(option => option.value === "none") && noneIndex !== 0;

            if (allStatesSelected) {
                value = [{ value: "__all__", label: "All States" }];
            } else if (noneSelected) {
                value = [{ value: "none", label: "None" }];
            }
            if (!allStatesSelected && !noneSelected && (value.length > 1 || value.length === 0)) {
                value = value.filter(option => option.value !== "__all__" && option.value !== "none");
            }
        }
        const newProductItems = [...payload.product_details];
        newProductItems[productIndex].distribution_cost[distIndex][field] = value;
        setProductItems(newProductItems);
    };

    const addProduct = () => {
        setProductItems([
            ...payload.product_details,
            {
                id: Date.now(),
                size: [],
                new_size: null,
                online_srp: "",
                weight: "", // 6943725569 - Added weight field on variant level
                sku: generateSku(),
                available_qty: "",
                is_engratved: false,
                is_fulfilled: false,
                distribution_cost: [
                    {
                        id: Date.now() + 1,
                        state: [],
                        cost_to_retailer: "",
                        qty_limit: "",
                        distributor: []
                    }
                ]
            }
        ]);
    };

    const addDistributionCost = productIndex => {
        const newProductItems = [...payload.product_details];
        newProductItems[productIndex].distribution_cost.push({
            id: Date.now(),
            state: [],
            cost_to_retailer: "",
            qty_limit: "",
            distributor: ""
        });
        setProductItems(newProductItems);
    };

    const removeProduct = index => {
        const newProductItems = payload.product_details.filter((_, i) => i !== index);
        setProductItems(newProductItems);

        const newVariantSizeForImages = payload.variant_size_for_images.filter((_, i) => i !== index);
        const newProductValue = payload.productValue.filter((_, i) => i !== index);
        setPayload({
            ...payload,
            variant_size_for_images: newVariantSizeForImages,
            productValue: newProductValue
        });
    };

    const removeDistributionCost = (event, productIndex, distIndex) => {
        event.preventDefault();
        const newProductItems = [...payload.product_details];
        newProductItems[productIndex].distribution_cost = newProductItems[productIndex].distribution_cost.filter((_, i) => i !== distIndex);
        setProductItems(newProductItems);
    };

    const handleCreate = (index, inputValue, field) => {
        setIsLoading(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoading(false);
            setListing({ ...listing, [field]: [...listing.sizeList, newOption] });
            const newProductItems = [...payload.product_details];
            const newProductValues = [...payload.productValue];
            newProductValues[index] = newOption;
            newProductItems[index]["size"] = newOption;
            newProductItems[index]["new_size"] = newOption;
            //setProductValue(newProductValues);
            setPayload({ ...payload, variant_size_for_images: newProductValues, productValue: newProductValues });
            setProductItems(newProductItems);
        }, 1000);
    };

    const handleDistributorCreate = (productIndex, distIndex, field, value) => {
        setIsLoading(true);
        setTimeout(() => {
            const newOption = createOption(value);
            setIsLoading(false);
            setListing({ ...listing, [field]: [...listing.distributorList, newOption] });
            const newProductItems = [...payload.product_details];
            newProductItems[productIndex].distribution_cost[distIndex]["distributor"] = newOption;
            newProductItems[productIndex].distribution_cost[distIndex]["new_distributor"] = newOption;
            //setDistributorValue(newOption);
            setProductItems(newProductItems);
        }, 1000);
    };

    const getSizeOptions = () => {
        return listing.sizeList.map(option => ({
            ...option,
            isDisabled: payload.productValue.some(product => product.value === option.value)
        }));
    };

    useEffect(() => {
        setPayload(prevPayload => ({ ...prevPayload, product_details: productItems }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productItems]);

    return (
        <div className={styles.sectionConatiner}>
            <div>
                <h2>Product Details</h2>
            </div>
            <div>
                {payload &&
                    payload.product_details.map((item, index) => (
                        <div key={index}>
                            <hr />

                            {/* className={styles.gridContainer} */}
                            {/* <div style={{ marginBottom: "2px" }}>{`#Variant-${index + 1}`}</div> */}
                            <div style={{ marginBottom: "3rem", display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                <div className={styles.inputRelativeContainer}>
                                    <h3>Options *</h3>
                                    <div className={styles.inputContainer} style={{ minWidth: "200px" }}>
                                        <CreatableSelect
                                            isClearable
                                            isDisabled={isLoading}
                                            placeholder="select size..."
                                            isLoading={isLoading}
                                            onChange={value => handleProductChange(index, "size", value)}
                                            onCreateOption={value => handleCreate(index, value, "sizeList")}
                                            options={getSizeOptions()}
                                            value={item?.size}
                                        />
                                    </div>
                                    {requiredFields.options[index] ? (
                                        <div className={styles.errorMessage}>
                                            <span style={{ color: "red" }}>* This field is required</span>
                                        </div>
                                    ) : null}
                                </div>
                                <div className={styles.inputRelativeContainer}>
                                    <h3>Online SRP *</h3>
                                    <div className={styles.inputContainer}>
                                        <input
                                            type="number"
                                            id="online_srp"
                                            placeholder="Online Srp"
                                            name="online_srp"
                                            min={0}
                                            className={styles.inputStyle}
                                            onChange={e => {
                                                const value = e.target.value;
                                                if (value === "" || (parseInt(value) >= 0 && value[0] !== "-")) {
                                                    handleProductChange(index, "online_srp", e.target.value);
                                                } else {
                                                    e.target.value = "";
                                                }
                                            }}
                                            value={item?.online_srp ?? ""}
                                            onKeyDown={e => handleKeyDown(e, "online_srp")}
                                            onWheel={e => {
                                                e.preventDefault();
                                                e.target.blur();
                                                setTimeout(() => e.target.focus(), 0);
                                            }}
                                        />
                                    </div>
                                    {requiredFields.srp[index] ? (
                                        <div className={styles.errorMessage}>
                                            <span style={{ color: "red" }}>* This field is required</span>
                                        </div>
                                    ) : null}
                                </div>
                                {/* 7022577647 - Weight field on Update Product Form */}
                                {user && user.isAdmin && (
                                    <div className={styles.inputRelativeContainer}>
                                        <h3>Weight (lbs)</h3>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="number"
                                                id="weight"
                                                placeholder="Weight (lbs)"
                                                name="weight"
                                                min={0}
                                                className={styles.inputStyle}
                                                onChange={e => {
                                                    const value = e.target.value;
                                                    if (value === "" || (parseInt(value) >= 0 && value[0] !== "-")) {
                                                        handleProductChange(index, "weight", e.target.value);
                                                    } else {
                                                        e.target.value = "";
                                                    }
                                                }}
                                                value={item?.weight ?? ""}
                                                onKeyDown={e => handleKeyDown(e, "weight")}
                                                onWheel={e => {
                                                    e.preventDefault();
                                                    e.target.blur();
                                                    setTimeout(() => e.target.focus(), 0);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div>
                                    <h3>UPC</h3>
                                    <div className={styles.inputContainer}>
                                        <input
                                            type="text"
                                            id="upc"
                                            name="upc"
                                            className={styles.inputStyle}
                                            onChange={e => handleProductChange(index, "upc", e.target.value)}
                                            value={item?.upc ?? ""}
                                            onKeyDown={e => handleKeyDown(e, "upc")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3>SKU</h3>
                                    <div className={styles.inputContainer}>
                                        <input

                                            id="sku"
                                            placeholder="SKU"
                                            name="sku"
                                            className={styles.inputStyle}
                                            disabled={user?.isAdmin ? false : true}
                                            value={item?.sku ?? ""}
                                            onChange={(e)=> {
                                                if(user?.isAdmin){
                                                    handleProductChange(index, "sku", e.target.value)
                                                }
                                            } }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3>Available QTY</h3>
                                    <div className={styles.inputContainer}>
                                        <input
                                            type="number"
                                            id="available_qty"
                                            name="available qty"
                                            min="0"
                                            disabled
                                            className={styles.inputStyle}
                                            value={item?.available_qty}
                                            onChange={e => {
                                                const value = parseInt(e.target.value);
                                                if (value === "" || (parseInt(value) >= 0 && value[0] !== "-")) {
                                                    handleProductChange(index, "available_qty", e.target.value);
                                                } else {
                                                    e.target.value = "";
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="engravable"
                                        name="engravable"
                                        onChange={e => handleProductChange(index, "engravable", e.target.checked)}
                                    />
                                </div>
                                <h3>Is Engravable ?</h3>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="fulfillable"
                                        name="fulfillable"
                                        onChange={e => handleProductChange(index, "fulfillable", e.target.checked)}
                                    />
                                </div>
                                <h3>Is Fulfillable ?</h3>
                            </div> */}
                                {payload.product_details.length === 1 ? null : (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-danger"
                                                onClick={() => removeProduct(index)}
                                                style={{ marginTop: "30px" }}>
                                                <FaTrash />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {item.distribution_cost.length > 0 && (
                                <div>
                                    <h2>Distribution & Costs</h2>
                                    <hr />
                                    {item.distribution_cost.map((dist_item, distIndex) => (
                                        <div key={distIndex}>
                                            <div style={{ marginBottom: "2rem" }} className={styles.gridDistributionGrid}>
                                                <div>
                                                    <h3>State *</h3>
                                                    <div className={styles.inputContainer}>
                                                        <Select
                                                            className="select-input"
                                                            placeholder="Select states..."
                                                            isMulti
                                                            value={dist_item.state}
                                                            onChange={value => handleDistributionChange(index, distIndex, "state", value)}
                                                            options={listing.stateList}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            allowSelectAll={true}
                                                            checked={true}
                                                        />
                                                    </div>
                                                    {requiredFields.state[index] ? (
                                                        <div>
                                                            <span style={{ color: "red", marginTop: "5px" }}>* This field is required</span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div>
                                                    <h3>Cost To Retailer (Optional)</h3>
                                                    <div className={styles.inputContainer}>
                                                        <input
                                                            type="number"
                                                            id="cost_to_retailer"
                                                            name="cost_to_retaier"
                                                            className={styles.inputStyle}
                                                            onChange={e => {
                                                                const value = e.target.value;
                                                                if (value === "" || (parseInt(value) >= 0 && value[0] !== "-")) {
                                                                    handleDistributionChange(index, distIndex, "cost_to_retailer", e.target.value);
                                                                } else {
                                                                    e.target.value = "";
                                                                }
                                                            }}
                                                            onKeyDown={e => handleKeyDown(e, "cost_to_retailer")}
                                                            onWheel={e => {
                                                                e.preventDefault();
                                                                e.target.blur();
                                                                setTimeout(() => e.target.focus(), 0);
                                                            }}
                                                            value={dist_item?.cost_to_retailer ?? ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <h3>Qty Limit (Optional)</h3>
                                                    <div className={styles.inputContainer}>
                                                        <input
                                                            type="number"
                                                            id="qty_limit"
                                                            name="qty_limit"
                                                            className={styles.inputStyle}
                                                            onChange={e => {
                                                                const value = e.target.value;
                                                                if (value === "" || (parseInt(value) >= 0 && value[0] !== "-")) {
                                                                    handleDistributionChange(index, distIndex, "qty_limit", e.target.value);
                                                                } else {
                                                                    e.target.value = "";
                                                                }
                                                            }}
                                                            value={dist_item?.qty_limit ?? ""}
                                                            onWheel={e => {
                                                                e.preventDefault();
                                                                e.target.blur();
                                                                setTimeout(() => e.target.focus(), 0);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <h3>Distributor *</h3>
                                                    <div className={styles.inputContainer}>
                                                        <CreatableSelect
                                                            isClearable
                                                            isDisabled={isLoading}
                                                            isLoading={isLoading}
                                                            onChange={value => handleDistributionChange(index, distIndex, "distributor", value)}
                                                            onCreateOption={value => handleDistributorCreate(index, distIndex, "distributorList", value)}
                                                            options={listing.distributorList}
                                                            value={dist_item.distributor || dist_item.new_distributor}
                                                        />
                                                    </div>
                                                    {requiredFields.distributor[index] ? (
                                                        <div>
                                                            <span style={{ color: "red", marginTop: "5px" }}>* This field is required</span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {item.distribution_cost.length === 1 ? null : (
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "end", marginBottom: "0.8rem" }}>
                                                        <button
                                                            className="btn btn-sm btn-danger"
                                                            onClick={event => removeDistributionCost(event, index, distIndex)}>
                                                            <FaTrash />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {/* 7278215305 - increase font size */}
                                    <button type="button" onClick={() => addDistributionCost(index)} className={`${styles.distributionButton} button-font-size`}>
                                        Add Cost By State
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>

            <hr />
            <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {/* 7278215305 - increase font size */}
                    <button type="button" onClick={addProduct} className={`${styles.productButton} button-font-size`}>
                        Add Variant
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SectionTwoUpdateProduct;
