import { prefixAndFilterMapping } from "customHooks/useFilter";
import { addURLParam, removeURLParam } from "./urls";

// pass paramaets to API's used on /dashboard page
export const getParams = (filter, setClearAll) => {
    let anyFilterHasValues = false;
    let new_params = {};
    if (filter?.startDate && filter?.endDate) {
        new_params.date_start = filter.startDate.format("YYYY-MM-DD");
        new_params.date_end = filter.endDate.format("YYYY-MM-DD");
    }
    if (filter?.chargeBackStartDate && filter?.ChargeBackEndDate) {
        new_params.shipping_chargeback_start_date = filter.chargeBackStartDate.format("YYYY-MM-DD");
        new_params.shipping_chargeback_end_date = filter.ChargeBackEndDate.format("YYYY-MM-DD");
    }
    // 6367053025 - Generic Date Filter bug changes- Start
    if (filter?.InvoiceShippingStartDate && filter?.InvoiceShippingEndDate) {
        new_params.invoice_date_start = filter.InvoiceShippingStartDate.format("YYYY-MM-DD");
        new_params.invoice_date_end = filter.InvoiceShippingEndDate.format("YYYY-MM-DD");
    }
    if (filter?.InvoiceDiscountCodeChargebackStartDate && filter?.InvoiceDiscountCodeChargebackEndDate) {
        new_params.invoice_discount_code_chargeback_start_date = filter.InvoiceDiscountCodeChargebackStartDate.format("YYYY-MM-DD");
        new_params.invoice_discount_code_chargeback_end_date = filter.InvoiceDiscountCodeChargebackEndDate.format("YYYY-MM-DD");
    }
    if (filter?.customerStartDate && filter?.customerEndDate) {
        new_params.customer_start_date = filter.customerStartDate.format("YYYY-MM-DD");
        new_params.customer_end_date = filter.customerEndDate.format("YYYY-MM-DD");
    }
    if (filter?.discountCodeChargebackStartDate && filter?.discountCodeChargebackEndDate) {
        new_params.discount_code_chargeback_start_date = filter.discountCodeChargebackStartDate.format("YYYY-MM-DD");
        new_params.discount_code_chargeback_end_date = filter.discountCodeChargebackEndDate.format("YYYY-MM-DD");
    }
    // 6367053025 - Generic Date Filter bug changes- End

    // if (filter?.invoiceStartDate && filter?.invoiceEndDate) {
    //     new_params.invoice_start_date = filter.invoiceStartDate.format("YYYY-MM-DD");
    //     new_params.invoice_end_date = filter.invoiceEndDate.format("YYYY-MM-DD");
    // }

    // To handle chargebacks start date and end date filter => This filter is used in Discount code chargebacks page.
    if (filter?.chargebackStartDate && filter?.chargebackEndDate) {
        new_params.chargeback_start_date = filter.chargebackStartDate.format("YYYY-MM-DD");
        new_params.chargeback_end_date = filter.chargebackEndDate.format("YYYY-MM-DD");
    }

    // To handle date submitted at filter =? This filter is used in Manual Child Order Page
    if (filter?.dataSubmittedAtStart && filter?.dataSubmittedAtEnd) {
        new_params.date_submitted_start = filter.dataSubmittedAtStart.format("YYYY-MM-DD");
        new_params.date_submitted_end = filter.dataSubmittedAtEnd.format("YYYY-MM-DD");
    }

    // Monday Task : 6530680655 - Payouts(Billing) Page URL Params
    // To handle order start date and end date filter => This filter is used in Payouts (billing) page.
    if (filter?.orderStartDate && filter?.orderEndDate) {
        new_params.order_date_start = filter.orderStartDate.format("YYYY-MM-DD");
        new_params.order_date_end = filter.orderEndDate.format("YYYY-MM-DD");
    }

    if (filter?.search) {
        new_params.search = filter.search;
    }

    // for UI => filter.discountCodes, for backend => params.discount_codes 
    // Param key => What we send to API
    // Filter key => Key on UI/React side
    const filterProperties = [
        { filterKey: "brands", paramKey: "brand" },
        { filterKey: "products", paramKey: "product" },
        { filterKey: "assignees", paramKey: "assignee" },
        { filterKey: "states", paramKey: "state" },
        { filterKey: "utms", paramKey: "utm_sources" },
        { filterKey: "utmsMedium", paramKey: "utm_mediums" },
        { filterKey: "utmsCampaign", paramKey: "utm_campaigns" },
        { filterKey: "fulfillments", paramKey: "fulfillment" },
        { filterKey: "productTypes", paramKey: "product_types" },
        { filterKey: "productCategory", paramKey: "productCategory" },
        { filterKey: "productNames", paramKey: "product_name" },
        { filterKey: "locationIds", paramKey: "location_id" },
        { filterKey: "cities", paramKey: "cities" },
        { filterKey: "accepts_marketing", paramKey: "boolean" },
        { filterKey: "customer_states", paramKey: "states" },
        { filterKey: "discountCodes", paramKey: "discount_codes" },
        { filterKey: "channels", paramKey: "channel" },
        { filterKey: "shippingRate", paramKey: "shippingRate" },
        { filterKey: "billingStatuss", paramKey: "billing_status" },
        { filterKey: "billingStatus", paramKey: "billing_status" },
        { filterKey: "shippingChargebackBrands", paramKey: "shipping_chargeback_brands" },
        { filterKey: "invoiceNumbers", paramKey: "invoiceNumber" },
        { filterKey: "typeDiscounts", paramKey: "typeDiscount" },
        { filterKey: "inventory_location", paramKey: "inventory_location" },
        { filterKey: "sku", paramKey: "sku" },
        { filterKey: "user", paramKey: "user" },
        { filterKey: "discountCodeBillingStatus", paramKey: "billing_status" },
        { filterKey: "discountCodeType", paramKey: "typeDiscount" },
        { filterKey: "discountCodeBrand", paramKey: "brand" },
        { filterKey: "invoiceStatus", paramKey: "invoice_status" },
        { filterKey: "billableUser", paramKey: "billable_user" },
        // { filterKey: "brands", paramKey: "brands" },
        { filterKey: "inventoryStatus", paramKey: "inventory_status" },
        { filterKey: "bulkOrders", paramKey: "bulk_orders" },//BCD - 4275
        { filterKey: "userRoles", paramKey: "roles" },
        { filterKey: "userStatus", paramKey: "user_status"},
        { filterKey: "retailerInventory", paramKey: "retailer_inventory"},
        // Manual Child Orders
        { filterKey: "childOrderType", paramKey: "child_order_type" },
        { filterKey: "batchStatus", paramKey: "batch_status" },
        // Monday Task : 6530680655 - Payouts(Billing) Page URL Params
        { filterKey: "tracking_statuses", paramKey: "tracking_status" },
        { filterKey: "financial_status", paramKey: "financial_status" },
        { filterKey: "order_status", paramKey: "order_status" },
        { filterKey: "payoutStatus", paramKey: "payout_status" },
        { filterKey: "orderCity", paramKey: "order_cities" },
        { filterKey: "orderState", paramKey: "order_states" },
        { filterKey: "orderZipCodes", paramKey: "order_zip_codes" },
        // Pre Order filter for Dashboard and orders page
        { filterKey: "preOrders", paramKey: "pre_orders" },
        // Subscription Page Filter
        { filterKey: "plan", paramKey: "plan" },
        { filterKey: "status", paramKey: "status" }, // 7196353058 - Subscription Page Status Filter
        //6509739352 - Store Filter on Shipping and discount code chargebacks
        { filterKey: "store", paramKey: "store" },
    ];

    filterProperties.forEach(({ filterKey, paramKey }) => {
        if (filter[filterKey]?.length > 0) {
            new_params[paramKey] = filter[filterKey].map((item) => item.value);
            // if (setClearAll) setClearAll(true);
            anyFilterHasValues = true;
        }
    });

    if (setClearAll) {
        setClearAll(anyFilterHasValues);
    }

    return new_params;
};

export const removeBadge = (e, filter, setFilter, selected, setSelected, setClearAll, searchInput, setPage = () => {}) => {
    // Logic to remove badge for start date and end date from fulfillment page only
    setPage(1);
    if (e.component === 'fulfillmentDate' && filter?.fulfillmentStartDate && filter?.fulfillmentEndDate) {
        setFilter({ ...filter, fulfillmentStartDate: null, fulfillmentEndDate: null });
        removeURLParam(window, "startDate");
        removeURLParam(window, "endDate");
        return
    }

    // Logic to remove badge for chargebacks start date and end date from discount code page
    if (e.component === "date" && filter?.chargebackStartDate && filter?.chargebackEndDate) {
        setFilter({ ...filter, chargebackStartDate: null, chargebackEndDate: null });
        removeURLParam(window, "chargebackStartDate");
        removeURLParam(window, "chargebackEndDate");
        return
    }

    // 6367053025 - Generic Date Filter bug changes- Start
    if (e.component === "invoiceShippingChargeBackDate" && filter?.InvoiceShippingStartDate && filter?.InvoiceShippingEndDate) {
        setFilter({ ...filter, InvoiceShippingStartDate: null, InvoiceShippingEndDate: null });
        removeURLParam(window, "InvoiceShippingStartDate");
        removeURLParam(window, "InvoiceShippingEndDate");
        return
    }
    if (e.component === "invoiceDiscountCodeChargeBackDate" && filter?.InvoiceDiscountCodeChargebackStartDate && filter?.InvoiceDiscountCodeChargebackEndDate) {
        setFilter({ ...filter, InvoiceDiscountCodeChargebackStartDate: null, InvoiceDiscountCodeChargebackEndDate: null });
        removeURLParam(window, "InvoiceDiscountCodeChargebackStartDate");
        removeURLParam(window, "InvoiceDiscountCodeChargebackEndDate");
        return
    }
    if (e.component === "customerDate" && filter?.customerStartDate && filter?.customerEndDate) {
        setFilter({ ...filter, customerStartDate: null, customerEndDate: null });
        removeURLParam(window, "customerStartDate");
        removeURLParam(window, "customerEndDate");
        return
    }
    if (e.component === "discountCodeChargebackDate" && filter?.discountCodeChargebackStartDate && filter?.discountCodeChargebackEndDate) {
        setFilter({ ...filter, discountCodeChargebackStartDate: null, discountCodeChargebackEndDate: null });
        removeURLParam(window, "discountCodeChargebackStartDate");
        removeURLParam(window, "discountCodeChargebackEndDate");
        return
    }
    // 6367053025 - Generic Date Filter bug changes- End
    
    if (filter?.invoiceStartDate && filter?.invoiceEndDate) {
        setFilter({ ...filter, invoiceStartDate: null, invoiceEndDate: null });
        return
    }

    // Logic to remove badge for date submitted at filter from Manual Child Order Page
    if (e.component === "chargeBackDate" && filter?.dataSubmittedAtStart && filter?.dataSubmittedAtEnd) {
        setFilter({ ...filter, dataSubmittedAtStart: null, dataSubmittedAtEnd: null });
        removeURLParam(window, "dataSubmittedAtStart");
        removeURLParam(window, "dataSubmittedAtEnd");
        return
    }

    // Avoid clearing start and end date of dashboard page
    if (window.location.pathname !== "/dashboard") {
        if (filter?.startDate && filter?.endDate) {
            setFilter({ ...filter, startDate: null, endDate: null });
            removeURLParam(window, "startDate");
            removeURLParam(window, "endDate");
            return
        }
    }

    if (filter?.search) {
        setFilter({ ...filter, search: null });
        removeURLParam(window, "search")
        if (searchInput) {
            searchInput.value = ""
        }
        return
    }

    //logic for selection of row checkboxes
    if (e.component === "selected" && selected && setSelected) {
        let items = selected
        items = items.filter(item => item.id !== e.item.id)
        setSelected(items)
        let checkbox = document.getElementById('checkbox_' + e.item.id)
        if (checkbox) {
            checkbox.checked = false
        }
    }

    if (prefixAndFilterMapping[e.component]) {
        removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    }

    const filterProperties = [
        { filterKey: "assignees", component: "assignee" },
        { filterKey: "products", component: "product" },
        { filterKey: "brands", component: "brand" },
        { filterKey: "fulfillments", component: "fulfillment" },
        { filterKey: "states", component: "state" },
        { filterKey: "productTypes", component: "product_type" },
        { filterKey: "productCategory", component: "productCategory" },
        { filterKey: "productNames", component: "productNames" },
        { filterKey: "locationIds", component: "locationIds" },
        { filterKey: "utms", component: "utm" },
        { filterKey: "utmsMedium", component: "utm_medium" },
        { filterKey: "utmsCampaign", component: "utm_campaign" },
        { filterKey: "discountCodes", component: "discount_code" },
        { filterKey: "channels", component: "sales_channel" },
        { filterKey: "shippingRate", component: "shippingRate" },
        { filterKey: "cities", component: "cities" },
        { filterKey: "accepts_marketing", component: "accepts_marketing" },
        { filterKey: "customer_states", component: "customer_states" },
        { filterKey: "search", component: "search" },
        { filterKey: "selected", component: "selected" },   //
        { filterKey: "user", component: "user" },
        { filterKey: "inventory_location", component: "inventory_location" },
        { filterKey: "sku", component: "sku" },
        { filterKey: "tracking_companies", component: "tracking_companies" },
        { filterKey: "tracking_statuses", component: "tracking_statuses" },
        { filterKey: "discountCodeBillingStatus", component: "discountCodeBillingStatus"},
        { filterKey: "discountCodeType", component: "discountCodeType" },
        { filterKey: "invoiceNumbers", component: "invoiceNumbers" }, 
        { filterKey: "discountCodeBrand", component: "discountCodeBrand" }, 
        { filterKey: "invoiceStatus", component: "invoice_status" }, 
        { filterKey: "billableUser", component: "billableUser" }, 
        { filterKey: "inventoryStatus", component: "inventoryStatus" },
        { filterKey: "bulkOrders", paramKey: "bulk_order" }, //BCD - 4275
        { filterKey: "inventoryStatus", component: "inventoryStatus" }, 
        { filterKey: "userRoles", component: "userRoles" },
        { filterKey: "userStatus", component: 'userStatus' },
        { filterKey: "retailerInventory", component: 'retailerInventory' },
        // Manual Child Orders
        { filterKey: "childOrderType", component: "childOrderType" },
        { filterKey: "batchStatus", component: "batchStatus" },
        // Pre Order filter for Dashboard and orders page
        { filterKey: "preOrders", component: "preOrders" },
        // Subscription Page Filter
        { filterKey: "plan", component: "plan" },
        { filterKey: "status", component: "status" }, // 7196353058 - Subscription Page Status Filter
        //6509739352 - Store Filter on Shipping and discount code chargebacks
        { filterKey: "store", component: "store" },
    ];

    const { item } = e;
    const filterProperty = filterProperties.find(({ component }) => e.component === component);
    if (filterProperty) {
        const { filterKey } = filterProperty;
        setFilter({ ...filter, [filterKey]: filter[filterKey].filter((filterItem) => filterItem.value !== item.value) });
    }
};

// Date range filter on change handler
export const handleDateRange = (start, end, setFilter, filter) => {
    setFilter({ ...filter, startDate: start, endDate: end, });
    addURLParam(window, "startDate", start);
    addURLParam(window, "endDate", end);
}

/* 
    Function to handle sorting functionality
    Required Props:-
    1) sort, column:- sorting column name
    2) setSortOrder :- state variable for setting sort order i.e. ascending or descending 
    3) setSort :- state varialbe for setting sorting column name 
*/

export const handleSort = (sort, column, setSortOrder, setSort) => {
    if (sort === column) {
        setSortOrder({ order: "desc", columnName: column });
        column = `-${column}`;
    } else {
        setSortOrder({ order: "asc", columnName: column });
    }
    setSort(column);
};

/*
    Sort function with icons
    Required props :- 
    1) onClick() function, 
    2) sortingOrder which is ascending or descending, 
    3) column name -> column name in database
*/

export const Sort = (props) => {
    const { children, column, onClick, sortingOrder } = props

    /* 
        This common fucntion receives column as a arugment from props which is used to sort the listing table. 
        If Column name starts with "-" (minus sign) then it is descending order otherwise it is acsending.
    */
    let sortIcon = column === sortingOrder?.columnName ? sortingOrder?.order === "asc" ? "sort-up" : "sort-down" : "sort";

    return (
        <a href="#" className="text-gray" onClick={onClick}>
            <i className={"fas fa-" + sortIcon} />
            {children}
        </a>
    );
};

export const onFilterChange = (e, prefix, filter, setFilter) => {
    if (e == "__all__") {
        let filteredRecords = [];
        let checkbox = document.querySelectorAll(`.checkbox_${prefix}`);
        for (let i = 0; i < checkbox.length; i++) {
            checkbox[i].checked = true;
            filteredRecords.push({
                value: checkbox[i].value,
                label: checkbox[i].dataset.label,
            });
            addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
        }
        let updatedFilter = { ...filter };
        let filterNameForPrefix = prefixAndFilterMapping[prefix]
        updatedFilter[filterNameForPrefix] = filteredRecords;
        setFilter(updatedFilter);
    } else {
        let filteredRecords = [...new Set(filter[`${prefixAndFilterMapping[prefix]}`])];
        if (e.target.checked) {
            filteredRecords.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        } else {
            filteredRecords = filteredRecords.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        }
        let updatedFilter = { ...filter };
        updatedFilter[prefixAndFilterMapping[prefix]] = filteredRecords;
        setFilter(updatedFilter);
    }
};

export const clearFilter = (prefix, setFilter, setClearAll) => {
    const filterKey = prefixAndFilterMapping[prefix];
    if (filterKey) {
        setFilter((prevFilter) => ({ ...prevFilter, [filterKey]: [] }));
        removeURLParam(window, filterKey);
    }

    if (setClearAll) {
        setClearAll(false);
    }
};

// This function is used to generate sku id - Monday Id : 6618268581
export const generateSku= () =>{
    // const date = Date.now();

    // Convert the Date object to an epoch timestamp (in milliseconds)
    // const epochTimestamp = date;

    // return Math.floor(epochTimestamp / 1000);
    return new Date().valueOf() 
}