/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This is the code to create pagination for tabular data.
  
 */

import React, { useState } from "react";
import styles from "./DataTable.module.css";
import { Table } from "reactstrap";

const DataTable = ({ columns, data }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // eslint-disable-next-line no-unused-vars
    const [maxPageButtons, setMaxPageButtons] = useState(10);

    const totalPages = Math.ceil(data.length / rowsPerPage);

    // Paginated data
    const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset to first page
    };

    const handleNextPageGroup = () => {
        if (currentPage + maxPageButtons <= totalPages) {
            setCurrentPage(currentPage + maxPageButtons);
        } else {
            setCurrentPage(totalPages);
        }
    };

    const handlePrevPageGroup = () => {
        if (currentPage - maxPageButtons > 0) {
            setCurrentPage(currentPage - maxPageButtons);
        } else {
            setCurrentPage(1);
        }
    };

    const getPageNumbers = () => {
        const startPage = Math.floor((currentPage - 1) / maxPageButtons) * maxPageButtons + 1;
        const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);
        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    return (
        <div className={styles.tableContainer}>
            <div className={styles.paginationControls}>
                <label htmlFor="rowsPerPage">Rows per page : </label>
                <select id="rowsPerPage" value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ marginLeft: "5px" }}>
                    {[5, 10, 20, 50, 100].map((number) => (
                        <option key={number} value={number}>
                            {number}
                        </option>
                    ))}
                </select>
            </div>

            <Table className="align-items-center table-flush">
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.map((row, index) => (
                        <tr key={index}>
                            {columns.map((column) => (
                                <td key={column}>{row[column]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className={styles.pagination}>
                <button
                    type="button"
                    onClick={handlePrevPageGroup}
                    disabled={currentPage <= 1}
                >
                    Prev
                </button>
                {getPageNumbers().map((pageNumber) => (
                    <button
                        type="button"
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={currentPage === pageNumber ? styles.activePage : ""}
                    >
                        {pageNumber}
                    </button>
                ))}
                <button
                    type="button"
                    onClick={handleNextPageGroup}
                    disabled={currentPage >= totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default DataTable;
