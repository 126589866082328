import React, { useState, useEffect, useRef } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

/**
 * Convert HTLM content to Editor State
 * @param {*} html
 * @returns EditorState
 */
const htmlToEditorState = (html) => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  }
  return EditorState.createEmpty();
};

function RichTextEditor({ defaultValue = "", onChange = () => { } }) {
  const [editorState, setEditorState] = useState(htmlToEditorState(defaultValue));

  useEffect(() => {
    // Update the editor state only when defaultValue changes significantly.
    if (defaultValue && defaultValue !== draftToHtml(convertToRaw(editorState.getCurrentContent()))) {
      const newEditorState = htmlToEditorState(defaultValue);
      setEditorState(EditorState.moveFocusToEnd(newEditorState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    onChange(draftToHtml(convertToRaw(newEditorState.getCurrentContent())).replace(/<p><\/p>/g, ""));
  };

  return (
    <div className="border rounded p-1">
      <Editor
        editorState={editorState}
        placeholder="Type here..."
        onEditorStateChange={handleEditorChange}
      />
    </div>
  )
};

export default RichTextEditor;