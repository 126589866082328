// Hooks is used to detect weather current screen is mobile device or desktop device
import { useState, useEffect } from "react";

function useMobileDetection(threshold = 768) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < threshold);

  useEffect(() => {
    function handleResize() {
      let result = window.innerWidth < threshold;
      setIsMobile(result);
    }

    handleResize(); // Set initial value on mount

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [threshold]);

  return isMobile;
}

export default useMobileDetection;