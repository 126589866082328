/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This is the main component for product details which fecthes the data related to particular product and pass it to sections.
  
 */

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import styles from "./viewProduct.module.css";
import { Container, Spinner, Button } from "reactstrap";
import { api } from "services/api";
import { Link, useHistory } from "react-router-dom";
import ProdDetailSectionOne from "./ProdDetailsSectionOne";
import ProdDetailsSectionThree from "./ProdDetailsSectionThree";
import ProdDetailsSectionTwo from "./ProdDetailsSectionTwo";
import ProdDetailsSectionFour from "./ProdDetailsSectionFour";
import ProdDetailsSectionFive from "./ProdDetailsSectionFive";
import ProdDetailsSectionSix from "./ProdDetailsSectionSix";
import { useToasts } from 'react-toast-notifications';

const fetchData = async (endpoint, setterFn) => {
    try {
        const response = await api.get(endpoint);
        const data = response?.data;
        setterFn(data);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

const ProductDetails = () => {
    const { addToast } = useToasts();
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get("productId");
    const { user } = useContext(AuthContext);
    const [productDetails, setProductDetails] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData(`product/product-details/${productId}/`, setProductDetails);
    }, [productId]);

    const refeshPage = () => {
        fetchData(`product/product-details/${productId}/`, setProductDetails);
    };

    const handleStripePublish = async () => {
        try {
            // Define the endpoint and include the product ID as a query parameter
            const endpoint = `/product/react/publish-to-stripe/?id=${productId}`;

            // Make the GET request to the Django API endpoint
            const response = await api.get(endpoint);
            console.log("response: ", response);

            // Check if the response is successful
            if (response.status === 200 && response.data.success) {
                // Handle success
                console.log("Product submitted to Stripe:", response.data.message);
                return response.data;
            } else {
                // Handle failure
                console.error("Failed to submit product to Stripe:", response.data.message);
                return response.data;
            }
        } catch (error) {
            // Handle any errors that occurred during the API call
            console.error("Error during the API call:", error);
            throw error;
        }
    };

    const handleMashAndGrapePublish = async () => {
        try {
            // Make the POST request to the Django API endpoint
            const response = await api.post('/product/react/publish-to-mg/', { product: [productId] });
            console.log("response: ", response);

            // Check if the response is successful
            if (response.status === 200 && response.data.success) {
                // Handle success
                addToast(response.data.message, { appearance: "success", autoDismiss: true });
            } else {
                // Handle failure
                console.error("Failed to publish product to Mash&Grape:", response.data.message);
                addToast(response.data.message, { appearance: "error", autoDismiss: true });
            }
        } catch (error) {
            // Handle any errors that occurred during the API call
            console.error("Error during the API call:", error);
            throw error;
        }
    };

    return (
        <React.Fragment>
            <Container fluid style={{ paddingTop: "7rem" }}>
                {productDetails ? (
                    <div className={styles.productConatiner}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <h1>Product Detail</h1>
                            </div>
                            <div style={{ display: "flex", gap: "5px" }}>
                                <div>
                                    <Link to="/product">
                                        <Button color="primary" size="sm" outline>
                                            All products
                                        </Button>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={`/product-availability?search=${productDetails.product.name}`}>
                                        <Button color="primary" size="sm" outline>
                                            Inventory
                                        </Button>
                                    </Link>
                                </div>
                                {user && productDetails.product.is_published && !user.isRetailer && (
                                    <div>
                                        {!productDetails.show_barcart_button ? (
                                            <a href={window.env.APP_URL + `product/${productDetails.product.id}/checkout-button/`}>
                                                <Button color="primary" size="sm" outline>
                                                    Checkout Button
                                                </Button>
                                            </a>
                                        ) : (
                                            <a href={window.env.APP_URL + `product/${productDetails.product.id}/buy-button/`}>
                                                <Button color="primary" size="sm" outline>
                                                    Barcart Button
                                                </Button>
                                            </a>
                                        )}
                                    </div>
                                )}
                                {user && user.isAdmin && productDetails.product.shopify_id && productDetails?.shopify_url && (
                                    <div>
                                        <a href={productDetails?.shopify_url}>
                                            <Button color="primary" size="sm" outline>
                                                See in Shopify
                                            </Button>
                                        </a>
                                    </div>
                                )}
                                {user && !user.isRetailer && !productDetails.product.lock && (
                                    <div>
                                        <Link to={`/update-product?productId=${productDetails.product.id}`}>
                                            {/* <Link> */}
                                            <Button color="primary" size="sm" outline>
                                                Edit
                                            </Button>
                                        </Link>
                                    </div>
                                )}
                                {productDetails.product.published_url && (
                                    <div>
                                        <a href={productDetails.product.published_url ?? "#"}>
                                            <Button color="primary" size="sm" outline>
                                                View Published Product
                                            </Button>
                                        </a>
                                    </div>
                                )}
                                {user && user.isAdmin && productDetails?.product?.publish_to_stripe_url && (
                                    <div>
                                        <Button onClick={handleStripePublish} color="primary" size="sm" outline>
                                            Publish To Stripe
                                        </Button>
                                    </div>
                                )}
                                {user && user.isAdmin && productDetails.product.workflow === "user" && (
                                    <div>
                                        <Button onClick={handleMashAndGrapePublish} size="sm" outline color="success">
                                            <i class="fas fa-share"></i>
                                            <span>Publish To Mash&Grape</span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div style={{ marginBottom: "3rem" }}>
                                <ProdDetailSectionOne productDetails={productDetails} />
                            </div>
                            <div style={{ marginBottom: "3rem" }}>
                                <ProdDetailsSectionTwo productDetails={productDetails} refeshPage={refeshPage} />
                            </div>
                            <div style={{ marginBottom: "3rem" }}>
                                <ProdDetailsSectionThree productDetails={productDetails} />
                            </div>
                            <div style={{ marginBottom: "3rem" }}>
                                <ProdDetailsSectionFour productDetails={productDetails} refeshPage={refeshPage} />
                            </div>
                            <div style={{ marginBottom: "3rem" }}>
                                <ProdDetailsSectionFive productDetails={productDetails} />
                            </div>
                            {/*<div style={{ marginBottom: "3rem" }}>
                                <ProdDetailsSectionSix productDetails={productDetails} />
                            </div>*/}
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <button className={styles.backBtn} onClick={() => history.push("/product")}>
                                    ← Back
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
                        <Spinner />
                    </div>
                )}
            </Container>
        </React.Fragment>
    );
};

export default ProductDetails;
