/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to update product where data is fetched and passes to createProduct component with updateProduct={true}.
  
 */

import React, { useState, useEffect } from "react";
import { api } from "services/api";
import { Spinner } from "reactstrap";
import CreateProduct from "../create-product/CreateProduct";

const fetchData = async (endpoint, setterFn) => {
    try {
        const response = await api.get(endpoint);
        const data = response?.data;
        setterFn(data);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

function createValueLabelObject(value, label) {
    return {
        value: value,
        label: label || value?.toString()
    };
}

const UpdateProduct = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get("productId");
    const [productDetails, setProductDetails] = useState(null);
    //const [variant_images, setVariant_images] = useState([]);
    const [updatePayload, setUpdatePayload] = useState({
        title: "",
        description: "",
        abv: "",
        brand: "",
        newlyCreatedBrand: "",
        product_category: "",
        product_type: "",
        upc: "",
        new_product_type: "",
        tags: [],
        variant_size_for_images: [],
        images:[],
        disabledOptions:[],
        product_details: [
            {
                id: Date.now(),
                size: [],
                new_size: null,
                online_srp: "",
                weight: "", // 6943725569 - Added weight field on variant level
                sku: "",
                upc: "",
                available_qty: "",
                engrated: false,
                fulfilled: false,
                distribution_cost: [
                    {
                        id: Date.now() + 1,
                        state: [],
                        cost_to_retailer: 0,
                        qty_limit: 0,
                        distributor: [],
                        new_distributor: ""
                    }
                ]
            }
        ]
    });


    useEffect(() => {
        fetchData(`product/product-details/${productId}/`, setProductDetails);
    }, [productId]);

    useEffect(() => {
        if (productDetails) {
            setUpdatePayload(prevPayload => ({
                ...prevPayload,
                title: productDetails.product?.name,
                description: productDetails.product?.description,
                abv: productDetails.product?.abv,
                brand: createValueLabelObject(productDetails.product?.brand_details?.id, productDetails.product?.brand_details?.name),
                product_category: productDetails.product?.product_category,
                product_type: createValueLabelObject(productDetails?.product?.product_type, productDetails?.product?.product_type),
                tags: productDetails.product?.formatted_tags,
                variant_size_for_images:productDetails.product?.product_variants.map(value=>({id:value.option_details.id,value:value.option_details.id,label:value.option_details.name})),
                productValue:productDetails.product?.product_variants.map(value=>({value:value.option_details.id,label:value.option_details.name})),
                images:productDetails.product?.product_images.map(size=>({id:size.size_id,image:size.image,imageToPreview:size.image,title:size.size_name,image_id:size.image_id})),
                disabledOptions:productDetails.product?.product_images.map(size=>(size.size_id)),
                product_details: productDetails.product?.product_variants.map(variants => ({
                    id: variants?.id,
                    size: createValueLabelObject(variants?.option_details?.id, variants?.option_details?.name),
                    new_size: null,
                    online_srp: variants?.online_srp,
                    weight: variants?.weight ? parseFloat(variants?.weight) : null, // 6943725569 - Added weight field on variant level
                    sku: variants?.sku,
                    upc: variants?.upc,
                    available_qty: variants?.available_qty,
                    distribution_cost: variants.cost_per_item.map(cost => ({
                        id: cost?.id,
                        state: cost?.state_details.length > 0 ? cost?.state_details.map(data => ({ value: data?.code, label: data?.name })) : [],
                        qty_limit: cost?.quantity,
                        cost_to_retailer:cost?.price,
                        distributor: createValueLabelObject(cost?.distributor_details?.id, cost?.distributor_details?.name),
                        new_distributor: null
                    }))
                }))
            }));

            //setVariant_images(productDetails.product?.product_images.map(images=>({id:images.size_id,image:images.image,imageToPreview:images.image,title:images.name})));
        }
    }, [productDetails]);

    return (
        <div>
            {productDetails ? (
                <CreateProduct updateProduct={true} updatePayload={updatePayload} productId={productId}/>
            ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default UpdateProduct;
