import React, { useEffect, useState, useContext } from "react";
import { useToasts } from "react-toast-notifications";
import { api } from "../../services/api";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Input, Table } from "reactstrap";

export const Reship = ({ isOpen, order, onExit, handleRefresh }) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [Order, setOrder] = useState(order);
  const [orderDetails, setOrderDetails] = useState({
    order_tax: order.taxes,
    order_tax1: 0,
    order_shipping: order.shipping,
    order_shipping1: order.shipping,
    order_subtotal: order.subtotal,
    order_subtotal1: 0,
    order_total1: 0,
    order_total: order.total,
    retailer_fee: 0,
  });
  const { addToast } = useToasts();

  /**
   * show and hide the module alternatively with each click of the reship button
   */
  const toggle = () => {
    setShowModal(!showModal);
  };

  // Open reship module and set all the fields
  useEffect(() => {
    setShowModal(isOpen);
    if (isOpen && order) {
      let options = order;
      setOrder(options);
      setInputs({});
    }
  }, [isOpen, order]);

  //calculate total amount and set the reship value in setOrderDetails 
  useEffect(() => {
    let tax1 = orderDetails.order_tax1;
    let shipping = orderDetails.order_shipping1;
    let reshipFee = orderDetails.retailer_fee;
    let total = parseFloat(orderDetails.order_subtotal1) + parseFloat(orderDetails.order_shipping1) + parseFloat(orderDetails.retailer_fee) + parseFloat(orderDetails.order_tax1)

    setOrderDetails({
      ...orderDetails,
      order_tax1: tax1,
      order_shipping1: shipping,
      order_total: (Order.total),
      order_total1: total,
      retailer_fee: reshipFee,
    });
  }, [orderDetails.order_subtotal, orderDetails.order_shipping1, orderDetails.retailer_fee, orderDetails.order_tax1, orderDetails.order_subtotal1])

  //set the order details from the original(parent) orders
  useEffect(() => {
    setInputs({});
    {
      (Order.items) &&
        Order.items?.map(product => {
          setInputs(prevState =>
          (
            {
              ...prevState,
              [`${product.id}`]: product.quantity,
              [`${product.id}-1`]: product.quantity,
            }
          )
          );
        })
      setOrderDetails({
        order_tax: order.taxes,
        order_tax1: 0,
        order_shipping: Order.shipping,
        order_shipping1: Order.shipping,
        order_total: order.total,
        order_total1: 0,
        order_subtotal: order.subtotal,
        order_subtotal1: order.subtotal,
        retailer_fee: 0,
      })
    }
  }, [Order]);

  /**
   * API call to create reship order
   * @param {*}
   */
  const handleReshipOrder = () => {
    const orderId = Order.id;
    let line_items = [];
    line_items = Object.entries(inputs).map(([key, value]) => {
      if (key.endsWith('-1')) {
        return {
          "id": `${key.slice(0, -2)}`,
          "quantity": `${value}`,
        }
      }
    });
    setLoading(true);
    api.post('order/reship/', {
      order: orderId,
      line_items: line_items.filter(x => x != null),
      shipping: Number(orderDetails.order_shipping1).toFixed(2),
      taxes: Number(orderDetails.order_tax1).toFixed(2),
      retailer_fee: orderDetails.retailer_fee,
      total: Number(orderDetails.order_total1).toFixed(2),
      subtotal: Number(orderDetails.order_subtotal1).toFixed(2),
    })
      .then(response => {
        setLoading(false);
        if (response?.data?.success) {
          addToast("Reship order successfully created!", { appearance: 'success', autoDismiss: true })
          setShowModal(false);
          // Call parent to refresh content
          if (handleRefresh) {
            handleRefresh();
          }
        } else {
          let message = "Something went wrong!";
          if (response?.data?.message) {
            message = response.data.message;
          } else if (response?.data?.error) {
            let errors = Object.values(response.data.error);
            if (errors.length > 0) {
              message = errors[0];
            }
          }
          addToast(message, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 30000 });
        }
      }).catch(err => {
        console.log(err)
        addToast("Something went wrong, please try again!!", { appearance: 'error', autoDismiss: true })
        setShowModal(false);
        setLoading(false);
      });
  }

  /**
   * Set shipping value
   * @param {*} e event
   */
  const handleShipping1 = (e) => {
    setOrderDetails({
      ...orderDetails,
      order_shipping1: parseFloat(e.target.value || 0)
    })
  };

  /**
   * Set tax value
   * @param {*} e event
   */
  const handleTax1 = (e) => {
    setOrderDetails({
      ...orderDetails,
      order_tax1: parseFloat(e.target.value || 0)
    })
  };

  /**
   * Set reship fee
   * @param {*} e event
   */
  const handleReshipFee = (e) => {
    setOrderDetails({
      ...orderDetails,
      retailer_fee: parseFloat(e.target.value || 0)
    })
  };

  // On change of product quantity set the product details to setOrderDetails
  const handleReship = (e, product) => {
    // Updated quantity of product cannot be greater than the quantity of original order product.
    if (e.target.value <= product.quantity) {
      let oldProductQuantity = inputs[e.target.name];
      let newProductQuantity = parseInt(e.target.value);
      if (isNaN(parseInt(e.target.value))) {
        newProductQuantity = 0;
      }
      setInputs(prevState => ({ ...prevState, [e.target.name]: newProductQuantity }));
      // Name of input tag of updated product quantity of reship order ends with "-1"
      if (e.target.name.endsWith('-1')) {
        setInputs(prevState => ({ ...prevState, [`${product.id}`]: product.quantity }));
        let subtotal1 = parseFloat(orderDetails.order_subtotal1) + product.price * (newProductQuantity - oldProductQuantity);
        if(subtotal1 < 0) {
          subtotal1 = 0;
        }
        setOrderDetails({
          ...orderDetails,
          order_subtotal1: parseFloat(subtotal1),
          order_total1: parseFloat(parseFloat(subtotal1) + parseFloat(orderDetails.retailer_fee) + parseFloat(orderDetails.order_tax1) + parseFloat(orderDetails.order_shipping1)),
        });
      }
    }
  };

  return (
    <>
      {showModal &&
        <Modal isOpen={showModal} toggle={toggle} onExit={onExit} dialogClassName="modal-90w"
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
          centered>
          <Form>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">{`Reship : ${order.shopify_id}`}</span>
            </ModalHeader>
            <ModalBody>
              <Table className="align-items-center table-flush split-order"
                cellSpacing={0}
                responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="pl-5 pr-5" style={{ textAlign: 'left' }}>Order Items</th>
                    <th scope="col" className="pl-3 pr-3">Description</th>
                    <th scope="col" className="pl-3 pr-3">Original Order</th>
                    <th scope="col" className="pl-3 pr-3">Reship Order</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items?.map(product => (
                    <tr>
                      <td scope="row-8">
                        <div key={product.id + "_prod_" + order.id}>
                          {!product.hidden_refunded &&
                            <div key={product.id + "_prod_" + order.id}
                              className="row my-2 d-flex align-items-center"
                            >
                              <div className="col-2 pr-0">
                                {(product.image && product.show) ?
                                  <img src={product.image}
                                    className="img-thumbnail rounded-0"
                                    width="100%" />
                                  : <img
                                    src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                    className="img-thumbnail rounded-0"
                                    width="100%" />
                                }
                              </div>
                              <div className="col-10">
                                {product.show ?
                                  <>
                                    <a href={window.env.APP_URL + "product/" + product.product_id}>
                                      <span
                                        className="font-weight-bolder">{product.quantity}x</span>
                                      <span className="text-wrap"> {product.name}</span>
                                    </a>
                                    <div>
                                      <span style={{ fontWeight: "lighter" }}>Sku: {product.sku}</span>
                                      <br></br>
                                      <span style={{ fontWeight: "lighter" }}>Price: ${product.price}</span>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <span
                                      className="font-weight-bolder">{product.quantity}x</span>
                                    <span className="text-wrap"> OTHER PRODUCT</span>
                                  </>
                                }
                              </div>

                            </div>
                          }
                        </div>
                      </td>
                      <td className={'order-title-price'}>Quantity</td>
                      <td className={'text-center'}>{product.quantity}</td>
                      <td className={'text-center'}>
                      <Input type='number'
                        name={`${product.id}-1`}
                        step={1}
                        min={0}
                        defaultValue={product.quantity}
                        value={parseFloat(inputs[`${product.id}-1`]).toFixed(0)}
                        onChange={((e)=>{handleReship(e, product)})}
                      />
                      </td>
                    </tr>
                  ))}
                    <tr className={'split-details'}>
                    <td></td>
                    <td className={'order-title-fees'}>Sub Total</td>
                    <td className={'order-values'}>${orderDetails.order_subtotal}</td>
                    <td>
                      <Input type='number'
                        name={'order-subtotal-1'}
                        step={0.1}
                        min={0}
                        defaultValue={orderDetails.order_subtotal}
                        value={parseFloat(orderDetails.order_subtotal1).toFixed(2)}
                        onChange={((e)=>{ setOrderDetails({...orderDetails, order_subtotal1 : e.target.value }) })}
                      />
                    </td>
                  </tr>   
                  <tr className={'split-details'}>
                    <td></td>
                    <td className={'order-title-fees'}>Shipping Paid</td>
                    <td className={'order-values'}>${orderDetails.order_shipping}</td>
                    <td>
                      <Input type='number'
                        name={'shipping-fee-1'}
                        step={0.1}
                        min={0}
                        value={orderDetails.order_shipping1}
                        onChange={handleShipping1}
                      />
                    </td>
                  </tr>
                  <tr className={'split-details'}>
                    <td></td>
                    <td className={'order-title-fees'}>Tax Paid</td>
                    <td className={'order-values'}>${orderDetails.order_tax}</td>
                    <td>
                      <Input type='number'
                        step={0.1}
                        min={0}
                        onChange={handleTax1}
                      />
                    </td>
                  </tr>
                  <tr className={'split-details'}>
                    <td></td>
                    <td className={'order-title-fees'}>Retailer Fee</td>
                    <td></td>
                    <td>
                      <Input type='number'
                        step={0.1}
                        min={0}
                        onChange={handleReshipFee}
                      />
                    </td>
                  </tr>
                  <tr className={'split-details'}>
                    <td></td>
                    <td className={'order-title-fees'}>Order Total</td>
                    <td className={'order-values'}>${parseFloat(orderDetails.order_total)?.toFixed(2)}</td>
                    <td>
                      <Input type='number'
                        step={0.1}
                        value={orderDetails.order_total1?.toFixed(2)}
                        readOnly
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>Cancel</Button>
              <Button
                color="primary" type="submit"
                disabled={loading}
                onClick={handleReshipOrder}>
                Submit
                {loading && <Spinner size="sm" color="light" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>}
    </>
  )
}
