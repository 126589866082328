import React, { useContext, useState } from "react";
import Moment from "react-moment";
import { useHistory } from 'react-router-dom';
import { AuthContext } from "../../contexts/AuthContext";
import { FaCheckCircle, FaMailBulk, FaCreditCard, FaUserLock, FaUserFriends, FaTrash } from "react-icons/fa";
import { MdBlockFlipped } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useToasts } from "react-toast-notifications";

const UsersTableRow = ({ userRow, onOptionsSelect }) => {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
  const { addToast } = useToasts()
    const toggle = () => setDropdownOpen(prevState => !prevState);
    return (
        <tr key={userRow?.id}>
            {/*<td style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }} title={userRow?.name}>
                <Link to={`/user/change/${userRow?.id}`} className="btn btn-link p-0">
                    {userRow?.name || "-"}
                </Link>
            </td>*/}

            <td className="pl-3 pr-3 ">
                <a
                    style={{ fontWeight: "bold" }}
                    href={`/user-change?${new URLSearchParams({ userId: `${userRow?.id}` }).toString()}`}>
                    {userRow?.name}
                </a>
            </td>

            <td>{userRow?.email || "-"}</td>

            <td>
                {userRow?.last_login ? (
                    <Moment format="MM/DD/YYYY" withTitle>
                        {userRow?.last_login}
                    </Moment>
                ) : (
                    "-"
                )}
            </td>
            <td>
                {userRow?.invitation_sent_at ? (
                    <Moment format="MM/DD/YYYY" withTitle>
                        {userRow?.invitation_sent_at}
                    </Moment>
                ) : (
                    "-"
                )}
            </td>
            <td>{userRow?.payment_collected ? <FaCheckCircle size={20} color="#2dce89" /> : "-"}</td>
            <td>{userRow?.referred_by || "-"}</td>
            <td>{userRow?.is_active ? <FaCheckCircle size={20} color="#2dce89" /> : <MdBlockFlipped size={20} color="#f5365c" />}</td>
            {user.isAdmin && (
                <td className="pl-3 pr-3">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle className="btn text-primary" role="button" size="sm" color="white" onClick={e => e.preventDefault()}>
                            <i className="fas fa-ellipsis-v text-dark"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem onClick={e => onOptionsSelect.sendInvitation(userRow?.id)}>
                                <FaTelegramPlane size={20} /> Send Invitation
                            </DropdownItem>

                            <DropdownItem onClick={e => onOptionsSelect.sendCheckout(userRow?.id)}>
                                <FaMailBulk size={20} /> Send Checkout
                            </DropdownItem>

                            <DropdownItem onClick={e => onOptionsSelect.collectPaymentInfo([userRow?.id])}>
                                <FaCreditCard size={20} /> Collect Payment Information
                            </DropdownItem>
                            <DropdownItem onClick={e => {
                              navigator.clipboard.writeText(userRow?.activation_link);

                              addToast("Account activation link copied!", {
                                appearance: 'success',
                                autoDismiss: true,
                              })
                            }}>
                            <FaCreditCard size={20} /> Generate account activation link
                            </DropdownItem>

                            <DropdownItem onClick={e => onOptionsSelect.view(userRow?.email)}>
                                <FaUserLock size={20} /> View
                            </DropdownItem>

                            <DropdownItem onClick={e => onOptionsSelect.duplicateUser([userRow?.id])}>
                                <FaUserFriends size={20} /> Duplicate User
                            </DropdownItem>

                            <hr style={{ marginTop: "5px", marginBottom: "5px" }} />

                            {userRow?.is_active ? (
                                <DropdownItem style={{ color: "#f5365c" }} onClick={e => onOptionsSelect.disableUser(userRow.id)}>
                                    <MdBlockFlipped size={20} /> Disable User
                                </DropdownItem>
                            ) : (
                                <DropdownItem style={{ color: "#2dce89" }} onClick={e => onOptionsSelect.enableUser(userRow.id)}>
                                    <FaCheckCircle size={20} /> Activate User
                                </DropdownItem>
                            )}
                            {userRow?.is_active ? (
                                <DropdownItem style={{ color: "#f5365c" }} onClick={e => onOptionsSelect.deleteUser(userRow?.id, userRow?.name)}>
                                    <FaTrash size={20} /> Delete
                                </DropdownItem>
                            ) : (
                                ""
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </td>
            )}
        </tr>
    );
};

export default UsersTableRow;