/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to section four for product Details
              - This components shows all the buy button versions for a partticular product.
  
 */

import React, { useEffect, useState } from "react";
import styles from "./viewProduct.module.css";
import moment from "moment/moment";
import { api } from "services/api";
import { Table, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from "reactstrap";
import DeleteModel from "./DeleteModal";
import { FaTrash, FaEdit } from "react-icons/fa";

export default function ProdDetailsSectionFour({ productDetails, refeshPage }) {
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [name, setName] = useState(false);
    const [id, setId] = useState(false);
    const toggle = id => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleDeleteBuyButton = (id, label) => {
        setName(label);
        setId(id);
        setModalIsOpen(true);
    };
    const handleDelete = id => {
        // Handle the delete logic here
        setModalIsOpen(false);
        setLoading(true);
        api.delete(`product/buy-button/delete/${id}/`)
            .then(response => {
                setModalIsOpen(!modalIsOpen);
                refeshPage();
            })
            .catch(error => {
                console.log("Error in deleting buy button", error);
            });
    };

    useEffect(() => {
        if (productDetails) {
            setLoading(false);
        }
    }, [productDetails]);

    const handleEditBuyButton = id => {
        window.location.href = window.env.APP_URL + `product/checkout-button/${id}/update/`;
    };
    return (
        <>
            <DeleteModel modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} onConfirmation={handleDelete} name={name} id={id} />
            <div className={styles.sectionConatiner} style={{ overflowX: "scroll" }}>
                <div>
                    <h2>Buy Button Versions</h2>
                    <hr />
                </div>
                {!loading ? (
                    <Table className="align-items-center table-flush">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Label</th>
                                <th>Button Color</th>
                                <th>Button Text</th>
                                <th>Quantity Select</th>
                                <th>Time stamp</th>
                                <th>Buy Button Code</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {productDetails.product.product_buy_buttons.map((buy_button_data, index) => (
                                <tr key={index}>
                                    <td>{buy_button_data.user}</td>
                                    <td>{buy_button_data.label}</td>
                                    <td>
                                        {" "}
                                        <div style={{ width: "32px", height: "32px", backgroundColor: buy_button_data.button_color }}></div>
                                    </td>
                                    <td>{buy_button_data.button_text}</td>
                                    <td>{buy_button_data.button_quantity_select}</td>
                                    <td>
                                        {buy_button_data.timestamp && buy_button_data.timestamp !== ""
                                            ? moment(buy_button_data.timestamp).format("MMMM Do YYYY, h:mm:ss a")
                                            : "-"}
                                    </td>
                                    <td>
                                        <a href={window.env.APP_URL + `product/checkout-button/${buy_button_data.id}/`}>Buy Button Code</a>
                                    </td>
                                    <td>
                                        <Dropdown isOpen={dropdownOpen[buy_button_data.id] || false} toggle={() => toggle(buy_button_data.id)}>
                                            <DropdownToggle
                                                className="btn text-primary"
                                                role="button"
                                                size="sm"
                                                color="white"
                                                onClick={e => e.preventDefault()}>
                                                <i className="fas fa-ellipsis-v text-dark"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem onClick={() => handleEditBuyButton(buy_button_data.id)}>
                                                    <FaEdit size={20} color="primary" /> Edit
                                                </DropdownItem>
                                                <DropdownItem onClick={() => handleDeleteBuyButton(buy_button_data.id, buy_button_data.label)}>
                                                    <FaTrash size={20} color="red" /> Delete
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", paddingTop: "10px", paddingBottom: "10px" }}>
                        <Spinner />
                    </div>
                )}
            </div>
        </>
    );
}
