import Widget from "./Widget";
import Charts from "./Charts";
import Filter from "components/Dashboard/DashboardFilters/index";
import moment from "moment";
import useFilter from "customHooks/useFilter";
import { useLocation } from "react-router-dom";
import React from 'react'
import { processURLSearchParams } from "utils/urls";


const Dashboard = () => {
  const urlSearchParams = useLocation().search;

  // If start and end end date is not present in URL then only add default start and end date
  let startDateFromUrl = new URL(document.location).searchParams.get("startDate");
  let endDateFromUrl = new URL(document.location).searchParams.get("endDate");
  let shouldAddDefaultStartAndEndDate = startDateFromUrl && endDateFromUrl;

  const [filter, setFilter] = useFilter({
    startDate: shouldAddDefaultStartAndEndDate ? moment(startDateFromUrl) : moment(),
    endDate: shouldAddDefaultStartAndEndDate ? moment(endDateFromUrl) : moment(),
  });

  React.useMemo(() => {
    processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
  }, [urlSearchParams]);

  return (
    <>
      <div className={"pt-7 header bg-primary pb-6"}>
        <Filter filter={filter} setFilter={setFilter} />
        <Widget filters={filter} />
      </div>
      <div class="container-fluid mt--5">
        <Charts filters={filter} />
      </div>
    </>
  );
};

export default Dashboard;
