import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { components } from "react-select";
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { MDBCol, MDBIcon } from "mdbreact";
import { api } from "../../services/api";
import { State_listoptions, EastCoastStateOptions, WestCoastStateOptions } from "../../utils/states";
import { GetStateObjectFromStateCode, GetStateObjectFromStateValue } from "utils/states";
import { CustomMultiSelectDropdown } from "components/CustomDropdown/CustomDropdown";

const MultiValue = (props) => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === "__all__") {
    labelToBeDisplayed = "All states are selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  alert(currentValues);
  let toBeRendered = children;
  if (currentValues.some((val) => val.value === "__all__")) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

export function ProductsSearchForm({ url, toggle, isOpen, loading, formData }) {
  // This component is being re-used on 2 pages, orders page, retailer-override
  const GetChannelObjectFromChannelId = (channelId) => {
    return OrderChannel_list?.filter((channelObject) => channelObject.id === channelId)[0];
  }
  
  const [isLoading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedState, setselectedState] = useState([]);
  const [selectedChannel, setselectedChannel] = useState([]);

  const product_variant_list = [];
  const { addToast } = useToasts();
  const [data, setData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });
  const [paramsFilter, setParamsFilter] = useState({});
  const [inventory_location, setInventory_location] = useState("");
  const [InventoryLocation_list, setInventoryLocationlist] = useState([]);
  const [selectedStateDropdownValue, setselectedStateDropdownValue] = useState( formData ? [GetStateObjectFromStateCode(formData.state)] : []);
  const [selectedChannelDropdownValue, setselectedChannelDropdownValue] = useState( formData ? [GetChannelObjectFromChannelId(formData.channel)] : []);
  const [stateIsValid, setStateIsValid] = useState(true);
  const [channelIsValid, setChannelIsValid] = useState(true);
  const [OrderChannel_list, setOrderChannel_list] = useState([]);

  useEffect(() => {
    // set default sales channel when this component is getting called from orders page
    if ((OrderChannel_list.length>0) && (formData?.retailer_override_through_order_page)){
      setselectedChannel(formData.channel);
      setselectedChannelDropdownValue(GetChannelObjectFromChannelId(formData.channel[0]));
    }
  },[OrderChannel_list])
  useEffect(() => {
    let customParams;

      getInventoryLocation();
      getOrderChannelList();
    if (formData) {
      setselectedState([GetStateObjectFromStateCode(formData.state)?.value]);
      if (!formData?.retailer_override_through_order_page){
        setselectedChannel([GetChannelObjectFromChannelId(formData.channel).value]);
      }

      // Select invengory location by default If inventory location is passed 
      if (formData?.inventory_location !== "") {
        setInventory_location(formData.inventory_location);
      }

      customParams = {
        variant_skus : formData.variant
      };
    } 

    api
      .get(url, {
        params: formData && !paramsFilter.search ? customParams : paramsFilter,
    })
    .then((response) => {
      setData(response.data);
      if (formData){
        let variantsList = [];
        response.data.results.forEach((product) => {
          product.variants.forEach((variant) => {
            if (customParams.variant_skus.includes(variant["sku"])) {
              variantsList.push(parseInt(variant.id));
            }
          })
        })
        setSelectedProduct(variantsList);
      }
      })
      .catch((error) => console.log(error));
  }, [paramsFilter]);

  useEffect(() => {
    const westCoastOption = { value: "West Coast", code: "__west_coast__", label: "West Coast"}
    const eastCoastOption = { value: "East Coast", code: "__east_coast__", label: "East Coast"}
    State_listoptions.splice(1,0,eastCoastOption);
    State_listoptions.splice(2,0,westCoastOption);
  },[])

  let timer = null;
  
  const getOrderChannelList = () => {
    // To fetch the channels list for sales channel dropdown when the page initially loads the first time
    api
      .get(`order/channel-list-api/`)
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          let channels = [{
            id: "__all__",
            label: "Select All",
            value: "__all__",
        }];
          let finalResult = channels.concat(response.data);
          setOrderChannel_list(finalResult);
        }
      })
      .catch((err) => {
        console.error();
      });
  };

  const handleSearch = (e) => {
    // limpamos o timer
    clearTimeout(timer);
    // armazenamos o timer novamente
    timer = setTimeout(function () {
      setParamsFilter({
        ...paramsFilter,
        search: e.target.value,
      });
    }, 750);
  };

  const handleProductCheckbox = (e, product_id) => {
    if (selectedProduct.length <= 0) {
      let checkbox = document.querySelectorAll(".variant_" + product_id);
      for (let i = 0; i < checkbox.length; i++) {
        if (e.target.checked === true && checkbox[i].checked === false) {
          checkbox[i].checked = true;
        }
        if (e.target.checked === false && checkbox[i].checked === true) {
          checkbox[i].checked = false;
        }
        if (e.target.checked === false) {
          checkbox[i].checked = false;
        }
        if (
          e.target.checked === true &&
          product_variant_list.includes(parseInt(product_id)) === false
        ) {
          setSelectedProduct(product_variant_list);
        }
        for (let i = 0; i < checkbox.length; i++) {
          if (e.target.checked === true) {
            if (
              product_variant_list.includes(parseInt(checkbox[i].value)) ===
              false
            ) {
              product_variant_list.push(parseInt(checkbox[i].value));
              setSelectedProduct(product_variant_list);
            }
          }
        }
      }
    } else if (selectedProduct.length > 0) {
      if (e.target.checked === false) {
        let remove_checkbox = document.querySelectorAll(
          ".variant_" + product_id
        );
        for (let i = 0; i < remove_checkbox.length; i++) {
          remove_checkbox[i].checked = false;
          var removeVariantIdIndex = selectedProduct.indexOf(
            parseInt(remove_checkbox[i].value)
          );
          if (removeVariantIdIndex !== -1) {
            selectedProduct.splice(removeVariantIdIndex, 1);
            setSelectedProduct(selectedProduct);
          }
          setSelectedProduct(selectedProduct);
        }
      } else if (e.target.checked === true) {
        var new_product_variant_list = [];
        new_product_variant_list = selectedProduct;
        var productIdIndex = new_product_variant_list.indexOf(product_id);
        if (productIdIndex !== -1) {
          new_product_variant_list.splice(productIdIndex, 1);
        }
        let checkbox = document.querySelectorAll(".variant_" + product_id);
        for (let i = 0; i < checkbox.length; i++) {
          if (e.target.checked === true && checkbox[i].checked === false) {
            checkbox[i].checked = true;
          }
          if (e.target.checked === false && checkbox[i].checked === true) {
            checkbox[i].checked = false;
            var checkboxproductIdIndex = new_product_variant_list.indexOf(
              checkbox[i].value
            );
            if (productIdIndex !== -1) {
              new_product_variant_list.splice(checkboxproductIdIndex, 1);
            }
            setSelectedProduct(new_product_variant_list);
          }
          if (
            e.target.checked === true &&
            new_product_variant_list.includes(parseInt(product_id)) === false
          ) {
            setSelectedProduct(new_product_variant_list);
          }
          for (let i = 0; i < checkbox.length; i++) {
            if (e.target.checked === true) {
              if (
                new_product_variant_list.includes(
                  parseInt(checkbox[i].value)
                ) === false
              ) {
                new_product_variant_list.push(parseInt(checkbox[i].value));
                setSelectedProduct(new_product_variant_list);
              }
            }
          }
        }
      }
    }
  };

  const handleVariantProductCheckbox = (e, variant_id) => {
    var new_product_variant_list = [];
    if (selectedProduct.length > 0) {
      var myIndex = selectedProduct.indexOf(variant_id);
      if (myIndex !== -1) {
        selectedProduct.splice(myIndex, 1);
      } else {
        new_product_variant_list = selectedProduct;
        new_product_variant_list.push(parseInt(variant_id));
        setSelectedProduct(new_product_variant_list);
      }
    } else {
      new_product_variant_list = selectedProduct;
      new_product_variant_list.push(parseInt(variant_id));
      setSelectedProduct(new_product_variant_list);
    }
  };

  const handleChange = (selected) => {
    let isSelectAll = selected.some((state) => state.value === "__all__");
  
    if (isSelectAll) {
      setselectedState("__all__");
      setselectedStateDropdownValue(State_listoptions.filter((state) => state.value !== "__all__" && state.value !== "East Coast" && state.value !== "West Coast"))
    } else {
      let new_state_list = [];
      let newDropdownValue = [];
  
      selected.forEach((event) => {
        if (event.value === "West Coast") {
          new_state_list.push(...WestCoastStateOptions.map((state) => state.value));
          newDropdownValue = newDropdownValue.concat(WestCoastStateOptions);
        } else if (event.value === "East Coast") {
          new_state_list.push(...EastCoastStateOptions.map((state) => state.value));
          newDropdownValue = newDropdownValue.concat(EastCoastStateOptions);
        } else {
          new_state_list.push(event.value);
          const selectedStateOption = State_listoptions.find((option) => option.value === event.value);
          if (selectedStateOption) {
            newDropdownValue.push(selectedStateOption);
          }
        }
      });
  
      setselectedState(new_state_list);
      setselectedStateDropdownValue(newDropdownValue);
    }
  };

  const handleChannelChange = (selected) => {
    setselectedChannelDropdownValue(selected);
    var new_channel_list = [];
    selected.forEach((event) => {
      if (event.value === "__all__") {
        setselectedChannel("__all__");
      } else {
        new_channel_list.push(event.value);
        setselectedChannel(new_channel_list);
      }
    });
  };

  const getInventoryLocation = () => {
    api
      .get(`inventory-location-list/`)
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          setInventoryLocationlist(response.data);
        }
      })
      .catch((err) => {});
  };

  const handleNext = () => {
    if (data.next) {
      let results = data.results;
      setLoading(true);
      api
        .get(data.next)
        .then((response) => {
          const new_results = results.concat(response.data.results);
          setData({
            ...data,
            results: new_results,
            next: response.data.next,
            previous: response.data.previous,
          });
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (
      inventory_location.length !== 0 &&
      selectedState.length !== 0 &&
      selectedProduct.length !== 0
    ) {
      document.getElementById("save_btn").disabled = true;
      loading = true;
      api
        .post("retailer-override-ui/", {
          inventory_location: inventory_location,
          state: selectedState,
          variant: selectedProduct,
          sales_channel: selectedChannel,
        })
        .then((response) => {
          if (response.status === 201) {
            addToast(response.data.message, {
              appearance: "success",
              autoDismiss: true,
            });
            toggle();

            // Avoiding page refresh when orders page is calling this component
            if(!formData) {
              setTimeout(() => {
                window.location.reload(false);
              }, 1000);
            }
          } else if (
            response.data.success === false &&
            response.data.status === 400
          ) {
            var errors_list = response.data.errors_list;
            errors_list.forEach((element) => {
              if (
                element.variant_id &&
                element.state &&
                element.inventory_location &&
                element.OrderChannel
              ) {
                addToast(
                  "VARIANT :" +
                    element.variant_id +
                    ", STATE: " +
                    element.state +
                    ", INVENTORY LOCATION: " +
                    element.inventory_location +
                    ", CHANNEL:" +
                    element.OrderChannel +
                    " Already exist !",
                  {
                    appearance: "error",
                    autoDismiss: true,
                  }
                );
              } else {
                addToast("Created Successfully", {
                  appearance: "success",
                  autoDismiss: true,
                });
                setTimeout(() => {
                  window.location.reload(false);
                }, 1000);
              }
            });
            toggle();
          } else {
            addToast(response.data.message, {
              appearance: "error",
              autoDismiss: true,
            });
            toggle();
          }
        })
        .catch((err) => {
          addToast("Something went wrong, please try again !", {
            appearance: "error",
            autoDismiss: true,
          });
          toggle();
        });
    } else {
      addToast("Missing required field !", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <div className="form-group mb-3 d-flex flex-column" data-field="name">
        <Modal
          style={{ marginTop: "100px" }}
          isOpen={isOpen}
          onExit={toggle}
          toggle={() => toggle()}
          size={"lg"}
        >
          <Form onSubmit={handleSubmitForm}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">
                Retailer Assignment Overrides
              </span>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Row>
                  <Col>
                    <Label>Inventory Location *</Label>
                    <br />
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={
                        inventory_location
                          ? { value: inventory_location, label: inventory_location }
                          : null
                      }
                      options={InventoryLocation_list.map(({ name }) => ({
                        value: name,
                        label: name,
                      }))}
                      onChange={(selectedOption) =>
                        setInventory_location(selectedOption ? selectedOption.value : "")
                      }
                      placeholder="Select inventory location"
                      isClearable
                    />

                  </Col>
                  <Col>
                    <Label>State *</Label>
                    <CustomMultiSelectDropdown
                        onChangeHandler={handleChange}
                        options={State_listoptions}
                        placeholder="Select one or multiple state(s)"
                        isValid={stateIsValid}
                        values={selectedStateDropdownValue}
                    />        
                  </Col>
                </Row>
                <Row>
                  <Col>
                      <Label>Sales Channel</Label>
                      <CustomMultiSelectDropdown
                          onChangeHandler={handleChannelChange}
                          options={OrderChannel_list}
                          placeholder="Select one or multiple channel(s)"
                          isValid={channelIsValid}
                          values={selectedChannelDropdownValue}
                      />        
                  </Col>
                </Row>
                <Label className="mt-3">Variant *</Label>
                <MDBCol style={{ display: "-webkit-box" }}>
                  <MDBIcon icon="search" className="mr-2 ml-0" />
                  <input
                    className="form-control form-control-sm"
                    onKeyDown={handleSearch}
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </MDBCol>
                <ul
                  className={"p-2 overflow-auto"}
                  style={{ maxHeight: "300px" }}
                  id={"filter_"}
                >
                  {data.results.map((product) => (
                    <div key={product.id + "_prod_"}>
                      <div
                        key={product.id + "_product_"}
                        className="row my-2 d-flex align-items-center"
                        style={{ width: "40rem" }}
                      >
                        <div className="col-2 pr-0">
                          <Label className={"text-sm"}>
                            <input
                              type="checkbox"
                              id={"_" + product.id}
                              className={"checkbox_product"}
                              value={product.id}
                              data-label={product.name}
                              data-image={product.image}
                              defaultChecked={product.checked}
                              onChange={(e) =>
                                handleProductCheckbox(e, product.id)
                              }
                              style={{ marginRight: "20px" }}
                            />
                          </Label>
                          {product.image ? (
                            <img
                              src={product.image}
                              className="img-thumbnail rounded-0"
                              width="50%"
                            />
                          ) : (
                            <img
                              src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                              className="img-thumbnail rounded-0"
                              width="50%"
                            />
                          )}
                        </div>
                        <div className="col-10">
                          <a
                            href={window.env.APP_URL + "product/" + product.id}
                            target="_blank"
                          >
                            <span className="text-wrap"> {product.name}</span>
                            <span
                              className="text-right"
                              style={{ paddingLeft: "10px", color: "black" }}
                            ></span>
                          </a>
                        </div>
                      </div>
                      {product.variants &&
                        product.variants.map((variant, index) => (
                          <div
                            className={"row sub-product ml-3"}
                            key={index + "_variant_"}
                          >
                            <div
                              className="col-2 pr-0 variant-container"
                              key={index}
                            >
                              <Label className={"text-sm"}>
                                <input
                                  type="checkbox"
                                  id={"_" + variant.id}
                                  className={"variant_" + product.id}
                                  value={variant.id}
                                  data-label={variant.sku}
                                  data-image={variant.image}
                                  // if variant sku from props and variant sku from api is same then checkbox is selected by default 
                                  defaultChecked={formData ? formData.variant.includes(variant.sku) : variant.checked}
                                  onChange={(e) =>
                                    handleVariantProductCheckbox(e, variant.id)
                                  }
                                  style={{ marginRight: "20px" }}
                                />
                              </Label>
                              {variant.image ? (
                                <img
                                  src={variant.image}
                                  className="img-thumbnail rounded-0"
                                  width="50%"
                                />
                              ) : (
                                <img
                                  src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                  className="img-thumbnail rounded-0"
                                  width="50%"
                                />
                              )}
                            </div>
                            <a href={void 0}>
                              {variant?.size_title === null ?
                                <span className="text-wrap">{`${variant.sku}`}</span>
                                :
                                <span className="text-wrap">{`${variant.size_title} - ${variant.sku}`}</span>
                              }
                              <span
                                className="text-right"
                                style={{ color: "black" }}
                              ></span>
                            </a>
                          </div>
                        ))}
                    </div>
                  ))}

                  {data.results.length < data.count && (
                    <li className={"text-center"}>
                      {!isLoading ? (
                        <Button className={"btn btn-sm "} onClick={handleNext}>
                          show more
                        </Button>
                      ) : (
                        <span className={"text-sm text-muted"}>Loading...</span>
                      )}
                    </li>
                  )}
                </ul>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="mt-0 pt-0">
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button color="primary" id="save_btn" onClick={handleSubmitForm}>
                Save
                {loading && <Spinner size="sm" color="light" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </>
  );
}
