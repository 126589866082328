import React, { useState, useEffect } from 'react';
import { api } from "../../services/api";
import { useToasts } from "react-toast-notifications";
import {
  Modal,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Form,
  FormFeedback,
  FormGroup, Label,
  Input,
  Spinner
} from 'reactstrap';
import RichTextEditor from "components/RichTextEditor";

export const ComposeEmailForm = ({ isOpen, orders, onExit }) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [loading, setLoading] = useState(false)
  const [fromEmail, setFromEmail] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [ccEmail, setCcEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");
  const { addToast } = useToasts();

  const toggle = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    setShowModal(isOpen);
    if (isOpen && orders) {
      // Clear form data if set last time
      setContent("");
      setCcEmail("");
      setFromEmail("");
      setContentError("");

      // Take all email Id's from all the orders and make them comma separated
      setToEmail(orders.map(order => order.email).join(","));

      // Set email subject
      if (orders.length === 1) {
        setSubject(`A message about your order ${orders[0].shopify_id}`);
      } else {
        setSubject(`A message about your order [Order Number]`);
      }
    }
  }, [isOpen])

  /**
   * On click of submit call the API to send email to customer
   * @param {*} e 
   * @returns 
   */
  const handleSendEmail = (e) => {
    e.preventDefault();

    // Check user has added email content or not
    if (!content) {
      setContentError("Please enter email content");
      return;
    }

    const data = {
      subject,
      content,
      orders,
      email_cc: ccEmail,
      email_to: toEmail,
      email_from: fromEmail,
    };

    setLoading(true);

    api
      .post("order/send/email/", data)
      .then(() => {
        setShowModal(false);
        setLoading(false);
        addToast("E-mail being sent!", { appearance: "success", autoDismiss: true });
      })
      .catch((error) => {
        setLoading(false);
        addToast("Something went wrong, please try again!!", { appearance: "error" });
      });
  };

  return (
    <>
      {showModal &&
        <Modal size="lg" isOpen={showModal} toggle={toggle} onExit={onExit}>
          <Form onSubmit={handleSendEmail}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">Email Customer</span>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="emailFrom">From *</Label>
                <Input
                  id="emailFrom"
                  placeholder="name@example.com"
                  type="select"
                  onChange={(e) => setFromEmail(e.target.value)}
                  required
                >
                  <option value="">------</option>
                  <option>help@flaviar.com</option>
                  <option>partner@flaviar.com</option>
                  {/*<option>openbar@mashandgrape.com</option>
                  <option>support@getbarcart.com</option> 6833483186-Barcart emails rebrand - Changes*/}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="emailTo">To *</Label>
                <Input
                  id="emailTo"
                  placeholder="name@example.com"
                  type="email"
                  value={toEmail}
                  readOnly={true}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="emailCc">Cc</Label>
                <Input
                  id="emailCc"
                  placeholder="Cc"
                  type="email"
                  value={ccEmail}
                  onChange={(e) => setCcEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="subject">Subject *</Label>
                <Input
                  id="subject"
                  placeholder="Subject"
                  type="text"
                  defaultValue={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  readOnly={false}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="contect">Content *</Label>
                <RichTextEditor
                  onChange={setContent}
                  defaultValue={content}
                />
                <Input hidden={true} invalid />
                <FormFeedback>{contentError}</FormFeedback>
              </FormGroup>
              <a href={`mailto:${toEmail}?bcc=${toEmail}&subject=${subject}`} target="_blank">Compose in Gmail</a>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>Cancel</Button>
              <Button color="primary" type="submit" disabled={loading}
              >
                Submit
                {loading && <Spinner size="sm" color="light" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  )
};
