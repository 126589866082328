import React, {useEffect, useState} from 'react';
import {Button, DropdownMenu, DropdownToggle, Label, UncontrolledDropdown} from "reactstrap";

const LabelFilter = ({color = 'white', onChange, selected}) => {
    const [labelCreated, setLabelCreated] = useState(false)
    const [labelNotCreated, setLabelNotCreated] = useState(false)
    const [labelIssue, setLabelIssue] = useState(false)

    useEffect(()=>{

        let testLabelCreated = selected.filter(s => ("label_created" === s.value))
        setLabelCreated(testLabelCreated?.length > 0)

        let testLabelNotCreated = selected.filter(s => ("label_not_created" === s.value))
        setLabelNotCreated(testLabelNotCreated?.length > 0)

        let testLabelIssue = selected.filter(s => ("label_issue" === s.value))
        setLabelIssue(testLabelIssue?.length > 0)


    }, [selected, onChange])
    return (
        <UncontrolledDropdown size={"sm"}>
            <DropdownToggle
                color={color}
                className={(color === 'white') ? "text-primary" : "p-10px"}
                caret>
                Label
            </DropdownToggle>
            <DropdownMenu className="more-filters-dropdown-menu" right>
                <ul className={"pl-2 overflow-auto"} style={{maxHeight: "300px"}}>
                    <li className={"list-unstyled pr-2"}>
                        <Button disabled={selected.length === 3} color={"primary"} size={'sm'} block={true} onClick={(e) => onChange('__all__', 'label')}  >
                                Select All
                        </Button>
                    </li>
                    <li className={"list-unstyled mt-2"}>
                        <Label className={"text-sm"}>
                            <input type="checkbox"
                                id={"label_created"}
                                className={"checkbox_" + 'label'}
                                data-label={"Label Created"}
                                value={"label_created"}
                                onChange={(e)=> onChange(e, "label")}
                                defaultChecked={ labelCreated }
                            /> Label Created
                        </Label>
                    </li>
                    <li className={"list-unstyled "}>
                        <Label className={"text-sm"}>
                            <input type="checkbox"
                                id={"label_not_created"}
                                className={"checkbox_" + 'label'}
                                value={"label_not_created"}
                                onChange={(e)=> onChange(e, "label")}
                                data-label={"Label Not Created"}
                                defaultChecked={ labelNotCreated }
                            /> Label Not Created
                        </Label>
                    </li>
                    <li className={"list-unstyled "}>
                        <Label className={"text-sm"}>
                            <input type="checkbox"
                                   id={"label_issue"}
                                    className={"checkbox_" + 'label'}
                                   value={"label_issue"}
                                   onChange={(e)=> onChange(e, "label")}
                                   data-label={"Label Issue"}
                                   defaultChecked={ labelIssue }
                            /> Label Issue
                        </Label>
                    </li>
                </ul>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default LabelFilter;