import { AuthContext } from "contexts/AuthContext";
import React, { useState, useContext, useEffect } from "react";
import { Card, CardHeader, Col, Container, Row, Button } from "reactstrap";
import { addURLParam, processURLSearchParams } from "utils/urls";
import Filter from "../../components/Filters/Filter";
import { getParams, removeBadge, onFilterChange, clearFilter } from "../../utils/commonFunctions";
import useFilter from "customHooks/useFilter";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import axios from "axios";
import fileDownload from "js-file-download";
import BadgePillFilter from "components/Filters/Badge";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import UsersTable from "./UsersTable";
import PagePagination from "feature/pagination/Pagination";
import DeleteUserModal from "./DeleteUserModal";
import { useLocation, NavLink } from "react-router-dom";
import "../../utils/commonStyles.css"

function UsersListingPage() {
    const baseURL = window.env.APP_URL ? window.env.APP_URL : "http://localhost:8000/";
    const { user, isAuthentication } = useContext(AuthContext);
    const [params, setParams] = useState([]);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({ order: "", columnName: "" });
    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useFilter({});
    const [refresh, setRefresh] = useState(false);
    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false
    });
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [selectedUserName, setSelectedUserName] = useState("");
    const [selectedUserId, setSelectedUserId] = useState();

    const { addToast } = useToasts();
    const cancelToken = React.useRef(undefined);

    const createRemoveBadgeWrapper = (filter, setFilter) => e => {
        removeBadge(
            e,
            filter,
            setFilter,
            () => {},
            () => {},
            "",
            searchInput
        );
    };

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const [currentFilter, setCurrentFilter] = useState(filter);

    // Handle Search
    let timer = null;
    const handleSearch = e => {
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            });
        }, 750);
    };

    // Handle Refresh
    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    useEffect(() => {
        if (filter !== currentFilter) {
            setPage(1);
            setCurrentFilter(filter);
        }
    }, [filter, currentFilter]);

    // To Fetch all data from API
    useEffect(() => {
        setLoading(true);
        let new_params = getParams(filter);
        if (typeof cancelToken.current != undefined) {
            cancelToken.current !== undefined && cancelToken.current.cancel("Operation canceled due to new request.");
        }
        cancelToken.current = axios.CancelToken.source();
        new_params = {
            ...new_params,
            page,
            ordering: sort
        };
        api.get("react/users/", {
            params: new_params,
            cancelToken: cancelToken.current.token
        })
            .then(response => {
                setUsers(response.data.results);
                setUsersCount(response.data.count);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) {
                                pageList.push(i);
                            }
                        }
                        return pageList;
                    }
                });
                setLoading(false);
                delete new_params["page"];
                delete new_params["ordering"];
                setParams(new_params);
            })
            .catch(err => {
                console.log(err);
            });
    }, [page, sort, filter, currentFilter, refresh, isMobile]);

    const urlSearchParams = useLocation().search;
    React.useMemo(() => {
        if (filter.urlParamsProcessed === false) processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams, filter, setFilter]);

    /**
     * Function for handling Export Functionality with Filters
     */
    const handleExportUsers = () => {
        // Update the export button state to show the exporting process
        setExportButton({
            label: "Exporting...",
            disabled: true
        });

        let new_params = params;

        api.get("users/react/export/", {
            params: new_params
        })
            .then(response => response.data)
            .then(response => {
                const success = response?.success | false;
                if (success) {
                    addToast(response.message, {
                        appearance: "success",
                        autoDismiss: false
                    });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = "users_export_" + timestamp + ".csv";
                    fileDownload(response, filename);
                }
                setExportButton({
                    label: "Export",
                    disabled: false
                });
            });
        // Reset the export button state after a short delay
        setTimeout(() => {
            setExportButton({
                label: "Export",
                disabled: false
            });
        }, 3000); // Adjust the delay as needed
    };

    const updateUserStatus = (userId, action) => {
        const payload = action === "disable" ? { disable: true } : { enable: true };
        api.put(`users/react/enable-disable-user/${userId}/`, payload)
            .then(response => {
                // Handle success, e.g., show a toast notification
                let message = response.data.success;
                // Optionally, refresh the user list to reflect the change
                setRefresh(!refresh);
                addToast(message, { appearance: "success", autoDismiss: true });
            })
            .catch(error => {
                // Handle error, e.g., show a toast notification
                console.log("Error updating user status:", error);
            });
    };

    const searchInput = document.getElementById("usersPageSearch");

    const handlePagination = page => {
        setPage(page);
    };

    const toggleDeleteModel = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const handleSendInvitation = userId => {
        api.post(`users/react/invite/${userId}/`)
            .then(response => {
                let flag = response?.data?.success;
                let message = response?.data?.message;
                if (flag) {
                    addToast(message, { appearance: "success", autoDismiss: true });
                } else {
                    addToast(message, { appearance: "error", autoDismiss: true });
                }
            })
            .catch(error => {
                addToast("An unexpected error occurred", { appearance: "error", autoDismiss: true });
            });
    };

    const handleSendCheckOut = userId => {
        api.post(`users/react/invite-checkout/${userId}/`)
            .then(response => {
                console.log("response: ", response);
                let successMessage = response.data.success;
                addToast(successMessage, { appearance: "success", autoDismiss: true });
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    let errorMessage = error.response.data.error;
                    addToast(errorMessage, { appearance: "error", autoDismiss: true });
                } else {
                    addToast("An unexpected error occurred", { appearance: "error", autoDismiss: true });
                }
            });
    };

    const handleCollectPaymentInfo = userId => {
        console.log("INSIDE HANDLE COLLECT PAYMENT INFO FUNCTION: ", userId);
        api.post(`users/react/collect-payment-info/${userId}/`)
            .then(response => {
                let flag = response?.data?.success;
                let message = response?.data?.message;
                if (flag) {
                    addToast(message, { appearance: "success", autoDismiss: true });
                } else {
                    addToast(message, { appearance: "error", autoDismiss: true });
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    let errorMessage = error.response.data.error;
                    addToast(errorMessage, { appearance: "error", autoDismiss: true });
                } else {
                    addToast("An unexpected error occurred", { appearance: "error", autoDismiss: true });
                }
            });
    };

    const handleView = userEmail => {
        const  url = `${baseURL}impersonate/${userEmail}`

        window.location.href = url
    };

    const handleDuplicateUser = userId => {
        const duplicatePageURL = `${baseURL}user/duplicate/${userId}`;
        window.location.href = duplicatePageURL;
    };

    const handleDisableUser = userId => {
        updateUserStatus(userId, "disable");
    };

    const handleEnableUser = userId => {
        updateUserStatus(userId, "enable");
    };

    const handleDeleteUser = (userId, userName) => {
        setSelectedUserName(userName);
        setSelectedUserId(userId);
        setModalIsOpen(true);
    };

    const handleDelete = userId => {
        // Handle the delete logic here
        // setModalIsOpen(false);
        const payload = { disable: true };
        api.put(`users/react/enable-disable-user/${userId}/`, payload)
            .then(response => {
                // Handle success, e.g., show a toast notification
                toggleDeleteModel();
                // Optionally, refresh the user list to reflect the change
                setRefresh(!refresh);
                addToast(response?.data?.success, { appearance: "success", autoDismiss: true });
            })
            .catch(error => {
                console.log("Error updating user status:", error);
            });
    };

    const retailerObject = filter?.userRoles?.some(item => item.value === "retailer");
    return (
        <>
            <Container fluid>
                <DeleteUserModal
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    onConfirmation={handleDelete}
                    userName={selectedUserName}
                    userId={selectedUserId}
                />
                <div className={"pt-7"}>
                    {isAuthentication && (
                        <Row className={"mb-2"}>
                            <Col xs={8} lg={2} className={"text-left"}>
                                {/* 7278215305 - increase font size */}
                                <input
                                    type="text"
                                    className={"button-font-size form-control form-control-sm"}
                                    placeholder={"Search"}
                                    id="usersPageSearch"
                                    defaultValue={filter.search}
                                    onChange={e => handleSearch(e)}
                                    newparams={""}
                                />
                            </Col>
                            <Col className={"text-right"}>
                                <div className={"d-none d-lg-inline-block mr-2"}>
                                    {/* 7278215305 - increase font size */}
                                    <button className="button-font-size btn btn-neutral btn-sm p-9px" onClick={() => handleRefresh()}>
                                        <i className="fas fa-sync-alt"></i>
                                    </button>
                                </div>
                                <Filter
                                    name={"Brand"}
                                    prefix={"brand"}
                                    onChange={e => onFilterChange(e, "brand", filter, setFilter)}
                                    newparams={params}
                                    selected={filter.brands}
                                    url={"/order/filter/brand/"}
                                    onClear={() => clearFilter("brand", setFilter)}
                                />
                                <Filter
                                    name={"Roles"}
                                    prefix={"userRoles"}
                                    onChange={e => onFilterChange(e, "userRoles", filter, setFilter)}
                                    newparams={params}
                                    selected={filter.userRoles}
                                    url={"users/react/filter/roles/"}
                                    onClear={() => clearFilter("userRoles", setFilter)}
                                />
                                <Filter
                                    name={"Status"}
                                    prefix={"userStatus"}
                                    onChange={e => onFilterChange(e, "userStatus", filter, setFilter)}
                                    newparams={params}
                                    selected={filter.userStatus}
                                    url={"users/react/filter/status/"}
                                    onClear={() => clearFilter("userStatus", setFilter)}
                                />
                                {retailerObject && (
                                    <Filter
                                        name={"Retailer Inventory"}
                                        prefix={"retailerInventory"}
                                        onChange={e => onFilterChange(e, "retailerInventory", filter, setFilter)}
                                        newparams={params}
                                        selected={filter.retailerInventory}
                                        url={"users/react/filter/retailer-inventory/"}
                                        onClear={() => clearFilter("retailerInventory", setFilter)}
                                    />
                                )}
                                {/* 7278215305 - increase font size */}
                                <Button color={"primary"} size={"sm"} disabled={exportButton.disabled} onClick={() => handleExportUsers()} className="button-font-size p-9px">
                                    <i className="fas fa-file-csv"></i> {exportButton.label}
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        {isAuthentication && (
                            <div className="col">
                                <Card className="shadow " style={{ overflowX: "scroll" }}>
                                    <CardHeader className="border-0">
                                        <Row className="d-flex justify-content-between align-items-center p-1">
                                            <div className="d-flex">
                                                <h3 className="mb-0">
                                                    Users <small>({usersCount})</small>
                                                </h3>
                                            </div>
                                            <Col className="text-right">
                                                    {/* 7278215305 - increase font size */}
                                                    <a href={'/user-add'} className="button-font-size btn btn-success btn-sm">
                                                        <i className="fas fa-plus" /> Add
                                                    </a>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <BadgePillFilter data={filter} onClick={createRemoveBadgeWrapper(filter, setFilter)} />
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    {isLoading ? (
                                        <div className="text-center mt-3 mb-3">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <UsersTable
                                                users={users}
                                                onOptionsSelect={{
                                                    sendInvitation: handleSendInvitation,
                                                    sendCheckout: handleSendCheckOut,
                                                    collectPaymentInfo: handleCollectPaymentInfo,
                                                    view: handleView,
                                                    duplicateUser: handleDuplicateUser,
                                                    disableUser: handleDisableUser,
                                                    enableUser: handleEnableUser,
                                                    deleteUser: handleDeleteUser
                                                }}
                                                sort={sort}
                                                setSort={setSort}
                                                setSortOrder={setSortOrder}
                                                sortOrder={sortOrder}
                                                user={user}
                                            />
                                            <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={usersCount} />
                                        </>
                                    )}
                                </Card>
                            </div>
                        )}
                    </Row>
                </div>
            </Container>
        </>
    );
}

export default UsersListingPage;