import React, {useState, useEffect} from 'react';
import { Container } from "reactstrap";
import jwt from "jsonwebtoken";

function JoseCuervoInventory() {
  // Embedding metabase report for - Jose Cuervo brand
  const [iframeUrl, setIframeUrl] = useState("");

  const METABASE_SITE_URL = "https://metabase.flaviar.com";
  const METABASE_SECRET_KEY = "e058032bbd4e558af5002eb1a3e13108c51fab1d40fb60df2e59728bce24f5a2";

  const payload = {
    resource: { question: 5476  },
    params: {},
    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
  };

  useEffect(() => {
    const token = jwt.sign(payload, METABASE_SECRET_KEY);
    const url = METABASE_SITE_URL + "/embed/question/" + token + "#bordered=true&titled=true";
    setIframeUrl(url);
  }, []);

  return (
    <Container fluid>
      <iframe
        title="JoseCuervoInventory"
        src={iframeUrl}
        id="dynamicIframe"
        style={{ width: "100%", height: '100vh', marginTop: '6.25rem'}}
        width="100%"
        height="100%"
        allowTransparency
    ></iframe>
    </Container>
  )
}

export default JoseCuervoInventory