import React, { Component } from 'react';
import Chart from 'chart.js/auto';
import  {useContext, useEffect, useRef, useState} from "react";
import {api} from "../../services/api";
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';

Chart.register(MatrixController, MatrixElement);


const MatrixChart = (props) => {

	const chartRef = React.createRef();
	const [data, setData] = useState([])
	const [chart, setChart] = useState()
    const [isLoading, setLoading] = useState(false);
	const [style, setStyle] = useState({})

	const getParams = () => {
        let new_params = {}

        if (props.filter?.states.length > 0) {
            new_params.states = []
            props.filter.states.map(item => new_params.states.push(item.value))
            props.setClearAll(true)
        }
		if (props.filter?.inventory_location.length > 0) {
			new_params.inventory_location = []
			props.filter.inventory_location.map(item => new_params.inventory_location.push(item.value))
			props.setClearAll(true)
		}
        return new_params
    }


	useEffect(() => {

		{ isLoading ?
			setStyle({display: 'none'}) :
			setStyle({})
		}
	}, [isLoading])
	useEffect(() => {
		setLoading(true)
		if (chart) {
			chart.destroy()
		}
		setData([])

		const new_params = getParams();
		const ctx = chartRef.current.getContext("2d", { alpha: false });

		api.get('order/charts/', {
			params: new_params
        }).then(response=> {
			setData([])
			setLoading(false)
			response.data.results.map(item => {
				item.inventory_locations.map(il => {
					data.push({
					'x': response.data.states.indexOf(item.state_code),
					'y': response.data.il.indexOf(il.name)
				})
				})
			})
				var chart = new Chart(ctx, {
			type: "matrix",
			data: {
			  datasets: [{
				data: data,
				backgroundColor: 'rgb(15,160,173)',
				  width: 9,
				  height: 8
			  }],
			},
			 options: {
				responsive: true,
				maintainAspectRatio: true,
				plugins: {
					legend: {
					  display: false
					},
					tooltip: {
						enabled: false
					}
			  	},
				scales: {
					y: {
						beginAtZero: true,
						ticks: {
							stepSize: 1,
							callback: function(value, index, values) {
								return response.data.il[value];
							},
							min: 1,
            				autoSkip: false,
							color: 'white'
						}
					},
					x: {
						position: 'top',
						beginAtZero: true,
						ticks: {
							stepSize: 1,
							callback: function(value, index, values) {
								return response.data.states[value] ;
							},
							min: 0,
            				autoSkip: false,
							color: 'white'

						}
					}
				}
			}
		});
		chart.update()
			setChart(chart)
			})
            .catch(error=> console.log(error))
	}, [props.filter])
		return (
			<>
				{isLoading &&
					<div className="text-center mt-3 mb-3">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				}

				<div style={style}>
					<canvas
					id="myChart"
					ref={chartRef}
					/>
				</div>
			</>


			)
}

export default MatrixChart;