/*
    Author - Akshay Vishwakarma
    Description - This is listing page for every AdventBatch 
    Jira (if any) - BCD - 4091
*/

import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Col,
    Button,
    Badge
} from "reactstrap";
import { api } from "services/api";
import { AuthContext } from "contexts/AuthContext";
import useFilter from "customHooks/useFilter";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import fileDownload from "js-file-download";
import './AdventBatch.css';

const CommonFieldsCapsules = ({ commonFields }) => {
    if (!commonFields) return null;
  
    return (
      <div className="badge-container">
        {/* Upper Row */}
        <div className="row">
          <Badge className="custom-badge">
            <span className="badge-title">Original Qty:</span>{' '}
            <span className="badge-data">{commonFields.batch_original_qty ?? '-'}</span>
          </Badge>
          <Badge className="custom-badge">
            <span className="badge-title">Fulfilled Qty:</span>{' '}
            <span className="badge-data">{commonFields.items_count ?? '-'}</span>
          </Badge>
          <Badge className="custom-badge">
            <span className="badge-title">Fulfillment Center:</span>{' '}
            <span className="badge-data">{commonFields.fulfillment_center ?? '-'}</span>
          </Badge>
          <Badge className="custom-badge">
            <span className="badge-title">States/Countries:</span>{' '}
            <span className="badge-data">{commonFields.states_countries ?? '-'}</span>
          </Badge>
        </div>
  
        {/* Lower Row */}
        <div className="row">
          <Badge className="custom-badge">
            <span className="badge-title">Parent Product:</span>{' '}
            <span className="badge-data">{commonFields.parent_order_product ?? '-'}</span>
          </Badge>
          <Badge className="custom-badge">
            <span className="badge-title">Child Product:</span>{' '}
            <span className="badge-data">{commonFields.child_order_product ?? '-'}</span>
          </Badge>
        </div>
      </div>
    );
  };

const AdventBatch = props => {
    const searchParams = new URLSearchParams(window.location.search);
    const batchId = searchParams.get("Id"); // price_descending
    const [adventBatch_api_result, setAdventBatch_api_result] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [page, setPage] = useState(1);
    const [additionalInfo, setAdditionalInfo] = useState([]);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [isLoading, setLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [selected, setSelected] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [refresh, setRefresh] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [filter, setFilter, clearFilterState] = useFilter({});
    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);
    useEffect(() => {
        getBatchList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filter, refresh]);

    const { addToast } = useToasts();

    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false,
    });

    const getBatchList = () => {
        setLoading(true);
        api.get(`flaviar/order/advent-batches-orders/?batch_id=${batchId}`)
            .then(response => {
                const list = response.data.orders.map(order => {
                    const s = selected.filter(item => parseInt(item.id) === order.id);
                    order.checked = s.length > 0;
                    return order;
                });
                setAdditionalInfo(response?.data?.common_fields)
                setAdventBatch_api_result(list);
                setTotalOrders(response.data.count);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) pageList.push(i);
                        }
                        return pageList;
                    }
                });
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            });
    }
    const handlePagination = page => {
        setPage(page);
    };
    
    /**
     * Function for handling Export Functionality
     */
    const handleExportAdventBatch = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        });
        api
            .get(`flaviar/order/advent-batch/export/?batch_id=${batchId}`)
            .then((response) => response.data)
            .then((response) => {
                const success = response?.success | false;
                if (success) {
                    addToast(response.message, {
                        appearance: "success",
                        autoDismiss: true,
                    });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = "advent-calendar_" + timestamp + ".csv";
                    fileDownload(response, filename);
                }
                setExportButton({
                    label: "Export",
                    disabled: false,
                });
            });
    };
    const { user, isAuthentication } = useContext(AuthContext);
    const handleRefresh = () => {
        // setRefresh(!refresh);
        getBatchList();
    };
    if (isAuthentication && !user.isAdmin) window.location.href = window.env.APP_URL;
    return (
        <>
            {isAuthentication && user.isAdmin && (
                <Container fluid>
                    <div className={"pt-7"}>
                        {isAuthentication && (
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={"2"} className={"text-left"}></Col>
                                <Col xs={2} lg={"10"} className={"text-right"}>
                                    <div className={"d-none d-lg-inline-block mr-2"}>
                                        <div className="mb-1 d-inline">
                                            <button className="btn btn-neutral btn-sm mr-2 p-9px" onClick={handleRefresh}>
                                                <i className="fas fa-sync-alt"></i>
                                            </button>
                                        </div>

                                        <Button
                                            color={"primary"}
                                            size={"sm"}
                                            disabled={exportButton.disabled}
                                            onClick={handleExportAdventBatch}
                                            className='p-9px'
                                        >
                                            <i className="fas fa-file-csv"></i> {exportButton.label}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {isAuthentication && (
                                <div className="col">
                                    <Card className="shadow ">
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col>
                                                    <h3
                                                        className="mb-0"
                                                        style={{
                                                            display: "inline-block",
                                                            paddingRight: "5px"
                                                        }}>
                                                        Advent Batch[{batchId ?? "-"}] <small>&nbsp;({totalOrders})</small>
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CommonFieldsCapsules commonFields={additionalInfo} />
                                        {isLoading ? (
                                            <div className="text-center mt-3 mb-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Batch Id
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Parent Order Name
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                child Order Name
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Original Quantity
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                New Quantity
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Status
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {adventBatch_api_result.map(record => (
                                                            <tr key={record.id}>
                                                                <td className="pl-3 pr-3 ">{record.batch_id ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.parent_order_name ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.child_order_name ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.original_quantity ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.new_quantity ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.status ?? "-"}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalOrders} />
                                            </>
                                        )}
                                    </Card>
                                </div>
                            )}
                        </Row>
                    </div>
                </Container>
            )}
        </>
    );
};
export default AdventBatch;