import React, { useContext, useEffect, useRef, useState } from "react";
import { api } from "../../services/api";
import { ComponentContext } from "../../contexts/ComponentContext";
import BadgePillFilter from "components/Filters/Badge";
import moment from "moment";
import Moment from 'react-moment';
import { useToasts } from "react-toast-notifications";
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Col,
    Button,
    UncontrolledTooltip,
    Input,
    ButtonGroup
} from "reactstrap";
import Filter from 'components/Filters/Filter';
import { useLocation, NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Select from 'react-select';
import { AuthContext } from "../../contexts/AuthContext";

const CreateMarketingCampaign = () => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [name, setName] = useState();
    const [type, setType] = useState();
    const [typeId, setTypeId] = useState();
    const [typeData, setTypeData] = useState();
    const [selectedType, setSelectedType] = useState();
    const [typeDetails, setTypeDetails] = useState();
    const [code, setCode] = useState();
    const [performanceLink, setPerformanceLink] = useState();
    const [brands, setBrands] = useState();
    const [products, setProducts] = useState();
    const [budget, setBudget] = useState();
    const [notes, setNotes] = useState();
    const [run, setRun] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [error, setError] = useState(false);
    const [productId, setProductId] = useState();
    const [brandId, setBrandId] = useState();
    const { user, isAuthentication } = useContext(AuthContext);


    /**
     * function for handling change of radio buttons and setting values for run 
     * @param {*} event 
     */
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'brand') {
            setRun("brand");
        } else if (event.target.value === "product") {
            setRun("product");
        }
    };

    const { addToast } = useToasts();

    /**
     * useEffect for API call
     */
    useEffect(() => {
        api({
            method: 'get',
            url: 'marketing/campaign/type/'
        }).then((response) => {
            const info = response?.data?.types.map((item) => {
                setTypeData(item);
            })

            const data = response?.data?.types.map(item => { return { "value": item.id, "label": item.name } });
            setType(data);
        }).catch((error) => console.log(error));

        api({
            method: 'get',
            url: 'marketing/campaign/brands/',
        }).then((response) => {
            setBrands(response?.data?.brand);
        })

        api({
            method: 'get',
            url: 'marketing/campaign/products/',
        }).then((response) => {
            setProducts(response?.data?.product);
        })
    }, []);

    /**
     * Function for fetching campaign type details
     * @param {*} id 
     */
    const handleTypeDetails = (id) => {
        setTypeId(id);
        api({
            method: 'get',
            url: 'marketing/campaign/typedetails/',
            params: { id: id }
        }).then((response) => {
            setTypeDetails(response.data.typeDetails);
        }).catch((error) => console.log(error));
    };

    /**
     * function for setting formatted start date
     * @param {*} e 
     */
    const handleStartDate = (e) => {
        setStartDate(moment(e).format("YYYY-MM-DD"));
    };

    /**
     * function for setting formatted end date
     * @param {*} e 
     */
    const handleEndDate = (e) => {
        setEndDate(moment(e).format("YYYY-MM-DD"));
    };

    /**
     * Function for handling submit of form
     * @param {*} e 
     * @returns 
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!run) {
            return setError(true);
        }

        if (!name) {
            return setError(true);
        }

        if (!budget && !typeDetails?.budget) {
            return setError(true);
        }

        if (run === 'product') {
            if (!productId) {
                return setError(true);
            }
        } else if (run === 'brand') {
            if (!brandId) {
                return setError(true);
            }
        }
        if (endDate < startDate) {
            return setError(true);
        }

        const data = {
            name: name,
            type_id: typeId,
            promo_code: code ? code : null,
            performance: performanceLink ? performanceLink : null,
            run: run,
            budget: budget ? budget : typeDetails?.budget,
            start_date: startDate,
            end_date: endDate,
            notes: notes,
            product_id: productId ? productId : null,
            brand_id: brandId ? brandId : null
        };
        api({
            method: 'post',
            url: "marketing/campaign/createcampaign/", data: data
        }).then((response) => {
            addToast("Saved Successfully!", { appearance: "success", autoDismiss: true });
            window.location.href = window.env.APP_URL + "marketing/campaign/detail/" + response?.data.id;
        }).catch((error) => {
            let message = "Something went wrong, please try again!!";
            if (error?.response?.data?.error) {
                message = error.response.data.error;
            };
            addToast(message, { appearance: "error" });
        });
    };
    const handleBrandChange = (e) => {
        const brandValueArray = []
        e.forEach(function (arrayItem) {
            brandValueArray.push(arrayItem.value)
        });
        setBrandId(brandValueArray)
    }
    const handleProductChange = (e) => {
        const productValueArray = []
        e.forEach(function (arrayItem) {
            productValueArray.push(arrayItem.value)
        });
        setProductId(productValueArray)
    }

    return (
        <Container fluid>
            <div className={"pt-7"} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2 className="mb-0">
                    Create Campaign
                </h2>
                <div style={{ width: 1110 }}>
                    <Card className="shadow mt-3" style={{}}>
                        <CardHeader className="border-0">
                            <div>
                                Name *
                                <Input placeholder="Name" onChange={(e) => setName(e.target.value)} />
                                {error && !name && (
                                    <div style={{ color: "red" }}>
                                        {`* This field is required`}
                                    </div>
                                )}
                            </div>
                            <div className="mt-1">
                                Type *
                                <Select
                                    options={type}
                                    onChange={(e) => handleTypeDetails(e.value)}
                                />
                            </div>
                            {typeDetails?.description ?
                                <div className="mt-1">
                                    Campaign Description
                                    <Input value={typeDetails?.description ? typeDetails.description : "Campaign Description"} disabled={true} />
                                </div>
                                :
                                <>
                                </>
                            }
                            {!typeDetails?.description ?
                                <div className="mt-1">
                                    Promo Code
                                    <Input placeholder="Promo code" onChange={(e) => setCode(e.target.value)} />
                                </div>
                                :
                                <>
                                </>
                            }
                            {!typeDetails?.description ?
                                <div className="mt-1">
                                    Campaign Performance Link
                                    {(isAuthentication && !user.isSupplier) ?
                                        <Input onChange={(e) => setPerformanceLink(e.target.value)} />
                                        :
                                        <Input disabled={true} />
                                    }
                                </div>
                                :
                                <>
                                </>
                            }
                            <div className="mt-1">
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ flex: 1, maxWidth: 155 }}>
                                        Run Campaign For *
                                    </div>
                                    {error && !run && (
                                        <div style={{ color: "red" }}>
                                            {`* This field is required`}
                                        </div>
                                    )}
                                </div>
                                {!typeDetails?.description ?
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ marginLeft: 20 }}>
                                                <Input type='radio' id="brand" name="brand" value="brand" checked={selectedOption === 'brand'} onChange={handleOptionChange} />
                                                Brand
                                            </div>
                                            <div style={{ marginRight: 20 }}>
                                                {selectedOption === 'brand' &&
                                                    <div>
                                                        Brand
                                                        <Select options={brands} onChange={(e) => handleBrandChange(e)} isMulti />
                                                        {error && run == 'brand' && !brandId && (
                                                            <div style={{ color: "red" }}>
                                                                {`* This field is required`}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                {selectedOption === 'product' &&
                                                    <div>
                                                        Product
                                                        <Select options={products} onChange={(e) => handleProductChange(e)} isMulti />
                                                        {error && run == 'product' && !productId && (
                                                            <div style={{ color: "red" }}>
                                                                {`* This field is required`}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <Input
                                                type='radio'
                                                value="product"
                                                id="product"
                                                name="product"
                                                checked={selectedOption === 'product'}
                                                onChange={handleOptionChange}
                                            />
                                            Product
                                        </div>
                                    </div>
                                    :
                                    <div style={{ flex: 1, marginLeft: 20 }}>
                                        <Input
                                            type='radio'
                                            value="product"
                                            id="product"
                                            name="product"
                                            checked={selectedOption === 'product'}
                                            onChange={handleOptionChange}
                                        />
                                        Product
                                        {selectedOption === 'product' &&
                                            <div>
                                                Product
                                                <Select options={products} onChange={(e) => handleProductChange(e)} isMulti />
                                                {error && run == 'product' && !productId && (
                                                    <div style={{ color: "red" }}>
                                                        {`* This field is required`}
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div style={{ marginTop: 15 }}>
                                Budget/Cost ($) *
                                <i className="fas fa-question-circle budget-tooltip" id="campaignText" style={{ marginLeft: 5 }} />
                                <div style={{ marginTop: 9 }}>
                                    <Input
                                        type="number"
                                        step="0.01"
                                        placeholder="Budget/Cost"
                                        onChange={(e) => setBudget(e.target.value)}
                                        defaultValue={typeDetails?.budget}
                                        disabled={typeDetails?.budget ? true : false}
                                    />
                                    {error && !budget && !typeDetails?.budget && (
                                        <div style={{ color: "red" }}>
                                            {`* This field is required`}
                                        </div>
                                    )}
                                </div>
                                <UncontrolledTooltip
                                    delay={0}
                                    placement="bottom"
                                    target="campaignText"
                                >
                                    Minimum Recommended Budget: $500
                                </UncontrolledTooltip>
                            </div>
                            <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, maxWidth: 516, marginRight: 28 }}>
                                    Start Date
                                    <DatePicker
                                        value={startDate ?
                                            startDate
                                            : null}
                                        placeholderText="Start Date"
                                        onChange={(date) => handleStartDate(date)}
                                        dateFormat={"yyyy-MM-dd"}
                                        isClearable={true}
                                    />
                                </div>
                                <div style={{ flex: 1, maxWidth: 516 }}>
                                    End Date
                                    <DatePicker
                                        value={endDate ?
                                            endDate
                                            : null}
                                        placeholderText="End Date"
                                        onChange={(date) => handleEndDate(date)}
                                        dateFormat={"yyyy-MM-dd"}
                                        isClearable={true}
                                    />
                                </div>
                            </div>
                            {error && endDate < startDate && (
                                <div style={{ color: "red" }}>
                                    {`* End date must be greater than start date `}
                                </div>
                            )}
                            <div style={{ marginTop: 15 }}>
                                Notes
                                <Input type="textarea" rows="5" onChange={(e) => setNotes(e.target.value)} />
                            </div>
                        </CardHeader>
                    </Card>

                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 7, maxWidth: 1110, justifyContent: 'space-between' }}>
                        <NavLink to={"/marketing-campaign"} style={{ cursor: "pointer" }}>
                            <a className="btn btn-lg">
                                <i className="fas fa-arrow-left"> Back</i>
                            </a>
                        </NavLink>
                        <a className="btn btn-success btn-lg" onClick={handleSubmit}>
                            <i className="fas fa-arrow-right"> Submit for Review</i>
                        </a>
                    </div>
                </div>
            </div>
        </Container >
    )
}

export default CreateMarketingCampaign;