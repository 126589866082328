import ShowError from "components/Errors";
import React, {useEffect, useState} from "react";
import {Button, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, UncontrolledDropdown} from "reactstrap";

const Bottle = ({color = "white", onSubmit, valueMin, valueMax}) => {
    const [errors, seterrors] = useState({valueMin : "", valueMax : ""});
    const [values, setvalues] = useState({valueMin : valueMin, valueMax : valueMax})
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState)

    const handleBottleCountChange = (e) => {
        e.preventDefault();
        if (e.target.value >= 0) { 
            setvalues({...values, [e.target.name]: e.target.value });
            seterrors({...errors, [e.target.name]: "" });
        }
        seterrors({ ...errors, [e.target.name]: e.target.value >= 0 ? "" : "Value can't be less than 0" }); 
    }

    useEffect(() => {
      setvalues({valueMin : valueMin, valueMax : valueMax})
    }, [valueMin, valueMax])
    

    return (
        <UncontrolledDropdown size={"sm"} isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
                color={color}
                className={(color === "white") ? "text-primary" : "p-10px"}
                caret>
                Bottles
            </DropdownToggle>
            <DropdownMenu className="more-filters-dropdown-menu" right>

                <Form className={"p-2"} onSubmit={(e) => onSubmit(e, values.valueMin, values.valueMax)}>

                        <h4>Bottles Quantity</h4>
                        <hr className={"m-0 mb-2"}/>
                        <FormGroup className={"m-0 mb-1"}>
                            <Label className={"text-sm"} >
                                Min
                            </Label>
                                <Input type={"number"}
                                       bsSize={"sm"}
                                       name="valueMin"
                                       value={values.valueMin}
                                       placeholder={"Min"}
                                       data-label={"Min"}
                                       onChange={(e) => handleBottleCountChange(e)}
                                       defaultValue={values.valueMin}/>
                                {errors.valueMin && <ShowError message={errors.valueMin}/>}
                        </FormGroup>
                        <FormGroup className={"m-0 mb-1"}>
                            <Label className={"text-sm"}>
                                Max
                            </Label>
                            <Input type={"number"}
                                   bsSize={"sm"}
                                   name="valueMax"
                                   value={values.valueMax}
                                   placeholder={"Max"}
                                   data-label={"Max"}
                                   onChange={(e) => handleBottleCountChange(e)}
                                   defaultValue={values.valueMax}/>
                            {errors.valueMax && <ShowError message={errors.valueMax}/>}
                        </FormGroup>
                        <FormGroup className={"m-0 mt-3 text-right"}>
                        <Button onClick={toggle} size={"sm"}>Cancel</Button>
                            <Button type={"submit"} size={"sm"} color={"primary"} >Apply</Button>
                        </FormGroup>
                </Form>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default Bottle;