import React from "react";
import { Card, CardHeader, UncontrolledTooltip } from "reactstrap";
import Moment from "react-moment";

const StoreWebhooks = ({ storeWebhooks, handleStoreWebhookCreate, handleStoreWebhooktrash }) => {
    return (
        <Card className="shadow mt-3">
            <CardHeader className="border-0">
                <h3>Store Webhooks</h3>
                <div className="table-responsive">
                    <table className="table mb-3">
                        <thead>
                            <tr>
                                <th className="py-3 text-sm text-muted" style={{ fontWeight: "bold" }}>
                                    Event
                                </th>
                                <th className="py-3 text-sm text-muted" style={{ fontWeight: "bold" }}>
                                    API
                                </th>
                                <th className="py-3 text-sm text-muted" style={{ fontWeight: "bold" }}>
                                    Created At
                                </th>
                                <th className="py-3 text-sm text-muted" style={{ fontWeight: "bold" }}>
                                    Status
                                </th>
                                <th className="py-3 text-sm text-muted" style={{ fontWeight: "bold" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <br />
                            {storeWebhooks?.map(storeWebhook => (
                                <tr className="border-bottom-0" key={storeWebhook?.id}>
                                    <td className="text-sm">{storeWebhook?.topic}</td>
                                    <td className="badge badge-secondary text-lowercase">{storeWebhook?.endpoint}</td>
                                    <td>
                                        <Moment format="MM/D/YYYY" withTitle>
                                            {storeWebhook?.created_at}
                                        </Moment>
                                    </td>
                                    <td>
                                        {storeWebhook.is_active ? (
                                            <>
                                                <i className="fas fa-check-circle text-success" id={"successtext" + storeWebhook?.id} />
                                                <UncontrolledTooltip delay={0} placement="bottom" target={"successtext" + storeWebhook?.id}>
                                                    Active
                                                </UncontrolledTooltip>
                                            </>
                                        ) : !storeWebhook.is_active && !storeWebhook.is_enabled ? (
                                            <>
                                                <i className="fas fa-exclamation-circle text-gray" id={"disabledText" + storeWebhook?.id} />
                                                <UncontrolledTooltip delay={0} placement="bottom" target={"disabledText" + storeWebhook?.id}>
                                                    Disabled
                                                </UncontrolledTooltip>
                                            </>
                                        ) : (
                                            <>
                                                <i className="fas fa-times-circle text-danger" id={"inactiveText" + storeWebhook?.id} />
                                                <UncontrolledTooltip delay={0} placement="bottom" target={"inactiveText" + storeWebhook?.id}>
                                                    Inactive
                                                </UncontrolledTooltip>
                                            </>
                                        )}
                                    </td>

                                    <td>
                                        {storeWebhook.is_active && storeWebhook.is_enabled ? (
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger btn-sm"
                                                onClick={() => handleStoreWebhooktrash(storeWebhook?.id)}>
                                                <i className="fa fa-trash-alt"></i>
                                            </button>
                                        ) : !storeWebhook.is_active && !storeWebhook.is_enabled ? (
                                            <button
                                                type="button"
                                                className="btn btn-outline-info btn-sm"
                                                onClick={() => handleStoreWebhookCreate(storeWebhook?.id)}>
                                                Enable
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="btn btn-outline-success btn-sm"
                                                onClick={() => handleStoreWebhookCreate(storeWebhook?.id)}>
                                                Create
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CardHeader>
        </Card>
    );
};

export default StoreWebhooks;
