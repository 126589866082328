import React, { useState } from "react";
import { Table, Button, Spinner, Card, CardHeader, Col, Container, Row } from "reactstrap";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";

function ProductLocator() {
  const { addToast } = useToasts();
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [city, setCity] = useState("");
  const [radius, setRadius] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [barcartsku, setBarCartSku] = useState("");

  /**
   * Fetch prodcuts based on give filter
   */
  const handleFilter = () => {
    // Do validation
    let errorMessage = "";
    if (!zipCode && !city) {
      errorMessage = "Please enter city name or zip code";
    } else if (!barcartsku) {
      errorMessage = "Please enter SKU";
    }

    if (errorMessage) {
      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
      return;
    }

    // Generate request body
    const requestBody = {
      "sku": barcartsku,
    };

    if (radius) {
      requestBody.radius = Number(radius);
    }

    if (zipCode) {
      requestBody.zipcode = zipCode;
    } else {
      requestBody.city = city;
    }

    setLoading(true);

    api.post("/nearest-retailer", requestBody)
      .then((response) => {
        setLoading(false);
        if (Array.isArray(response.data.data)) {
          setProducts(response.data.data);
          setTotalProducts(response.data.data.length);
        }
        // TODO: handle error
      })
      .catch((error) => {
        setLoading(false);
        // TODO: handle error
        console.log(error);
      });
  };

  return (
    <Container className="pt-7" fluid>
      <Row>
        <div className="col">
          <Row className={"mb-2"}>
            <Col xs={10} lg={"1"} className={"text-left"}>
              <input
                type="number"
                min="0"
                className={"form-control form-control-sm"}
                placeholder={"Radius"}
                defaultValue={city}
                onChange={(e) => setRadius(e.target.value)}
              />
            </Col>
            <Col xs={10} lg={"2"} className={"text-left"}>
              <input
              type="text"
                className={"form-control form-control-sm"}
                placeholder={"City"}
                defaultValue={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Col>
            <Col xs={10} lg={"2"} className={"text-left"}>
              <input
              type="text"
                className={"form-control form-control-sm"}
                placeholder={"Zip Code"}
                defaultValue={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </Col>
            <Col xs={10} lg={"2"} className={"text-left"}>
              <input
              type="text"
                className={"form-control form-control-sm"}
                placeholder={"Flaviar Checkout SKU"}
                defaultValue={barcartsku}
                onChange={(e) => setBarCartSku(e.target.value)}
              />
            </Col>
            <Col xs={10} lg={"2"} className={"text-left"}>
              <Button type={"submit"} size={"sm"} color={"primary"} disabled={isLoading}
                onClick={handleFilter}
              >
                Filter
                {isLoading && <Spinner size="sm" color="light" />}
              </Button>
            </Col>
          </Row>
          <Card className="shadow ">
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0"> Product Locator <small>({totalProducts})</small> </h3>
                </Col>
              </Row>
            </CardHeader>
            {isLoading ?
              <div className="text-center mt-3 mb-3">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div> :
              <>
                <Table className="align-items-center table-flush" responsive>
                  <thead className=" thead-light">
                    <tr>
                      <th scope="col" className="pl-3 pr-3"> Inventory ID</th>
                      <th scope="col" className="pl-3 pr-3"> Product Name</th>
                      <th scope="col" className="pl-3 pr-3"> SKU</th>
                      <th scope="col" className="pl-3 pr-3"> Quantity</th>
                      <th scope="col" className="pl-3 pr-3"> Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, index) => (
                      <tr key={index}>
                        <td className="pl-3 pr-3">{product.inventory_id}</td>
                        <td className="pl-3 pr-3">{product.product_name}</td>
                        <td className="pl-3 pr-3">{product.barcart_sku}</td>
                        <td className="pl-3 pr-3">{product.quantity}</td>
                        <td className="pl-3 pr-3">{product.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>}
          </Card>
        </div>
      </Row>
    </Container>
  );
}
export default ProductLocator;
