const Spinner = () => {
  return (
    <div className="text-center">
      <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem", color: "#5e72e4" }}      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
