/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This file contrains code related to Reason for a change of a  product page.
  
 */

import React from "react";
import styles from "../create-product/createProduct.module.css";

const ReasonForChange = ({ setPayload, payload, requiredFields }) => {
    const handleChange = (field, value) => {
        setPayload(prevPayload => ({
            ...prevPayload,
            [field]: value
        }));
    };

    return (
        <div>
            <div className={styles.sectionConatiner}>
                <div className={styles.productSectionOne}>
                    <div style={{ marginBottom: "1rem" }}>
                        <h3>Explain Your Changes *</h3>
                        <div className={styles.inputContainer}>
                            <textarea
                                type="text"
                                rows={5}
                                id="explanation"
                                placeholder="Explain your changes..."
                                name="explanation"
                                className={styles.textAreaStyle}
                                onChange={e => handleChange("explanation", e.target.value)}
                            />
                        </div>
                        {requiredFields.reason_of_change ? (
                            <div>
                                <span style={{ color: "red", marginTop: "5px" }}>* This field is required</span>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReasonForChange;
