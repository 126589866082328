import React, { useEffect, useState } from "react";
import { Modal, Button, ModalBody, ModalHeader, Form, Spinner } from "reactstrap";
const BulkNotes = ({ isOpen, onExit, selected, handleInternalNotes, notesLoading, inputNotes, handleRefresh }) => {
    const [modal, setModal] = useState();

    const toggle = () => {
        setModal(!isOpen);
    };

    useEffect(() => {
        setModal(isOpen);
    }, [isOpen]);

    let ids = [];
    let notes = [];
    selected.map(selectedInformation => {
        ids.push(+selectedInformation?.id); // 6011065090 - Prevent page reload after adding notes
        notes.push(selectedInformation?.note);
    });

    return (
        <Modal size="lg" isOpen={modal} toggle={toggle} onExit={onExit}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
                <span className="text-white h3">Bulk Notes</span>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={event => handleInternalNotes(event, ids, notes, handleRefresh)}>
                    <h1>Internal Notes</h1>
                    <textarea
                        name="inote"
                        onChange={event => (inputNotes.current = [event.target.value, ids])}
                        rows={10}
                        className="w-100"
                        defaultValue={selected.length === 1 ? notes[0] : ""}></textarea>
                    <div className="d-flex justify-content-between">
                        <Button color="danger" onClick={onExit}>
                            Close
                        </Button>
                        <Button color={"success"} disabled={notesLoading}>
                            Add note
                            {notesLoading && <Spinner size="sm" color="light" />}
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};
export default BulkNotes;