import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { api } from "../../services/api";
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import Dropdown from "./dropdown";


export const UploadCSVModalForm = props => {

  const [modal, setModal] = useState(props.isOpen);
  const [loading, setLoading] = useState(false)
  const [inventoryLocations, setInventoryLocations] = useState([]);
  const [inventoryLocationId, setInventoryId] = useState('');
  const { addToast } = useToasts();
  const [csvFile, setCSVFile] = useState('');

  const toggle = () => {
    setModal(!modal)
  };

  // OnClick on confirm button to upload CSV File along with inventory location id
  const handleConfirm = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("inventory_location_id", inventoryLocationId);
    formData.append("csv_file", csvFile);

    setLoading(true);
    api.post("/upload-inventory-csv", formData)
      .then((response) => {
        setModal(false);
        setLoading(false);
        let message = "CSV uploaded successfully!"
        if (response?.data?.status_code == 200) {
          message = response.data.data;
          addToast(message, { appearance: "success", autoDismiss: true });
        }
      }).catch((error) => {
        setLoading(false);
        addToast("Something went wrong, please try again!!", { appearance: "error" });
      });
  };

  useEffect(() => {
    setModal(props.isOpen)
  }, [props.isOpen])

  // API call to get the Inventory Locations of the logged-in retailer
  useEffect(() => {
    setLoading(true);
    api
      .get("/inventory-locations")
      .then((response) => {
          const options = response.data.data.map((option) => ({
            label: option.full_name,
            value: option.id,
          }));
          setInventoryLocations(options);
          setLoading(false);
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      {modal &&
        <Modal isOpen={modal} toggle={toggle} onExit={props.onExit}>
          <Form onSubmit={handleConfirm}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">Upload Inventory File</span>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="inventoryLocation">Inventory Location:</Label>
                <Dropdown
                  id="inventoryLocation"
                  onChange={(e) => setInventoryId(e)}
                  options={inventoryLocations}
                  placeholder="Select Inventory Location"
                />
              </FormGroup>
              <FormGroup>
                <Label for="inventoryFile">Inventory CSV File:</Label>
                <div>
                  <input
                    id="inventoryFile"
                    type="file"
                    accept=".csv"
                    onChange={(e) => setCSVFile(e.target.files[0])}
                  />
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>Cancel</Button>
              <Button color="primary" type="submit" disabled={loading}>
                Upload
                {loading && <Spinner size="sm" color="light" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>}
    </>
  )
}