import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Button, Label, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Input, FormGroup } from "reactstrap";
import { api } from "../../services/api";
import { MDBCol, MDBIcon } from "mdbreact";
import Select from "react-select";
import ParentOrderLineItems from "./ParentOrderLineItems";
import ChildOrderLineItems from "./ChildOrderLineItems";

export function CreateChildOrderModal({ toggle, isOpen, loading, handleRefresh }) {
    const { addToast } = useToasts();
    const [isLoading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(isOpen);
    const [orderId, setOrderId] = useState("");

    const [childOrderType, setChildOrderType] = useState("");
    const [childOrderLineItemSource, setChildOrderLineItemSource] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [parentOrderList, setParentOrderList] = useState([]);
    const [paramsFilter, setParamsFilter] = useState({});
    const [paramsVariantFilter, setParamsVariantFilter] = useState({});

    const [parentOrderLineItems, setParentOrderLineItems] = useState([]);
    const [childOrderLineItems, setChildOrderLineItems] = useState([]);

    const [selectedParentOrderLineItem, setSelectedParentOrderLineItem] = useState(null);

    const [selectedLineItems, setSelectedLineItems] = useState([]);
    const [quantityInputValues, setQuantityInputValues] = useState({});

    const [quantityErrors, setQuantityErrors] = useState({});

    // Add a new state for search variant loading
    const [isVariantSearchLoading, setIsVariantSearchLoading] = useState(false);

    // Add a state variable for quantity validation error
    // const [quantityError, setQuantityError] = useState("");

    const [variantData, setVariantData] = useState({
        results: [],
        count: 0,
        next: null,
        previous: null
    });

    const handleSubmitForm = e => {
        e.preventDefault();

        // Validate quantity input values
        const isQuantityValid = selectedLineItems.every(item => item.quantity !== undefined && item.quantity > 0);

        if (!isQuantityValid) {
            // setQuantityError("Please enter a valid quantity greater than 0 for all selected items.");
            addToast("Quantity must be greater than 0.", {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }

        // Clear quantity validation error if validation passes
        // setQuantityError("");

        // Construct the payload based on childOrderLineItemSource
        let payload = {
            parent_order_id: orderId.value,
            parent_order_line_item_id: selectedParentOrderLineItem,
            child_order_type: childOrderType,
            lineitem_source: childOrderLineItemSource
        };
        
        if (childOrderLineItemSource === "manual") {
            payload.product_variant_information = selectedLineItems;
        }

        api.post("order/flaviar/one-to-many/manual-child-order", payload)
            .then(response => {
                setShowModal(false);
                setLoading(false);
                if (response.data.success) {
                    addToast(response?.data?.message, { appearance: "success", autoDismiss: true });
                    // Call the parent component's refresh function
                    handleRefresh();
                } else {
                    let message = "Something went wrong, please try again!!";
                    if (response?.data?.message) message = response.data.message;
                    addToast(message, { appearance: "error" });
                }
            })
            .catch(error => {
                let message = "Something went wrong, please try again!!";
                if (error?.response?.data?.message) {
                    message = error.response.data.message;
                }
                setLoading(false);
                addToast(message, { appearance: "error" });
            });
    };

    useEffect(() => {
        setParentOrderList([{ value: "loading", label: "Loading...", isDisabled: true }]);
        getAllOrderList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsFilter]);

    const getAllOrderList = () => {
        api.get(`order/flaviar/one-to-many/parent-order-search`, {
            params: paramsFilter
        })
            .then(response => response)
            .then(response => {
                if (response.status === 200) {
                    let parentOrders = response.data.results.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                    setParentOrderList(parentOrders);
                }
            })
            .catch(err => {});
    };

    useEffect(() => {
        if (paramsVariantFilter) {
            setIsVariantSearchLoading(true); // Set loading to true when making the request
            api.get(`product/flaviar/one-to-many/variants`, {
                params: paramsVariantFilter
            })
                .then(response => response)
                .then(response => {
                    if (response.status === 200) {
                        setVariantData(response.data);
                    }
                })
                .catch(error => console.log(error))
                .finally(() => {
                    setIsVariantSearchLoading(false); // Set loading to false when the request is completed, regardless of success or failure
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsVariantFilter]);

    const handleSelectedParentOrderLineItem = newSelectedOrders => {
        setSelectedParentOrderLineItem(newSelectedOrders);
    };

    const toggleChildOrderType = option => {
        setChildOrderType(option);

        // Automatically set "Child Order Line Item Source" to "Manual" when "Advent Calendar" is selected
        if (option === "advent") {
            setChildOrderLineItemSource("manual");
        } else {
            // Set the desired value for "Subscription" if it's not already set
            if (childOrderLineItemSource !== "wms_lineup") {
                setChildOrderLineItemSource("");
            }
        }

        const clist = document.getElementsByClassName("checkbox_product");

        // Clear quantity-related state when changing child order type
        setQuantityInputValues({});
        setQuantityErrors({});
        setSelectedLineItems([]);
        for (var i = 0; i < clist.length; ++i) {
            clist[i].checked = false;
        }
    };

    const toggleChildOrderLineItemSource = option => {
        setChildOrderLineItemSource(option);

        // Clear quantity-related state when changing child order line item source
        setQuantityInputValues({});
        setQuantityErrors({});
        setSelectedLineItems([]);
    };

    let timer = null;

    const handeParentOrderSearch = e => {
        clearTimeout(timer);
        timer = setTimeout(function () {
            setParamsFilter({
                ...paramsFilter,
                search: e.target.value
            });
        }, 750);
    };

    useEffect(() => {
        if (orderId) {
            let parent_order_id = orderId?.value;
            api.get(`order/flaviar/one-to-many/parent-child-details?parent_order=${parent_order_id}`)
                .then(response => response)
                .then(response => {
                    if (response?.status === 200) {
                        let parentChildOrderDetails = response?.data?.results;
                        setParentOrderLineItems(parentChildOrderDetails?.[0]?.parent_order_lineitems);
                        setChildOrderLineItems(parentChildOrderDetails?.[0]?.child_order_lineitems);
                    }
                });
        }
    }, [orderId]);

    useEffect(() => {
        setShowModal(isOpen);
        if (isOpen) {
            // Clear form data if set last time
            setChildOrderType("");
            setChildOrderLineItemSource("");
            setSelectedParentOrderLineItem(null);
            setSearchValue("");
            setParentOrderList([]);
            setParamsFilter({});
            setParentOrderLineItems([]);
            setChildOrderLineItems([]);
            setOrderId("");
            setSelectedLineItems([]);
            setQuantityInputValues({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleSearchVariant = e => {
        clearTimeout(timer);
        timer = setTimeout(function () {
            setParamsVariantFilter({
                ...paramsVariantFilter,
                search: e.target.value
            });
        }, 750);
    };

    const handleProductCheckbox = (e, variantId) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            // If checkbox is checked, add a new object to the selectedLineItems array
            setSelectedLineItems(prevSelected => [
                ...prevSelected,
                {
                    // quantity: quantityInputValues[variantId] || "",
                    quantity: parseInt(quantityInputValues[variantId], 10) || 0, // Parse as integer, default to 0 if not a valid number
                    variant_id: variantId
                }
            ]);

            // Clear the quantity error for the checked variant
            setQuantityErrors(prevErrors => {
                const { [variantId]: removedError, ...rest } = prevErrors;
                return rest;
            });

            // To Show Error everytime when user checks the checkbox (Fixed but not used)
            // setQuantityErrors(prevErrors => ({
            //     ...prevErrors,
            //     [variantId]: "Quantity must be greater than 0."
            // }));
        } else {
            // If checkbox is unchecked, remove the object with the corresponding variantId from the selectedLineItems array
            setSelectedLineItems(prevSelected => prevSelected.filter(item => item.variant_id !== variantId));

            // Remove the quantity error for the unchecked variant
            setQuantityErrors(prevErrors => {
                const { [variantId]: removedError, ...rest } = prevErrors;
                return rest;
            });

            // Remove the quantity value for the variant
            setQuantityInputValues(prevValues => {
                const updatedValues = { ...prevValues };
                delete updatedValues[variantId];
                return updatedValues;
            });
        }
    };

    const isDecimal = value => /^\d+$/.test(value);

    const handleQuantityInputChange = (e, variantId) => {
        const newQuantity = e.target.value;
        const newQuantityValue = parseInt(newQuantity, 10) || 0;

        const checking = isDecimal(newQuantity);

        setQuantityInputValues(prevValues => ({
            ...prevValues,
            [variantId]: newQuantity
        }));

        // Update the quantity in the selectedLineItems array if the checkbox is checked
        // setSelectedLineItems(prevSelected => prevSelected.map(item => (item.variant_id === variantId ? { ...item, quantity: newQuantity } : item)));
        setSelectedLineItems(prevSelected =>
            prevSelected.map(item => (item.variant_id === variantId ? { ...item, quantity: parseInt(newQuantity, 10) || 0 } : item))
        );

        // Check for quantity errors and update the state

        if (newQuantityValue <= 0) {
            setQuantityErrors(prevErrors => ({
                ...prevErrors,
                [variantId]: "Quantity must be greater than 0."
            }));
        } else if (!checking) {
            setQuantityErrors(prevErrors => ({
                ...prevErrors,
                [variantId]: "Quantity must be an Integer."
            }));
        } else {
            // Clear the error if no issues
            setQuantityErrors(prevErrors => {
                const { [variantId]: removedError, ...rest } = prevErrors;
                return rest;
            });
        }
    };

    const handleNext = () => {
        if (variantData.next) {
            let results = variantData.results;
            setLoading(true);
            api.get(variantData.next)
                .then(response => {
                    const new_results = results.concat(response.data.results);
                    setVariantData({
                        ...variantData,
                        results: new_results,
                        next: response.data.next,
                        previous: response.data.previous
                    });
                    setLoading(false);
                })
                .catch(error => console.log(error));
        }
    };

    return (
        <>
            {showModal && (
                <div className="form-group mb-3 d-flex flex-column" data-field="name">
                    <Modal style={{ marginTop: "100px", maxWidth: "56.25rem" }} isOpen={showModal} onExit={toggle} toggle={() => toggle()}>
                        <Form onSubmit={handleSubmitForm}>
                            <ModalHeader toggle={toggle} className="bg-primary text-white">
                                <span className="text-white h3">Generate Child Order</span>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Row xs={12} style={{ alignItems: "center" }}>
                                        <Label xs={5} style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                                            Parent Order:
                                        </Label>{" "}
                                        <br />
                                        <Col xs={7} className={"text-left"}>
                                            <Select
                                                onChange={setOrderId}
                                                options={parentOrderList}
                                                onKeyDown={handeParentOrderSearch}
                                                placeholder="Search Parent Order"
                                                value={orderId}
                                            />
                                        </Col>
                                    </Row>

                                    {parentOrderLineItems.length > 0 && (
                                        <Row xs={12} style={{ display: "flex", flexDirection: "column" }}>
                                            <Label xs={12} style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                                                Parent Order Line Item:
                                            </Label>
                                            <ParentOrderLineItems
                                                parentOrdersData={parentOrderLineItems}
                                                selectedOrder={selectedParentOrderLineItem}
                                                onSelectParentOrderLineItem={handleSelectedParentOrderLineItem}
                                            />
                                        </Row>
                                    )}

                                    {childOrderLineItems.length > 0 && (
                                        <Row xs={12} style={{ display: "flex", flexDirection: "column" }}>
                                            <Label xs={12} style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                                                Child Order(s):
                                            </Label>
                                            <ChildOrderLineItems childOrderData={childOrderLineItems} />
                                        </Row>
                                    )}

                                    {orderId && (
                                        <Row xs={12} style={{ alignItems: "center", marginTop: "1rem" }}>
                                            <Label xs={4} style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                                                Child Order Type:
                                            </Label>
                                            <Col xs={8} className="row" style={{ paddingTop: "10px" }}>
                                                <Col>
                                                    <Input
                                                        name="option1"
                                                        type="radio"
                                                        checked={childOrderType === "subscriptions"}
                                                        onChange={() => toggleChildOrderType("subscriptions")}
                                                    />
                                                    <label>Subscriptions</label>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        name="option2"
                                                        type="radio"
                                                        checked={childOrderType === "advent"}
                                                        onChange={() => toggleChildOrderType("advent")}
                                                    />
                                                    <label>Advent Calendar</label>
                                                </Col>
                                            </Col>
                                        </Row>
                                    )}

                                    {orderId && (
                                        <Row xs={12} style={{ alignItems: "center", marginTop: "1rem" }}>
                                            <Label xs={4} style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                                                Child order Line Items Source:
                                            </Label>
                                            <Col xs={8} className="row" style={{ paddingTop: "10px" }}>
                                                <Col>
                                                    <Input
                                                        name="option3"
                                                        type="radio"
                                                        checked={childOrderLineItemSource === "wms_lineup"}
                                                        onChange={() => toggleChildOrderLineItemSource("wms_lineup")}
                                                        disabled={childOrderType === "advent"}
                                                    />
                                                    <label>WMS Lineup</label>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        name="option4"
                                                        type="radio"
                                                        checked={childOrderLineItemSource === "manual"}
                                                        onChange={() => toggleChildOrderLineItemSource("manual")}
                                                    />
                                                    <label>Manual</label>
                                                </Col>
                                            </Col>
                                        </Row>
                                    )}

                                    {childOrderLineItemSource === "manual" && (
                                        <Row className="mt-3" style={{ alignItems: "center" }}>
                                            <Label xs={4} style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                                                Search Line Items:
                                            </Label>
                                            <Col xs={12} className="px-4">
                                                <MDBCol style={{ display: "-webkit-box", padding: "0px" }}>
                                                    <MDBIcon icon="search" className="mr-2 ml-0" />
                                                    <input
                                                        className="form-control form-control-sm"
                                                        onKeyDown={handleSearchVariant}
                                                        type="text"
                                                        placeholder="Search"
                                                        aria-label="Search"
                                                    />
                                                    {/* Additional logic for displaying line item information based on searchValue */}
                                                </MDBCol>
                                                <ul className={"p-2 overflow-auto"} style={{ maxHeight: "300px" }} id={"filter_"}>
                                                    {isVariantSearchLoading && (
                                                        <div className="text-center mt-3 mb-3">
                                                            <div className="spinner-border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {!isVariantSearchLoading && variantData.results.length === 0 && (
                                                        <li className={"text-center"}>
                                                            <span className={"text-sm text-muted"}>No Records Found</span>
                                                        </li>
                                                    )}
                                                    {!isVariantSearchLoading &&
                                                        variantData.results.map(product => (
                                                            <div key={product.variant_id + "_prod_"}>
                                                                <div
                                                                    key={product.variant_id + "_product_"}
                                                                    className="row my-2 d-flex"
                                                                    style={{ width: "40rem" }}>
                                                                    <div className="col-2 pr-0">
                                                                        <Label className={"text-sm"}>
                                                                            <input
                                                                                type="checkbox"
                                                                                id={"_" + product.variant_id}
                                                                                className={"checkbox_product"}
                                                                                value={product.variant_id}
                                                                                data-label={product.product_name}
                                                                                data-image={product.product_image}
                                                                                onChange={e => handleProductCheckbox(e, product.variant_id)}
                                                                                style={{ marginRight: "20px" }}
                                                                            />
                                                                        </Label>
                                                                        {product.product_image ? (
                                                                            <img
                                                                                src={product.product_image}
                                                                                className="img-thumbnail rounded-0"
                                                                                width="50%"
                                                                                alt="variant"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                                                                className="img-thumbnail rounded-0"
                                                                                width="50%"
                                                                                alt="variant"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className="col-10">
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            <a
                                                                                href={window.env.APP_URL + "product/" + product.product_id}
                                                                                target="_blank"
                                                                                rel="noreferrer">
                                                                                <span className="text-wrap">{product.product_name}</span>
                                                                                <span
                                                                                    className="text-right"
                                                                                    style={{ paddingLeft: "10px", color: "black" }}></span>
                                                                            </a>
                                                                            <span style={{ color: "black" }}>
                                                                                <strong>Product ID:</strong> {product.product_id || "-"}
                                                                                <br />
                                                                                <strong>Size/Offer:</strong> {product.size || "N/A"}
                                                                                <br />
                                                                                <strong>SKU:</strong> {product.sku || "-"}
                                                                                <br />
                                                                                <strong>Price:</strong> {product.price || "-"}
                                                                                <br />
                                                                                <strong>Vintage Data:</strong> {product.vintage_data || "N/A"}
                                                                            </span>
                                                                            {selectedLineItems.some(item => item.variant_id === product.variant_id) && (
                                                                                <div className="row my-2" style={{ width: "80%" }}>
                                                                                    <label htmlFor="quantityInput" className="col-2 pr-0 text-sm">
                                                                                        Quantity:
                                                                                    </label>
                                                                                    <div className="col-10">
                                                                                        <input
                                                                                            type="number"
                                                                                            id={`quantityInput_${product.variant_id}`}
                                                                                            className={`form-control form-control-sm`}
                                                                                            value={quantityInputValues[product.variant_id]}
                                                                                            onChange={e => handleQuantityInputChange(e, product.variant_id)}
                                                                                            style={{ appearance: "textfield" }}
                                                                                            min={0}
                                                                                            onWheel={e => e.target.blur()}
                                                                                            onKeyDown={e => {
                                                                                                if (e.key === ".") {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Additional logic for displaying variants based on your structure */}
                                                                {product.variants &&
                                                                    product.variants.map((variant, index) => (
                                                                        <div className={"row sub-product ml-3"} key={index + "_variant_"}>
                                                                            {/* Render variant information here */}
                                                                        </div>
                                                                    ))}

                                                                <div
                                                                    className="row mx-auto"
                                                                    style={{ width: "80%" }}
                                                                    key={`quantityError_${product.variant_id}`}>
                                                                    {quantityErrors[product.variant_id] && (
                                                                        <div className="text-danger">{quantityErrors[product.variant_id]}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}

                                                    {!isVariantSearchLoading && variantData.next && (
                                                        <li className={"text-center"}>
                                                            {!isLoading ? (
                                                                <Button className={"btn btn-sm "} onClick={() => handleNext("parent")}>
                                                                    show more
                                                                </Button>
                                                            ) : (
                                                                <span className={"text-sm text-muted"}>Loading...</span>
                                                            )}
                                                        </li>
                                                    )}
                                                </ul>
                                            </Col>
                                        </Row>
                                    )}
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="mt-0 pt-0">
                                <Button color="secondary" onClick={toggle}>
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    id="save_btn"
                                    disabled={
                                        !orderId.value ||
                                        !selectedParentOrderLineItem ||
                                        !childOrderType ||
                                        !childOrderLineItemSource ||
                                        (childOrderLineItemSource === "manual" && selectedLineItems.length === 0) ||
                                        (childOrderLineItemSource === "manual" &&
                                            selectedLineItems.length > 0 &&
                                            selectedLineItems.some(item => item.quantity === 0)) ||
                                        isLoading
                                    }
                                    onClick={handleSubmitForm}>
                                    Save
                                    {isLoading && <Spinner size="sm" color="light" />}
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </div>
            )}
        </>
    );
}
