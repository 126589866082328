import React from "react";
import { Table } from "reactstrap";
// import ChargebacksTableRow from "./ChargebackTableRow";
import { Sort, handleSort } from "../../utils/commonFunctions";
import UsersTableRow from "./UsersTableRow";
const UsersTable = ({
    users,
    onOptionsSelect,
    sort,
    setSort,
    setSortOrder,
    sortOrder,
    user
}) => {
    return (
        <div className="col px-0">
            <Table className="align-items-center table-flush">
                <thead className="thead-light">
                    <tr>
                        <th scope="col">
                            <Sort onClick={() => handleSort(sort, "first_name", setSortOrder, setSort)} sortingOrder={sortOrder} column="first_name">
                                Full Name
                            </Sort>
                        </th>
                        <th scope="col">
                            <Sort onClick={() => handleSort(sort, "email", setSortOrder, setSort)} sortingOrder={sortOrder} column="email">
                                E-mail
                            </Sort>
                        </th>
                        <th scope="col">
                            <Sort onClick={() => handleSort(sort, "last_login", setSortOrder, setSort)} sortingOrder={sortOrder} column="last_login">
                                Last Login
                            </Sort>
                        </th>
                        <th scope="col">
                            <Sort
                                onClick={() => handleSort(sort, "invitation_sent_at", setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="invitation_sent_at">
                                Invitation Sent At
                            </Sort>
                        </th>
                        <th scope="col">Payment Collected</th>
                        <th scope="col">Referred By</th>
                        <th scope="col">Active</th>
                        <th scope="col" className="pl-3 pr-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map(userRecord => (
                        <UsersTableRow key={user?.id} userRow={userRecord} onOptionsSelect={onOptionsSelect} user={user} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
export default UsersTable;