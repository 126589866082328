import React, { useContext, useEffect, useRef, useState } from "react";
import { Table } from "reactstrap";
import { api } from "services/api";
import { getParams } from "utils/commonFunctions";
import Spinner from "../Spinner";
import axios from "axios";

export const ProductsTable = ({ filters }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [limit, setLimit] = useState(5);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showLessButton, setShowLessButton] = useState(false);
  const [count, setCount] = useState();
  const cancelToken = React.useRef(undefined);
  const intitialDataFetchingCompleted = React.useRef(false);

  useEffect(async () => {
    setIsLoading(true);
    let new_params = getParams(filters);
    if (cancelToken.current !== undefined) {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }
    cancelToken.current = axios.CancelToken.source();
    new_params.limit = limit;
    await api.get("/order/react/top-selling-items/", { params: new_params, cancelToken: cancelToken.current.token })
      .then((response) => {
        let products = [];
        if (response.status === 200 && response?.data) {
          products = response.data?.items.map((item) => {
            return { product: item.line_item__product__name, total: item.total, };
          });
          response.data?.count > 5 && setShowMoreButton(true);
          setCount(response.data?.count);
        }
        setData(products);
        setIsLoading(false);
      })
      .catch((error) => { console.error(error); });

    if (intitialDataFetchingCompleted.current === false) { intitialDataFetchingCompleted.current = true }
  }, [limit, filters]);

  const handleShowMoreButton = (e) => {
    e.preventDefault();
    const newLimit = limit + 5;
    if (newLimit >= count) { setShowMoreButton(false); }
    setLimit(newLimit);
    setShowLessButton(true);
  };

  const handleShowLessButton = (e) => {
    e.preventDefault();
    const newLimit = limit - 5;
    if (newLimit === 5) { setShowLessButton(false); }
    setLimit(newLimit);
    setShowMoreButton(true);
  };

  return (
    <>
      <h2>{"Top Products"}</h2>
      {intitialDataFetchingCompleted.current === false ? (<Spinner />) :
        (<Table id="top-products" responsive>
          <thead className="thead-light"><tr><th scope="col">Product</th><th scope="col">Total</th></tr></thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={`TOP_PRODUCTS_${index}`}>
                <td className="text-ellipsis" key={`PRODUCT_${index}`}>{item.product}</td>
                <td key={`TOTAL_${index}`}>{item.total}</td>
              </tr>
            ))}
            {isLoading && <tr><td colSpan={2}><Spinner /></td></tr>}
          </tbody>
          <tfoot>
            <tr align="center">
              <td colSpan={2}>
                {showLessButton && (<button className="btn btn-default btn-sm" onClick={handleShowLessButton}>{"Show less"}</button>)}
                {showMoreButton && (<button className="btn btn-default btn-sm" onClick={handleShowMoreButton} >{"Show more"}</button>)}
              </td>
            </tr>
          </tfoot>
        </Table>
        )}
    </>
  );
};

export const SalesChannelTable = ({ filters }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [limit, setLimit] = useState(5);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showLessButton, setShowLessButton] = useState(false);
  const [count, setCount] = useState();
  const cancelToken = React.useRef(undefined);
  const intitialDataFetchingCompleted = React.useRef(false);

  useEffect(async () => {
    setIsLoading(true);
    let new_params = getParams(filters);
    if (cancelToken.current !== undefined) {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }
    cancelToken.current = axios.CancelToken.source();
    new_params.limit = limit;
    await api.get("/order/react/top-selling-channels/", { params: new_params, cancelToken: cancelToken.current.token }).then((response) => {
      let channels = [];
      if (response.status === 200 && response?.data) {
        channels = response.data?.channels.map((item) => {
          return { channel: item.channel, orders: item.orders, total: `$${item.value}`, };
        });
        response.data?.count > 5 && setShowMoreButton(true);
        setCount(response.data?.count);
      }
      setData(channels);
      setIsLoading(false);
    })
      .catch((error) => { console.error(error); });

    if (intitialDataFetchingCompleted.current === false) { intitialDataFetchingCompleted.current = true }
  }, [limit, filters]);

  const handleShowMoreButton = (e) => {
    e.preventDefault();
    const newLimit = limit + 5;
    if (newLimit >= count) { setShowMoreButton(false); }
    setLimit(newLimit);
    setShowLessButton(true);
  };

  const handleShowLessButton = (e) => {
    e.preventDefault();
    const newLimit = limit - 5;
    if (newLimit === 5) { setShowLessButton(false); }
    setLimit(newLimit);
    setShowMoreButton(true);
  };

  return (
    <>
      <h2> {"Sales Channel"}</h2>
      {intitialDataFetchingCompleted.current === false ? (<Spinner />) :
        (<Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col" className="pl-3 pr-3"> Channel </th>
              <th scope="col" className="pl-3 pr-3"> Orders </th>
              <th scope="col" className="pl-3 pr-3"> Total </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={`SALES_CHANNEL_${index}`}>
                <td key={`CHANNEL_${index}`}>{item.channel}</td>
                <td key={`ORDERS_${index}`}>{item.orders}</td>
                <td key={`TOTAL_${index}`}>{item.total}</td>
              </tr>
            ))}
            {isLoading && <tr><td colSpan={3}><Spinner /></td></tr>}
          </tbody>
          <tfoot>
            <tr align="center">
              <td colSpan={3}>
                {showLessButton && (<button className="btn btn-default btn-sm" onClick={handleShowLessButton}> {"Show less"} </button>)}
                {showMoreButton && (<button className="btn btn-default btn-sm" onClick={handleShowMoreButton}>{"Show more"}</button>)}
              </td>
            </tr>
          </tfoot>
        </Table>)}
    </>
  );
};

export const RetailerPerformanceWidget = ({ filters }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [limit, setLimit] = useState(5);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showLessButton, setShowLessButton] = useState(false);
  const [count, setCount] = useState();
  const cancelToken = React.useRef(undefined);
  const intitialDataFetchingCompleted = React.useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let new_params = getParams(filters);
      if (cancelToken.current !== undefined) {
        cancelToken.current.cancel("Operation canceled due to new request.");
      }
      cancelToken.current = axios.CancelToken.source();
      new_params.limit = limit;
  
      try {
        const response = await api.get("/order/react/top-retailers/", { params: new_params, cancelToken: cancelToken.current.token });
        let channels = [];
        if (response.status === 200 && response?.data) {
          channels = response.data?.data?.top_retailers?.map((item) => {
            return { retailerName: item.retailer_name, avgFulfillmentDays: item.avg_fulfillment_days, totalOrders: item.total_orders };
          });
          response.data?.data?.count > 5 && setShowMoreButton(true);
          setCount(response.data?.data?.count);
        }
        setData(channels);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
    if (intitialDataFetchingCompleted.current === false) {
      intitialDataFetchingCompleted.current = true;
    }
  }, [limit, filters]);

  const handleShowMoreButton = (e) => {
    e.preventDefault();
    const newLimit = limit + 5;
    if (newLimit >= count) { setShowMoreButton(false); }
    setLimit(newLimit);
    setShowLessButton(true);
  };

  const handleShowLessButton = (e) => {
    e.preventDefault();
    const newLimit = limit - 5;
    if (newLimit === 5) { setShowLessButton(false); }
    setLimit(newLimit);
    setShowMoreButton(true);
  };

  return (
    <>
      <h2> {"Retailer Performance"}</h2>
      {intitialDataFetchingCompleted.current === false ? (<Spinner />) :
        (<Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col" className="pl-3 pr-3">Retailer</th>
              <th scope="col" className="pl-3 pr-3">Number of Orders</th>
              <th scope="col" className="pl-3 pr-3">Avg. Fulfillment Days</th>
            </tr>
          </thead>
          <tbody> 
            {data?.map((item, index) => (
              <tr key={`RETAILER_${index}`}>
                <td key={`RETAILER_NAME_${index}`}>{item.retailerName}</td>
                <td key={`TOTAL_ORDERS_${index}`}>{item.totalOrders}</td>
                <td key={`FULFILLMENT_DAYS_${index}`}>{item.avgFulfillmentDays}</td>
              </tr>
            ))}
            {isLoading && <tr><td colSpan={3}><Spinner /></td></tr>}
          </tbody>
          <tfoot>
            <tr align="center">
              <td colSpan={3}>
                {showLessButton && (<button className="btn btn-default btn-sm" onClick={handleShowLessButton}> {"Show less"} </button>)}
                {showMoreButton && (<button className="btn btn-default btn-sm" onClick={handleShowMoreButton}>{"Show more"}</button>)}
              </td>
            </tr>
          </tfoot>
        </Table>)}
    </>
  );
};
