const State_listoptions = [
  { value: "__all__", code: "__all__", label: "Select All" },
  { value: "Alabama", code: "AL", label: "Alabama" },
  { value: "Alaska", code: "AK", label: "Alaska" },
  { value: "Arizona", code: "AZ", label: "Arizona" },
  { value: "Arkansas", code: "AR", label: "Arkansas" },
  { value: "California", code: "CA", label: "California" },
  { value: "Colorado", code: "CO", label: "Colorado" },
  { value: "Connecticut", code: "CT", label: "Connecticut" },
  { value: "Delaware", code: "DE", label: "Delaware" },
  { value: "District Of Columbia", code: "DC", label: "District Of Columbia" },
  { value: "Florida", code: "FL", label: "Florida" },
  { value: "Georgia", code: "GA", label: "Georgia" },
  { value: "Hawaii", code: "HI", label: "Hawaii" },
  { value: "Idaho", code: "ID", label: "Idaho" },
  { value: "Illinois", code: "IL", label: "Illinois" },
  { value: "Indiana", code: "IN", label: "Indiana" },
  { value: "Iowa", code: "IA", label: "Iowa" },
  { value: "Kansas", code: "KS", label: "Kansas" },
  { value: "Kentucky", code: "KY", label: "Kentucky" },
  { value: "Louisiana", code: "LA", label: "Louisiana" },
  { value: "Maine", code: "ME", label: "Maine" },
  { value: "Maryland", code: "Maryland", label: "Maryland" },
  { value: "Massachusetts", code: "MA", label: "Massachusetts" },
  { value: "Michigan", code: "MI", label: "Michigan" },
  { value: "Minnesota", code: "MN", label: "Minnesota" },
  { value: "Mississippi", code: "MS", label: "Mississippi" },
  { value: "Missouri", code: "MO", label: "Missouri" },
  { value: "Montana", code: "MT", label: "Montana" },
  { value: "Nebraska", code: "NE", label: "Nebraska" },
  { value: "Nevada", code: "NV", label: "Nevada" },
  { value: "New Hampshire", code: "NH", label: "New Hampshire" },
  { value: "New Jersey", code: "NJ", label: "New Jersey" },
  { value: "New Mexico", code: "NM", label: "New Mexico" },
  { value: "New York", code: "NY", label: "New York" },
  { value: "North Carolina", code: "NC", label: "North Carolina" },
  { value: "North Dakota", code: "ND", label: "North Dakota" },
  { value: "Ohio", code: "OH", label: "Ohio" },
  { value: "Oklahoma", code: "OK", label: "Oklahoma" },
  { value: "Oregon", code: "OR", label: "Oregon" },
  { value: "Pennsylvania", code: "PA", label: "Pennsylvania" },
  { value: "Rhode Island", code: "RI", label: "Rhode Island" },
  { value: "South Carolina", code: "SC", label: "South Carolina" },
  { value: "South Dakota", code: "SD", label: "South Dakota" },
  { value: "Tennessee", code: "TN", label: "Tennessee" },
  { value: "Texas", code: "TX", label: "Texas" },
  { value: "Utah", code: "UT", label: "Utah" },
  { value: "Vermont", code: "VT", label: "Vermont" },
  { value: "Virginia", code: "VA", label: "Virginia" },
  { value: "Washington", code: "WA", label: "Washington" },
  { value: "West Virginia", code: "WV", label: "West Virginia" },
  { value: "Wisconsin", code: "WI", label: "Wisconsin" },
  { value: "Wyoming", code: "WY", label: "Wyoming" },
];

const WestCoastStateOptions = [
  { value: "Washington", code: "WA", label: "Washington" },
  { value: "Oregon", code: "OR", label: "Oregon" },
  { value: "California", code: "CA", label: "California" },
  { value: "Nevada", code: "NV", label: "Nevada" },
  { value: "Idaho", code: "ID", label: "Idaho" },
  { value: "Arizona", code: "AZ", label: "Arizona" },
  { value: "Montana", code: "MT", label: "Montana" },
  { value: "Wyoming", code: "WY", label: "Wyoming" },
  { value: "Colorado", code: "CO", label: "Colorado" },
  { value: "New Mexico", code: "NM", label: "New Mexico" },
  { value: "North Dakota", code: "ND", label: "North Dakota" },
  { value: "Nebraska", code: "NE", label: "Nebraska" },
  { value: "Kansas", code: "KS", label: "Kansas" },
  { value: "Oklahoma", code: "OK", label: "Oklahoma" },
  { value: "Texas", code: "TX", label: "Texas" },
  { value: "Minnesota", code: "MN", label: "Minnesota" },
  { value: "Iowa", code: "IA", label: "Iowa" },
  { value: "Missouri", code: "MO", label: "Missouri" },
  { value: "Louisiana", code: "LA", label: "Louisiana" }
]

const EastCoastStateOptions = [
  { value: "Wisconsin", code: "WI", label: "Wisconsin" },
  { value: "Indiana", code: "IN", label: "Indiana" },
  { value: "Kentucky", code: "KY", label: "Kentucky" },
  { value: "Tennessee", code: "TN", label: "Tennessee" },
  { value: "Georgia", code: "GA", label: "Georgia" },
  { value: "Florida", code: "FL", label: "Florida" },
  { value: "South Carolina", code: "SC", label: "South Carolina" },
  { value: "North Carolina", code: "NC", label: "North Carolina" },
  { value: "Virginia", code: "VA", label: "Virginia" },
  { value: "West Virginia", code: "WV", label: "West Virginia" },
  { value: "Ohio", code: "OH", label: "Ohio" },
  { value: "Pennsylvania", code: "PA", label: "Pennsylvania" },
  { value: "District Of Columbia", code: "DC", label: "District Of Columbia" },
  { value: "Maryland", code: "Maryland", label: "Maryland" },
  { value: "Delaware", code: "DE", label: "Delaware" },
  { value: "New Jersey", code: "NJ", label: "New Jersey" },
  { value: "Connecticut", code: "CT", label: "Connecticut" },
  { value: "Rhode Island", code: "RI", label: "Rhode Island" },
  { value: "Vermont", code: "VT", label: "Vermont" },
  { value: "New Hampshire", code: "NH", label: "New Hampshire" },
  { value: "Maine", code: "ME", label: "Maine" },
  { value: "New York", code: "NY", label: "New York" }
]

const GetStateObjectFromStateCode = (stateCode) => {
  let stateObject = State_listoptions.filter((stateObject) => stateObject.code.toLocaleLowerCase() === stateCode?.toLocaleLowerCase())[0];
  return stateObject ? stateObject : null;
}

const GetStateObjectFromStateValue = (stateName) => {
  let stateObject = State_listoptions.filter((stateObject) => stateObject.value.toLocaleLowerCase() === stateName?.toLocaleLowerCase())[0]
  return stateObject ? stateObject : null;
}


export { GetStateObjectFromStateValue, GetStateObjectFromStateCode, State_listoptions, WestCoastStateOptions, EastCoastStateOptions }
