import React, { useState, useEffect, useContext } from "react";
import { api } from "../../services/api";
import { useToasts } from "react-toast-notifications";
import {
  Modal,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { ComponentContext } from "../../contexts/ComponentContext";
import { getParams } from "utils/commonFunctions";

export const ChargebackStatusModal = ({
  isOpen,
  onSubmitURL,
  orders,
  onExit,
  onComplete,
  isFromDiscountCode,
  selectAllShippingChargebacksInThisView,
  filter,
  shippingChargebacksCount,
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const [billingStatus, setBillingStatus] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceRef, setInvoiceRef] = useState("");
  const { addToast } = useToasts();
  const { addComponent } = useContext(ComponentContext);
  
  const toggle = () => {
    setShowModal(!showModal);
  };
  
  useEffect(() => {
    setShowModal(isOpen);
    if (isOpen && orders?.length) {
      setBillingStatus(orders.length === 1 ? orders[0].billing_status : "");
      setInvoiceNumber(orders.length === 1 ? orders[0].invoice_ref : "");
      setInvoiceRef(orders.length === 1 ? orders[0].invoice_url : "");
    } 
  }, [isOpen]);

  /**
   * On click of submit call the API to update chargeback order status
   * @param {*} e
   * @returns
   */
  const handleUpdateStatus = (e) => {
    e.preventDefault();

    let orderIds = [];

    if (isFromDiscountCode) {
      orderIds = orders.map((order) => +order?.id);
    } else {
      orderIds = orders.map((order) => order.order_id);
    }

    const data = {
      order_ids: orderIds,
      billing_status: billingStatus,
      invoice_url: invoiceRef || "",
      invoice_ref: invoiceNumber || "",
    };

    // pass shipping-chargebacks page applied filter data, to
    // handleUpdateStatus api to process all records visible to user
    // if(selectAllShippingChargebacksInThisView) {
    if (true) {
      if (isFromDiscountCode) {
        let discountCodeChargebacksPageParams = getParams(filter);
        data.select_all_discount_code_chargebacks_in_view =
          selectAllShippingChargebacksInThisView;
        data.params = discountCodeChargebacksPageParams;
        data.discount_code_chargebacks_count_on_ui = shippingChargebacksCount;
      } else {
        let shippingChargebacksPageParams = getParams(filter);
        data.select_all_shipping_chargebacks_in_view =
          selectAllShippingChargebacksInThisView;
        data.params = shippingChargebacksPageParams;
        data.shipping_chargebacks_count_on_ui = shippingChargebacksCount;
      }
    }

    if (billingStatus === "VOID") {
      data.invoice_url = "";
      data.invoice_ref = "";
    }

    setLoading(true);
    api
      .put(`${onSubmitURL}`, data)
      .then(() => {
        addToast("Discount Code Chargeback Status Updated!", {
          appearance: "success",
          autoDismiss: true,
        });
        toggle();
        setLoading(false);
        addComponent({ statusChanged: true });
        if (onComplete) {
          onComplete();
        }
      })
      .catch((error) =>
        addToast(error.message, { appearance: "error", autoDismiss: true })
      );
  };

  return (
    <>
      {showModal && (
        <Modal size="lg" isOpen={showModal} toggle={toggle} onExit={onExit}>
          <Form onSubmit={handleUpdateStatus}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">Update Chargeback Status</span>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="billingStatus">Billing Status *</Label>
                <Input
                  id="billingStatus"
                  placeholder="Select Status"
                  type="select"
                  defaultValue={billingStatus}
                  onChange={(e) => setBillingStatus(e.target.value)}
                  required
                >
                  <option value="">------</option>
                  <option>PENDING</option>
                  <option>BILLED</option>
                  <option>PAID</option>
                  <option>PAST_DUE</option>
                  <option>VOID</option>
                </Input>
              </FormGroup>
              {billingStatus != "VOID" ? (
                <FormGroup>
                  <Label for="invoiceNumber">Invoice Number</Label>
                  <Input
                    id="invoiceNumber"
                    type="text"
                    defaultValue={invoiceNumber}
                    placeholder="Invoice Number"
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                  />
                </FormGroup>
              ) : undefined}
              {billingStatus != "VOID" ? (
                <FormGroup>
                  <Label for="invoiceRef">Invoice URL</Label>
                  <Input
                    id="invoiceUrl"
                    type="text"
                    defaultValue={invoiceRef}
                    placeholder="Invoice URL"
                    onChange={(e) => setInvoiceRef(e.target.value)}
                  />
                </FormGroup>
              ) : undefined}
              {selectAllShippingChargebacksInThisView && (
                <>
                  <h5 className="text-danger fw-500">
                    This action will affect {shippingChargebacksCount} discount code
                    chargebacks in current view
                  </h5>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button color="primary" type="submit" disabled={loading}>
                Submit
                {loading && <Spinner size="sm" color="light" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </>
  );
};
