import React from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { BiRestaurant, BiBeer } from "react-icons/bi";
import "./inventoryLocation.css"

const InventoryLocationCard = ({ cardOnClick, location }) => {
    return (
        <Card onClick={() => cardOnClick(location)} className="card-stats mb-xl-2 store-locator-card">
            <CardBody >
                <Row>
                    <Col className="col-2">
                        {location.category == "bar" ? <BiBeer size={40} /> : <BiRestaurant size={40} />}
                    </Col>
                    <Col className="col-10">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">{location?.full_name}</CardTitle>
                        <p className="m-0">{location?.ship_from_address1 + (location?.ship_from_address2 != null ? ", " + location?.ship_from_address2 : "")}</p>
                    </Col>
                </Row>
            </CardBody>
        </Card>)
}

export default InventoryLocationCard