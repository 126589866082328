import React, { useEffect, useState } from "react";
import { Button, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, UncontrolledDropdown } from "reactstrap";
import "../../../utils/commonStyles.css";

const StagingPrice = ({ color = 'white', onSubmit, valueMin, valueMax, refMaxDay, refMinDay }) => {

  return (
    <UncontrolledDropdown size={"sm"}>
      <DropdownToggle
        color={color}
        className={(color === 'white') ? "button-font-size text-primary p-10px" : "button-font-size p-10px"}
        style={{ fontSize: '.8rem' }}
        caret>
        Price
      </DropdownToggle>
      <DropdownMenu persist={true}>

        <Form className={'p-2'} onSubmit={onSubmit}>

          <h4>Price</h4>
          <hr className={'m-0 mb-2'} />
          <FormGroup className={'m-0 mb-1'}>
            <Label className={"text-sm"} >
              Min
            </Label>
            <Input type={"number"}
              bsSize={'sm'}
              placeholder={"Min"}
              data-label={"Min"}
              onChange={(e) => refMinDay.current = e.target.value}
              ref={refMinDay}
              defaultValue={valueMin} />
          </FormGroup>
          <FormGroup className={'m-0 mb-1'}>
            <Label className={"text-sm"}>
              Max
            </Label>
            <Input type={"number"}
              bsSize={'sm'}
              placeholder={"Max"}
              data-label={"Max"}
              onChange={(e) => refMaxDay.current = e.target.value}
              ref={refMaxDay}
              defaultValue={valueMax} />
          </FormGroup>
          <FormGroup className={'m-0 mt-3 text-right'}>
            <Button size={'sm'}>Cancel</Button>
            <Button type={'submit'} size={'sm'} color={'primary'} >Apply</Button>
          </FormGroup>
        </Form>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default StagingPrice;