import { useToasts } from "react-toast-notifications";
import {
    Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, DropdownMenu, UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import Select from "react-select";
import { ComponentContext } from "../../contexts/ComponentContext";
import { AuthContext } from "../../contexts/AuthContext";



export const FulfillmentStatus = (props) => {
    let currentValue = props.children || 'OPEN';

    const [value, setValue] = useState('');
    const [statusColor, setStatusColor] = useState('');

    const { addToast } = useToasts();

    useEffect(() => {
        setValue(currentValue);
    }, []);

    useEffect(() => {
        let color = '';
        switch(value){
            case "Delivered":
                color = "border-success text-success"
                break;
            case "DASHER_PICKED_UP":
                color = "border-danger text-danger"
                break;
            case "DELIVERY_CANCELLED":
                color = "border-danger text-danger"
                break;
            case "DL":
                color = "border-warning text-danger"
                break;
            case "In Transit":
                color = "border-danger text-danger"
                break;
            case "Order Being Packed":
                color = "border-primary text-primary"
                break;
            case "Voided Information":
                color = "border-primary text-primary"
                break;
            case "No Status Yet":
                color = "border-primary text-primary"
                break;
            case "Shipment Issue":
                color = "border-primary text-primary"
                break;
            case "confirmed":
                color = "border-primary text-primary"
                break;
        }
        setStatusColor(color);
    }, [value])

    /**
     * Main Handler function for Fulfillment Status
     * @param {*} e 
     */
    const handleStatusFulfillment = (e) => {
        const data = {
            tracking_status: e.target.value,
            fulfillment: props.fulfillment
        }

        api.put("fulfillment/trackingstatus/update/", data)
            .then(response => {
                const { tracking_status } = response.data;
                setValue(tracking_status);
                addToast('Saved Successfully', { appearance: 'success', autoDismiss: true });
            }).catch(error => addToast(error.message, { appearance: 'error' }));
    }

    const { user } = useContext(AuthContext);

    return (
        <>
            {user && user.isAdmin ?
                <UncontrolledDropdown size={"lg"} block>
                    <DropdownToggle
                        color={"white"}
                        className={`text-primary text-left border-primary px-2 py-1 ${statusColor}`}
                        style={{ width: "145px", overflow: 'hidden' }}
                        caret>
                        <span className={"d-inline-block "} style={{ width: "114px" }} >
                            {value === "ACTION_REQUIRED" ? "PENDING_CONFIRMATION" : value}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu style={{ height: '300px', overflow: 'scroll' }} positionFixed={true}>
                        {props.data?.map((status, index) =>
                            <DropdownItem key={index}
                                value={status.id}
                                onClick={(e) => handleStatusFulfillment(e)}
                            >{status.name}
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>
                :
                <span className={statusColor}>{props.children}</span>
            }
        </>
    )
}