import moment from "moment";
import { api } from "services/api";

/**
 * Clear all params from URL
 */
export const removeAllURLParams = () => {
    window.history.pushState({}, "", `${window.location.pathname}`);
};

/**
 * Add a parameter to the URL
 * @param {window} window : Window object of browser environment
 * @param {string} key : param name/key
 * @param {string, array} value : param value
 */
export const addURLParam = (window, key, value) => {
    let url = new URL(window.location);
    let search = url.search;
    let params = new URLSearchParams(search);

    // Check if key is one of the minMax Filters
    if (key === "search" || minMaxFilterurlParamsKeys.includes(key) || key === "startDate" || key === "endDate" || key === "chargeBackStartDate" || key === "ChargeBackEndDate" || key === "dataSubmittedAtStart" || key === "dataSubmittedAtEnd") {
        if (value !== null) {
            // for param key is "search", if value is empty string remove entire param 
            if (!value) {
                params.delete(key);
            } else {
                params.set(`${key}`, value);
            }
            window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
        }
        if (value === null) {
            removeURLParam(window, key);
        }
    } else {
        // for other params whose key is not "search"
        let valuesOfParamKey = params.get(key);
        let valuesOfKeyArray = [];

        // If param key already exist in URL => append
        if (valuesOfParamKey != null) {
            valuesOfKeyArray = valuesOfParamKey.split(",");
            // Add URL only if value does not exists in url for respective key
            if(!valuesOfKeyArray.includes(value)) {
                valuesOfKeyArray.push(value);
                params.set(`${key}`, valuesOfKeyArray.join(","));
                window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
            }
        } else {
            // If param key does not exist in URL => add
            params.set(`${key}`, value);
            window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
        }
    }
};

/**
 * Remove the value from the parameter
 * @param {window} window : Window object of browser environment
 * @param {string} key : param name/key
 * @param {string, array} value : param value
 */
export const removeURLParam = (window, key, value) => {
    let url = new URL(window.location);
    let search = url.search;
    // If search is null, return
    if(!search) return
    let params = new URLSearchParams(search);
    // if the value is not passed, remove the parameter entirely.
    if (value) {
        // split values by "," ==> return array ==> remove value passed to function from it
        let currentValuesForGivenParamKey = params.get(key).split(",");
        let newValuesArrayForGivenParamKey = currentValuesForGivenParamKey.filter((key) => key != value);

        // If a value is the only value for a given key, then remove entire parameter
        if (newValuesArrayForGivenParamKey.length === 0) {
            removeURLParam(window, key);
        } else {
            params.set(key, newValuesArrayForGivenParamKey.join(","));
            window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
        }
    } else {
        params.delete(key);
        window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
    }
};

/**
 * Set filter state based on passed URLSearchParams
 * @param {urlSearchParams object} urlSearchParams 
 * @param {filter state} filter 
 * @param {setFilter state} setFilter 
 */
export const processURLSearchParams = async (urlSearchParams, filter, setFilter) => {

    // For dashboard page : on load add startDate and endDate in url
    if (window.location.href.includes("/dashboard")) {
        addURLParam(window, "startDate", moment(filter.startDate))
        addURLParam(window, "endDate", moment(filter.endDate))
    }

    let updatedFilter = {
        ...filter
    };

    let getLabelsRequestPayload = {};

    // Create request payload for fetching labels
    for (const [key, value] of urlSearchParams.entries()) {
        getLabelsRequestPayload[`${key}`] = [];
        value.split(",").forEach((item) => {
            getLabelsRequestPayload[`${key}`].push(item);
        });
    }

    // Fetch labels for url params
    let labels = [];
    await api.post("/labels", getLabelsRequestPayload)
        .then((response) => {
            labels = response.data.data;
        })
        .catch((error) => {
            console.error(error);
        })


    if (urlSearchParams !== "") {
        for (const [key, value] of urlSearchParams.entries()) {
            // Check if key is "search" or one of the minMaxFilter url params key
            if (key === "search" || minMaxFilterurlParamsKeys.includes(key)) {
                updatedFilter[`${key}`] = value;
            } else if (key === "endDate" || key === "startDate" || key ==="chargeBackStartDate" || key === "ChargeBackEndDate" 
                    || key === "dataSubmittedAtStart" || key === "dataSubmittedAtEnd" || key === "orderStartDate" || key === "orderEndDate") {
                if (key === "endDate") {
                    updatedFilter["endDate"] = moment(value);
                };
                if (key === "startDate") {
                    updatedFilter["startDate"] = moment(value);
                };
                if (key === "ChargeBackEndDate") {
                    updatedFilter["ChargeBackEndDate"] = moment(value);
                };
                if (key === "chargeBackStartDate") {
                    updatedFilter["chargeBackStartDate"] = moment(value);
                };
                // Manual Child Order Page Date Filter
                if (key === "dataSubmittedAtStart") {
                    updatedFilter["dataSubmittedAtStart"] = moment(value);
                };
                if (key === "dataSubmittedAtEnd") {
                    updatedFilter["dataSubmittedAtEnd"] = moment(value);
                };
                // Monday Task : 6530680655 - Payouts(Billing) Page URL Params
                // Payout Order Start and End Date
                if (key === "orderStartDate") {
                    updatedFilter["orderStartDate"] = moment(value);
                };
                if (key === "orderEndDate") {
                    updatedFilter["orderEndDate"] = moment(value);
                };
            }
            else {
                value.split(",").forEach((item) => {
                    // If label is not found in API display id as label
                    let newLabel = labels[key] && labels[key][item] ? labels[key][item] : item;
                    updatedFilter[`${key}`].push({ value: item, label: newLabel });
                });
            }
        }

        updatedFilter.urlParamsProcessed = true;
        setFilter({
            ...updatedFilter
        });
    }
};


const minMaxFilterurlParamsKeys = [
    "StatusDayMax",
    "StatusDayMin",
    "bottleMin",
    "bottleMax",
    "OrderDayMin",
    "OrderDayMax",
]