import React from 'react'
import { Label } from 'reactstrap'
import './style.css'

const CLabel = (props) => {
    return (
        <Label className={props.required ? "custom-label-required" : "custom-label"}>
            {props.name}
        </Label>
    )
}

export default CLabel;