import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { api } from "services/api";

/**
 * React customHook for fetching data effeciently
 * @param {*} url 
 * @param {*} initialData 
 * @param {*} getParams: getParams() 
 * @returns 
 */
const useFetch = ({ url, initialData, sort, page }) => {
    const [data, setData] = useState(initialData);
    const [errors, setErrors] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const { addToast } = useToasts();

    // calling refetch will force a rerender on the hook
    const [shouldRefetch, setshouldRefetch] = useState(false);
    const refetch = () => { setshouldRefetch(!shouldRefetch); };

    useEffect(() => {
        if (errors) { addToast(errors, { appearance: "error" }); }
    }, [errors])

    const getParams = () => {
        let params = {};
        if (page) params.page = page;
        return params;
    };

    useEffect(() => {
        setisLoading(true)
        api.get(url, { params: getParams() })
            .then((response) => setData(response.data))
            .catch((error) => {
                setErrors(error.message);
            });
        setisLoading(false);
    }, [url, shouldRefetch]);

    return [data, errors, isLoading, refetch];
};
export default useFetch;