import React, { useContext, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { Age, CancelOrderModal, Fulfillment, OrderStatus, OrderStatusModalForm, SplitOrderModal, SKUMapppingModal } from "components/Order";
import { sha256 } from 'js-sha256';
import Moment from "react-moment";

import {
  Button,
  ButtonDropdown,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Pagination,
  PaginationItem,
  PaginationLink,
  PopoverBody,
  PopoverHeader,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledPopover,
  UncontrolledTooltip,
  Spinner, Badge,
} from 'reactstrap'

import BadgePillFilter from "components/Filters/Badge";

import { api } from "services/api";
import { AuthContext } from "contexts/AuthContext";

import DateRange from "../components/Filters/DateRange";
import Filter from "../components/Filters/Filter";
import MoreFilters from "../components/Filters/MoreFilters";
import StatusAge from "../components/Filters/StatusAge";
import fileDownload from "js-file-download";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import AssigneeSelect from "../components/Select/Assignee";
import AssigneeModalForm from "../components/Modal/Assignee";
import { ComponentContext } from "../contexts/ComponentContext";
import "../assets/css/order.css";
import OrderAge from "../components/Filters/OrderAge";
import { useLocation } from "react-router-dom";
import { UpdateOrderModal } from "components/Order/updateOrderModal";
import EAT_OOS from "../components/Select/DatePicker";
import { ComposeEmailForm } from "../components/Email/ComposeEmail";
import { AiOutlineSplitCells, BsFillSignpostSplitFill } from 'react-icons/fa';
import {GiSplitArrows} from "@react-icons/all-files/gi/GiSplitArrows"
import { ManualFulfilmentForm } from "../components/Fulfillment/index";
import { DoordashModal } from "../components/Doordash/create-delivery";
import { Reship } from "../components/Reship/Reship";
import { OrderRestockingModal } from "../components/OrderRestocking/order-restocking";
import { ProductsSearchForm } from "components/Retailer/OverrideRetailerModal";
import axios from "axios";
import { addURLParam, processURLSearchParams, removeAllURLParams, removeURLParam } from "../utils/urls";
import { RetailerEmail } from "components/Email/RetailerEmail";
import BulkNotes from "components/Modal/bulkNotes";
import useFilter from "customHooks/useFilter";
import { OrderPaymentStatus } from "components/OrderPaymentStatus";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { Sort, handleSort } from "../utils/commonFunctions";
import { mobileScreenThresholdValue } from "utils/constants";
import BulkETA from "components/Modal/BulkETA";
import "../utils/commonStyles.css";

const OrderPageListing = (props) => {
  let product_name = useQuery().get("productName");
  const [orders, setOrders] = useState([]);
  const [cancelOrder, setCancelOrder] = useState([]);
  const [splitOrder, setSplitOrder] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    previous: null,
    next: null,
    pages: () => {
      return [];
    },
  });

  const [sort, setSort] = useState("-id");
  const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    email: false,
    assignee: false,
    orderStatus: false,
    cancelOrder: false,
    splitOrder: false,
    fulfillement: false,
    doordash: false,
    reship: false,
    orderRestocking: false,
    overrideRetailer: false,
    skumapping: false,
    retailerEmail: false,
    bulkNotes: false,
    bulkETA:false
  
  });
  
  const [status, setStatus] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [reship_status, setReshipStatus] = useState([]);
  const [selected, setSelected] = useState([]);
  const [params, setParams] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [clearall, setClearAll] = useState(false);
  const [updateOrder, setUpdateOrder] = useState([]);
  const [orderFulfillement, setOrderFulfillement] = useState([]);
  const [ordersForEmail, setOrdersForEmail] = useState([]);
  const [orderForDoordash, setOrderForDoordash] = useState(null);
  const [ordersForReship, setOrdersForReship] = useState(null);
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [orderIdForRestockingFee, setOrderIdForRestockingFee] = useState(null);
  const [orderForRestockingFee,  setOrderForRestockingFee] = useState(null);
  const [variant, setVariant] = useState({});
  const [ordersForRetailerEmail, setOrderForRetailerEmail] = useState([]);
  const [toEmail, setToEmail] = useState("");
  const [financialStatusLock, setfinancialStatusLock] = useState(true);
  const [notesLoading, setNotesLoading] = useState(false);
  const [orderManagedBy, setOrderManagedBy] = useState(false); // 6193203826 - Block order changes on WMS Orders

  // Check weather screen size is mobile or desktop
  const isMobile = useMobileDetection(mobileScreenThresholdValue);
  
  const [filter, setFilter] = useFilter({});
  const orderCountData = [...new Array(90)].map((item, index) => ({
    id: index + 1,
    name: index + 1,
  }));

  const [productsSearchFormData, setproductsSearchFormData] = useState({
    inventory_location: "",
    state: [],
    channel:[],
    variant: [],
    retailer_override_through_order_page: true,
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let discount = useQuery().get("discount_codes");

  let date_start = useQuery().get("date_start");

  let date_end = useQuery().get("date_end");

  const convertSHA256 = (inputText) => {
    // To hash a passed string using SHA-256 algorithm, BCD- 2699 hash email
    if (inputText) {
      return sha256(inputText);
    }
    return inputText;
  };

  const handleCheckbox = (e) => {
    let checkbox = [...new Set(selected)];
    if (e.target.checked) {
      const orderRecord = orders.find(order => order.id == e.target.value);

      if (orderRecord) {
        checkbox.push({
          id: e.target.value,
          shopify_id: e.target.name,
          email: orderRecord.email,
          title: e.target.name,
          note: orderRecord.internal_note,
          wms_managed: orderRecord.order_managed_by
        });
      }
    } else {
      // !== strict inequality operator checking the order id available or not, so that's why we use != inequality operator
      checkbox = checkbox.filter((item) => item.id != e.target.value);
    }

    {/* 6193203826 - Block order changes on WMS Orders*/}
    const managedItems = checkbox.filter(item => item.wms_managed);
    managedItems.length > 0 ? setOrderManagedBy(true) : setOrderManagedBy(false);

    setSelected(checkbox);
  };

  const moreFiltersOnDesktop = {
    status : { hide : true },
    fulfillment : { hide : true },
    brand : { hide : true },
    product : { hide : true },
    dateRange : { hide : true },
    productTypes : { hide : true },
  };

  useEffect(() => {
    api
      .get("/order/order-status/")
      .then((response) => {
        setStatus(response.data.results);
      })
      .catch((error) => console.log(error));

    api
      .get("/order/assignee-states/")
      .then((response) => {
        setAssignee(response.data.results);
      })
      .catch((error) => console.log(error));
  }, []);

  const getParams = () => {
    let new_params = {
      page,
      ordering: sort,
    };

    if (filter.startDate && filter.endDate) {
      new_params.date_start = filter.startDate.format("Y-M-D");
      new_params.date_end = filter.endDate.format("Y-M-D");
      setClearAll(true);
    }

    if (date_start && date_end) {
      new_params.date_start = date_start;
      new_params.date_end = date_end;
      setClearAll(true);
    }

    if (filter.brands.length > 0) {
      new_params.brand = [];
      filter.brands.map((item) => new_params.brand.push(item.value));
      setClearAll(true);
    }

    if (filter.products.length > 0) {
      new_params.product = [];
      filter.products.map((item) => new_params.product.push(item.value));
      setClearAll(true);
    }
    
    if (filter.productTypes?.length > 0) {
      new_params.product_types = filter?.productTypes.map((item) => item.value);
      setClearAll(true);
    }

    if (filter?.productCategory?.length > 0) {
      new_params.productCategory = filter?.productCategory.map((item) => item?.value);
      setClearAll(true);
    }

    if (filter.states.length > 0) {
      new_params.state = [];
      filter.states.map((item) => new_params.state.push(item.value));
      setClearAll(true);
    }

    if (filter.search?.length > 0) {
      new_params.search = filter.search;
      setClearAll(true);
    }

    if (filter?.fulfillments.length > 0) {
      new_params.fulfillment = [];
      filter.fulfillments.map((item) =>
        new_params.fulfillment.push(item.value)
      );
      setClearAll(true);
    }

    if (filter?.reshipStatuses?.length > 0) {
      new_params.reship_status = [];
      filter?.reshipStatuses?.map((item) =>
        new_params?.reship_status.push(item.value)
      );
      setClearAll(true);
    }

    if (filter?.statuses.length > 0) {
      new_params.order_status = [];
      filter.statuses.map((item) => new_params.order_status.push(item.value));
      setClearAll(true);
    }

    if (filter?.financialStatus.length > 0) {
      new_params.financial_status = [];
      filter.financialStatus.map((item) =>
        new_params.financial_status.push(item.value)
      );
      setClearAll(true);
    }

    if (filter?.assignees.length > 0) {
      new_params.assignee = [];
      filter.assignees.map((item) => new_params.assignee.push(item.value));
      setClearAll(true);
    }

    if (filter?.risk_level.length > 0) {
      new_params.risk_level = [];
      filter.risk_level.map((item) => new_params.risk_level.push(item.value));
      setClearAll(true);
    }

    if (filter?.utms.length > 0) {
      new_params.utm_sources = [];
      filter.utms.map((item) => new_params.utm_sources.push(item.value));
      setClearAll(true);
    }

    if (filter?.utmsMedium.length > 0) {
      new_params.utm_mediums = [];
      filter.utmsMedium.map((item) => new_params.utm_mediums.push(item.value));
      setClearAll(true);
    }

    if (filter?.utmsCampaign.length > 0) {
      new_params.utm_campaigns = [];
      filter.utmsCampaign.map((item) =>
        new_params.utm_campaigns.push(item.value)
      );
      setClearAll(true);
    }

    if (filter?.discountCodes.length > 0) {
      new_params.discount_codes = [];
      filter.discountCodes.map((item) =>
        new_params.discount_codes.push(item.value)
      );
      setClearAll(true);
    }

    if (discount) {
      new_params.discount_codes = [];
      new_params.discount_codes.push(discount);
      setClearAll(true);
    }

    if (filter?.channels.length > 0) {
      new_params.channel = [];
      filter.channels.map((item) => new_params.channel.push(item.value));
      setClearAll(true);
    }

    if (filter?.sku.length > 0) {
      new_params.sku = [];
      filter.sku.map((item) => new_params.sku.push(item.value));
      setClearAll(true);
    }

    if (filter?.shippingRate.length > 0) {
      new_params.shippingRate = [];
      filter.shippingRate.map(item => new_params.shippingRate.push(item.value));
      setClearAll(true);
    }

    if (filter?.labels.length > 0) {
      new_params.label = [];
      filter.labels.map((item) => new_params.label.push(item.value));
      setClearAll(true);
    }

    if (filter?.bottleMin > 0) {
      new_params.min = filter.bottleMin;
      setClearAll(true);
    }

    if (filter?.bottleMax > 0) {
      new_params.max = filter.bottleMax;
      setClearAll(true);
    }
    if (filter?.StatusDayMin > 0) {
      new_params.status_day_min = filter.StatusDayMin;
      setClearAll(true);
    }

    if (filter?.StatusDayMax > 0) {
      new_params.status_day_max = filter.StatusDayMax;
      setClearAll(true);
    }
    if (filter?.OrderDayMin > 0) {
      new_params.order_day_min = filter.OrderDayMin;
      setClearAll(true);
    }

    if (filter?.OrderDayMax > 0) {
      new_params.order_day_max = filter.OrderDayMax;
      setClearAll(true);
    }

    if (filter?.referringSites.length > 0) {
      new_params.referringSites = [];
      filter.referringSites.map(item => new_params.referringSites.push(item.value));
      setClearAll(true);
    }

    if (filter?.orderCount.length > 0) {
      new_params.order_count = [];
      filter.orderCount.map(item => new_params.order_count.push(item.value));
      setClearAll(true);
    }

    //BCD - 4275
    if (filter?.bulkOrders.length > 0) {
      new_params.bulk_orders = [];
      filter.bulkOrders.map((item) => new_params.bulk_orders.push(item.value));
      setClearAll(true);
    }

    // Pre Order filter for Dashboard and orders page

    if (filter?.preOrders.length > 0) {
      new_params.pre_orders = [];
      filter.preOrders.map((item) => new_params.pre_orders.push(item.value));
      setClearAll(true);
    }

    setParams(new_params);
    return new_params;
  };

  const { component } = useContext(ComponentContext);
  const cancelToken = React.useRef(undefined);


  useEffect(() => {
    setLoading(true);

    const new_params = getParams();
    console.log(new_params)
    if (cancelToken.current !== undefined) {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }
    cancelToken.current = axios.CancelToken.source();

    api
      .get("/order/", {
        params: new_params,
        cancelToken: cancelToken.current.token,
      })
      .then((response) => {
        const list = response.data.results.map((order) => {
          const s = selected.filter((item) => parseInt(item.id) === order.id);
          order.checked = s.length > 0;
          return order;
        });
        setOrders(list);
        setTotalOrders(response.data.count);

        const totalPages = Math.ceil(
          response.data.count / window.env.PAGE_SIZE
        );
        const previous = page !== 1 ? page - 1 : 1;
        const next = page !== totalPages ? page + 1 : totalPages;
        setPagination({
          previous,
          next,
          pages: () => {
            let startCount = 1;
            let endCount = isMobile ? 4 : 12;
            let numberCount = Math.round(endCount / 2);
            const pageList = [];
            if (numberCount < 0) {
              numberCount = 1;
            }

            startCount = page - numberCount;

            if (startCount <= 0) startCount = 1
            if (page !== 1) endCount = page + numberCount;
            if (endCount > totalPages) endCount = totalPages;

            if (totalPages >= endCount) {
              for (let i = startCount; i <= endCount; i++) {
                pageList.push(i);
              }
            } else if (totalPages >= 1) {
              for (let i = 1; i <= totalPages; i++) {
                pageList.push(i);
              }
            }

            return pageList;
          },
        });
        setLoading(false);
        delete new_params["page"];
        delete new_params["ordering"];
        setParams(new_params);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, sort, filter, refresh, component]);

  useEffect(() => {
    if (date_start && date_end) {
      setFilter({
        ...filter,
        startDate: moment(new Date(date_start + "T03:00:00")),
        endDate: moment(new Date(date_end + "T03:00:00")),
      });
    }
  }, [date_end, date_start]);

  const handlePagination = (page) => {
    setPage(page);
  };

  const { user, isAuthentication } = useContext(AuthContext);

  let cursor = null;
  if(user) {
    cursor = user.isAdmin || user.isRetailer ? "pointer": "auto";
  }

  // This list is maintained in order to get proper filter.prefix
  // Currently prefix and filter.prefix are not same
  // url param key and filter.prefix (key) must be same
  // e.g. prefix = "brand" have filter.brands
  const prefixAndFilterMapping = {
    "brand" : "brands",
    "assignee" : "assignees",
    "risk_level" : "risk_level",
    "status" : "statuses",
    "fulfillment" : "fulfillments",
    "product" : "products",
    "product_type" : "productTypes",
    "productCategory" : "productCategory",
    "state" : "states",
    "payment_status" : "financialStatus",
    "label" : "labels",
    "utm" : "utms",
    "utm_medium" : "utmsMedium",
    "utm_campaign" : "utmsCampaign",
    "discount_code" : "discountCodes",
    "sales_channel" : "channels",
    "sku" : "sku",
    "shippingRate" : "shippingRate",
    "referringSites" : "referringSites",
    "orderCount" : "orderCount",
    "reship_status" : "reshipStatuses",
    "bulk_order":"bulkOrders", //BCD - 4275
    // Pre Order filter for Dashboard and orders page
    "preOrders" : "preOrders",
  }

  const onFilterChange = (e, prefix) => {
    if (e == "__all__") {
      let filteredRecords = [];
      let checkbox = document.querySelectorAll(`.checkbox_${prefix}`);
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true;
        filteredRecords.push({
          value: checkbox[i].value,
          label: checkbox[i].dataset.label,
        });
        addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
      }
      let updatedFilter = { ...filter };
      let filterNameForPrefix = prefixAndFilterMapping[prefix]
      updatedFilter[filterNameForPrefix] = filteredRecords;
      setFilter(updatedFilter);
    } else {
      let filteredRecords = [...new Set(filter[`${prefixAndFilterMapping[prefix]}`])];
      if (e.target.checked) {
          filteredRecords.push({ value: e.target.value, label: e.target.dataset.label });
          addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
      } else {
          filteredRecords = filteredRecords.filter(item => item.value !== e.target.value);
          removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
      }
      let updatedFilter = { ...filter };
      updatedFilter[prefixAndFilterMapping[prefix]] = filteredRecords;
      setFilter(updatedFilter);
    }
  };

  const onMinMaxFilterChange = (minKey, minValue, maxKey, maxValue) => {
    if(minValue.length > 0) addURLParam(window, minKey, minValue);
    if(maxValue.length > 0) addURLParam(window, maxKey, maxValue);
  }

  const handleBrands = (e, prefix) => {
    let checkbox = [...new Set(filter.brands)];
    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    }
    setFilter({ ...filter, brands: checkbox });
  };

  const handleDateRange = (start, end) => {
    setFilter({
      ...filter,
      startDate: start,
      endDate: end,
    });
    addURLParam(window, "startDate", start);
    addURLParam(window, "endDate", end);
  };

  const handleProducts = (e, prefix) => {
    let checkbox = [...new Set(filter.products)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    }

    setFilter({ ...filter, products: checkbox });
  };

  const handleProductTypes = (e, prefix) => {
    let checkbox = [...new Set(filter.productTypes)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    }

    setFilter({ ...filter, productTypes: checkbox });
  };

  let timer = null;
  const handleSearch = (e) => {
    addURLParam(window, "search", e.target.value);
    clearTimeout(timer);
    timer = setTimeout(function () {
      setFilter({
        ...filter,
        search: e.target.value,
      });
    }, 750);
  };

  const removeBadge = (e) => {
    if (e.component === "state") {
      let items = filter.states;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, states: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "brand") {
      let items = filter.brands;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, brands: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "product") {
      let items = filter.products;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, products: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "product_type") {
      let items = filter.productTypes.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, productTypes: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "date") {
      setFilter({ ...filter, startDate: null, endDate: null });
      removeURLParam(window, "startDate");
      removeURLParam(window, "endDate");
    } else if (e.component === "search") {
      let pathName = window.location.search.toString();
      if (pathName.startsWith("?productName=")) {
        window.location.assign("/orders");
      }
      setFilter({ ...filter, search: null });
      removeURLParam(window, "search");
      document.getElementById("orderPageSeachInput").value = "";
    } else if (e.component === "status") {
      let items = filter.statuses;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, statuses: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "payment_status") {
      let items = filter.financialStatus;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, financialStatus: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "fulfillment") {
      let items = filter.fulfillments;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, fulfillments: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    }else if (e.component === "productCategory") {
      let items = filter?.productCategory;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, productCategory: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "reship_status") {
      let items = filter.reshipStatuses;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, reshipStatuses: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "assignee") {
      let items = filter.assignees;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, assignees: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "risk_level") {
      let items = filter.risk_level;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, risk_level: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "utm") {
      let items = filter.utms;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, utms: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "utm_medium") {
      let items = filter.utmsMedium;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, utmsMedium: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "utm_campaign") {
      let items = filter.utmsCampaign;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, utmsCampaign: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "discount_code") {
      let items = filter.discountCodes;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, discountCodes: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "sales_channel") {
      let items = filter.channels;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, channels: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "sku") {
      let items = filter.sku;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, sku: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "shippingRate") {
      let items = filter.shippingRate;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, shippingRate: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "label") {
      let items = filter.labels;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, labels: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === "bottleMin") {
      setFilter({ ...filter, bottleMin: [] });
      removeURLParam(window, "bottleMin");
    } else if (e.component === "bottleMax") {
      setFilter({ ...filter, bottleMax: [] });
      removeURLParam(window, "bottleMax");
    } else if (e.component === "StatusDayMin") {
      setFilter({ ...filter, StatusDayMin: "" });
      removeURLParam(window, "StatusDayMin");
    } else if (e.component === "StatusDayMax") {
      setFilter({ ...filter, StatusDayMax: "" });
      removeURLParam(window, "StatusDayMax");
    } else if (e.component === "OrderDayMin") {
      setFilter({ ...filter, OrderDayMin: "" });
      removeURLParam(window, "OrderDayMin");
    } else if (e.component === "OrderDayMax") {
      setFilter({ ...filter, OrderDayMax: "" });
      removeURLParam(window, "OrderDayMax");
    } else if (e.component === "referringSites") {
      let items = filter.referringSites;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, referringSites: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
	  } else if (e.component === "orderCount") {
      let items = filter.orderCount;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, orderCount: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
	  } else if (e.component === 'financialStatus') {
      let items = filter.financialStatus
      items = items.filter(item => item.value !== e.item.value)
      setFilter({ ...filter, financialStatus: items })
    } else if (e.component === 'preOrders') {
      let items = filter.preOrders;
      items = items.filter(item => item.value !== e.item.value);
      setFilter({ ...filter, preOrders: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    } else if (e.component === 'selected') {
      let items = selected
      items = items.filter(item => item.id !== e.item.id)

      setSelected(items)
      let checkbox = document.getElementById('checkbox_' + e.item.id)
      if (checkbox) {
        checkbox.checked = false
      }
    }else if (e.component === "bulk_order") { //BCD - 4275
      let items = filter.bulkOrders;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, bulkOrders: items });
      removeURLParam(window, prefixAndFilterMapping[e.component], e.item.value);
    }
    setClearAll(false);
  };

  const handleFulfillment = (e, prefix) => {
    let checkbox = [...new Set(filter.fulfillments)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    }
    setFilter({ ...filter, fulfillments: checkbox });
  };

  const handleReshipStatus = (e, prefix) => {
    let checkbox = [...new Set(filter.reshipStatuses)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    }
    setFilter({ ...filter, reshipStatuses: checkbox });
  };

  const handleProductCategory = (e, prefix) => {
    let checkbox = [...new Set(filter.productCategory)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    }
    setFilter({ ...filter, productCategory: checkbox });
  };

  const handleStatus = (e, prefix) => {
    if (e == "__all__") {
      let status = [];

      let checkbox = document.querySelectorAll(".checkbox_status");
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true;
        status.push({
          value: checkbox[i].value,
          label: checkbox[i].dataset.label,
        });
        addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
      }
      setFilter({ ...filter, statuses: status });
    } else {
      let checkbox = [...new Set(filter.statuses)];

      if (e.target.checked) {
        checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
      } else {
        checkbox = checkbox.filter((item) => item.value !== e.target.value);
        removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
      }

      setFilter({ ...filter, statuses: checkbox });
    }
  };

  const handleUpdateOrder = (e) => {
    setUpdateOrder(e);
    setOpenModal({ updateOrder: !openModal.updateOrder });
  };

  const handleCancelOrder = (e) => {
    setCancelOrder(e);
    setOpenModal({ cancelOrder: !openModal.cancelOrder });
  };

  const handleSplitOrder = (e) => {
    setSplitOrder(e);
    setOpenModal({ splitOrder: !openModal.splitOrder });
  };

  const handleBottles = (e, valueMin, valueMax) => {
    e.preventDefault();
    setFilter({ ...filter, bottleMin: valueMin, bottleMax: valueMax });
    onMinMaxFilterChange("bottleMin", valueMin, "bottleMax", valueMax);
  };

  const handleStatusAge = (e,valueMin, valueMax) => {
    e.preventDefault();
    setFilter({ ...filter, StatusDayMin: valueMin, StatusDayMax: valueMax });
    onMinMaxFilterChange("StatusDayMin", valueMin, "StatusDayMax", valueMax);
  };

  const handleOrderAge = (e, valueMin, valueMax) => {
    e.preventDefault();
    setFilter({ ...filter, OrderDayMin: valueMin, OrderDayMax: valueMax });
    onMinMaxFilterChange("OrderDayMin", valueMin, "OrderDayMax", valueMax);
  };

  const refOrdersCheckbox = useRef(false);
  const handleOrdersCheckbox = (e) => {
    refOrdersCheckbox.current = e.target.checked;
    let checkboxes = document.querySelectorAll(".orderCheckbox");

    let items = [];
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
      if (e.target.checked === true) {
        {/* 6193203826 - Block order changes on WMS Orders*/}
        const orderRecord = orders.find(order => order.id == checkboxes[i].value);

        items.push({
          id: parseInt(checkboxes[i].value),
          title: checkboxes[i].name,
          email: checkboxes[i].email,
          wms_managed: orderRecord?.order_managed_by, /* 6193203826 - Block order changes on WMS Orders*/
        });
      }
    }

    {/* 6193203826 - Block order changes on WMS Orders*/}
    const managedItems = items.filter(item => item.wms_managed);
    managedItems.length > 0 ? setOrderManagedBy(true) : setOrderManagedBy(false);
    
    setSelected(items);
  };

  const handleClearAll = () => {
    setClearAll(false);
    setSelected([]);
    document.getElementById("orderPageSeachInput").value = "";
    setFilter({
      startDate: null,
      endDate: null,
      search: null,
      brands: [],
      products: [],
      states: [],
      financialStatus: [],
      fulfillments: [],
      statuses: [],
      assignees: [],
      risk_level: [],
      bottleMax: "",
      bottleMin: "",
      StatusDayMax: "",
      StatusDayMin: "",
      OrderDayMax: [],
      OrderDayMin: [],
      labels: [],
      utms: [],
      discountCodes: [],
      utmsMedium: [],
      utmsCampaign: [],
      channels: [],
      sku: [],
      shippingRate: [],
      productName: "",
      referringSites: [],
      orderCount: [],
      reshipStatuses: [],
      bulkOrders:[], //4275
      preOrders:[],
    });
    removeAllURLParams()
  };

  const { addToast } = useToasts();
  const [exportButton, setExportButton] = useState({
    label: "Export",
    disabled: false,
  });

  const handleExportOrders = () => {
    setExportButton({
      label: "Exporting...",
      disabled: true,
    });

    let new_params = params;

    new_params.order_export_ids = [];

    selected.map((item) => new_params.order_export_ids.push(item.id));

    api
      .get("order/export/", {
        params: new_params,
      })
      .then((response) => response.data)
      .then((response) => {
        const success = response?.success | false;

        if (success) {
          addToast(response.message, {
            appearance: "success",
            autoDismiss: false,
          });
        } else {
          const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
          const filename = "orders_" + timestamp + ".csv";
          fileDownload(response, filename);
        }

        setExportButton({
          label: "Export",
          disabled: false,
        });
      });
    // document.href = window.env.APP_URL + str
  };

  const handleExportLineItemOrder = () => {
    setExportButton({
      label: "Exporting...",
      disabled: true,
    });

    let new_params = params;

    new_params.order_ids = [];

    selected.map((item) => new_params.order_ids.push(item.id));

    api
      .get("order/export/item", {
        params: new_params,
      })
      .then((response) => response.data)
      .then((response) => {
        const success = response?.success | false;

        if (success) {
          addToast(response.message, {
            appearance: "success",
            autoDismiss: false,
          });
        } else {
          const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
          const filename = "orders_line_item_" + timestamp + ".csv";
          fileDownload(response, filename);
        }

        setExportButton({
          label: "Export",
          disabled: false,
        });
      });
    // document.href = window.env.APP_URL + str
  };

  const clearFilter = (prefix) => {
    if (prefix === "state") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        states: [],
      });
    } else if (prefix === "brand") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        brands: [],
      });
    } else if (prefix === "productCategory") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        productCategory: [],
      });
    }
      else if (prefix === "product") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        products: [],
      });
    } else if (prefix === "product_type") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        productTypes: [],
      });
    } else if (prefix === "fulfillment") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        fulfillments: [],
      });
    } else if (prefix === "payment_status") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        financialStatus: [],
      });
    } else if (prefix === "status") {
      setFilter({
        ...filter,
        statuses: [],
      });
      removeURLParam(window, prefixAndFilterMapping[prefix]);
    } else if (prefix === "assignee") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        assignees: [],
      });

    } else if (prefix === "risk_level") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        risk_level: [],
      });
    } else if (prefix === "utm") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        utms: [],
      });
    } else if (prefix === "utm_medium") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        utmsMedium: [],
      });
    } else if (prefix === "utm_campaign") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        utmsCampaign: [],
      });
    } else if (prefix === "discount_code") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        discountCodes: [],
      });
    } else if (prefix === "sales_channel") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        channels: [],
      });
    } else if (prefix === "sku") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        sku: [],
      });
    } else if (prefix === "shippingRate") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
          ...filter,
          shippingRate: []
      });
    } else if (prefix === "referringSites") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
          ...filter,
          referringSites: []
      })
    } else if (prefix === "orderCount") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
          ...filter,
          orderCount: []
      });
    } else if (prefix === "reship_status") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        reshipStatuses: [],
      });
    }else if (prefix === "bulk_order") {//BCD - 4275
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        bulkOrders: [],
      });
    } else if (prefix === "preOrders") {
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setFilter({
        ...filter,
        preOrders: [],
      });
    }
    

    setClearAll(false);

    let checkbox = document.querySelectorAll(
      ".checkbox_" + prefix + ":checked"
    );
    if (checkbox.length > 0) {
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = false;
      }
    }
  };

  if (isAuthentication && !user.isSupplier && !user.isAdmin && !user.isRetailer && !user.isFlaviarAdmin) {
    window.location.href = window.env.APP_URL;
  }

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const exitAssigneeModal = () => {
    setOpenModal({
      assignee: false,
    });
  };

  const exitOrderStatusModal = () => {
    setOpenModal({
      orderStatus: false,
    });
  };

  const exitRetailerEmailModal = () => {
    setOpenModal({
      retailerEmail: false
    });
  };

  const exitBulkNotesModal = () => {
    setOpenModal({
      bulkNotes: false
    });
  };

  const exitUpdateOrderModal = () => {
    setOpenModal({
      updateOrder: false,
    });
  };

  const inputNotes = useRef();

  const exitSplitOrderModal = () => {
    setOpenModal({
      splitOrder: false,
    });
  };

  const exitBulkETAModal = () => {
    setOpenModal({
      bulkETA: false,
    });
  };

  const handleInternalNotes = (e, orderId, internalNote, handleRefresh) => {
    e.preventDefault();

    let notes = internalNote;
    let id = orderId;
    let idArray = [];
    if (Array.isArray(orderId)) {
      // If Parameter is an array assign it to idArray
      idArray = orderId
    } else if (Number.isInteger(orderId)) {
      // If Parameter is an integer push it to idArray
      idArray.push(orderId);
    }
    if (inputNotes.current) {
      [notes, id] = inputNotes.current;
    }

    setNotesLoading(true);

    const data = {
      notes: notes,
      orders: idArray, 
    };
    api
      .put("order/internal-notes/", data)
      .then((response) => {
        setNotesLoading(false);
        let notes_orders = orders.map((item) => {
          // 6011065090 - Prevent page reload after adding notes
          if(Number.isInteger(id)){
            if (item.id === id) {
              item.internal_note = notes;
            }
          }
          else if (Array.isArray(id)){
            if (id.includes(item.id)) {
              item.internal_note = notes;
            }
          }
         
          return item;
        });

        // 6011065090 - Prevent page reload after adding notes
        // if (handleRefresh) {
        //   setSelected([]);
        //   handleRefresh();
        // }

        setOrders(notes_orders);
        addToast("Saved Successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        // // Clear selected orders 
        // // 6011065090 - Prevent page reload after adding notes
        // if (selected.length > 0) {
        //   setSelected([])
        // }

        exitBulkNotesModal();
        inputNotes.current = null;

        // Close notes popover
        document.body.click();
      })
      .catch((error) => {
        setNotesLoading(false);
        addToast(error.message, { appearance: "error" });
      });
  };

  /**
 * Handler to open manual fulfillement
 *  @param {*} orderFulfillement 
 */
  const handleFulfillmentModal = (orderFulfillement) => {
    setOrderFulfillement(orderFulfillement);
    setOpenModal({ fulfillement: true });
  };

   /* 
   * Handler to open send email to customer
   * @param {*} ordersForEmail 
   */
  const handleOpenEmailModal = (ordersForEmail) => {
    setOrdersForEmail(ordersForEmail);
    setOpenModal({ email: true });
  };

/**
 * Function for opening email retailer modal and fetching retailer email details
 * @param {*} ordersForRetailer 
 */
  const handleOpenRetailerEmail = (ordersForRetailer) => {
    const orderId = ordersForRetailer.map((id) => {
      return id.id;
    })

    api({
      method: 'get',
      url: "order/fetch-retailer-email", params: {orderId: orderId[0]}
    })
      .then((response) => {
        setToEmail(response);
      })
      .catch((error) => console.log(error));
    setOrderForRetailerEmail(ordersForRetailer);
    setOpenModal({ retailerEmail: true });
  };
  
  const handleBulkNotes = () => {
    setOpenModal({ bulkNotes: true });
  }

   /**
    * Handler to open sku mapping modal
    * @param {*} productSku - barcart sku
    * @param {*} productName - barcart product name
    * @param {*} orderAssignee - order assignee
    */
  const handleOpenSKUMappingModal = (
    productSku,
    productName,
    orderAssignee
  ) => {
    setOpenModal({ skumapping: true });
    setVariant({
      name: productName,
      sku: productSku,
      orderAssignee: orderAssignee,
    });
  };

  /**
   * Open doordash Module and call the API to get the order details
   * @param {*} orderForDoordash 
   */
  const handleDoordashDelivery = (orderForDoordash) => {
    setOpenModal({ doordash: true });

    const data = {
      id_order: orderForDoordash.id,
    }

    api
      .get("/order/assignee/", { params: data })
      .then((response) => {
        if (response.status === 200) {
          setOrderForDoordash(response.data.data);
        } else {
          let message = "Something went wrong, please try again!!"
          if (response?.data?.message) {
            message = response.data.message;
          };
          addToast(message, { appearance: "error" });
        }
      }).catch((error) => {
        let message = "Something went wrong, please try again!!"
        if (error?.response?.data?.message) {
          message = error.response.data.message;
        };
        addToast(message, { appearance: "error" });
      });
  };


  const updateOrderStatus = (orderId) => {
      api
        .get("/order/line-item/", {
          params: {
            order_id: orderId,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data?.results) {
            const updatedOrders = orders.map((orderItem) => {
              let result = response.data?.results[0];
              if (result && orderItem.id === orderId ) {
                orderItem.items = result.items;
                orderItem.order_status = result.order_status;
                orderItem.assignee = result.assignee;
                orderItem.financial_status = result.financial_status;
              }
              return orderItem;
            });
            setOrders(updatedOrders);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const handleOpenReshipModal = (ordersForReship) => {
    setOrdersForReship(ordersForReship);
    setOpenModal({ reship: true });
  }
  
  /**
   * Open restocking Module and call the API to get the order details
   * @param {*} orderIdForRestockingFee 
   */  
  const handleRestockingFee = (orderIdForRestockingFee) => {
    setOpenModal({ orderRestocking: true });
    setOrderIdForRestockingFee(orderIdForRestockingFee.id);

    let data = {
      order_id: orderIdForRestockingFee.id,
    };
    
    api
      .get("order/restocking-fee/details", { params: data })
      .then((response) => {
        if (response.status === 200) {
          setOrderForRestockingFee(response.data.data.rows);
        } else {
          let message = "Something went wrong, please try again!!"
          if (response?.data?.message) {
            message = response.data.message;
          };
          addToast(message, { appearance: "error" });
        }
      }).catch((error) => {
        let message = "Something went wrong, please try again!!"
        if (error?.response?.data?.message) {
          message = error.response.data.message;
        };
        addToast(message, { appearance: "error" });
      });
  };

  const retailerAssignment = (orderForRetailerOverride) => {
    let paramsForRetailerAssignment = {
      "inventory_location": orderForRetailerOverride.assignee != "-" ? orderForRetailerOverride.assignee : "",
      "state": orderForRetailerOverride.shipping_state,
      "channel":[orderForRetailerOverride.channel_id],
      "variant": [],
      "retailer_override_through_order_page": true,
    }
    let orderVariants = orderForRetailerOverride.items.reduce((previousValue, currentValue) => {
      previousValue.push(currentValue.sku);
      return previousValue;
    }, []);
    paramsForRetailerAssignment.variant = orderVariants;
    setproductsSearchFormData(paramsForRetailerAssignment);
    setOpenModal({ overrideRetailer: !openModal.overrideRetailer });
  }

  /**
   * To update the items' mapping status after creating the mappings.
   * @param {*} assignee order assignee
   * @param {*} sku barcart sku
   * @param {*} quantity quantity
   */
  const updateSKUMappingStatus = (assignee, sku, quantity) => {
    const updatedOrders = orders.map((orderItem) => {
      if (orderItem.assignee === assignee) {
        const lineItems = orderItem.items.map((product) => {
          if (product.sku === sku) {
            product.mapped_sku.status = true;
            product.mapped_sku.quantity = quantity;
          }
          return product;
        });
        orderItem.items = lineItems;
      }
      return orderItem;
    });

    setOrders(updatedOrders);
  };

  /**
   * Function for returning color of order status
   * @param {*} orderStatus
   * @returns
   */
  const getOrderStatusColor = (orderStatus) => {
    let color = '';
    switch (orderStatus) {
      case "CANCELLED":
        color = "border-danger text-danger";
        break;
      case "CONFIRMED":
        color = "border-success text-success";
        break;
      case "OPEN":
        color = 'border-success text-success';
        break;
      case "CLOSED":
        color = 'border-light text-light';
        break;
    }
    return color;
  };
  const urlSearchParams = useLocation().search;
  React.useMemo(() => {
      processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
  }, [urlSearchParams]);

  const [financeStatus, setFinanceStatus] = useState([
    {
      "id": "paid",
      "name": "Paid",
    },
    {
      "id": "partially_paid",
      "name": "Partially Paid",
    },
    {
      "id": "partially_refunded",
      "name": "Partially Refunded",
    },
    {
      "id": "refunded",
      "name": "Refunded",
    },
    {
      "id": "pending",
      "name": "Pending",
    },
  ]);
  const handleEmptyVariable = (value) => {
    if (value !== "" && value !== null && value !== undefined) {
      return true;
    }
    return false
  }

  const copyOrderIdToClipboard = (orderId) => {
    navigator.clipboard.writeText(orderId); 
    addToast("Order ID copied to clipboard!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const handleBulkEta =(selected)=>{
    setOpenModal({bulkETA:true})
  }

  /**
   * Clearing all the selected orders
   * @param {*}
   */
  const onCompleteActions = () => {
    window.location.reload();
  };

  return (
    <>
      {isAuthentication ? (
        <Container fluid>
          <OrderStatusModalForm
            data={status}
            isOpen={openModal.orderStatus}
            orders={selected}
            onComplete={onCompleteActions}
            onExit={exitOrderStatusModal}
          />
          <CancelOrderModal
            data={status}
            order={cancelOrder}
            isOpen={openModal.cancelOrder}
            orders={selected}
            onExit={exitOrderStatusModal}
          />
          <SplitOrderModal
            data={status}
            order={splitOrder}
            isOpen={openModal.splitOrder}
            orders={selected}
            onExit={exitSplitOrderModal}
          />
          <AssigneeModalForm
            data={assignee}
            isOpen={openModal.assignee}
            orders={selected}
            onComplete={onCompleteActions}
            onExit={exitAssigneeModal}
          />
          <UpdateOrderModal
            data={status}
            order={updateOrder}
            isOpen={openModal.updateOrder}
            orders={selected}
            onExit={exitUpdateOrderModal}
          />
          <ManualFulfilmentForm
            orders={orderFulfillement}
            isOpen={openModal.fulfillement}
            onExit={() => setOpenModal({ fulfillement: !openModal.fulfillement })}
          />

          <ComposeEmailForm
            orders={ordersForEmail}
            isOpen={openModal.email}
            onExit={() => setOpenModal({ email: !openModal.email })}
          />

          <RetailerEmail
            isOpen={openModal.retailerEmail}
            onExit={exitRetailerEmailModal}
            orders={ordersForRetailerEmail}
            toEmail={toEmail}
          />
          {openModal?.bulkNotes && selected.length > 0 &&
          <BulkNotes
            isOpen ={openModal.bulkNotes}
            onExit={exitBulkNotesModal}
            selected ={selected}
            handleInternalNotes={handleInternalNotes}
            notesLoading ={notesLoading}
            inputNotes = {inputNotes}
            handleRefresh ={handleRefresh}
          />
          }
          <DoordashModal
            order={orderForDoordash}
            isOpen={openModal.doordash}
            handleRefresh={handleRefresh}
            onExit={() => setOpenModal({ doordash: !openModal.doordash })}
          />

          {
            ordersForReship && <Reship
              order={ordersForReship}
              isOpen={openModal.reship}
              handleRefresh={handleRefresh}
              onExit={() => setOpenModal({ reship: !openModal.reship })}
            />
          }
          
          <OrderRestockingModal
            orderId={orderIdForRestockingFee}
            order={orderForRestockingFee}
            isOpen={openModal.orderRestocking}
            handleRefresh={handleRefresh}
            onExit={() => setOpenModal({ orderRestocking: !openModal.orderRestocking })}
          />
          <SKUMapppingModal
            isOpen={openModal.skumapping}
            onExit={() => setOpenModal({ skumapping: !openModal.skumapping })}
            variant={variant}
            updateSKUMappingStatus={updateSKUMappingStatus}
          />

          {
            openModal.bulkETA ? 
              <BulkETA 
                isOpen={openModal.bulkETA} 
                onExit={exitBulkETAModal} 
                selected={selected} 
                handleClearAll={handleClearAll} 
                handleRefresh={handleRefresh} 
              /> 
            : null
          }



          <div className={"pt-7"}>
            {isAuthentication && (
              <Row className={"mb-2"}>
                <Col xs={8} lg={2} className={"text-left"}>
                  <input
                    type="text"
                    className={"button-font-size form-control form-control-sm"}
                    placeholder={"Search"}
                    id="orderPageSeachInput"
                    defaultValue={filter.search}
                    onChange={handleSearch}
                    newparams={""}
                  />
                </Col>
                <Col xs={4} lg={10} className={"text-right"}>
                  {/* 7278215305 - increase font size */}
                  <button
                    className="button-font-size btn btn-neutral btn-sm p-9px"
                    onClick={handleRefresh}
                  >
                    <i className="fas fa-sync-alt"></i>
                  </button>
                  <div className={"d-none d-lg-inline-block mr-2"}>

                    <Filter
                      name={"Assignee"}
                      prefix={"assignee"}
                      onChange={onFilterChange}
                      newparams={params}
                      selected={filter.assignees}
                      url={"order/filter/assignee/"}
                      inputSearch={true}
                      onClear={() => clearFilter("assignee")}
                    />

                    <Filter
                      name={"Status"}
                      prefix={"status"}
                      onChange={onFilterChange}
                      newparams={params}
                      selected={filter.statuses}
                      url={"/order/filter/status/"}
                      inputSearch={true}
                      onClear={() => clearFilter("status")}
                    />

                    <StatusAge
                      onSubmit={handleStatusAge}
                      valueMin={filter.StatusDayMin}
                      valueMax={filter.StatusDayMax}
                    />

                    <OrderAge
                      onSubmit={handleOrderAge}
                      valueMin={filter.OrderDayMin}
                      valueMax={filter.OrderDayMax}
                    />

                    <Filter
                      name={"Fulfillment"}
                      prefix={"fulfillment"}
                      onChange={onFilterChange}
                      newparams={params}
                      selected={filter.fulfillments}
                      url={"/order/filter/fulfillment/"}
                      inputSearch={false}
                      onClear={() => clearFilter("fulfillment")}
                    />

                    <Filter
                      name={"Product"}
                      prefix={"product"}
                      onChange={onFilterChange}
                      newparams={params}
                      selected={filter.products}
                      url={"/order/filter/product/"}
                      onClear={() => clearFilter("product")}
                    />

                    <Filter
                      name={"Product Type"}
                      prefix={"product_type"}
                      onChange={onFilterChange}
                      newparams={params}
                      selected={filter.productTypes}
                      url={"/order/filter/product_type/"}
                      onClear={() => clearFilter("product_type")}
                    />

                    <Filter
                      name={"Brand"}
                      prefix={"brand"}
                      onChange={onFilterChange}
                      newparams={params}
                      selected={filter.brands}
                      url={"/order/filter/brand/"}
                      onClear={() => clearFilter("brand")}
                    />

                    <Filter
                      name={"Pre-Orders"}
                      prefix={"preOrders"}
                      onChange={onFilterChange}
                      newparams={params}
                      selected={filter.preOrders}
                      // TODO: @AW - 23-04-2024 Please add URL for filter options
                      url={"/order/filter/pre-orders"}
                      onClear={() => clearFilter("preOrders")}
                    />

                    <DateRange onCallback={handleDateRange}></DateRange>

                    {/* Export button (dropdown) */}
                    <ButtonDropdown
                      size={"sm"}
                      isOpen={exportDropdownOpen}
                      toggle={() => setExportDropdownOpen(!exportDropdownOpen)}
                    >
                      {/* 7278215305 - increase font size */}
                      <DropdownToggle
                        style = { { fontSize:'.8rem', width: '100px' }}
                        color="white"
                        className="text-primary p-10px"
                        caret
                      >
                        <i className="fas fa-file-csv" /> {exportButton.label}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={handleExportOrders}>Orders</DropdownItem>
                        <DropdownItem onClick={handleExportLineItemOrder}>Order Line Items</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                  <MoreFilters
                    moreFiltersOnDesktop={moreFiltersOnDesktop}
                    user={user}
                    filter={filter}
                    orderCountData={orderCountData}
                    onChange={{
                      // assignee: onFilterChange,
                      label: onFilterChange,
                      bottle: handleBottles,
                      productCategory: handleProductCategory,
                      utm: onFilterChange,
                      utmMedium: onFilterChange,
                      utmCampaign: onFilterChange,
                      discountCode: onFilterChange,
                      channel: onFilterChange,
                      sku: onFilterChange,
                      shippingRate: onFilterChange,
                      status: handleStatus,
                      paymentStatus: onFilterChange,
                      fulfillment: handleFulfillment,
                      product: handleProducts,
                      productTypes: handleProductTypes,
                      brand: handleBrands,
                      state: onFilterChange,
                      dateRange: handleDateRange,
                      referringSites: onFilterChange,
                      // orderCount: onFilterChange,
                      // statusAge: handleStatusAge,
                      // orderAge: handleOrderAge,
                      reship_status: handleReshipStatus,
                      bulkOrder:onFilterChange, //BCD - 4275
                    }}
                    onClear={{
                      assignee: () => clearFilter("assignee"),
                      state: () => clearFilter("state"),
                      paymentStatus: () => clearFilter("payment_status"),
                      utm: () => clearFilter("utm"),
                      utm_medium: () => clearFilter("utm_medium"),
                      utm_campaign: () => clearFilter("utm_campaign"),
                      discount_code: () => clearFilter("discount_code"),
                      channel: () => clearFilter("sales_channel"),
                      sku: () => clearFilter("sku"),
                      shippingRate: () => clearFilter("shippingRate"),
                      referringSites: () => clearFilter("referringSites"),
                      orderCount: () => clearFilter("orderCount"),
                      brand: () => clearFilter("brand"),
                      fulfillment: () => clearFilter("fulfillment"),
                      product: () => clearFilter("product"),
                      status: () => clearFilter("status"),
                      productTypes: () => clearFilter("product_type"),
                      reshipStatuses: () => clearFilter("reship_status"),
                      productCategory: () => clearFilter("productCategory"),
                      bulk_order:()=>clearFilter("bulk_order"), //BCD - 4275
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              {isAuthentication && (
                <div className="col">
                  <Card className="shadow" xs={12}>
                    <CardHeader className="border-0">
                      <Row>
                        <Col className="pl-8px" style={{ display: "flex" }}>
                          <h3 className="mb-0">
                            Orders <small>({totalOrders})</small>
                          </h3>
                          {clearall && (
                            <a
                              href={void 0}
                              className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                              onClick={handleClearAll}
                              id="clear_all_filters"
                            >
                              <i className="fas fa-times-circle"></i> Clear All
                              filters
                            </a>
                          )}
                        </Col>
                        <Col className="text-right d-none d-md-block">
                          <a
                            href={window.env.APP_URL + "order/"}
                            className="button-font-size btn btn-sm btn-light"
                          >
                            Switch to old version
                          </a>
                          {/* 6193203826 - Block order changes on WMS Orders*/}
                          {selected.length > 0 && user.isAdmin && (!orderManagedBy) && (
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn text-primary"
                                href="#"
                                role="button"
                                size="sm"
                                color="white"
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-cogs" /> actions
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => handleOpenEmailModal(selected)}
                                >
                                  <i className="fas fa-envelope" /> E-mail customer
                                </DropdownItem>

                                <DropdownItem
                                  href="#"
                                  onClick={(e) =>
                                    setOpenModal({
                                      orderStatus: !openModal.orderStatus,
                                    })
                                  }
                                >
                                  <i className="fas fa-file-invoice" /> Update
                                  Order Status
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={(e) =>
                                    setOpenModal({
                                      assignee: !openModal.assignee,
                                    })
                                  }
                                >
                                  <i className="fas fa-file-invoice" /> Update
                                  Assignee
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => handleOpenRetailerEmail(selected)}
                                >
                                  <i className="fas fa-envelope" /> E-mail  Retailer
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => handleBulkNotes(selected)}
                                >
                                  <i className="fa fa-sticky-note" /> Add Bulk Notes
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => handleBulkEta(selected)}
                                >
                                  <i className="fa fa-sticky-note" /> Add Bulk ETA
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                        </Col>
                        <Col sm="12">
                          <BadgePillFilter
                            data={filter}
                            selected={selected}
                            onClick={removeBadge}
                          />
                        </Col>
                      </Row>
                    </CardHeader>
                    {isLoading ? (
                      <div className="text-center mt-3 mb-3">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <Table
                          id="orders-table"
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                          <tr>
                            <th scope="col" className="pl-3 pr-3">
                              <input
                                type="checkbox"
                                name={'check_orders'}
                                id={'checkbox_orders'}
                                ref={refOrdersCheckbox}
                                defaultChecked={refOrdersCheckbox.current}
                                onChange={handleOrdersCheckbox}
                              />
                            </th>

                            <th scope="col"></th>

                            {(user.isAdmin || user.isRetailer  || user.isSupplier) && (
                              <th scope="col">
                                Notes
                              </th>
                            )}

                            <th scope="col">
                              <Sort
                                onClick={() => handleSort(sort, 'shopify_created_at', setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="shopify_created_at"
                              >
                                Age
                              </Sort>
                            </th>
                            <th scope="col">
                              Risk Level
                            </th>

                            <th scope="col" style={{ minWidth: '200px' }}>
                              Order
                            </th>
                            <th scope="col">
                              Products
                            </th>
                            <th scope="col">
                              Assignee
                            </th>
                            <th scope="col">
                              <Sort
                                onClick={() => handleSort(sort, 'billing_name', setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="billing_name"
                              >
                                Name
                              </Sort>
                            </th>
                            <th scope="col">
                              <Sort
                                onClick={() => handleSort(sort, 'shipping_state', setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="shipping_state"
                              >
                                State
                              </Sort>
                            </th>
                            <th scope="col">
                              <Sort
                                onClick={() => handleSort(sort, 'shipping_zip', setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="shipping_zip"
                              >
                                Zip Code
                              </Sort>
                            </th>
                            <th scope="col">
                              <Sort
                                onClick={() => handleSort(sort, 'shopify_created_at', setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="shopify_created_at"
                              >
                                Date
                              </Sort>
                            </th>
                            <th scope="col">
                              <Sort
                                onClick={() => handleSort(sort, 'order_status', setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="order_status"
                              >
                                Order Status
                              </Sort>
                            </th>
                            <th></th>
                            <th scope="col" className="pl-1 pr-1 text-center">
                              <Sort
                                onClick={() => handleSort(sort, 'order_status_updated_at', setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="order_status_updated_at"
                              >
                                Status Age
                              </Sort>
                            </th>
                            {(!user.isSupplier) && (
                              <th scope="col">
                                <Sort
                                  onClick={() => handleSort(sort, 'eta_for_oos', setSortOrder, setSort)}
                                  sortingOrder={sortOrder}
                                  column="eta_for_oos"
                                >
                                  ETA for OOS
                                </Sort>
                              </th>
                            )}
                            <th scope="col">
                              <Sort
                                onClick={() => handleSort(sort, 'financial_status', setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="financial_status"
                              >
                                Payment Status
                              </Sort> &nbsp;
                              <i id="financeStatus" className={`${financialStatusLock ? 'fa fa-lock' : 'fa fa-unlock'}`}
                                 aria-hidden="true" style={{ fontSize: 'small', cursor: 'pointer' }}
                                 onClick={() => setfinancialStatusLock(!financialStatusLock)}></i>
                              <UncontrolledTooltip
                                delay={0}
                                placement="top"
                                target={'financeStatus'}
                              >
                                Change Payment Status
                              </UncontrolledTooltip>
                            </th>
                            <th scope="col">
                              <Sort
                                onClick={() => handleSort(sort, 'global_fulfillment_status', setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="global_fulfillment_status"
                              >
                                Fulfillment
                              </Sort>
                            </th>
                            <th scope="col">
                              Fulfillment Days
                            </th>
                            <th>
                              Expected Delivery Date
                            </th>
                            <th>
                              RESHIP STATUS
                            </th>
                            <th scope="col">
                              DELIVERY METHOD
                            </th>
                            <th scope="col">
                              <Sort onClick={() => handleSort(sort, 'shipping', setSortOrder, setSort)}
                                    sortingOrder={sortOrder} column="shipping">
                                Shipping
                              </Sort>
                            </th>
                            <th scope="col">
                              <Sort onClick={() => handleSort(sort, 'total', setSortOrder, setSort)}
                                    sortingOrder={sortOrder} column="total">
                                Total
                              </Sort>
                            </th>
                            <th scope="col">
                              <Sort onClick={() => handleSort(sort, 'source', setSortOrder, setSort)}
                                    sortingOrder={sortOrder} column="source">
                                Sales Channel
                              </Sort>
                            </th>
                            <th scope="col">
                              Discount Code
                            </th>
                            <th scope="col">
                              Bulk Order
                            </th>
                            {user.isAdmin === true && <th scope="col" >WMS Processing Status</th>}

                            <th scope="col"></th>
                          </tr>
                          </thead>
                          <tbody>
                          {orders.map((order) => (
                            <tr key={order.id}>
                              <td className={'pr-3 pl-3'}>
                                <input
                                  type="checkbox"
                                  name={order.shopify_id}
                                  value={order.id}
                                  id={'checkbox_' + order.id}
                                  className={'orderCheckbox'}
                                  onChange={(e) => handleCheckbox(e)}
                                  defaultChecked={order?.checked}
                                />
                              </td>

                              <td className={'pr-1 pl-1'}>
                                <i
                                  className={
                                    'fas fa-receipt pl-1 pr-1 ' +
                                    order.label.color
                                  }
                                  data-toggle="tooltip"
                                  id={'tooltip_' + order.id}
                                  onClick={() => {{navigator.clipboard.writeText(order.tracking_number)}}}
                                ></i>
                                {
                                  (order.tracking_company && order.tracking_number) ?
                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target={'tooltip_' + order.id}
                                    >
                                      {order.tracking_company} : {order.tracking_number} <br/>
                                      Click to copy
                                    </UncontrolledTooltip>
                                    :
                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target={'tooltip_' + order.id}
                                    >
                                      No Label Generated Yet
                                    </UncontrolledTooltip>
                                }
                              </td>
                              {(user.isAdmin || user.isRetailer || user.isSupplier) && (
                                  <td>
                                    {order.internal_note && (user.isAdmin || user.isRetailer ||(user.isSupplier && order?.show_order_notes)) ? (
                                    <>
                                      <i
                                        className={'fa fa-sticky-note text-primary'}
                                        style={{ cursor: 'pointer' }}
                                        id={'popover_' + order.id}
                                      ></i>
                                      <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target={'popover_' + order.id}
                                      >
                                        {parse(order.internal_note)}
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <i
                                      className={'fa fa-sticky-note '}
                                      style={{ cursor: 'pointer' }}
                                      id={'popover_' + order.id}
                                    ></i>
                                  )}
                                  {user.isAdmin && (
                                    <UncontrolledPopover
                                      placement="bottom"
                                      width={'300px'}
                                      target={'popover_' + order.id}
                                      trigger="legacy"
                                      disabled={order.order_managed_by}
                                    >
                                      <PopoverHeader>
                                        Internal Notes
                                      </PopoverHeader>
                                      <PopoverBody className={''}>
                                        <Form
                                          onSubmit={(e) =>
                                            handleInternalNotes(
                                              e,
                                              order.id,
                                              order.internal_note
                                            )
                                          }
                                        >
                                            <textarea
                                              name="inote"
                                              onChange={(e) =>
                                                (inputNotes.current = [
                                                  e.target.value,
                                                  order.id,
                                                ])
                                              }
                                              rows={10}
                                              style={{ width: '100%' }}
                                              defaultValue={order.internal_note}
                                            ></textarea>
                                          <Button color={'success'} disabled={notesLoading}>
                                            Add note
                                            {notesLoading && <Spinner size="sm" color="light"/>}
                                          </Button>
                                        </Form>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  )}
                                  {
                                    order.instructions_to_seller_name &&
                                    order.instructions_to_seller_name.match(/Instructions to seller/i) &&
                                    order.instructions_to_seller_value != '' &&
                                    (
                                      <>
                                        <i
                                          className={'fas fa-exclamation pl-1 pr-1 '}
                                          data-toggle="tooltip"
                                          id={'tooltipinfo_' + order.id}
                                          onClick={() => { navigator.clipboard.writeText(order.instructions_to_seller_value) }}
                                          style={{
                                            color: '#2dce89',
                                            marginLeft: '5px',
                                          }}
                                        ></i>
                                        <UncontrolledTooltip
                                          delay={0}
                                          placement="top"
                                          target={'tooltipinfo_' + order.id}
                                        >
                                          {order.instructions_to_seller_name} : {order.instructions_to_seller_value ? order.instructions_to_seller_value : 'No instructions!'}
                                          <br/>
                                          Click to copy
                                        </UncontrolledTooltip>
                                      </>
                                    )
                                  }
                                </td>
                              )}

                              <td>
                                <Age data={order.age_order}>
                                  {order.age_order.label}
                                </Age>
                              </td>
                              <td>
                                {order.risk_level === 'Low' ?
                                  <Badge color="success">
                                    {order.risk_level}
                                  </Badge> : order.risk_level === 'Medium' ?
                                    <Badge color="primary">
                                      {order.risk_level}
                                    </Badge> :
                                    <Badge color="danger">
                                      {order.risk_level}
                                    </Badge>
                                }
                              </td>

                              <td className="flex show-only-on-hover-parent">
                                <i
                                  className={'fas fa-copy pl-1 pr-1 show-only-on-hover'}
                                  data-toggle="tooltip"
                                  id={'tooltiporderid_' + order.id}
                                  onClick={(e) => copyOrderIdToClipboard(order.shopify_id)}
                                  style={{ cursor: cursor }}
                                ></i>
                                <a
                                  href={
                                    window.env.APP_URL + 'order/' + order.id
                                  }
                                  className="btn btn-link p-0"
                                >
                                  {order.shopify_id}
                                </a>
                                {user.isAdmin &&
                                  order.order_status !== 'CLOSED' &&
                                  order.parent == null &&
                                  (!order.is_parent) &&
                                  (!order.order_managed_by) &&
                                  (
                                    <a
                                      href="javascript:void(0)"
                                      onClick={handleSplitOrder.bind(
                                        this,
                                        order
                                      )}
                                    >
                                      <GiSplitArrows size={'1rem'}/>
                                    </a>
                                  )
                                }

                              </td>
                              <td>
                                {order.items.map((product) => (
                                  <div key={product.id + '_prod_' + order.id}>
                                    {!product.hidden_refunded && (
                                      <div
                                        key={product.id + '_prod_' + order.id}
                                        className="row my-2 d-flex align-items-center"
                                        style={{ width: '20rem' }}
                                      >
                                        <div className="col-2 pr-0">
                                          {product.image && product.show ? (
                                            <img
                                              src={product.image}
                                              className="img-thumbnail rounded-0"
                                              width="100%"
                                            />
                                          ) : (
                                            <img
                                              src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                              className="img-thumbnail rounded-0"
                                              width="100%"
                                            />
                                          )}
                                        </div>
                                        <div className="col-10">
                                          {product.show ? (
                                            <>
                                              <a
                                                href={
                                                  window.env.APP_URL +
                                                  'product/' +
                                                  product.product_id
                                                }
                                              >
                                                  <span className="font-weight-bolder">
                                                    {product.quantity}x
                                                  </span>
                                                <span className="text-wrap">
                                                    {' '}
                                                  {product.name}
                                                  </span>
                                              </a>
                                              <div>
                                                {product.mapped_sku?.status ? (
                                                  <>
                                                        <span
                                                          className="text-wrap"
                                                          style={{
                                                            color: 'green',
                                                            fontWeight:
                                                              'lighter',
                                                          }}
                                                        >
                                                          SKU: {product.sku}
                                                        </span>
                                                    <div>
                                                          <span
                                                            className="text-wrap"
                                                            style={{
                                                              color: 'grey',
                                                              fontWeight:
                                                                'lighter',
                                                            }}
                                                          >
                                                            Quantity:{' '}
                                                            {product.mapped_sku?.quantity}
                                                          </span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <a
                                                    className="text-wrap"
                                                    id={`tooltip_sku_${order.id}_${product.id}`}
                                                    onClick={
                                                      user.isAdmin || user.isRetailer ?
                                                        () => {
                                                          order.assignee != '-' && !order.order_managed_by &&
                                                          handleOpenSKUMappingModal(
                                                            product.sku,
                                                            product.name,
                                                            order.assignee
                                                          )
                                                        }
                                                        : undefined
                                                    }
                                                    style={{
                                                      color: 'red',
                                                      fontWeight: 'lighter',
                                                      cursor: cursor,
                                                    }}
                                                  >
                                                    SKU: {product.sku}
                                                    <UncontrolledTooltip
                                                      delay={0}
                                                      placement="bottom"
                                                      target={`tooltip_sku_${order.id}_${product.id}`}
                                                    >
                                                      Retailer SKU not linked
                                                    </UncontrolledTooltip>
                                                  </a>
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                                <span className="font-weight-bolder">
                                                  {product.quantity}x
                                                </span>
                                              <span className="text-wrap">
                                                  {' '}
                                                OTHER PRODUCT
                                                </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </td>
                              <td className="pl-1 pr-1 ">
                                {/* 6193203826 - Block order changes on WMS Orders*/}
                                <AssigneeSelect
                                  data={null}
                                  updateOrderStatus={updateOrderStatus}
                                  orderId={order.id}
                                  stateOrder={order.shipping_state}
                                  disabled={order.order_managed_by}
                                >
                                  {order.assignee}
                                </AssigneeSelect>
                              </td>
                              <td>
                                <a
                                  target={'_blank'}
                                  href={
                                    'mailto:' +
                                    order.email +
                                    '?subject=Your order ' +
                                    order.shopify_id
                                  }
                                >
                                  {order.billing_name}
                                </a>
                                {user.isAdmin &&
                                  (order.channel_id == 40 || !order.shopify_api_id) &&
                                  (
                                    <a
                                      onClick={handleUpdateOrder.bind(
                                        this,
                                        order
                                      )}
                                      style={{ marginLeft: '5px' }}
                                    >
                                      <i className="fas fa-edit text-center" style={{ cursor: 'pointer' }}></i>
                                    </a>
                                  )}
                                {user.isAdmin &&
                                  //  order.domain is domain name of the store to which that order belongs to
                                  order.domain &&
                                  order.shopify_api_id &&
                                  order.channel_id != 40 &&
                                  (
                                    <a
                                      href={'https://' + order.domain + '/admin/orders/' + order.shopify_api_id}
                                      target="_blank"
                                      style={{ marginLeft: '5px' }}
                                    >
                                      <i className="fas fa-edit text-center" style={{ cursor: 'pointer' }}></i>
                                    </a>
                                  )}
                                {(user.isAdmin && order.emailorderforcustomer_set.length > 0) &&
                                  <a style={{ marginLeft: '5px' }}>
                                    <i className="far fa-envelope"> x{order.emailorderforcustomer_set.length}</i>
                                  </a>
                                }
                              </td>

                              <td>
                                {order.shipping_state}
                              </td>
                              <td>
                                {order.shipping_zip}
                              </td>
                              <td>
                                <Moment format="MM/D/YYYY" withTitle>
                                  {order.shopify_created_at}
                                </Moment>
                              </td>
                              <td className="pl-1 pr-1" title={order.order_status}>
                                {user.isSupplier ?
                                  <Button
                                    color={'white'}
                                    className={`text-primary text-left border-primary px-2 py-1 ${getOrderStatusColor(order.order_status)}`}
                                    style={{
                                      width: '130px',
                                      overflow: 'hidden',
                                      pointerEvents: 'none',
                                    }}
                                  >
                                    {order.order_status}
                                  </Button>
                                  :
                                  // 6193203826 - Block order changes on WMS Orders*/}
                                  <OrderStatus
                                    data={status}
                                    order={order.id}
                                    status={order.order_status}
                                    updateOrderStatus={updateOrderStatus}
                                    productCategory={order.items}
                                    disabled={order.order_managed_by}
                                  >
                                    {order.order_status}
                                  </OrderStatus>
                                }
                              </td>
                              <td>
                                {handleEmptyVariable(order.email) || handleEmptyVariable(order.phone) ?
                                  <a
                                    target={'_blank'}
                                    href={handleEmptyVariable(order.email)
                                      ? window.env.ORDER_STATUS_PAGE_URL +
                                      '?email=' + convertSHA256(order.email) +
                                      '&order=' + order.shopify_id?.replace('#', '').replace('MG', '')
                                      : window.env.ORDER_STATUS_PAGE_URL +
                                      '?phone=' + convertSHA256(order.phone) +
                                      '&order=' + order.shopify_id?.replace('#', '').replace('MG', '')
                                    }
                                  >
                                    <i className={'fas fa-link'}></i>
                                  </a>
                                  : <i className={'fas fa-link'}></i>
                                }
                              </td>
                              <td className="pl-1 pr-1 text-center">
                                <Age data={order.order_status_age_react}>
                                  {order.order_status_age_react.label}
                                </Age>
                              </td>
                              {(!user.isSupplier) && (
                                <td>
                                  <EAT_OOS
                                    className={'form-control form-control-sm border-light border order_eta_for_oos'}
                                    eta={order.eta_for_oos}
                                    order={order.id}
                                    order_managed_by={order.order_managed_by}
                                  />
                                </td>
                              )}
                              <td className="pl-1 pr-1 text-center">
                                {/* 6193203826 - Block order changes on WMS Orders*/}
                                <OrderPaymentStatus
                                  data={financeStatus}
                                  status={order.financial_status}
                                  order={order.id}
                                  updateOrderStatus={updateOrderStatus}
                                  financialStatusLock={financialStatusLock}
                                  orderManagedLock={order.order_managed_by}
                                >
                                  {order.financial_status}
                                </OrderPaymentStatus>
                              </td>
                              <td>
                                <Fulfillment>
                                  {order.global_fulfillment_status}
                                </Fulfillment>
                              </td>
                              <td>
                                {order.total_fulfillment_days}
                              </td>
                              <td>
                                {order.expected_delivery_date == null ? '-' :
                                  <Moment format="MM/D/YYYY" withTitle>
                                    {order.expected_delivery_date}
                                  </Moment>
                                }
                              </td>
                              <td>
                                {
                                  order.reship_status ? order.reship_status : '-'
                                }
                              </td>
                              <td>
                                  <span
                                    className={
                                      'badge ' + order.shipping_method.color
                                    }
                                  >
                                    {order.shipping_method.label}
                                  </span>
                              </td>
                              <td>${order.shipping}</td>
                              <td>
                                ${order.total}
                                {user.isAdmin &&
                                  order.channel != 'subscriptions' &&
                                  order.store_id == 1 &&
                                  order.order_status != 'CANCELLED' &&
                                  !order.order_managed_by && (
                                    <a
                                      href="javascript:void(0)"
                                      onClick={handleCancelOrder.bind(
                                        this,
                                        order
                                      )}
                                      style={{ marginLeft: '5px' }}
                                    >
                                      <i className="fas fa-undo fa-1x"/>
                                    </a>
                                  )}
                                {user.isAdmin &&
                                  order.domain &&
                                  order.shopify_api_id &&
                                  order.channel != 'subscriptions' &&
                                  order.store_id != 1 &&
                                  order.order_status != 'CANCELLED' &&
                                  !order.order_managed_by && (
                                    <a
                                      href={'https://' + order.domain + '/admin/orders/' + order.shopify_api_id}
                                      target="_blank"
                                      style={{ marginLeft: '5px' }}
                                    >
                                      <i className="fas fa-undo fa-1x"/>
                                    </a>
                                  )}
                              </td>
                              <td>{order.source}</td>
                              <td>{order.discount_code ? order.discount_code : '-'}</td>
                              <td>{order.bulk_order_title ?? '-'}</td>
                              {user.isAdmin === true && <td scope="col" >
                                {order.wms_processing_status ?
                                  <span style={{'color': 'green'}}>{order.wms_processing_status}</span> :
                                  <span style={{'color': 'red'}}>no status</span>
                                }
                              </td>}
                              {(user.isAdmin || user.isRetailer) && (
                                <td className="text-right">
                                  <UncontrolledDropdown>
                                    {/* 6193203826 - Block order changes on WMS Orders*/}
                                    <DropdownToggle
                                      className="btn text-primary"
                                      role="button"
                                      size="sm"
                                      color="white"
                                      onClick={(e) => e.preventDefault()}
                                      hidden={order.order_managed_by}
                                    >
                                      <i className="fas fa-ellipsis-v text-dark"></i>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      right
                                      positionFixed={true}
                                      className="dropdown-menu-arrow"
                                      modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                                    >
                                      {user.isAdmin && (
                                        <DropdownItem
                                          onClick={(e) => handleOpenEmailModal([order])}
                                        >
                                          <i className="fas fa-envelope"/> E-mail customer
                                        </DropdownItem>
                                      )}
                                      <DropdownItem
                                        disabled={!!(order.is_fulfilled || order.assignee === '-')}
                                        onClick={(e) => handleFulfillmentModal([order])}
                                      >
                                        <i className=" fas fa-shipping-fast fa-fw"/> Create Manual Fulfillment
                                      </DropdownItem>
                                      {user.isAdmin &&
                                        <>
                                          <DropdownItem
                                            onClick={(e) => handleDoordashDelivery(order)}
                                          >
                                            <i className="fas fa-truck"/> Create Doordash Delivery
                                          </DropdownItem>
                                        </>
                                      }
                                      {/* Allow retailer assignment from orders page for Admin only and if shiiping_state is not null */}
                                      {user.isAdmin && order.shipping_state &&
                                        (<DropdownItem
                                            onClick={(e) => retailerAssignment(order)}>
                                            <i className="fas fa-warehouse"/> Retailer Assignment
                                          </DropdownItem>
                                        )}
                                      {user.isAdmin &&
                                        <DropdownItem
                                          disabled={order.shopify_id.includes('-R') ? true : false}
                                          onClick={(e) => handleOpenReshipModal(order)}
                                        >
                                          <i className="fas fa-truck"/> Reship
                                        </DropdownItem>
                                      }
                                      {user.isAdmin &&
                                        <DropdownItem
                                          onClick={(e) => handleRestockingFee(order)}
                                        >
                                          <i className="fas fa-dollar-sign"/> Charge Reshipping Fee
                                        </DropdownItem>
                                      }
                                      {user.isAdmin &&
                                        <DropdownItem
                                          href="#"
                                          onClick={(e) => handleOpenRetailerEmail([order])}
                                        >
                                          <i className="fas fa-envelope"/> E-mail Retailer
                                        </DropdownItem>
                                      }
                                      {user.isAdmin &&
                                        <DropdownItem
                                          href="#"
                                          onClick={(e) => handleBulkNotes([order])}
                                        >
                                          <i className="fas fa-envelope"/>Add Bulk Notes
                                        </DropdownItem>
                                      }
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              )}
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                        <PagePagination pagination={pagination} handlePagination={handlePagination} page={page}
                                        totalCount={totalOrders}/>
                      </>
                    )}
                  </Card>
                </div>
              )}
            </Row>
          </div>

          {openModal.overrideRetailer &&
            <ProductsSearchForm
              url={'retailer-assignment-product-list/'}
              isOpen={openModal.overrideRetailer}
              toggle={() => setOpenModal({ overrideRetailer: !openModal.overrideRetailer })}
              loading={isLoading}
            formData={productsSearchFormData}
            />
            
        }
        </Container>
      ) : null}
    </>
  );
};

export default OrderPageListing;
