import React, { useContext, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { api } from "../../services/api";
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import Select from "react-select";

const BulkSkuModule = props => {
  const [barcartSku, SetBarcartSku] = useState([]);
  const [modal, setModal] = useState(props.isOpen);
  const [idStagingSku, setIdStagingSku] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    setModal(props.isOpen)
    setIdStagingSku(props.stagingRecords)
  }, [props.isOpen])

  const handleConfirm = () => {
    let ids = idStagingSku.map(stagingRecords => parseInt(stagingRecords.id));
    const data = {
      stage_inventory_id: ids,
      variant_sku: barcartSku.value,
    };
    setLoading(true);
    api.post("/bulk-sku-mapping", data)
      .then(response => {
        setLoading(false);
        if (response?.data?.statusCode === 200) {
          addToast('Saved Successfully', { appearance: 'success', autoDismiss: true });
          window.location.href = '/retailer-staging';
        } else {
          let message = "Something went wrong!";
          if (response.data.message) {
            message = response.data.message;
          }
          addToast(message.replace(/(?:\r\n|\r|\n)/g, ("\n")), { appearance: 'error', autoDismiss: true, autoDismissTimeout: 30000 });
        }
        setModal(props.onExit);
      }).catch(error => {
        setLoading(false);
        addToast(error, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 30000 });
        console.error(error);
      });
  };

  return (
    <>
      {modal &&
        <Modal isOpen={modal} toggle={toggle} onExit={props.onExit}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">Bulk SKU Update</span>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="order_status">Flaviar Checkout SKU</Label>
                <Select
                  className="basic-single form-control m-0 p-0 border-0"
                  classNamePrefix="select"
                  placeholder={"Select Flaviar Checkout SKU"}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="order_status"
                  options={props.options}
                  onChange={(e) => SetBarcartSku(e)}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit" disabled={loading} onClick={handleConfirm}>
                Confirm
                {loading && <Spinner size="sm" color="light" />}
              </Button>
            </ModalFooter>
        </Modal>}
    </>
  )
}

export default BulkSkuModule;