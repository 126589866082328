/*
    Monday Task ID - 6322428226
    Author - Akshay Vishwakarma
*/
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
function DeleteModel({ modalIsOpen, setModalIsOpen, onConfirmation, name, id }) {
    const toggleModel = () => {
        setModalIsOpen(!modalIsOpen);
    };
    return (
        <Modal isOpen={modalIsOpen} toggle={toggleModel}>
            <ModalHeader className="bg-danger text-white">
                <span className="text-white h3">Confrim Delete</span>
            </ModalHeader>
            <ModalBody>Are you sure you want to delete {name} ?</ModalBody>
            <ModalFooter>
                <Button onClick={toggleModel}>Cancel</Button>
                <Button
                    className="bg-danger text-white"
                    onClick={() => {
                        onConfirmation(id);
                        // toggleModel();
                    }}>
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default DeleteModel;