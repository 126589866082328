import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from "chart.js";
import Spinner from "../../Spinner";
import { api } from "services/api";
import { getParams } from "utils/commonFunctions";
import axios from "axios";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: { title: { display: false, }, ticks: { color: "black", }, },
    x: { ticks: { color: "black", autoSkip: true, }, grid: { display: false, } },
  },
  plugins: { legend: { display: false, }, title: { display: false, }, tooltip: { mode: "index", intersect: false, }, },
  hover: { mode: "nearest", intersect: false, },
};

export default function BarChart({ url, filters }) {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const cancelToken = React.useRef(undefined);

  useEffect(() => {
    setIsLoading(true);
    let new_params = getParams(filters);
    if (cancelToken.current !== undefined) {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }
    cancelToken.current = axios.CancelToken.source();
    api.get(url, { params: new_params, cancelToken: cancelToken.current.token, })
      .then((response) => {
        let labels = [];
        let data = [];
        if (response.status === 200 && response?.data) {
          labels = response.data?.days.map((item) => item.day);
          data = response.data?.days.map((item) => item.orders);
        }
        setIsLoading(false);
        setChartData({ labels, data });
      })
      .catch((error) => { console.error(error); });
  }, [filters]);

  return (
    <>
      {isLoading ? (<Spinner />) : (
        <div className="chart">
          <Bar options={options}
            data={{
              labels: chartData?.labels,
              datasets: [{ label: "Orders", type: "bar", barThickness: 12, borderRadius: 20, data: chartData?.data, backgroundColor: "#fb6340", },],
            }} />
        </div>)}
    </>);
}
