/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { AuthProvider } from "contexts/AuthContext";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { ToastProvider } from 'react-toast-notifications';
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import {ComponentProvider} from "./contexts/ComponentContext";
import "assets/css/extra-styles.css";

Sentry.init({
    dsn: "https://b739acd269f344048642563763d7e782@o1350402.ingest.sentry.io/6630170",
    integrations: [new Integrations.BrowserTracing()],
});

const setting = {
  color: {
    theme: "#3669C9"
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } }
    ]
  }
};
    ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <ToastProvider>
        <Switch>
            <ComponentProvider>
              <Route path="/" render={(props) => <AdminLayout {...props} />} />
            </ComponentProvider>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/admin/order" />
        </Switch>
      </ToastProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
