// Custom useFilter hook, to make sure filter names remain constant acrss whole app
// Currently its being used by Order + Dashboard page

import React from "react";
import useCustomState from "./useCustomState";

// Key : Value Pairs
// Value must match with filter object key
// Key indicates => className suffix
// Valye => filter object of filter state

export const prefixAndFilterMapping = {
    "state": "states",
    "assignee": "assignees",
    "risk_level": "risk_level",
    "product": "products",
    "brand": "brands",
    "shippingChargebackBrands": "shippingChargebackBrands",
    "channels": "channels",
    "typeDiscount": "typeDiscounts",
    "fulfillment": "fulfillments",
    "product_type": "productTypes",
    "productCategory": "productCategory",
    "productNames": "productNames",
    "locationIds": "locationIds",
    "discount_code": "discountCodes",
    "sales_channel": "channels",
    "shippingRate": "shippingRate",
    "cities": "cities",
    "customer_states": "customer_states",
    "accepts_marketing": "accepts_marketing",
    "search": "search",
    "tracking_statuses": "tracking_statuses",
    "tracking_companies": "tracking_companies",
    "billingStatuss": "billingStatuss",
    // UTM Filters
    "utm": "utms",
    "utm_campaign": "utmsCampaign",
    "utm_medium": "utmsMedium",

    // order page reship_status filter
    "reship_status": "reshipStatuses",

    // Discount Code Chargebacks Filters
    "discountCodeBillingStatus": "discountCodeBillingStatus",
    "discountCodeType": "discountCodeType",
    "invoiceNumbers": "invoiceNumbers",
    "discountCodeBrand": "discountCodeBrand",
    "invoiceStatus": "invoiceStatus",
    "billableUser": "billableUser",
    "inventoryStatus": "inventoryStatus",
    //BCD - 4275
    "bulk_order": "bulkOrders",

    // Users Page Filters
    "userRoles": "userRoles",
    "userStatus": "userStatus",
    "retailerInventory": "retailerInventory",

    // Manual Child Order Page Filters
    "childOrderType" : "childOrderType",
    "batchStatus" : "batchStatus",
    // Monday Task : 6530680655 - Payouts(Billing) Page URL Params
    "payoutStatus" : "payoutStatus",
    "orderCity" : "orderCity",
    "orderState" : "orderState",
    "orderZipCodes": "orderZipCodes",
    "financial_status" : "financial_status",
    "order_status" : "order_status",
    // Pre Order filter for Dashboard and orders page
    "preOrders" : "preOrders",
    // Subscription Page Filter
    "plan": "plan",
    "status": "status", // 7196353058 - Subscription Page Status Filter
    //6509739352 - Store Filter on Shipping and discount code chargebacks
    "store" : "store",
}

const useFilter = ({
    startDate,
    endDate,
    tracking_statuses,
    chargebackStartDate,
    chargebackEndDate,
    invoiceStartDate,
    invoiceEndDate,
    dataSubmittedAtStart,
    dataSubmittedAtEnd,
    InvoiceShippingStartDate,
    InvoiceShippingEndDate,
    InvoiceDiscountCodeChargebackStartDate,
    InvoiceDiscountCodeChargebackEndDate,
    customerStartDate,
    customerEndDate,
    discountCodeChargebackStartDate,
    discountCodeChargebackEndDate
}) => {
    let tempStartDate = startDate ? startDate : null;
    let tempEndDate = endDate ? endDate : null;
    let tempTrackingStatuses = tracking_statuses ? tracking_statuses : [];
    let tempChargebackStartDate = chargebackStartDate ? chargebackStartDate : null;
    let tempChargebackEndDate = chargebackEndDate ? chargebackEndDate : null;
    // 6367053025 - Generic Date Filter bug changes- Start
    let tempInvoiceShippingEndDate = InvoiceShippingEndDate ? InvoiceShippingEndDate : null;
    let tempInvoiceDiscountCodeChargebackEndDate = InvoiceDiscountCodeChargebackEndDate ? InvoiceDiscountCodeChargebackEndDate : null;
    let tempCustomerEndDate = customerEndDate ? customerEndDate : null;
    let tempDiscountCodeChargebackEndDate = discountCodeChargebackEndDate ? discountCodeChargebackEndDate : null;
    let tempInvoiceStartDate = invoiceStartDate ? invoiceStartDate : null;
    let tempInvoiceShippingStartDate = InvoiceShippingStartDate ? InvoiceShippingStartDate : null;
    let tempInvoiceDiscountCodeChargebackStartDate = InvoiceDiscountCodeChargebackStartDate ? InvoiceDiscountCodeChargebackStartDate : null;
    let tempCustomerStartDate = customerStartDate ? customerStartDate : null;
    let tempDiscountCodeChargebackStartDate = discountCodeChargebackStartDate ? discountCodeChargebackStartDate : null;
    // 6367053025 - Generic Date Filter bug changes- End
    let tempInvoiceEndDate = invoiceEndDate ? invoiceEndDate : null;
    // Manual Child Order Date Submitted Filter
    let tempDataSubmittedAtStart = dataSubmittedAtStart ? dataSubmittedAtStart : null;
    let tempDataSubmittedAtEnd = dataSubmittedAtEnd ? dataSubmittedAtEnd : null;

    const [filter, setFilter, clearFilter] = useCustomState({
        search: "",
        assignees: [],
        risk_level: [],
        brands: [],
        invoiceStatus: [],
        states: [],
        fulfillments: [],
        fulfillmentStartDate: null,
        fulfillmentEndDate: null,
        financialStatus: [],
        statuses: [],
        labels: [],
        discountCodes: [],
        channels: [],
        shippingRate: [],
        referringSites: [],
        inventory_location: [],
        user: [],
        payoutStatus: [],
        tracking_companies: [],
        tracking_statuses: tempTrackingStatuses,
        locationIds: [],
        discountCodeType: [],
        billableUser: [],
        invoiceNumbers: [],
        discountCodeBillingStatus: [],
        discountCodeBrand: [],
        reshipStatuses: [],
        // Product filters
        products: [],
        productTypes: [],
        productCategory: [],
        productNames: [],
        sku: [],
        // Date filters
        startDate: tempStartDate,
        endDate: tempEndDate,
        invoiceEndDate: tempInvoiceEndDate,
        invoiceStartDate: tempInvoiceStartDate,
        InvoiceShippingStartDate: tempInvoiceShippingStartDate,
        InvoiceDiscountCodeChargebackStartDate: tempInvoiceDiscountCodeChargebackStartDate,
        customerStartDate: tempCustomerStartDate,
        discountCodeChargebackStartDate: tempDiscountCodeChargebackStartDate,
        // Chargebacks Date filters => This is used in Discount Code Chargebacks page.
        chargebackStartDate: tempChargebackStartDate,
        chargebackEndDate: tempChargebackEndDate,
        InvoiceShippingEndDate: tempInvoiceShippingEndDate,
        customerEndDate: tempCustomerEndDate,
        discountCodeChargebackEndDate: tempDiscountCodeChargebackEndDate,
        InvoiceDiscountCodeChargebackEndDate: tempInvoiceDiscountCodeChargebackEndDate,
        // UTM Filters
        utms: [],
        utmsMedium: [],
        utmsCampaign: [],
        // Min max filters
        bottleMax: [],
        bottleMin: [],
        StatusDayMax: [],
        StatusDayMin: [],
        OrderDayMin: [],
        OrderDayMax: [],
        orderCount: [],
        customer_states: [],
        accepts_marketing: [],
        cities: [],
        urlParamsProcessed: false,
        //It indicates the selected table row checkboxes.
        selected: [],
        inventoryStatus: [],
        bulkOrders: [], //BCD - 4275
        // User Page Filter
        userRoles: [],
        userStatus: [],
        retailerInventory: [],
        // Manual Child Orders
        childOrderType: [],
        batchStatus: [],
        dataSubmittedAtStart: tempDataSubmittedAtStart,
        dataSubmittedAtEnd: tempDataSubmittedAtEnd,
        // Pre Order filter for Dashboard and orders page
        preOrders: [],
        // Subscription Page Filter
        plan: [],
        //6509739352 - Store Filter on Shipping and discount code chargebacks
        store: [],
        status: [], // 7196353058 - Subscription Page Status Filter
    });

    return [filter, setFilter, clearFilter];
};

export default useFilter;
