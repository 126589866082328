import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormText, Label, Form, FormGroup } from 'reactstrap';
import { api } from 'services/api';
import './checkMark.css'
import { ReactComponent as CheckMark } from '../../assets/svg/checkMark.svg';

const storeLocatorCSSFilePath = window.env.WEB_WIDGET_BASE_URL + "/barcartInventoryLocator.css";
const storeLocatorJSFilePath = window.env.WEB_WIDGET_BASE_URL + "/barcartInventoryLocator.js";

const CreateCredentialsDialog = (args) => {

    const toggle = () => args.setModal(!args.modal);
    const [step, setstep] = React.useState("1")
    const [host, sethost] = React.useState("")
    const [generatedInventoryLocationCredentialsResponse, setgeneratedInventoryLocationCredentialsResponse] = React.useState({})

    const generateInventoryLocationCredentials = async () => {
        await api.post("product-locator-web-widget/key/", { host })
            .then((response) => response)
            .then((response) => {
                if (response.status === 200) {
                    setgeneratedInventoryLocationCredentialsResponse(response.data.result)
                }
            }).catch((err) => { console.log(err) });
        setstep("s")
    }
    
    return (
        <>
            {step === "1" ?
                <Modal isOpen={args.modal} toggle={toggle} {...args} size="lg" centered={true}>
                    <Form onSubmit={async (e) => {
                        e.preventDefault()
                        await generateInventoryLocationCredentials()
                    }}>
                        <FormGroup>
                            <ModalHeader toggle={toggle}><h2>Generate inventory location widget credentials</h2></ModalHeader>
                            <ModalBody>
                                <Input required onChange={(e) => sethost(e.target.value)} placeholder='Enter hostname '></Input>

                                <div className='p-1'>
                                    <FormText>
                                        For example if web widget is gonna be integrated on www.example.com/stores then enter example.com
                                    </FormText>
                                </div>

                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary">
                                    Proceed
                                </Button>
                                <Button color="secondary" onClick={toggle}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </FormGroup>
                    </Form>
                </Modal> : <ShowInventoryLocationGeneratedCredentials modal={args.modal} setModal={args.setModal} toggle={toggle} setstep={setstep} generatedInventoryLocationCredentialsResponse={generatedInventoryLocationCredentialsResponse} />
            }
        </>
    )
}
export default CreateCredentialsDialog

const ShowInventoryLocationGeneratedCredentials = (props) => {

    return (<>
        <Modal isOpen={props.modal} size="lg" centered={true}>
            <ModalBody className='pt-0'>
                <CheckMark />

                <h2 className='text-center mb-6'>Credentials generated successfully</h2>

                <h5>Copy-paste the stylesheet &lt;link&gt; into your &lt;head&gt; before all other stylesheets to loads of index.html</h5>
                <figure >
                    <pre>
                        <code class="language-html" data-lang="html">
                            <span style={{ color: "blue" }}>&lt;link rel="stylesheet" href="{storeLocatorCSSFilePath}"&gt;</span>
                        </code>
                    </pre>
                </figure>

                <h5 className='mt-2'>Copy-paste the js file &lt;src&gt; into your &lt;head&gt; of index.html</h5>
                <figure>
                    <pre >
                        <code class="language-html" data-lang="html">
                            <span style={{ color: "blue" }}>&lt;script type="module" crossorigin src="{storeLocatorJSFilePath}"&gt;</span>
                        </code>
                    </pre>
                </figure>


                <h5 className='mt-2'>Copy-paste the html tag anywhere in your frontend code to display Inventory Locator</h5>
                <figure>
                    <pre >
                        <code class="language-html" data-lang="html" style={{ color: "blue" }}>
                            &lt;store-locator <br></br>
                            api_key="{props.generatedInventoryLocationCredentialsResponse.api_key}" <br></br>
                            heading="Find a store" <br></br>
                            web_widget_id="{props.generatedInventoryLocationCredentialsResponse.web_widget_id}" <br></br>
                            user_id="{props.generatedInventoryLocationCredentialsResponse.user}"&gt; <br></br>
                            &lt;/store-locator&gt;
                        </code>
                    </pre>
                </figure>

                <h5><b>Note: Requests will be only allowded from domain : {props.generatedInventoryLocationCredentialsResponse.host}</b><br></br>
                <b>Download Inventory location widget integrations steps now, which contains your API Key. If you do not download the steps now, you will not be able to retrive these later on.</b>
                </h5>

                <Button className='p-2 mt-4' outline color="secondary" onClick={() => {
                    downloadInstructions('barcartWebWidgetInstructions.txt',
                        props.generatedInventoryLocationCredentialsResponse.api_key,
                        props.generatedInventoryLocationCredentialsResponse.web_widget_id,
                        props.generatedInventoryLocationCredentialsResponse.user,
                        props.generatedInventoryLocationCredentialsResponse.host)
                }}>
                    <i class='fas fa-download'>&nbsp;</i> Download Instructions
                </Button>
            </ModalBody>
            <ModalFooter>

                <Button outline color="primary" onClick={() => { props.setModal(false); props.setstep(1); }}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    </>)

}

const downloadInstructions = (filename, api_key, web_widget_id, user, host) => {

    let instructions =
    `\tStep 1 : Copy-paste the stylesheet <link> into your <head> before all other stylesheets loads, into of Entry point of your frontend project [index.html]
    \t\t<link rel="stylesheet" href="${storeLocatorCSSFilePath}">\n
    Step 2 : Copy-paste the js file <src> into your <head>, into of Entry point of your frontend project [index.html]
    \t\t<script type="module" crossorigin src="${storeLocatorJSFilePath}">\n
    Step 3 : Copy-paste the html tag given below anywhere in your frontend code to display Inventory Locator\n
    \t<store-locator
    \tapi_key="${api_key}" 
    \theading="Find a store"
    \tweb_widget_id="${web_widget_id}"
    \tuser_id="${user}"> 
    \t</store-locator>\n\n
    \tNote: Requests will be only allowded from domain : ${host}
    `
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(instructions));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}