import { CardComponent } from "../../../components/Dashboard/Widget";
import { Container, Row, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { api } from "services/api";

const Widget = ({ filters }) => {
  const [cardText, setCardText] = useState("Orders");
  const [orderStatus, setOrderStatus] = useState("");
  const [finacialStatus, setFinancialStatus] = useState("");

  useEffect(() => {
    // Fetching order status and payment status list from database to pass it from dashboard while navigating to order listing page
    api
      .get("/order/filter/status")
      .then((response) => {
        let orderStatus = "&statuses=";
        response.data.results.forEach(element =>{
          if (element["id"] !== "CANCELLED"){
            orderStatus = orderStatus + element["id"] + ",";
          }
        })
        setOrderStatus(orderStatus.slice(0,-1));
      })
      .catch((error) => console.log(error));

      api
      .get("/order/filter/financial-status")
      .then((response) => {
        let financialStatus = "&financialStatus=";
        response.data.results.forEach(element =>{
          if (element["id"] !== "refunded"){
            financialStatus = financialStatus + element["id"] + ",";
          }
        })
        setFinancialStatus(financialStatus.slice(0,-1));
      })
      .catch((error) => console.log(error));
  }, []);

  // Generating selected filters string to send from react dashboard to orders page in order
  // to match the count on dashboard orders card and orders page 
  let filter = "";
  let date_start = "startDate=" + String(filters["startDate"].format("YYYY-MM-DD"));
  let date_end = "&endDate=" + String(filters["endDate"].format("YYYY-MM-DD"));
  for (const key in filters) {
    let listFilter = [];
    if (key != "startDate" && key != "endDate" && filters[key]?.length > 0) {
      for (var i = 0; i < filters[key].length; i++) {
        if (!listFilter.includes(filters[key][i]["value"])) {
          listFilter.push(filters[key][i]["value"]);
        }
      }
      listFilter = "&" + key + "=" + listFilter.join(",");
    }
    filter = filter + listFilter;
  }
  filter = "orders?" + date_start + date_end + filter + orderStatus + finacialStatus;
  filter = filter.replace("?&", "&");

  return (
    <Container className="mt-3 p-0" fluid>
      <Row className={"row-cols-1 row-cols-lg-3 row-cols-md-2 px-xl-0 px-lg-0 px-md-0 px-sm-3 px-3"}>
        <Col className="m-0 p-3">
          <CardComponent title={"Sales"} name={"sales"} url={"/dashboard/react/sales/"} icon={"ni ni-money-coins"} backgroundColor={"orange"} filters={filters} />
        </Col>
        <Col className="m-0 p-3" onMouseEnter={() => setCardText("")} onMouseLeave={() => setCardText("Orders")}>
          <a href={filter} target="_blank"><CardComponent title={cardText} name={"orders"} url={"/dashboard/react/orders/"} icon={"ni ni-box-2"} backgroundColor={"primary"} filters={filters} /></a>
        </Col>
        <Col className="m-0 p-3">
          <CardComponent title={"Average Order"} name={"avg"} url={"/dashboard/react/avg-orders/"} icon={"ni ni-money-coins"} backgroundColor={"primary"} filters={filters} />
        </Col>
        <Col className="m-0 p-3">
          <CardComponent title={"Avg. Fulfillment Days"} name={"avg"} url={"/dashboard/react/avg-fulfillments/"} icon={"ni ni-money-coins"} backgroundColor={"primary"} filters={filters} />
        </Col>
        <Col className="m-0 p-3">
          <CardComponent title={"Items Sold"} name={"items"} url={"/dashboard/react/items/"} icon={"fas fa-wine-bottle"} backgroundColor={"green"} filters={filters} />
        </Col>
        <Col className="m-0 p-3">
          <CardComponent role="button" title={"Customers"} name={"customers"} url={"/dashboard/react/customers/"} icon={"ni ni-single-02"} backgroundColor={"info"} filters={filters}
            onClick={() => { window.location.href = window.env.APP_URL + 'customer/' }} />
        </Col>
      </Row>
    </Container>
  );
};

export default Widget;
