import React from 'react';
// This custom hooks, provide cleastState function
// to set default value as current value
const useCustomState = (initialValue = null) => {
    const [state, setState] = React.useState(initialValue);
    const clearState = () => {
        setState(initialValue);
    };
    return [state, setState, clearState];
};
export default useCustomState;