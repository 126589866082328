import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
import ConfirmationModal from '../Modal/ConfirmationModal'
import {AuthContext} from "../../contexts/AuthContext";

export const DoordashModal = ({ isOpen = false, order, onExit, handleRefresh }) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [orderId, setOrderId] = useState("");
  const [shopifyId, setShopifyId] = useState("");
  const [dropoffAddress1, setDropoffAddress1] = useState("");
  const [dropoffAddress2, setDropoffAddress2] = useState("");
  const [dropoffCity, setDropoffCity] = useState("");
  const [dropoffState, setDropoffState] = useState("");
  const [dropoffZip, setDropoffZip] = useState("");
  const [dropoffPhone, setDropoffPhone] = useState("");
  const [pickupAddress1, setPickupAddress1] = useState("");
  const [pickupAddress2, setPickupAddress2] = useState("");
  const [pickupCity, setPickupCity] = useState("");
  const [pickupState, setPickupState] = useState("");
  const [pickupZip, setPickupZip] = useState("");
  const [pickupPhone, setPickupPhone] = useState("");
  const { addToast } = useToasts();
  const {user} = useContext(AuthContext);
  const [modelIsOpen, setmodelIsOpen] = useState(false);

  //Only admin can edit the fields and rest user read only field
  const isFieldDisabled = !user.isAdmin;

  /**
   * show and hide the module alternatively with each click of the crate doordash button
   */
  const toggle = () => {
    setShowModal(!showModal);
  };

  /**
   * API call to create doordsah delivery
   */
  const handleDoordashDelivery = () => {
    let data = {
      order_id: orderId,
      shipping_address1: dropoffAddress1,
      shipping_address2: dropoffAddress2,
      shipping_city: dropoffCity,
      shipping_state: dropoffState,
      shipping_zip: dropoffZip,
      shipping_phone: dropoffPhone,
      business_address1: pickupAddress1,
      business_address2: pickupAddress2,
      business_city: pickupCity,
      business_state: pickupState,
      business_zip: pickupZip,
      business_phone: pickupPhone,
    };

    api
      .post("order/create-doordash-delivery", data)
      .then(() => {
        addToast("Doordash Delivery Created Successfully!", { appearance: "success", autoDismiss: true });

        // Call parent to refresh content
        if (handleRefresh) {
          handleRefresh();
        }
      }).catch((error) => {
        let message = "Something went wrong, please try again!!"
        if (error?.response?.data?.message) {
          message = error.response.data.message;
        };
        addToast(message, { appearance: "error" });
      });
  };

  // Open doordash module and set all the fields
  useEffect(() => {
    setShowModal(isOpen);
    if (isOpen && order) {
      setShopifyId(order.shopify_id);
      setOrderId(order.id);
      setDropoffAddress1(order.shipping_address1);
      setDropoffAddress2(order.shipping_address2);
      setDropoffCity(order.shipping_city);
      setDropoffState(order.shipping_state);
      setDropoffZip(order.shipping_zip);
      setDropoffPhone(order.shipping_phone);
      setPickupAddress1(order.pickup_address.pickup_address_1);
      setPickupAddress2(order.pickup_address.pickup_address_2);
      setPickupCity(order.pickup_address.pickup_city);
      setPickupState(order.pickup_address.pickup_state);
      setPickupZip(order.pickup_address.pickup_zip);
      setPickupPhone(order.pickup_address.pickup_phone);
    }
  }, [isOpen, order]);

  /**
   * Toggle Confirmation popup
   */
  const toggleModel = () => {
    setmodelIsOpen(!modelIsOpen);
  };

  /**
   * Open confirmation popup and close the Create Doordash Delivery module
   */
  const handalConfirm = () => {
    toggleModel();
    setShowModal(!showModal);
  };

  return (
    <>
      <ConfirmationModal
        modelIsOpen={modelIsOpen}
        setmodelIsOpen={setmodelIsOpen}
        onConfirmation={handleDoordashDelivery}
        modalBodyMessage="Are you sure you want to create Doordash delivery?"
      />
      {showModal &&
        <Modal size="lg" isOpen={showModal} toggle={toggle} onExit={onExit}>
          <ModalHeader toggle={toggle} className="bg-primary text-white">
            <span className="text-white h3">{`Create Doordash Delivery ${shopifyId}`}</span>
          </ModalHeader>
          <ModalBody>
            <Row xs="1" lg="3">
              <Col>
                <FormGroup>
                  <Label>Dropoff Address 1 *</Label>
                  <Input
                    id="dropoff_address1"
                    placeholder="Dropoff Address 1"
                    type="text"
                    value={dropoffAddress1}
                    onChange={(e) => setDropoffAddress1(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Dropoff Address 2</Label>
                  <Input
                    id="dropoff_address2"
                    placeholder="Dropoff Address 2"
                    type="text"
                    value={dropoffAddress2}
                    onChange={(e) => setDropoffAddress2(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Dropoff City *</Label>
                  <Input
                    id="dropoff_city"
                    placeholder="Dropoff City"
                    type="text"
                    value={dropoffCity}
                    onChange={(e) => setDropoffCity(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row xs="1" lg="3">
              <Col>
                <FormGroup>
                  <Label>Dropoff State *</Label>
                  <Input
                    id="dropoff_state"
                    placeholder="Dropoff State"
                    type="text"
                    value={dropoffState}
                    onChange={(e) => setDropoffState(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
              <Col> 
                <FormGroup>
                  <Label>Dropoff Zipcode *</Label>
                  <Input
                    id="dropoff_zipcode"
                    placeholder="Dropoff Zipcode"
                    type="text"
                    value={dropoffZip}
                    onChange={(e) => setDropoffZip(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Dropoff Phone Number *</Label>
                  <Input
                    id="dropoff_phone_number"
                    placeholder="Dropoff Phone Number"
                    type="text"
                    value={dropoffPhone}
                    onChange={(e) => setDropoffPhone(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br/>
            <Row xs="1" lg="3">
              <Col>
                <FormGroup>
                  <Label>Pickup Address 1 *</Label>
                  <Input
                    id="pickup_address1"
                    placeholder="Pickup Address 1"
                    type="text"
                    value={pickupAddress1}
                    onChange={(e) => setPickupAddress1(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Pickup Address 2</Label>
                  <Input
                    id="pickup_address2"
                    placeholder="Pickup Address 2"
                    type="text"
                    value={pickupAddress2}
                    onChange={(e) => setPickupAddress2(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Pickup City *</Label>
                  <Input
                    id="pickup_city"
                    placeholder="Pickup City"
                    type="text"
                    value={pickupCity}
                    onChange={(e) => setPickupCity(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row xs="1" lg="3">
              <Col>
                <FormGroup>
                  <Label>Pickup State *</Label>
                  <Input
                    id="pickup_state"
                    placeholder="Pickup State"
                    type="text"
                    value={pickupState}
                    onChange={(e) => setPickupState(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Pickup Zipcode *</Label>
                  <Input
                    id="pickup_zipcode"
                    placeholder="Pickup Zipcode"
                    type="text"
                    value={pickupZip}
                    onChange={(e) => setPickupZip(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Pickup Phone Number *</Label>
                  <Input
                    id="pickup_phone_number"
                    placeholder="Pickup Phone Number"
                    type="text"
                    value={pickupPhone}
                    onChange={(e) => setPickupPhone(e.target.value)}
                    disabled={isFieldDisabled}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <div className="text-center">
            <Button
              className="my-4"
              color="primary"
              type="submit"
              id="save_btn"
              onClick={handalConfirm}
            >
              Create Doordash Delivery
            </Button>
          </div>
        </Modal>
      }
    </>
  );
};
